import React, { useEffect } from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Menu from "../../components/UI/Menu";
import { Container, Video } from "./styles";
import Footer from "../../components/UI/Footer";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";
import courseshelp from "../../assets/videos/CoursesHelpUser.mp4";


const CoursesHelpUser = () => {
  useEffect(() => {
    
  }, []);
  
  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconAjuda}>AJUDA ACOMPANHAMENTO DAS FORMAÇÕES</Banner>
      <ContentBox>
        <Video controls>
          <source src={courseshelp} type="video/mp4" />
        </Video>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesHelpUser;