import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  EventTitle,
  EventDate,
  EventTime,
  EventDesc,
  EventEquipe,
  Text,
} from "./styles";

import { format, getHours, getMinutes } from "date-fns";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images } from "../../../constants";
import Banner from "../../../components/UI/Banner";

import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";
import ellipsis from "../../../utils/ellipsis";
import Visibility from "../../../components/UI/Visibility";

const ScheduleView = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const navigate = useNavigate();

  const [event, setEvent] = useState({ tips: [], documents: [] });

  const isEditor = (category) => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === category
    );
    return editor > -1 ? true : false;
  };

  const [loadEvent, loadEventInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/calendar/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if (response.data.length > 0) {
          setEvent({ ...response.data[0] });
        } else {
          if(user){
            navigate(`/agenda`)
          }
          else{
            navigate(`/login/redirect/*agenda*${id}`)
          }
        }
        console.log("SCH", response);
      }
    },
  });

  useEffect(() => {
    loadEvent({
      params: {
        id,
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loadEventInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconDocumentos}
        url={`/agenda/editar/${event.id}`}
        editorId={event.category}
      >
        {event.title && ellipsis(event.title, 20)}
      </Banner>
      <ContentBox>
        <EventTitle>{event?.title}</EventTitle>

        {event.eventDate && (
          <EventDate>
            <Text>Data: </Text>
            <Text>
              {format(new Date(parseInt(event?.eventDate)), "dd/MM/yyyy")}
            </Text>
          </EventDate>
        )}

        {event.begin && event.end && (
          <EventTime>
            <Text>Horário:</Text>
            <Text>
              {getMinutes(new Date(event?.begin)) ===
                getMinutes(new Date(event?.end)) &&
              getHours(new Date(event?.begin)) ===
                getHours(new Date(event?.end))
                ? format(new Date(event?.begin), "HH:mm")
                : `${format(new Date(event.begin), "HH:mm")} - ${format(
                    new Date(event.end),
                    "HH:mm"
                  )}`}
            </Text>
          </EventTime>
        )}

        <EventDesc>
          <Text>{event.description}</Text>
        </EventDesc>

        <EventEquipe>
          <Text>Equipe envolvida:</Text>
          <Text>{event.team}</Text>
        </EventEquipe>

        {isEditor(event.category) && (
          <Visibility visibility={event?.visibilities} align={"center"} />
        )}
        <CancelButton
          text="Voltar"
          onClick={() => {
            navigate(-1);
          }}
        />
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default ScheduleView;
