import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Menu from "../../components/UI/Menu";
import Footer from "../../components/UI/Footer";
import Banner from "../../components/UI/Banner";
import { images, icons } from "../../constants";
import "./styles.css";

import documentosAdd from "../../assets/videos/documentos-add.mp4";
import documentosDel from "../../assets/videos/documentos-del.mp4";
import documentosView from "../../assets/videos/documentos-view.mp4";
import agendaAdd from "../../assets/videos/agenda-add.mp4";
import agendaDel from "../../assets/videos/agenda-del.mp4";
import formacoesAdd from "../../assets/videos/formacoes-add.mp4";
import formacoesDel from "../../assets/videos/formacoes-del.mp4";
import formacoesView from "../../assets/videos/formacoes-view.mp4";
import avaliacoesAdd from "../../assets/videos/avaliacoes-add.mp4";
import avaliacoesDel from "../../assets/videos/avaliacoes-del.mp4";
import avaliacoesView from "../../assets/videos/avaliacoes-view.mp4";
import bibliotecaNews from "../../assets/videos/biblioteca-news.mp4";
import bibliotecaDel from "../../assets/videos/biblioteca-del.mp4";
import bibliotecaView from "../../assets/videos/biblioteca-view.mp4";
import videotecaAdd from "../../assets/videos/videoteca-add.mp4";
import videotecaDel from "../../assets/videos/videoteca-del.mp4";
import videotecaView from "../../assets/videos/videoteca-view.mp4";

const ManagerManual = () => {
  return (
    <div>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconAjuda}> MANUAL DO USUÁRIO - TÉCNICO</Banner>
      <ContentBox>
      <main class="help">
        <article>
          <h2 class="help accent center" id="documentos">Documentos</h2>
          <h3 class="help" id="documentos-add">Adicionar Documento</h3>

          <video class="help" controls>
            <source src={documentosAdd} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para adicionar itens à sua seção, clique no botão indicado <img class="help-inline-icons" src={icons.editBlack} alt="Editar" /></p>
          <p>Preencha os campos solicitados de acordo com a sua necessidade.</p>
          <p><strong>Dica:</strong> Quanto mais informações forem colocadas, melhor para o usuário identificar e buscar a
            informação desejada</p>
          <p>Você pode adicionar <strong>palavras-chave</strong> para facilitar a busca do documento.</p>
          <p>Lembre-se que após digitar o termo, aperte o <strong>ENTER</strong> para confirmar.</p>
          <p>Para anexar um arquivo, clique em <img class="help-inline-icons" src={images.iconClip} alt="Anexar" /><strong>Anexar
              Documento</strong></p>
          <p><strong>Visibilidade: </strong>selecione se o documento vai ficar <strong>Público</strong> ou
            <strong>Restrito</strong> para uma ou mais categorias</p>

          <br />
          <h3 class="help" id="documentos-del">Editar ou excluir documento</h3>

          <video class="help" controls>
            <source src={documentosDel} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para editar ou excluir documentos, a partir da listagem de documentos disponíveis, vá até o documento desejado
            e clique no botão Editar <img class="help-inline-icons" src={icons.editBlack} alt="Editar" /></p>
          <br />
          <h4>Editar:</h4>
          <p class="indent">Edite os campos de acordo com a sua necessidade e clique em <strong>Salvar</strong>.</p>
          <br />
          <h4>Excluir:</h4>
          <p class="indent">Para excluir o documento, clique em <img class="help-inline-icons" src={images.apagar} alt="apagar" /> e em seguida confirme a operação em <strong>OK</strong></p>
          
          <br />
          <h3 class="help" id="documentos-view">Visualizar documento</h3>

          <video class="help" controls>
            <source src={documentosView} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Os documentos são divididos em <strong>Documentos Gerais</strong>, <strong>Indicadores</strong>,
            <strong>Orientações Didáticas</strong></p>
          <p>Para ver a lista de documentos, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em &nbsp;
            <img class="help-inline-icons" src={icons.documentos_black} alt="formacoes" /> Documentos</p>
          <p>Para ver mais detalhes clique no título.</p>
          <p>Você também pode clicar no título da formação para ver mais detalhes.</p>

        </article>
        <br />
        <article>
          <h2 class="help accent center" id="agenda">Agenda</h2>
          <h3 class="help" id="agenda-add">Adicionar Evento</h3>

          <video class="help" controls>
            <source src={agendaAdd} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para adicionar eventos na agenda, clique no botão indicado <img class="help-inline-icons"
              src={icons.editBlack} alt="Editar" /></p>
          <p>Preencha os campos solicitados de acordo com a sua necessidade.</p>
          <p><strong>Dica: </strong>para escolher a data, você pode clicar nos números da roda de seleção, ou clique
            novamente na hora ou minuto para abrir a caixa de seleção.</p>
          <p>Escolha a visibiliade do documento para <strong>Público</strong> ou <strong>Restrito</strong> a uma ou mais
            categorias.</p>
          <p>Após você salvar, o evento aparecerá na listagem de eventos da Agenda na data escolhida.</p>
          <br />
          <h3 class="help" id="agenda-del">Editar ou excluir evento</h3>

          <video class="help" controls>
            <source src={agendaDel} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para editar ou excluir eventos na agenda, vá ao dia do evento desejado e clique no botão Editar 
            <img class="help-inline-icons" src={icons.editBlack} alt="Editar" /></p>
          <h4>Editar:</h4>
          <p class="indent">Edite os campos de acordo com a sua necessidade e clique em <strong>Salvar</strong>.</p>
          <h4>Excluir:</h4>
          <p class="indent">Para excluir o evento, clique em <img class="help-inline-icons" src={images.apagar}
              alt="apagar" /> e em seguida confirme a operação em <strong>OK</strong></p>
        </article>

        <br />
        <article>
          <h2 class="help accent center" id="formacoes">Formações</h2>
          <h3 class="help" id="formacoes-add">Adicionar formação</h3>

          <video class="help" controls>
            <source src={formacoesAdd} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para adicionar eventos de formações, clique no botão indicado <img class="help-inline-icons"
              src={icons.editBlack} alt="Editar" /></p>
          <p>Escolha se a formação vai ser <strong>Complementar</strong> ou <strong>Elementar</strong></p>
          <p>Preencha as demais informações solicitadas de acordo com a sua necessidade.</p>
          <p>Na seção Encontros, coloque as informações de data, hora e local da formação.</p>
          <p><strong>Dica: </strong>para escolher a data, você pode clicar nos números da roda de seleção, ou clique
            novamente na hora ou minuto para abrir a caixa de seleção.</p>
          <p>Para adicionar mais encontros, clique em <strong>+ Adicionar encontro</strong></p>
          <p>Você também pode adicionar Documentos, para isso, clique em <img class="help-inline-icons"
              src={images.iconClip} alt="Editar" /><strong>Anexar Documento</strong></p>
          <p>Escolha a visibiliade do documento para <strong>Público</strong> ou <strong>Restrito</strong> a uma ou mais
            categorias.</p>
          <p>Após você Salvar, a formação aparecerá na listagem de Inscrições Abertas.</p>
          <br />
          <h3 class="help" id="formacoes-del">Editar ou excluir formações</h3>

          <video class="help" controls>
            <source src={formacoesDel} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para editar ou excluir formações, a partir da lista de formações abertas, vá até a formação desejada e
            clique no botão Editar <img class="help-inline-icons" src={icons.editBlack} alt="Editar" /></p>
          <h4>Editar:</h4>
          <p class="indent">Edite os campos de acordo com a sua necessidade e clique em <strong>Salvar</strong>.</p>
          <h4>Excluir:</h4>
          <p class="indent">Para excluir o evento, clique em <img class="help-inline-icons" src={images.apagar}
              alt="apagar" /> e em seguida confirme a operação em <strong>OK</strong></p>
          <br />
          <h3 class="help" id="formacoes-view">Visualizar formações</h3>

          <video class="help" controls>
            <source src={formacoesView} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para ver a lista de formações, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em &nbsp;
            <img class="help-inline-icons" src={icons.formacoes_black} alt="formacoes" /> Formações</p>
          <p>Para ver mais detalhes, clique em <strong>+Leia Mais</strong>.</p>
          <p>Você também pode clicar no título da formação para ver mais detalhes.</p>
        </article>
        <br />
        <article>
          <h2 class="help accent center" id="avaliacoes">Avaliações</h2>
          <h3 class="help" id="avaliacoes-add">Adicionar avaliação</h3>

          <video class="help" controls>
            <source src={avaliacoesAdd} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para adicionar uma avaliação, clique no botão indicado <img class="help-inline-icons"
              src={icons.editBlack} alt="Editar" /></p>
          <p>Escolha o tipo de formação e as datas de início e fim.</p>
          <p>Preencha o título e qual a plataforma a ser utilizada.</p>
          <p>Você pode adicionar arquivos de <strong>Orientações </strong>ou de <strong>Material de Apoio </strong>clicando
            em <img class="help-inline-icons" src={images.iconClip} alt="Anexar" /><strong>Anexar</strong>.</p>
          <p>Escolha a visibiliade do documento para <strong>Público</strong> ou <strong>Restrito</strong> a uma ou mais
            categorias.</p>
          <p>Para confirmar, clique em <strong>Salvar</strong></p>
          <br />
          <h3 class="help" id="avaliacoes-del">Editar ou excluir avaliações</h3>

          <video class="help" controls autoplay loop muted>
            <source src={avaliacoesDel} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para editar ou excluir avaliações, a partir da lista de avaliações disponíveis, vá até o item desejado
            e clique no botão Editar <img class="help-inline-icons" src={icons.editBlack} alt="Editar" /></p>
          <h4>Editar:</h4>
          <p class="indent">Edite os campos de acordo com a sua necessidade e clique em <strong>Salvar</strong>.</p>
          <h4>Excluir:</h4>
          <p class="indent">Para excluir o evento, clique em <img class="help-inline-icons" src={images.apagar}
              alt="apagar" /> e em seguida confirme a operação em <strong>OK</strong></p>
          <br />
          <h3 class="help" id="avaliacoes-view">Visualizar avaliações</h3>

          <video class="help" controls>
            <source src={avaliacoesView} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para ver a lista de avaliações, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em &nbsp;
            <img class="help-inline-icons" src={icons.indicadores_black} alt="avaliacoes" /> Avaliações</p>
          <p>Para ver mais detalhes clique no título.</p>
        </article>

        <br />
        <article>
          <h2 class="help accent center" id="biblioteca">Biblioteca</h2>
          <h3 class="help" id="biblioteca-news">Cadastrar Novidades</h3>

          <video class="help" controls>
            <source src={bibliotecaNews} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para cadastrar uma novidade, clique no botão indicado <img class="help-inline-icons"
              src={icons.editBlack} alt="Editar" /> e depois em <strong>Cadastrar Novidades</strong></p>
          <p>Insira uma imagem de capa clicando em cima da imagem de exemplo.</p>
          <p><strong>Dica:</strong></p>
          <p class="indent">Caso queira, você pode cortar a imagem para ter um melhor ajuste.<br />
            Para isso, faça uma caixa de seleção na área que deseja manter e clique em <strong>Cortar</strong></p>
          <p>Após verificar que está tudo certo, clique em <strong>Salvar</strong>.</p>
          <p>Preencha o título e a descrição.</p>
          <p>Para confirmar, clique em <strong>Salvar</strong></p>
          <br />
          <h3 class="help" id="biblioteca-del">Editar ou excluir novidades</h3>

          <video class="help" controls>
            <source src={bibliotecaDel} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para editar ou excluir novidades, a partir da lista de novidades disponíveis, vá até a novidade desejada e
            clique no botão Editar <img class="help-inline-icons" src={icons.editBlack} alt="Editar" /></p>
          <h4>Editar:</h4>
          <p class="indent">Edite os campos de acordo com a sua necessidade e clique em <strong>Salvar</strong>.</p>
          <h4>Excluir:</h4>
          <p class="indent">Para excluir o evento, clique em <img class="help-inline-icons" src={images.apagar}
              alt="apagar" /> e em seguida confirme a operação em <strong>OK</strong></p>
          <br />
          <h3 class="help" id="biblioteca-view">Visualizar novidades</h3>

          <video class="help" controls>
            <source src={bibliotecaView} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para ver a lista de novidades, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em &nbsp;
            <img class="help-inline-icons" src={icons.biblioteca_black} alt="bilblioteca" /> Biblioteca</p>
          <p>Para ver mais detalhes, clique no título da novidade.</p>
        </article>
        <br />
        <article>
          <h2 class="help accent center" id="videoteca">Videoteca</h2>
          <h3 class="help" id="videoteca-add">Cadastrar vídeos</h3>

          <video class="help" controls>
            <source src={videotecaAdd} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para cadastrar um vídeo, clique no botão indicado <img class="help-inline-icons" src={icons.editBlack} alt="Editar" />
          </p>
          <p>Digite o título, descrição e palavras-chave.</p>
          <p><strong>Não esqueça!</strong> Após adicionar uma palavra-chave, aperte o <strong>ENTER</strong> para confirmar
          </p>

          <div class="help indent">
            <h4 class="accent--small">Adicionando Habilidades ou Orientações Didáticas</h4>
            <p>Para cadastrar Habilidades ou Orientações Didáticas relacionadas, vá ao campo de busca e começe a digitar
              (mínimo de 3 caracteres).</p>
            <p>Localize o item na listagem que aparece e clique em <img class="help-inline-icons" src={icons.iconAdd}
                alt="adicionar" /> para adicionar.</p>
            <p class="indent"><strong>Dica:</strong> você pode adicionar mais de uma habilidade ou orientação</p>

            <h4 class="accent--small">Removendo Habilidades ou Orientações Didáticas</h4>
            <p>Para remover, localize o item na listagem que aparece e clique em <img class="help-inline-icons"
                src={icons.iconDelete} alt="remover" /> para remover.</p>
          </div>

          <p>Para confirmar, clique em <strong>Salvar</strong></p>
          <br />
          <h3 class="help" id="videoteca-del">Editar ou excluir vídeos</h3>

          <video class="help" controls>
            <source src={videotecaDel} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para editar ou excluir um vídeo, busque pelo vídeo no campo de pesquisa (min. 3 caracteres), vá até o vídeo
            desejado e clique no botão Editar <img class="help-inline-icons" src={icons.editBlack} alt="Editar" /></p>
          <h4>Editar:</h4>
          <p class="indent">Edite os campos de acordo com a sua necessidade e clique em <strong>Salvar</strong>.</p>
          <h4>Excluir:</h4>
          <p class="indent">Para excluir o vídeo, clique em <img class="help-inline-icons" src={images.apagar}
              alt="apagar" /> e em seguida confirme a operação em <strong>OK</strong>.</p>

          <br />
          <h3 class="help" id="videoteca-view">Visualizar vídeos</h3>

          <video class="help" controls>
            <source src={videotecaView} type="video/mp4" />
            Seu navegador não suporta o vídeo nesse formato.
          </video>
          <p>Para ver a lista de vídeos, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img
              class="help-inline-icons" src={icons.videoteca_black} alt="videoteca" /> Videoteca.</p>
          <p>Você pode escolher um vídeo da lista ou pode pesquisar por um clicando na pesquisa (palavra com mínimo 3
            caracteres).</p>
          <p>Clique na miniatura do vídeo para reproduzir ou no título para ver mais detalhes.</p>
        </article>

      </main>
    
      </ContentBox>
      <Footer />
    </div>
)};

export default ManagerManual;