import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.align)};
  gap: 1rem;
  width: 100%;
  margin-top: 0.7rem;

  font: var(--body);
  color: var(--primary);
  background-color: rgba(0,0,0,0);
  /* border: 1px solid orange; */

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Text = styled.div`
  
`;

export const VisibilityItem = styled.div`
  background-color: var(--softBlue);
  color: var(--secondary);
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
`;