import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Title = styled.h1``;

export const Description = styled.div``;

export const Teacher = styled.div``;

export const Meetings = styled.div``;

export const Date = styled.div``;

export const Place = styled.div``;

export const VisibilitiesContainer = styled.div``;

export const ItemDados = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 90%;
  margin-top: 0rem;
  padding-left: 2rem;

  font: var(--body);
  color: var(--primary);
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-end;
  gap: 2rem;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button{
      width: 10rem;
    }
  }
`;