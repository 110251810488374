import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import {
  BoxNames,
  Contact,
  Container,
  ContentNames,
  ContentText,
  ImgCemforpe,
  Link,
  Map,
  Text,
  TextItem,
  Title,
} from "./styles";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";

const ProjectMusic = () => {
  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.icon_musica}>PROJETO MÚSICA</Banner>
      <ContentBox>

          <Text>
            PROJETO MÚSICA
          </Text>
        
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default ProjectMusic;