import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  margin-top: 10rem;
  background-color: var(--secondary);
`;

export const Title = styled.h1`
  font: var(--title);
  margin: 4rem 0;
  color: var(--primary);
`;
export const Form = styled.form`
  width: 100%;
  max-width: 90rem;
  position: relative;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 4rem;
  gap: 2rem;
  position: relative;

  @media (max-width: 700px) {
    padding: 0 1.6rem;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
`;
export const Place = styled.span`
  color: var(--primary);
`;
export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-end;
  gap: 2rem;
`;

export const DeleteButton = styled.button`
  background: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--hardRed);
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 500px) {
    position: initial;
    align-self: center;
  }

  &:hover {
    transform: scale(1.1) rotate(20deg);
  }
`;
