import React, { useState } from "react";
import TimeKeeper from "react-timekeeper";
import { FiClock } from "react-icons/fi";
import { format } from "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  Container,
  Clock,
  DateIcon,
  SelectDate,
  DateInput,
  DateLabel,
  InfoMessage,
} from "./styles";

const InputTime = ({
  getValue = null,
  setValue = null,
  clockFontSize = 1.288,
  fontSize = 1.6,
  label = "Hora",
  placeholder = "Selecione a Hora",
  setField = () => {},
  onChange = () => {},
  onBlur = () => {},
  value,
  error,
  name,
  ...rest
}) => {
  const [clockOpen, setClockOpen] = useState(false);
  const [time, setTime] = useState(() => {
    if (setValue) {
      return {
        hour: setValue.getHours(),
        minute: setValue.getMinutes(),
      };
    } else {
      return {
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
      };
    }
  });
  const [dateTime, setDateTime] = useState(setValue ? setValue : null);

  const handleClockOpen = () => {
    setClockOpen(!clockOpen);
  };

  const handleTime = (data) => {
    // console.log(data);
    const newTime = {
      ...time,
      hour: data.hour,
      minute: data.minute,
    };
    setTime(newTime);
    const timeOK = new Date().setHours(data.hour, data.minute);
    setDateTime(timeOK);
    // setField(name, format(timeOK, "HH'h'mm"));
    setField(name, timeOK);
  };

  return (
    <ClickAwayListener onClickAway={() => setClockOpen(false)}>
      <Container {...rest}>
        <DateLabel fontSize={fontSize}>{label}</DateLabel>
        <DateInput fontSize={fontSize}>
          <SelectDate
            dateTime={dateTime}
            name={name}
            type="text"
            id={name}
            value={value && format(value, "HH'h'mm")}
            readOnly
            onChange={onChange}
            onBlur={onBlur}
            onClick={handleClockOpen}
            fontSize={fontSize}
          />
          <DateIcon>
            <FiClock />
          </DateIcon>
        </DateInput>
        <Clock isOpen={clockOpen}>
          <TimeKeeper
            time={time}
            onChange={(data) => handleTime(data)}
            hour24Mode={true}
          />
        </Clock>
        {error && <InfoMessage>*&nbsp;{error}</InfoMessage>}
      </Container>
    </ClickAwayListener>
  );
};

export default InputTime;
