import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth } from "../../hooks/useAuth";

const PrivateRouteVideo = () => {
  const { user } = useAuth();
  const editor = user?.profiles[0]?.editor?.findIndex(
    (edit) => edit.id === "890798c6-a166-4b87-a059-11d4bbf6a1ac"
  );

  return editor > -1 ? <Outlet /> : <Navigate to="/login/redirect/*agenda" />;
};

export default PrivateRouteVideo;
