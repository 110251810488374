import React, { useState, useEffect } from "react";
import {
  Container,
  ContainerAgenda,
  Left,
  Right,
  Calendar,
  Legend,
  LegendItem,
  DateSelect,
  Search,
} from "./styles";
import Accessibility from "../../components/UI/Accessibility";
import Menu from "../../components/UI/Menu";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import InputSearch from "../../components/UI/Inputs/InputSearch";
import DayPicker from "react-day-picker";
import {
  format,
  parseISO,
  isWithinInterval,
  endOfDay,
  startOfDay,
  getYear,
} from "date-fns";

import "./day-picker.css";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

import Banner from "../../components/UI/Banner";
import { images } from "../../constants";

import Meet from "./Components/Meet";

const Agenda = () => {
  const { user } = useAuth();

  const [selected, setSelected] = useState(new Date());

  const [agendaAtividades, setAgendaAtividades] = useState([]);

  const [loadYear, setLoadYear] = useState([]);

  const [year, setYear] = useState(getYear(new Date()));

  const [atividades, setAtividades] = useState([]);

  const [type, setType] = useState({});

  const [filter, setFilter] = useState("");

  const [liveSearch, setliveSearch] = useState(false);

  const [textSearch, setTextSearch] = useState("");

  // const [typeStyle, setTypeStyle] = useState({});

  //eslint-disable-next-line
  const [loadAgenda, loadAgendaInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/calendar",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        let yearLoad;
        if (response.data[0].meetings.length > 0) {
          yearLoad = getYear(new Date(response.data[0].meetings[0].eventdate));
        } else {
          if (response.data[0].eventDate !== null) {
            yearLoad = getYear(new Date(response.data[0].eventDate));
          } else {
            yearLoad = getYear(new Date(response.data[0].begin));
          }
        }

        // console.log("Carga do Ano: ", yearLoad);
        console.log("loadAgenda", response);

        if (loadYear.indexOf(yearLoad) < 0) {
          setAgendaAtividades([...agendaAtividades, ...response.data]);
          setLoadYear([...loadYear, yearLoad]);
        }
      }
    },
  });

  const [loadAgendaPublica, loadAgendaInfoPublica] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/publicCalendar",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        let yearLoad;
        if (response.data[0]?.meetings?.length > 0) {
          yearLoad = getYear(new Date(response.data[0].meetings[0].eventdate));
        } else {
          if (response.data[0]?.eventDate !== null) {
            yearLoad = getYear(new Date(response.data[0]?.eventDate));
          } else {
            yearLoad = getYear(new Date(response.data[0]?.begin));
          }
        }

        // console.log("Carga do Ano: ", yearLoad);
        console.log("loadAgenda", response);

        if (loadYear.indexOf(yearLoad) < 0) {
          setAgendaAtividades([...agendaAtividades, ...response.data]);
          setLoadYear([...loadYear, yearLoad]);
        }
      }
    },
  });

  async function load() {
    if (user) {
      await loadAgenda({
        params: {
          year,
        },
      });
    } else {
      await loadAgendaPublica({
        params: {
          year,
        },
      });
    }
  }

  useEffect(() => {
    load();
    //eslint-disable-next-line
  }, [year]);

  useEffect(() => {
    // console.log("user", user);
    load();

    //eslint-disable-next-line
  }, []);

  const checkFilter = (atividade) => {
    if (filter === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3") {
      return atividade.mainCategory === filter;
    }
    if (filter !== "") {
      return atividade.category === filter;
    }
    return true;
  };
  // const fixDateForAllBrowsers = (dateString) => dateString.replace(/-/g, "/");

  const dateFilter = (atividade) => {
    if(checkFilter(atividade)===false){
      return false;
    }

    let filtro = false;
    if (atividade.meetings.length === 0) {
      if (atividade.eventDate !== null) {
        filtro = isWithinInterval(selected, {
          start: startOfDay(new Date(atividade.eventDate)),
          end: endOfDay(new Date(atividade.eventDate)),
        });
      } else {
        filtro = isWithinInterval(selected, {
          start: startOfDay(new Date(atividade.begin)),
          end: endOfDay(new Date(atividade.end)),
        });
      }
    } else {
      // console.log("consultou os meetings");
      atividade.meetings.forEach((meet) => {
        if (!filtro) {
          filtro = isWithinInterval(selected, {
            start: startOfDay(parseISO(meet.eventdate)),
            end: endOfDay(parseISO(meet.eventdate)),
          });
        }
      });
    }
    // console.log("teste filtro atividade", atividade, filtro);

    return filtro;
  };

  const isEditor = (idCategory) => {
    return (
      user?.profiles[0]?.editor?.findIndex(
        (editor) => editor.id === idCategory
      ) >= 0
    );
  };

  useEffect(() => {
    let temp = [];
    agendaAtividades.filter(checkFilter).forEach((atividade) => {
      if (atividade.meetings.length === 0) {
        if (atividade.eventDate !== null) {
          temp.push({
            from: new Date(atividade.eventDate),
            to: new Date(atividade.eventDate),
          });
        } else {
          temp.push({
            from: new Date(atividade.begin),
            to: new Date(atividade.end),
          });
        }
      } else {
        atividade.meetings.forEach((meet) => {
          temp.push({
            from: parseISO(meet.eventdate),
            to: parseISO(meet.eventdate),
          });
        });
      }
    });
    // console.log(temp);

    if (textSearch.trim().length < 3) {
      setAtividades(agendaAtividades.filter(dateFilter));
    }

    //configura os marcadores no pickday
    // console.log("Agenda atividades", agendaAtividades);
    setType({ mark: temp });
    //eslint-disable-next-line
  }, [agendaAtividades, filter]);

  /**
   *
   * type manipular com os tipos de marcação para o daypicker
   * formato {type:data}
   * type = tipo do evento
   * 
   * data = inserir a data diretamente no formato DATE ou por range com 
   * {from:data, to:data}
   * 
   * typeStyle define o estilo para cada tipo de evento
   * 
   * formato {
   *   type: {
   *     color: 'white',
   *     backgroundColor: 'black'
   *   }
   * }
   *
   */

  useEffect(() => {
    setAtividades(agendaAtividades.filter(dateFilter));
    //eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    // console.log("atividades", atividades);
    //eslint-disable-next-line
  }, [atividades]);

  const handleTextSearch = (text) => {
    setTextSearch(text.target.value);
  };

  useEffect(() => {
    if (textSearch.trim().length < 3) {
      setliveSearch(false);
      setAtividades(agendaAtividades.filter(dateFilter));
    } else {
      setliveSearch(true);
      setAtividades(agendaAtividades.filter(textFilter));
      //A api tem que trazer os dados em ordem decrescente em relação a data start
    }

    //eslint-disable-next-line
  }, [textSearch]);

  //filtro do Search
  const textFilter = (atividade) => {
    let temp = false;

    if (atividade.title.toLowerCase().indexOf(textSearch.trim().toLowerCase()) >= 0) {
      temp = true;
    }
    if (
      atividade.description.toLowerCase().indexOf(textSearch.trim().toLowerCase()) >= 0
    ) {
      temp = true;
    }

    if (atividade.teacher !== null) {
      if (
        atividade.teacher.toLowerCase().indexOf(textSearch.trim().toLowerCase()) >= 0
      ) {
        temp = true;
      }
    }

    if (atividade.teacher_contact !== null) {
      if (
        atividade.teacher_contact
          .toLowerCase()
          .indexOf(textSearch.trim().toLowerCase()) >= 0
      ) {
        temp = true;
      }
    }

    if (atividade.team !== null) {
      if (atividade.team.toLowerCase().indexOf(textSearch.trim().toLowerCase()) >= 0) {
        temp = true;
      }
    }

    return temp;
  };

  const handleDayClick = (day) => {
    setSelected(day);
  };

  const handleMonth = (month) => {
    // console.log(getYear(new Date(month)));
    if (loadYear.indexOf(getYear(new Date(month))) < 0) {
      // console.log("fazer o load do novo ano");
      setYear(getYear(new Date(month)));
    }
  };

  const searchCategory = (category) => {
    if (category === "890798c6-a166-4b87-a059-11d4bbf6a1ac") {
      return "agenda";
    }
    if (category === "0e3b686a-9b75-4de0-a2b7-28ec578e8119") {
      return "formacoes";
    }
    if (category === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3") {
      return "avaliacoes";
    }
  };

  const periodo = () => {
    let min = Math.min(loadYear);
    let max = Math.max(loadYear);
    // console.log(loadYear);
    return `Pesquisa realizada inclui os eventos do${
      max === min ? "" : "s"
    } ano${max === min ? "" : "s"} de ${min}${max === min ? "" : `-${max}`}`;
  };

  return (
    <Container>
      {(loadAgendaInfo.loading || loadAgendaInfoPublica.loading) && (
        <FullScreenLoading />
      )}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconAgenda}
        url="/agenda/add"
        editorId="890798c6-a166-4b87-a059-11d4bbf6a1ac"
      >
        AGENDA
      </Banner>

      <Search>
        <InputSearch value={textSearch} onChange={handleTextSearch} />
      </Search>
      <ContentBox>
        <ContainerAgenda>
          <Left visible={!liveSearch}>
            <Calendar>
              <DayPicker
                mode="single"
                selectedDays={selected}
                onDayClick={handleDayClick}
                modifiers={type}
                months={[
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ]}
                weekdaysShort={["D", "S", "T", "Q", "Q", "S", "S"]}
                weekdaysLong={[
                  "Domingo",
                  "Segunda",
                  "Terça",
                  "Quarta",
                  "Quinta",
                  "Sexta",
                  "Sábado",
                ]}
                onMonthChange={handleMonth}
                todayButton={"Hoje"}
              />
            </Calendar>
            <Legend>
              <LegendItem
                onClick={() => {
                  setFilter("c6fd3cff-dee0-4a06-b1f0-821692257687");
                }}
                selected={filter === "c6fd3cff-dee0-4a06-b1f0-821692257687"}
              >
                Eventos Deped
              </LegendItem>
              <LegendItem
                onClick={() => {
                  setFilter("c89c92e0-f70c-450f-9307-d2ea062c5a4b");
                }}
                selected={filter === "c89c92e0-f70c-450f-9307-d2ea062c5a4b"}
              >
                Eventos Auditório
              </LegendItem>
              <LegendItem
                onClick={() => {
                  setFilter("4fd7b9f6-7de6-4c93-b430-92b202da88f6");
                }}
                selected={filter === "4fd7b9f6-7de6-4c93-b430-92b202da88f6"}
              >
                Formação Complementar
              </LegendItem>
              <LegendItem
                onClick={() => {
                  setFilter("2457a23b-101f-485a-a4a1-c7fa7ab7926b");
                }}
                selected={filter === "2457a23b-101f-485a-a4a1-c7fa7ab7926b"}
              >
                Formação Elementar
              </LegendItem>
              <LegendItem
                onClick={() => {
                  setFilter("0d8bd0d9-47f5-4bf6-9ddd-044861e242a3");
                }}
                selected={filter === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"}
              >
                Avaliações
              </LegendItem>
              <LegendItem
                onClick={() => {
                  setFilter("");
                }}
                selected={filter === ""}
              >
                Todos
              </LegendItem>
            </Legend>
          </Left>
          <Right liveSearch={liveSearch}>
            {liveSearch === false ? (
              <DateSelect>
                {format(new Date(selected), "dd/MM/yyyy")}
                {atividades.length === 0 && (
                  <span>Não existe evento agendado para esta data</span>
                )}
              </DateSelect>
            ) : (
              <DateSelect>
                <span>{periodo()}</span>
                <span>Buscando eventos com o texto: {textSearch}</span>
                {atividades.length === 0 && (
                  <span>
                    Não foi encontrado nenhum evento com o texto: {textSearch}
                  </span>
                )}
              </DateSelect>
            )}
            {liveSearch
              ? atividades.map((atividade, index) => (
                  <Meet 
                    atividade={atividade} 
                    selectedDay={selected} 
                    iseditor={isEditor(atividade.category)} 
                    category={searchCategory(atividade.mainCategory)} 
                    key={index}
                  />
                ))
              : atividades.map((atividade, index) => (
                  <Meet 
                    atividade={atividade} 
                    selectedDay={selected} 
                    isEditor={isEditor(atividade.category)} 
                    category={searchCategory(atividade.mainCategory)} 
                    key={index}
                  />
                ))}
          </Right>
        </ContainerAgenda>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Agenda;
