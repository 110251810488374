import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
  /* font-size: calc(var(--size) * 1.6); */
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  font-weight: 800;
  margin: 3rem 0rem 1rem 0rem;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Avaliacao = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  /* padding-left: 5rem; */
  width: 100%;

  font: var(--body);

  & + div {
    border-top: 1px solid var(--primary);
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const AvaliacaoTitle = styled.div`
  margin: 1rem 0rem;

  font: var(--title);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const AvaliacaoDesc = styled.div`
  margin: 0rem 1.5rem;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const AvaliacaoList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 2.5rem;
  /* margin: 1rem 0rem 1rem 2rem; */
  /* width: 100%; */

  @media (max-width: 600px) {
    margin-left: 0rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const AvaliacaoItem = styled.div`
  margin-bottom: 0.7rem;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const AvaliacaoLink = styled.a`
  cursor: pointer;
  margin-left: 0.5rem;

  span {
    margin-left: 0.5rem;
  }
  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const MaterialList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* margin: 1rem 0rem 1rem 5rem; */
  flex-wrap: wrap;
  margin-left: 2.5rem;

  @media (max-width: 600px) {
    margin-left: 0rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Orientacao = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.7rem;
  flex-wrap: wrap;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const OrientacaoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.7rem;
  flex-wrap: wrap;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Plataforma = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.7rem;

  span {
    margin-left: 0.5rem;
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Editar = styled.a`
  img {
    width: 2rem;
    margin-left: 0.8rem;
  }
`;

export const Link = styled.a`
`;

export const LoadingMore = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  span{
    width:3rem;
  }
`;