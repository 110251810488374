import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Title, Description, Teacher, Meetings, ItemDados, Controls, Message } from "./styles";
import { format } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images } from "../../../constants";
import Banner from "../../../components/UI/Banner";
// import Cargo from "../../../components/UI/Cargo";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";
import Modal from "../../../components/UI/Modal";
import CargoV2 from "../../../components/UI/CargoV2.0";

import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";

import { useFormik } from "formik";

const CoursesView = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const navigate = useNavigate();

  const [documento, setDocumento] = useState({});

  const [ModalOpenSubscribe, setModalOpenSubscribe] = useState(false);

  const [ModalOpenAlertSubscribe, setModalOpenAlertUnsubscribe] = useState(false);
  const [subscriptionStatusMessage, setModalOpenSubscriptionStatusMessage] = useState("");

  const handleModalAlertSubscribe = () => {
    setModalOpenAlertUnsubscribe((oldModalOpenAlertUnsubscribe) => !oldModalOpenAlertUnsubscribe);
  };

  const handleAlertSubscribeOk = () => {
    if(subscriptionStatusMessage==="Inscrição realizada com sucesso! Verifique a confirmação da inscrição no seu e-mail!"){
      navigate("/formacoes");
    }
    else{
      handleModalAlertSubscribe();
    }
  };

  const handleModalSubscribe = () => {
    setModalOpenSubscribe((oldModalOpenSubscribe) => !oldModalOpenSubscribe);
  };

  const [loadDocument, loadDocumentInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data[0]);
        setDocumento(response.data[0]);
      }
    },
  });

  const [subscribe, loadSubscribeInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/subscription",
    method: "post",
    onCompleted: (response) => {
      handleModalSubscribe();
      handleModalAlertSubscribe();
      if (!response.error) {
        setModalOpenSubscriptionStatusMessage("Inscrição realizada com sucesso! Verifique a confirmação da inscrição no seu e-mail!")
      }
    },
    onError: (error)=>{
      handleModalSubscribe();
      handleModalAlertSubscribe();
      setModalOpenSubscriptionStatusMessage("Erro ao realizar a incrição, por favor, tente novamente!")
    }
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      avatar: "",
      cpf: "",
      classes:{
        rgf1:[],
        rgf2:[]
      },
      phone_number1: "",
      phone_number2: "",
      rgf1:"",
      rgf2:"",
    },

    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    loadDocument({
      params: {
        id,
      },
    });
    formik.setFieldValue("name", user.name);
    formik.setFieldValue("email", user.dados.email);
    formik.setFieldValue("cpf", user.dados.cpf);
    formik.setFieldValue("classes", user.dados.classes);
    formik.setFieldValue("phone_number1", user.dados.phone_number1);
    formik.setFieldValue("phone_number2", user.dados.phone_number2);
    formik.setFieldValue("rgf1", user.dados.rgf1);
    formik.setFieldValue("rgf2", user.dados.rgf2);

    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    subscribe({
      data: {
        id
      },
    });
  };

  return (
    <Container>
      {loadDocumentInfo.loading && <FullScreenLoading />}
      {loadSubscribeInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconDocumentos}
      >
        Inscrição da Formação
      </Banner>
      <ContentBox>
        <Title>{documento.title}</Title>
        <Description>{documento.description}</Description>
        <Teacher>
          <p>
            <b>Formador: </b>
            {documento.teacher}{" "}
            {documento.teacherContact && `(${documento.teacherContact})`}
          </p>
        </Teacher>
        <Meetings>
          {documento.documents?.map((d, n) => (
            <span key={n}>
              <a
                href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/course/${d.url}`}
                target="_blank"
                rel="noreferrer"
              >
                {d.originalName || `Documento ${n + 1}`}
              </a>
            </span>
          ))}
        </Meetings>
        <Meetings>
          <b>
            {documento.meetings?.length}
            {documento.meetings?.length > 0 && documento.meetings?.length === 1
              ? " Encontro:"
              : " Encontros:"}
          </b>
          {documento.meetings?.map((e, i) => (
            <div key={i}>
              <span>
                - {e.place} -{" "}
                {format(parseInt(e.eventDate), "dd.MM.yyyy", {
                  locale: ptBR,
                })}
                {" das "}
                {format(parseInt(e.eventBegin), "HH'h'mm", {
                  locale: ptBR,
                })}
                {" às "}
                {format(parseInt(e.eventEnd), "HH'h'mm", {
                  locale: ptBR,
                })}
              </span>
            </div>
          ))}
        </Meetings>

      Dados do Incrito:
      
        {/* {formik.values.cargos?.map((cargo,index)=>(
          <Cargo 
              key={index}
              numero={index+1}
              rgf={cargo.rgf}
              cargo={cargo.cargo}
              escola={cargo.escola}
              htpf={cargo.htpf}
            />
        ))} */}

        {
          formik.values.classes.rgf1.length>0 && <CargoV2 classes={formik.values.classes.rgf1} />
        }

        {
          formik.values.classes.rgf2.length>0 && <CargoV2 classes={formik.values.classes.rgf2} />
        }

        {/* {formik.values.cargos.length===0 && <ItemDados>{`<p>RGF${formik.values.rgf2?`1: ${formik.values.rgf1}<p/><p>RGF2: ${formik.values.rgf2}`:`: ${formik.values.rgf1}`}</p>`}</ItemDados>} */}
        { formik.values.classes.rgf1.length===0 && <ItemDados>{formik.values.rgf2?<><p>RGF1: {formik.values.rgf1}</p><p>RGF2: {formik.values.rgf2}</p></>:<p>RGF: {formik.values.rgf1}</p>}</ItemDados>}
        
        <ItemDados>
          <span>E-mail: {formik.values.email}</span>
        </ItemDados>

        <ItemDados>
        CPF: {`${formik.values.cpf.slice(0, formik.values.cpf.length-8)}.${formik.values.cpf.slice(formik.values.cpf.length-8, formik.values.cpf.length-5)}.${formik.values.cpf.slice(formik.values.cpf.length-5, formik.values.cpf.length-2)}-${formik.values.cpf.slice(formik.values.cpf.length-2, formik.values.cpf.length)}`}
        </ItemDados>

        <ItemDados>Qualquer divergência nos dados, por favor, solicitar correção ao SGE.</ItemDados>

        <Controls>
          <CancelButton
            text="Cancelar"
            onClick={() => {
              navigate(-1);
            }}
          />
          <ConfirmButton
            text="Confirmar Inscrição"
            type="submit"
            disabled={!formik.isValid}
            onClick={()=>{handleModalSubscribe()}}
          />
        </Controls>

        <Modal  open={ModalOpenSubscribe} close={handleModalSubscribe} buttonClose = {false} width={100} height={15}>
          <Message>
            <div>Deseja se inscrever na formação: {documento.title} ?</div>
            <div>
              <ReadMoreButton text="SIM" onClick={handleSubmit}/>
              <ReadMoreButton text="NÃO" onClick={handleModalSubscribe}/>
            </div>
          </Message>
        </Modal>

        <Modal  open={ModalOpenAlertSubscribe} close={handleModalAlertSubscribe} buttonClose = {false} width={50} height={15}>
          <Message>
            <div>{subscriptionStatusMessage}</div>
            <ReadMoreButton onClick={handleAlertSubscribeOk} text="Ok" />
          </Message>
        </Modal>

      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesView;
