import React, { useState, useEffect } from "react";
import {
  Container,
  Main,
  Title,
  List,
  Search,
  Loading,
  LoadingMore,
  Description,
  Icon,
} from "./styles";
import Accessibility from "../../components/UI/Accessibility";
import { InView } from "react-intersection-observer";
import Menu from "../../components/UI/Menu";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import InputSearch from "../../components/UI/Inputs/InputSearch";
import LoadingItem from "../../components/UI/LoadingItem";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";
import { images, icons } from "../../constants";
import Banner from "../../components/UI/Banner";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import SearchMessage from "../../components/UI/SearchMessage";

import Document from "./Components/Document"

const Documentos = () => {
  const { user } = useAuth();

  const [time, setTime] = useState(0);

  const [initialLoading, setInitialLoading] = useState(true);

  const [documentos, setDocumentos] = useState([]);

  const [docType, setDocType] = useState([]);

  const [dropFlag, setDropFlag] = useState("");

  const [searchMain, setSearchMain] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [searchDocument, setSearchDocument] = useState([]);

  //As apis devem trazer em ordem decrescente da ordem de criação dos documentos

  const isEditor = (category) => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === category
    )
    return editor > -1 ? true : false;
  };

  useEffect(() => {

    // setDocType([
    //   {category:"Documentos Gerais",id:"14fe1389-eb1c-4c12-b3d0-6db44d25653a"},
    //   {category:"Indicadores",id:"6aa79c58-5986-4b4c-bc3b-316df6030e9c"},
    //   {category:"Orientações Didáticas",id:"3c0e3d5b-3456-4e3c-a89f-9cb3cf41cc82"}
    // ]);

    loadCategories({
      params: {
        id: "46150c88-042a-4220-b81e-bb6c399fa275",
      },
    });

    if (user?.profiles[0].idUserHasProfile) {
      loadDocuments({
        params: {
          initial: documentos.length,
          limit: 10,
          idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
          idsubcategory: dropFlag,
          idUserHasProfile: user?.profiles[0].idUserHasProfile
            ? user?.profiles[0].idUserHasProfile
            : null,
        },
      });
    } else {
      loadDocumentsPublic({
        params: {
          initial: documentos.length,
          limit: 10,
          idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
          idsubcategory: dropFlag,
          idUserHasProfile: user?.profiles[0].idUserHasProfile
            ? user?.profiles[0].idUserHasProfile
            : null,
        },
      });
    }

    //eslint-disable-next-line
  }, []);

  const handleTextSearch = (text) => {
    setTextSearch(text.target.value);
  };

  useEffect(() => {
    setTime(0);
    if (textSearch.trim().length < 3) {
      setSearchMain(false);
    } else {
      setSearchMain(true);
      //setSearchDocument(documentos.filter((doc)=>{return doc.title.indexOf(textSearch)>=0}));
      //idUserHasProfile: user?.profiles[0].idUserHasProfile?user?.profiles[0].idUserHasProfile:null,
      setSearchDocument([]);
    }
    //eslint-disable-next-line
  }, [textSearch]);

  //eslint-disable-next-line
  const [loadDocuments, loadDocumentsInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/documents/byTerm/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if (response.data.length > 0) {
          if (documentos[0]?.type === dropFlag) {
            let valid = documentos.findIndex(
              (doc) => doc.id === response.data[0]?.id
            )
            if(valid<0){
              setDocumentos([...documentos, ...response.data]);
            }
          } else {
            setDocumentos(response.data);
          }
        }
        // console.log("loadDocumentos", response);
        setInitialLoading(false);
      }
      console.log(response.data)
    },
  });

  //eslint-disable-next-line
  const [loadDocumentsPublic, loadDocumentsPublicInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/publicDocuments/byTerm/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if (response.data.length > 0) {
          if (documentos[0]?.type === dropFlag) {
            let valid = documentos.findIndex(
              (doc) => doc.id === response.data[0]?.id
            )
            if(valid<0){
              setDocumentos([...documentos, ...response.data]);
            }
          } else {
            setDocumentos(response.data);
            
          }
        }
        console.log("loadDocumentos", response);
        setInitialLoading(false);
      }
    },
  });

  //eslint-disable-next-line
  const [loadCategories, loadCategoriesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/category",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("categorias", response);
        setDocType(response.data);
      }
    },
  });

  const [loadSearchDocuments, loadSearchDocumentsInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/documents/byTerm/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("Search Documents", response);
        if(response.data.length>0){
          let valid = searchDocument.findIndex(
            (doc) => doc.id === response.data[0]?.id
          )
          if(valid<0){
            setSearchDocument([...searchDocument, ...response.data]);
          }
        }
        setTime(response.config.time);
      }
    },
  });

  useEffect (()=>{
    if(searchDocument.length===0 && (textSearch.trim().length >= 3)){
      if (user?.profiles[0].idUserHasProfile) {
        loadSearchDocuments({
          params: {
            term: textSearch.trim(),
            initial: 0,
            limit: 5,
            idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
            idUserHasProfile: user?.profiles[0].idUserHasProfile
              ? user?.profiles[0].idUserHasProfile
              : null,
          },
          debounced: true,
        });
      } else {
        loadSearchDocumentsPublic({
          params: {
            term: textSearch.trim(),
            initial: 0,
            limit: 5,
            idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
            idUserHasProfile: user?.profiles[0].idUserHasProfile
              ? user?.profiles[0].idUserHasProfile
              : null,
          },
          debounced: true,
        });
      }
    }
    // eslint-disable-next-line
  },[searchDocument]);

  const [loadSearchDocumentsPublic, loadSearchDocumentsPublicInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/publicDocuments/byTerm/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data.length>0){
          let valid = searchDocument.findIndex(
            (doc) => doc.id === response.data[0]?.id
          )
          if(valid<0){
            setSearchDocument([...searchDocument, ...response.data]);
          }
        }
        setTime(response.config.time);
      }
    },
  });

  const loadMore = (InviewObj, entry) => {
    if (InviewObj) {
      // console.log("carregar mais");
      if (user?.profiles[0].idUserHasProfile) {
        loadSearchDocuments({
          params: {
            term: textSearch.trim(),
            initial: searchDocument.length,
            limit: 10,
            idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
            idUserHasProfile: user?.profiles[0].idUserHasProfile
              ? user?.profiles[0].idUserHasProfile
              : null,
          },
          debounced: true,
        });
      } else {
        loadSearchDocumentsPublic({
          params: {
            term: textSearch.trim(),
            initial: searchDocument.length,
            limit: 10,
            idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
            idUserHasProfile: user?.profiles[0].idUserHasProfile
              ? user?.profiles[0].idUserHasProfile
              : null,
          },
          debounced: true,
        });
      }
    }
  };

  const loadMoreDocument = (InviewObj, entry) => {
    console.log(`Carregado ${documentos.length} documentos`)
    if (InviewObj) {
      // console.log("carregar mais");

      if (documentos[0].type === dropFlag) {
        if (user?.profiles[0].idUserHasProfile) {
          loadDocuments({
            params: {
              initial: documentos.length,
              limit: 10,
              idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
              idsubcategory: dropFlag,
              idUserHasProfile: user?.profiles[0].idUserHasProfile
                ? user?.profiles[0].idUserHasProfile
                : null,
            },
            debounced: true,
          });
        } else {
          loadDocumentsPublic({
            params: {
              initial: documentos.length,
              limit: 10,
              idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
              idsubcategory: dropFlag,
              idUserHasProfile: user?.profiles[0].idUserHasProfile
                ? user?.profiles[0].idUserHasProfile
                : null,
            },
            debounced: true,
          });
        }
      } else {
        if (user?.profiles[0].idUserHasProfile) {
          loadDocuments({
            params: {
              initial: 0,
              limit: 20,
              idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
              idsubcategory: dropFlag,
              idUserHasProfile: user?.profiles[0].idUserHasProfile
                ? user?.profiles[0].idUserHasProfile
                : null,
            },
          });
        } else {
          loadDocumentsPublic({
            params: {
              initial: 0,
              limit: 20,
              idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
              idsubcategory: dropFlag,
              idUserHasProfile: user?.profiles[0].idUserHasProfile
                ? user?.profiles[0].idUserHasProfile
                : null,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (dropFlag !== "") {
      if (documentos[0]?.type !== dropFlag) {
        setDocumentos([]);
        if (user?.profiles[0].idUserHasProfile) {
          loadDocuments({
            params: {
              initial: 0,
              limit: 10,
              idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
              idsubcategory: dropFlag,
              idUserHasProfile: user?.profiles[0].idUserHasProfile
                ? user?.profiles[0].idUserHasProfile
                : null,
            },
          });
        } else {
          loadDocumentsPublic({
            params: {
              initial: 0,
              limit: 10,
              idcategory: "46150c88-042a-4220-b81e-bb6c399fa275",
              idsubcategory: dropFlag,
              idUserHasProfile: user?.profiles[0].idUserHasProfile
                ? user?.profiles[0].idUserHasProfile
                : null,
            },
          });
        }
      }
    }
    //eslint-disable-next-line
  }, [dropFlag]);

  // useEffect(() => {
  //   console.log("Teste searchDocument", searchDocument);
  //   //eslint-disable-next-line
  // }, [searchDocument]);

  // useEffect(() => {
  //   documentos.filter((document) => {
  //     return document.type === "14fe1389-eb1c-4c12-b3d0-6db44d25653a";
  //   }).map((doc)=>{console.log("teste map filter ", doc);return null;})
  //   //eslint-disable-next-line
  // }, [document]);

  return (
    <Container>
      {initialLoading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconDocumentos}
        url="/documentos/add"
        editorId="46150c88-042a-4220-b81e-bb6c399fa275"
      >
        DOCUMENTOS
      </Banner>
      <Search>
        <InputSearch
          value={textSearch}
          onChange={handleTextSearch}
          loading={
            loadSearchDocumentsInfo.loading ||
            loadSearchDocumentsPublicInfo.loading
          }
        />
      </Search>
      <ContentBox>
        <Description>
        Neste espaço você encontra os principais documentos produzidos ou direcionados para a rede municipal
        de educação de mogi das cruzes, como: currículo municipal, ODs, indicadores, entre outros. Os documentos exibidos são os mais recentes,
        clique no ícone ao lado do tópico desejado para exapandir. E se necessário, utilize a ferramenta
        de busca para encontrar algum documento em específico.
        </Description>
        {!searchMain ? (
          <Main>
            {docType.map((tipo, index) => (
              <List key={`list${index}`}>
                <Title
                  onClick={() => {
                    setDropFlag(dropFlag === tipo.id ? "" : tipo.id);
                  }}
                >
                  <Icon src={icons.documentos_black} />
                  {tipo.category}
                  <span>
                    {dropFlag === tipo.id ? (
                      <MdArrowDropUp />
                    ) : (
                      <MdArrowDropDown />
                    )}
                  </span>
                  {(loadDocumentsInfo.loading ||
                    loadDocumentsPublicInfo.loading) &&
                    documentos.length === 0 &&
                    dropFlag === tipo.id && (
                      <Loading>
                        <LoadingItem />
                      </Loading>
                    )}
                </Title>
                {documentos.map((documentoTemp, indexdocumento) => (
                  <Document 
                    doc={documentoTemp}
                    isEditor={isEditor(documentoTemp.type)}
                    search={searchMain}
                    visible={dropFlag === tipo.id}
                    key={documentoTemp.id}
                  />
                ))}
                {(loadDocumentsInfo.loading ||
                  loadDocumentsPublicInfo.loading) &&
                  dropFlag === tipo.id && (
                    <LoadingMore>
                      <span>
                        <LoadingItem />
                      </span>
                    </LoadingMore>
                  )}
                {documentos.length > 0 && dropFlag === tipo.id && (
                  <InView
                    as="div"
                    onChange={(inView, entry) =>
                      loadMoreDocument(inView, entry)
                    }
                  ></InView>
                )}
              </List>
            ))}
          </Main>
        ) : (
          <Main>
            {searchDocument.length > 0 && (
              <SearchMessage total={searchDocument.length} time={time} />
            )}
            {searchDocument.length > 0
              ? searchDocument.map((documentoTemp, index) => (
                  <Document 
                    doc={documentoTemp}
                    isEditor={isEditor(documentoTemp.type)}
                    search={searchMain}
                    visible={true}
                    key={index}
                  />
                ))
              : !(
                  loadSearchDocumentsInfo.loading ||
                  loadSearchDocumentsPublicInfo.loading
                ) && (
                  <SearchMessage
                    message={`Sua busca por "${textSearch}" não encontrou resultados.`}
                  />
                )}
            {searchDocument.length > 0 && (
              <InView
                as="div"
                onChange={(inView, entry) => loadMore(inView, entry)}
              ></InView>
            )}
          </Main>
        )}
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Documentos;
