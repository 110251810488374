import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth } from "../../hooks/useAuth";

const PrivateRouteLibrary = () => {
  const { user } = useAuth();
  const editor = user?.profiles[0]?.editor?.findIndex(
    (edit) => edit.id === "f0d87522-fcc7-4f8b-aec3-3e64fce9915a"
  );

  return editor > -1 ? <Outlet /> : <Navigate to="/login/redirect/*biblioteca" />;
};

export default PrivateRouteLibrary;
