import React from "react";
import { IoImageOutline } from "react-icons/io5";
import { useTheme } from "../../../hooks/useTheme";
import { Container, Image, PreviewContainer, Text } from "./styles";

const AttachPlaceholder = ({
  src = false,
  altText = "Sem imagem",
  noImageText = "Clique para anexar",
  width = 25,
  height = 25,
  disabled = false,
  id,
  onChange,
  onClick = () => {},
  ...rest
}) => {
  const { themeFile } = useTheme();

  return (
    <Container
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}
      htmlFor={id}
      {...rest}
    >
      <input type="file" id={id} accept="image/*" onChange={onChange} />
      {src ? (
        <Image src={src} alt={altText} />
      ) : (
        <PreviewContainer>
          <IoImageOutline size="3rem" color={themeFile.COLORS.gray} />
          <Text>{noImageText}</Text>
        </PreviewContainer>
      )}
    </Container>
  );
};

export default AttachPlaceholder;
