import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdError } from "react-icons/md";
import { useTheme } from "../../../hooks/useTheme";

import { useAuth } from "../../../hooks/useAuth";

import { Container } from "./styles";

// import { icons } from "../../../constants";

const Error = ({
  text = "Sessão expirada. Clique para fazer login novamente.",
  errorCode,
  pushUrl = "/login",
  ...rest
}) => {
  const navigate = useNavigate();
  const { themeFile } = useTheme();
  const { setSocket, updateUser, socket } = useAuth();

  const [showError, setShowError] = useState(true);

  const handleError = (code) => {
    // console.log(code);
    // console.log(text);
    switch (code) {
      case 400:
        setShowError(false);
        break;
      case 401:
        localStorage.removeItem(`[@${process.env.REACT_APP_NAME}User]`);
        localStorage.removeItem(`[@${process.env.REACT_APP_NAME}Token]`);
        // localStorage.removeItem(`[@${process.env.REACT_APP_NAME}SessionID]`);
        socket?.disconnect();
        setSocket(null);
        updateUser(null);
        navigate(pushUrl);
        setShowError(false);
        break;
      // case 403:
      //   navigate("/");
      //   setShowError(false);
      //   break;
      // case 406:
      //   navigate("/painel");
      //   setShowError(false);
      //   break;
      case 500:
        setShowError(false);
        break;
      default:
        break;
    }
  };

  return (
    showError && (
      <Container {...rest} onClick={() => handleError(errorCode)}>
        <MdError size={54} color={themeFile.COLORS.hardRed} />
        <span>
          {errorCode === 500 ? "Sistema indisponível no momento." : text}
        </span>
      </Container>
    )
  );
};

export default Error;
