import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import {
  Container,
  // Search,
  // IconBox,
  // ListContent,
  ListItem,
  // Info,
  // Button,
  // Update,
  // Title,
  Table,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
// import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import ReportTable from "../CoursesReport/Component/ReportTable";

import useApi from "../../hooks/useApi";
// import { useAuth } from "../../hooks/useAuth";

// import { images, icons } from "../../constants";

const CoursesManager = () => {
  // const { user } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const [courseInfo, setCourseInfo] = useState({});
  const [controlPage, setControlPage] = useState({ListAtividadeOpen:false,ListInscritosOpen:false,RelatorioOpen:false});
  const [listInfoSubscribe, setListInfoSubscribe] = useState([]);
  const [listActivities, setListActivities] = useState([]);
  const [relatorioLoading, setRelatorioLoading] = useState(false);

  const [loadCourse, loadCourseInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("course by user", response.data[0]);
        setCourseInfo(response.data[0]);
        if(!(response.data[0].isEditor||response.data[0].isTutor)){
          navigate(`/home2`);
        }
      }
    },
  });

  const [listSubscribe, ListSubscribeInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/subscription",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listSubscribe", response.data);
        setListInfoSubscribe(response.data);
      }
    },
  });

  const [loadListActivities, LoadListActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listActivities", response.data);
        setListActivities(response.data);
      }
    },
  });

  useEffect(() => {
    // console.log("Entrou em course Manager");
    loadCourse({
      params:{
        id,
      }
    });

    listSubscribe({
      params: {
        id,
      },
    });

    loadListActivities({
      params: {
        idContent:id,
      },
    });

    // eslint-disable-next-line
  }, []);

  const handleDownload = () => {
    let maxWidth = [
      5,
      10,
      15,
      10,
      10,
      10
    ]

    listInfoSubscribe.forEach((sub,index)=>{
      if(sub.RGF?.length>maxWidth[0])maxWidth[0]=sub.RGF.length;
      if(sub.Nome?.length>maxWidth[1])maxWidth[1]=sub.Nome.length;
      if(sub.CPF?.length>maxWidth[2])maxWidth[2]=sub.CPF.length;
      if(sub.email?.length>maxWidth[3])maxWidth[3]=sub.email.length;
      if(sub.Escola?.length>maxWidth[4])maxWidth[4]=sub.Escola.length;
      if(sub.Classe?.length>maxWidth[5])maxWidth[5]=sub.Classe.length;
    });

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById("tableCourses"));
    maxWidth.forEach((width)=>{worksheet['!cols'].push({ wch: width+2 })});
    // console.log(worksheet);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    XLSX.writeFile(workbook, `${courseInfo?.title}.xlsx`, { compression: true });
  };



  return (
    <Container>
      {(loadCourseInfo.loading ||
       ListSubscribeInfo.loading ||
       LoadListActivitiesInfo.loading ||
       relatorioLoading) && <FullScreenLoading />}
      <Menu />
      <Banner
        // icon={images.iconNovidades}
        url={`/formacoes/editar/${courseInfo?.id}`}
        editorId={"0e3b686a-9b75-4de0-a2b7-28ec578e8119"}
      >
        Curso: {courseInfo?.title}
      </Banner>

      {/* <Search>
        <InputSearch
          value={search}
          onChange={handleSearch}
          onClick={handleSearch}
        />
      </Search> */}

      <ContentBox>

        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate("/coursemanager/list");}}
        >
          <MdArrowLeft fontSize={36}/> Voltar
        </ListItem>

        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={controlPage.ListAtividadeOpen} 
                 onClick={()=>{setControlPage({...controlPage,ListAtividadeOpen:!controlPage.ListAtividadeOpen})}}
        >
          Lista de Atividades {controlPage.ListAtividadeOpen?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
        </ListItem>

        <ListItem row={true} 
                  reduct={0} 
                  justify={"space-around"}
                  visible={controlPage.ListAtividadeOpen} 
        >
          {(courseInfo.isTutor || courseInfo.isEditor) &&<ListItem 
            visible={true}
            onClick={()=>{navigate(`/coursemanager/${id}/activities/add/course`)}} 
            divide={4} 
            height={5}
            border={true}
            roundborder={true} 
            pointer={true}
            selected={true}>
              Criar Atividade para a Turma
          </ListItem>}
          {courseInfo.segment!==null && courseInfo.isEditor && <ListItem 
            visible={true}
            onClick={()=>{navigate(`/coursemanager/${id}/activities/add/segment`)}} 
            divide={4} 
            height={5}
            roundborder={true} 
            border={true}
            pointer={true}
            selected={true}>
              Criar Atividade para o Segmento
          </ListItem>}
          {(courseInfo.isTutor || courseInfo.isEditor) &&<ListItem 
            visible={true}
            onClick={()=>{navigate(`/courseregistermanager/${id}`)}} 
            divide={4} 
            height={5}
            border={true}
            roundborder={true} 
            pointer={true}
            selected={true}>
              Registro de Atividades da Turma
          </ListItem>}
          
        </ListItem>

        {listActivities.sort().map((activity)=>(<ListItem border={true} reduct={0} padV={0} row={true} visible={controlPage.ListAtividadeOpen}>
          <ListItem visible={true} reduct={30} align={"flex-start"} padW={2.5} padV={0} marTop={0}>
            {activity.module!==null && <>{activity.module===0?"Modulo único":`Modulo: ${activity.module}` }<br/></>}
            {activity.title}<br/>
            {activity.description}<br/>
            Carga Horaria: {activity.activityWorkload}<br/>
            Periodo da atividade: {format(parseInt(activity.activityBegin), " dd/MM/yyyy ", {locale: ptBR,})}{` - `}{format(parseInt(activity.activityEnd), " dd/MM/yyyy ", {locale: ptBR,})}
          </ListItem>

          <ListItem visible={true} reduct={70} marTop={0} justify={"space-between"}>
            <ReadMoreButton onClick={()=>{navigate(`/coursemanager/${id}/activities/edit/${activity.idContentActivity}`)}} text="Editar Atividade" />
          </ListItem>
        </ListItem>))}

        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={controlPage.ListInscritosOpen} 
                 onClick={()=>{setControlPage({...controlPage,ListInscritosOpen:!controlPage.ListInscritosOpen})}}
        >
          Lista de Inscritos do Curso {controlPage.ListInscritosOpen?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
        </ListItem>
        <ListItem reduct={0} visible={controlPage.ListInscritosOpen}>
          {<Table id="tableCourses">
              <thead>
                <tr><th colSpan="6">{courseInfo?.title}</th></tr>
                <tr>
                  <th>RGF</th>
                  <th>Nome</th>
                  <th className='cpfClass'>CPF</th>
                  <th className='emailClass'>E-mail</th>
                  <th>Escola</th>
                  <th className='classeClass'>Classe</th>
                </tr>
              </thead>
              <tbody>
                {listInfoSubscribe.map((sub,index)=>(<tr key={index}>
                  <td>{sub.RGF}</td>
                  <td>{sub.Nome}</td>
                  <td>{sub.CPF}</td>
                  <td>{sub.email && sub.email}</td>
                  <td>{sub.Escola}</td>
                  <td>{sub.Classe}</td>
                </tr>))}
              </tbody>
            </Table>}
            {<ReadMoreButton onClick={handleDownload} text="Download xlsx" />}  
        </ListItem>

        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={controlPage.RelatorioOpen} 
                 onClick={()=>{setControlPage({...controlPage,RelatorioOpen:!controlPage.RelatorioOpen})}}
        >
          Relatório do Curso {controlPage.RelatorioOpen?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
        </ListItem>
        
        {id!=="" && <ReportTable 
          id={id}
          tablevisible={controlPage.RelatorioOpen}
          loading={(load)=>{setRelatorioLoading(load)}}
        />}
        
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default CoursesManager;
