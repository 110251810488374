import styled, { css } from "styled-components";
// import { images } from "../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  color: var(--primary);
  background-color: var(--secondary);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -2.5rem;
  position: relative;
  width: 100%;
`;

export const IconBox = styled.div`
  margin: 0rem 1rem;
  padding-top: 1rem;
  img{
    width: ${(props) => props.width ? css`${props.width}rem` : css`10rem`};
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;
  flex-wrap:wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:${(props) => props.info ? css`space-between` : css`center`};
  flex-wrap:wrap;
  width: ${(props) => (props.divide ? css`${props.divide>2?98/3:98/props.divide}%` : props.reduct ? (css`${100-props.reduct}%`): css`100%`) };
  ${(props) => props.height && css`height: ${props.height}rem;`};
  margin: 1rem 0 0 0;
  cursor: ${(props) => props.notpointer ? css`auto` : css`pointer`};
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
  height: 9vh;
`;

export const Info = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  flex-wrap:nowrap;
  width: 80%;
  margin: 0;
  padding: 1rem;
  border: 0;
  background-color:var(--secondary);
  color:var(--primary);
  p {
    display: flex;
    width: 100%;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;
    justify-content:flex-start;
  }
  title{
    display: flex;
    width: 100%;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;
    justify-content:flex-start;
    font-weight: bold
  }
`;

export const Update = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0 0 0.5rem 1rem;
  padding: 0;
  border: 0;
  background-color:var(--secondary);
  color:var(--gray);
`;

export const Button = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-end;
  justify-content:center;
  flex-wrap:nowrap;
  width: 20%;
  margin: 0;
  padding: 1rem;
  border: 0;
  background-color:var(--secondary);
  color:var(--primary);
`;


