import React, { useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useTheme } from "../../hooks/useTheme";
import { useAuth } from "../../hooks/useAuth";
import { MdClose } from "react-icons/md";
import InputText from "../../components/UI/Inputs/InputText";
import InputPassword from "../../components/UI/Inputs/InputPassword";
import Accessibility from "../../components/UI/Accessibility";
import Footer from "../../components/UI/Footer";
import ContentBox from "../../components/UI/ContentBox";
import ConfirmButton from "../../components/UI/ConfirmButton";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import Error from "../../components/UI/Error";
import { Container, BackButton, Form, Item, Controls, Text } from "./styles";
import { icons } from "../../constants";

const Login = () => {
  const navigate = useNavigate();
  const { login, loginGoogle, sessionLoginInfo } = useAuth();
  const { themeFile } = useTheme();
  const { id, local } = useParams();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Digite o nome de usuário."),
      password: Yup.string().required("Digite sua senha."),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values);
      login({
        username: values.username,
        password: values.password,
        navigate,
        redirect:local,
      });
    },
  });

  const handleBack = () => {
    navigate("/");
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const handleLogin = async (googleData) => {
    // console.log(googleData);
    loginGoogle({
      googleData,
      navigate,
      redirect:local,
    });
  };

  useEffect(() => {
    sessionLoginInfo.timeout = false;
  });

  useEffect(() => {
    console.log("teste local",local);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {sessionLoginInfo.loading && <FullScreenLoading />}
      {sessionLoginInfo.status && (
        <Error errorCode={401} text="Login e/ou senha inválida." />
      )}
      <Accessibility />
      <BackButton onClick={handleBack}>
        <MdClose
          size={themeFile.SIZES.base * 30}
          color={themeFile.COLORS.primary}
        />
      </BackButton>
      <ContentBox>
        <Player
          autoplay={true}
          loop={true}
          controls={false}
          src={icons.logoCanalJSON}
          style={{ width: "20rem" }}
        />
        <Player
          autoplay={true}
          loop={true}
          controls={false}
          src={icons.cadeado}
          style={{ width: "15rem" }}
        />

        <Item>
          <GoogleOAuthProvider clientId="767716918091-ft5l97hruu13bpr4h0pq32iltv53ve3f.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleLogin(credentialResponse);
              }}
              onError={() => {
                return (
                  <Error
                    errorCode={401}
                    text="Falha ao tentar fazer login com sua conta Google."
                  />
                );
              }}
            />
            {/* <button onClick={handleLogout}>logout</button> */}
          </GoogleOAuthProvider>
        </Item>
        <Item>
          <Text>
            Utilize sua conta Google para acessar. Qualquer problema, por favor, envie um e-mail para canalpedagogico@se-pmmc.com.br
          </Text>
        </Item>
        {id==="teste" && <Form onSubmit={formik.handleSubmit} onKeyDown={handleEnter}>
          <Item>
            <InputText
              placeholder="Usuário"
              columns={1}
              name="username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="username"
              value={formik.values.username}
              error={
                formik.touched.username &&
                formik.errors.username &&
                formik.errors.username
              }
            />
          </Item>
          <Item>
            <InputPassword
              placeholder="Senha"
              columns={1}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="current-password"
              value={formik.values.password}
              error={
                formik.touched.password &&
                formik.errors.password &&
                formik.errors.password
              }
            />
          </Item>
          <Controls>
            <ConfirmButton
              text="Entrar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>}
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Login;
