import React, { useState, useEffect } from "react";
import { startOfDay } from "date-fns";
import DayPicker from "react-day-picker";
import { MdCalendarToday, MdRemoveCircleOutline } from "react-icons/md";
import "./day-picker.css";
import { format, isLastDayOfMonth, lastDayOfMonth, isDate } from "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  Container,
  Calendar,
  DateIcon,
  SelectDate,
  DateInput,
  DateLabel,
  InfoMessage,
} from "./styles";

const InputDate = ({
  name,
  getDate = null,
  setDate = null,
  calendarFontSize = 1.288,
  fontSize = 1.6,
  label = "Data",
  singleComponent = true,
  disabledBefore = null,
  disabledAfter = null,
  enabledWeek = null,
  enabledMonthlyDay = null,
  cancel = false,
  onChange = () => {},
  onBlur = () => {},
  value,
  setField,
  error,
  placeholder = "Selecione a Data",
  ...rest
}) => {
  const [CalendarOpen, setCalendarOpen] = useState(false);
  const [DateSet, setDateSet] = useState(false);
  const [DateDay, setDateDay] = useState(new Date());
  const [disableDays, setdisableDays] = useState();
  const [weekDay, setWeekDays] = useState([]);
  const [monthlyDay, setMonthlyDay] = useState();

  const verifyWeekDay = (day) => {
    if (weekDay !== null) {
      return weekDay.indexOf(day.getDay()) < 0;
    }
  };

  const verifyMonthlyDay = (day) => {
    if (isLastDayOfMonth(new Date(enabledMonthlyDay))) {
      // console.log(lastDayOfMonth(day));
      if (lastDayOfMonth(day).getDate() < monthlyDay) {
        return !isLastDayOfMonth(day);
      }
    }
    return monthlyDay !== day.getDate();
  };

  const configDisabled = () => {
    let temp = {};
    if (setDate) {
      setDateSet(true);
      setDateDay(setDate);
    }
    if (disabledBefore !== null) {
      temp = { ...temp, before: new Date(disabledBefore) };
    } else {
      temp = { ...temp, before: null };
    }
    if (disabledAfter !== null) {
      temp = { ...temp, after: new Date(disabledAfter) };
    } else {
      temp = { ...temp, after: null };
    }
    if (enabledMonthlyDay !== null) {
      temp = [temp, verifyMonthlyDay];
    }
    if (enabledWeek !== null) {
      temp = [temp, verifyWeekDay];
    }
    setdisableDays(null);
    setdisableDays(temp);
  };

  useEffect(() => {
    configDisabled();
    //eslint-disable-next-line
  }, [disabledBefore, disabledAfter, setDate, weekDay, monthlyDay]);

  useEffect(() => {
    setWeekDays(enabledWeek);
  }, [enabledWeek]);

  useEffect(() => {
    setMonthlyDay(new Date(enabledMonthlyDay).getDate());
  }, [enabledMonthlyDay]);

  const handleCalendarOpen = () => {
    if (isDate(setDate)) {
      setDateDay(new Date(setDate));
    }
    if (singleComponent) {
      setCalendarOpen((oldCalendarOpen) => !oldCalendarOpen);
    } else {
      if (disabledBefore !== null) {
        setdisableDays({ ...disableDays, before: new Date(disabledBefore) });
        setCalendarOpen((oldCalendarOpen) => !oldCalendarOpen);
      }
    }
    configDisabled();
  };

  const handleDayClick = (day, { disabled }) => {
    console.log("clicou no dia");
    if (getDate !== null) {
      if (!disabled) {
        setDateDay(day);
        setDateSet(true);
        getDate(day);
      }
    } else {
      setDateDay(day);
      setDateSet(true);
    }
    // setField(name, format(new Date(day), "dd/MM/yyyy"));
    console.log("setou a data");
    setField(name, startOfDay(new Date(day)).getTime());
    setCalendarOpen(false);
  };

  const cancelSelect = (event) => {
    setDateDay(null);
    setDateSet(false);
    if (getDate !== null) {
      getDate("");
    }
    event.stopPropagation();
  };

  const MONTHS = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const WEEKDAYS_LONG = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sabado",
  ];
  const WEEKDAYS_SHORT = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

  return (
    <ClickAwayListener onClickAway={() => setCalendarOpen(false)}>
      <Container {...rest}>
        <DateLabel fontSize={fontSize}>{label}</DateLabel>
        <DateInput fontSize={fontSize}>
          <SelectDate
            dateSet={DateSet}
            name={name}
            type="text"
            id={name}
            value={value && format(value, "dd/MM/yyyy")}
            readOnly
            onChange={onChange}
            onBlur={onBlur}
            onClick={() => {
              handleCalendarOpen();
            }}
          />
          <DateIcon>
            {cancel && DateSet && (
              <span
                className="remove"
                onClick={(event) => {
                  cancelSelect(event);
                }}
              >
                <MdRemoveCircleOutline />
              </span>
            )}
            <MdCalendarToday />
          </DateIcon>
        </DateInput>
        {error && <InfoMessage>*&nbsp;{error}</InfoMessage>}
        <Calendar isOpen={CalendarOpen} calendarFontSize={calendarFontSize}>
          <DayPicker
            onDayClick={handleDayClick}
            disabledDays={disableDays}
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            month={
              isDate(DateDay)
                ? new Date(DateDay)
                : isDate(disabledBefore)
                ? new Date(disabledBefore)
                : new Date()
            }
          />
        </Calendar>
      </Container>
    </ClickAwayListener>
  );
};

export default InputDate;
