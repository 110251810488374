import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding: 0.8rem;
  background-color: var(--purple);
  border-radius: 0.8rem;
  font: var(--body);
  color: var(--secondary);
  margin-bottom: 1rem;
  align-self: center;
  > p {
    text-align: center;
  }
`;
