import React, { useEffect, useState } from "react";
// import { format, isBefore, startOfYear, subMonths, startOfMonth } from "date-fns";
import {
  // MdArrowDropUp,
  // MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import {
  Container,
  // Search,
  // IconBox,
  // ListContent,
  ListItem,
  // Info,
  // Button,
  // Update,
  Area,
  ItemCourse,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
// import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import useApi from "../../hooks/useApi";
// import { useAuth } from "../../hooks/useAuth";

// import { images, icons } from "../../constants";

const CoursesManagerList = () => {
  // const { user } = useAuth();
  const navigate = useNavigate();
  const [coursesList, setCoursesList] = useState([]);

  const [loadCourse, loadCourseInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("course by user", response.data);
        let temp = response.data.filter((course)=>{
          return course.isEditor || course.isTutor;
        });
        console.log("temp", temp);
        setCoursesList(temp);
        if(!(temp[0]?.isEditor||temp[0]?.isTutor)){
          navigate(`/home2`);
        }
      }
    },
  });

  useEffect(() => {
    // console.log("Entrou em courses Manager");
    loadCourse();
    // eslint-disable-next-line
  }, []);



  // const controlList = (typeChange=0, segmentChange=0)=>{
  //   let list = [];
  //   coursesList.forEach((type)=>{
  //     if(segmentChange!==0){
  //       if(type.courseType===typeChange){
  //         type.segments.forEach((segment)=>{
  //           if(segment.segment===segmentChange){
  //             if(segment.open){
  //               segment.open=false;
  //             }
  //             else{
  //               segment.open=true;
  //             }
  //           }
  //         })
  //       }
  //     }
  //     else{
  //       if(type.courseType===typeChange){
  //         if(type.open){
  //           type.open=false;
  //         }
  //         else{
  //           type.open=true;
  //         }
  //       }
  //     }
  //     list.push(type);
  //   })
  //   setCoursesList(list);
  // };

  return (
    <Container>
      {loadCourseInfo.loading && <FullScreenLoading />}
      <Menu />
      <Banner
        // icon={images.iconNovidades}
      >
        Gestão de atividades dos cursos
      </Banner>

      {/* <Search>
        <InputSearch
          value={search}
          onChange={handleSearch}
          onClick={handleSearch}
        />
      </Search> */}

      <ContentBox>
        <Area visible={true}>
          <ListItem border={true} 
                  roundborder={true} 
                  pointer={true} 
                  row={true} 
                  reduct={0} 
                  visible={true} 
                  selected={true} 
                  onClick={()=>{navigate("/formacoes/manager");}}
          >
            <MdArrowLeft fontSize={36}/> Voltar para gestão de formação
          </ListItem>
          {coursesList.map((course)=>(
                    course.hasSubscription && <ItemCourse
                      key={course.id} 
                      visible={true}
                      reduct={0}
                      roundborder={false}>
                      <div className="text">{course.title}</div> 
                      <div className="button">
                        <div>
                          <ReadMoreButton onClick={()=>{navigate(`/coursemanager/${course.id}`)}} text="Gestão de atividades" />
                        </div>
                        <div>
                          <ReadMoreButton onClick={()=>{navigate(`/courseregistermanager/${course.id}`)}} text="Registro de atividades" />
                        </div>
                      </div>
                    </ItemCourse>
                  ))}
        </Area>
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default CoursesManagerList;
