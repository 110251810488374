import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  AvaliacaoTitle,
  AplicationTime,
  Orientation,
  Material,
  Plataforma,
  Result,
  Text,
  List,
  Link,
} from "./styles";
import { format, isSameDay } from "date-fns";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images } from "../../../constants";
import Banner from "../../../components/UI/Banner";

import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";
import ellipsis from "../../../utils/ellipsis";
import Visibility from "../../../components/UI/Visibility";
// import { Avaliacao } from "../../Avaliacoes/styles";

import { MdInsertDriveFile } from "react-icons/md";
import { BsDot } from "react-icons/bs";

const AvaliacoesView = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const navigate = useNavigate();

  const [avaliacao, setAvaliacao] = useState({tips:[],documents:[]});

  const isEditor = () => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"
    );
    return editor > -1 ? true : false;
  };

  const [loadAvaliacao, loadAvaliacaoInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/evaluation/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data.length>0){
          setAvaliacao({...response.data[0]});
        }
        else{
          if(user){
            navigate("/avaliacoes");
          }
          else{
            navigate(`/login/redirect/*avaliacoes*${id}`);
          }
        }
        console.log("AVA", response);
      }
    },
  });

  useEffect(() => {
    loadAvaliacao({
      params: {
        id,
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loadAvaliacaoInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconDocumentos}
        url={`/avaliacoes/editar/${avaliacao.id}`}
        editorId={"0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"}
      >
        {avaliacao.title && ellipsis(avaliacao.title,20)}
      </Banner>
      <ContentBox>

        <AvaliacaoTitle>
          {avaliacao.title}
        </AvaliacaoTitle>
        
        {avaliacao.begin && <AplicationTime>
          <Text>
            Período da aplicação:{" "}
          </Text>
          <Text>
            {format(
              new Date(parseInt(avaliacao.begin)),
              "dd/MM/yyyy"
            )}
          </Text>
          <Text>
            {
              !isSameDay(
                new Date(parseInt(avaliacao.begin)),
                new Date(parseInt(avaliacao.end))
              ) &&<><Text> - </Text><Text>{format(
                new Date(parseInt(avaliacao.end)),
                "dd/MM/yyyy"
              )}</Text></>
            }
          </Text>
        </AplicationTime>}
                  
        {avaliacao.tips.length>0 && <Orientation>

          <Text>
            Orientação:
          </Text>

          <List>

            {avaliacao.tips.map((tip, index)=>(
                <Link
                  href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/evaluation/${tip.url}`}
                  target="_blank"
                  key={index}
                >
                  <MdInsertDriveFile />
                  <span>{tip.originalName}</span>
                </Link>
            ))}

          </List>

        </Orientation>}

        {avaliacao.documents.length>0 && <Material>

          <Text>
            Materiais de Apoio:
          </Text>

          <List>

            {avaliacao.documents.map((tip, index)=>(
                <Link
                  href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/evaluation/${tip.url}`}
                  target="_blank"
                  key={index}
                >
                  <MdInsertDriveFile />
                  <span>{tip.originalName}</span>
                </Link>
            ))}

          </List>

        </Material>}

        {avaliacao.platform && (
          <Plataforma>
            <Text>
              Plataforma:{" "}
            </Text>
            <Text>
              {avaliacao.platform.indexOf("http") >= 0 ||
              avaliacao.platform.indexOf("www") >= 0 ? (
                  <Link href={avaliacao.platform} target="_blank">
                    {avaliacao.platform}
                  </Link>
              ) : (
                  <span>{avaliacao.platform}</span>
              )}
            </Text>
          </Plataforma>      
        )}

        {avaliacao.resultado && (
          <Result>

            <Link href={avaliacao.resultado}>
              <BsDot />
              Resultado
            </Link>

          </Result>
        )}

        {isEditor() && <Visibility visibility={avaliacao?.visibilities} align={"center"}/>}
        <CancelButton text="Voltar" onClick={()=>{navigate(-1)}}/>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default AvaliacoesView;
