import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  margin-top: 10rem;
  background-color: var(--secondary);
`;

export const Title = styled.h1`
  font: var(--title);
  margin: 4rem 0;
  color: var(--primary);
`;

export const Separator = styled.div`
  margin: 1.4rem 0;
  height: 0.1rem;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 90rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 4rem;
  gap: 2rem;

  @media (max-width: 700px) {
    padding: 0 1.6rem;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
`;

export const ItemFloor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
`;

export const Place = styled.span`
  color: var(--primary);
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-end;
  gap: 2rem;
`;

export const MeetContainer = styled.div`
  padding: 1rem;
  width: 100%;
  border: 1px solid black;
  border-radius: 1rem;
  position: relative;
`;

export const MeetTitle = styled.div`
  font-family: var(--fontFamily);
  font-size: calc(var(--size) * 1.6);
  font-weight: var(--mediumFont);
  color: var(--purple);
  margin: 0.6rem 0 1rem 0;
`;

export const DocumentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

export const DocumentFile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 2rem;
`;

export const DocumentUrl = styled.a`
  color: var(--primary);
`;

export const DocumentName = styled.span`
  color: var(--primary);
`;

export const DocumentSize = styled.span`
  color: var(--primary);
`;

export const DocumentDeleteButton = styled.button`
  background: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--hardRed);

  &:hover {
    transform: scale(1.1) rotate(20deg);
  }
`;

export const AddEvent = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 1rem;
  background-color: var(--purple);
  border-radius: 1rem;
  color: var(--secondary);
  cursor: pointer;
  font: var(--body);
`;

export const DeleteEvent = styled.button`
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: unset;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1) rotate(180deg);
  }
`;

export const DeleteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const DeleteButton = styled.button`
  background: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--hardRed);

  &:hover {
    transform: scale(1.1) rotate(20deg);
  }
`;

export const Grade = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ListButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-around;
  flex-wrap:nowrap;
  width: 100%;
  margin: 1rem 0 0 0;
  background-color: var(--secondary);
`;

export const InputSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  flex-wrap:nowrap;
  width: 50rem;
  margin: 1rem 0 0 0;
  background-color: var(--secondary);
`;