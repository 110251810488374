import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const ImgCemforpe = styled.img`
  width: 60%;
  border-radius: 1rem;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-align: justify;
  font: var(--body);
`;

export const ButtonAcessar = styled.b`
  display: flex;
  font: var(--body);
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-align: justify;
  font: var(--title);
`;

export const BoxNames = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ContentNames = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 100%;
  margin-bottom: 3rem;
  
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const TextItem = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-align: justify;
  font-family: var(--familyBody);
  padding: 0 0 0 4rem;
  line-height: 1.3rem;
  font-size: calc(var(--size) * 1.6);
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: justify;
  line-height: 0.8rem;
  font-family: var(--familyBody);
  font-size: calc(var(--size) * 1.3);
`;

export const Map = styled.iframe`
  width: 100%;
  max-width: 45rem;
  height: 25rem;
  border-radius: 1rem;
  margin: 1rem;
`;

export const Link = styled.div`
  transition: transform 0.3s ease;
  cursor: pointer;

  :hover {
    transform: scale(0.9);
  }
`