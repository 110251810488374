import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth } from "../../hooks/useAuth";

const PrivateRouteTest = () => {
  const { user } = useAuth();
  const editor = user?.profiles[0]?.editor?.findIndex(
    (edit) => edit.id === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"
  );

  return editor > -1 ? <Outlet /> : <Navigate to="/login/redirect/*avaliacoes" />;
};

export default PrivateRouteTest;
