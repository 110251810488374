import React from "react";
import { useNavigate } from "react-router";
import { Container } from "./styles";

const CancelButton = ({ text, disabled = false, ...rest }) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Container
      disabled={disabled}
      onClick={handleCancel}
      {...rest}
      type="button"
    >
      {text}
    </Container>
  );
};

export default CancelButton;
