import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import { Container, Title, Form, Item, Place, Controls } from "./styles";
import InputSelect from "../../../components/UI/Inputs/InputSelect";
import InputRadio from "../../../components/UI/Inputs/InputRadio";
import InputText from "../../../components/UI/Inputs/InputText";
import InputDate from "../../../components/UI/Inputs/InputDate";
import InputCheck from "../../../components/UI/Inputs/InputCheck";
import InputTime from "../../../components/UI/Inputs/InputTime";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";

const today = new Date().getTime();

const ScheduleForm = ({ action }) => {
  const [categories, setCategories] = useState([]);
  const { user } = useAuth();
  const [visibilities, setVisibilities] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const [saveEvent, saveEventInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/calendar",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("salvou", response);
        navigate("/agenda");
        // setVisibilities(response.data);
      }
    },
  });

  const [editEvent, editEventInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/calendar",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("editou", response);
        navigate("/agenda");
        // setValues on Formik initial load
        // setVisibilities(response.data);
      }
    },
  });

  const [deleteEvent, deleteEventInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/calendar",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("deleted", response);
        navigate("/agenda");
        // setVisibilities(response.data);
      }
    },
  });

  const [loadEvent, loadEventInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/calendar",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("event", response);
        if (response.data.length > 0) {
          formik.setFieldValue("description", response.data[0].description);
          // new visibilities
          let vis = [];
          if (response.data[0].visibilities.length > 0) {
            response.data[0].visibilities.forEach((oldVis) => {
              vis.push(oldVis.idvisibility);
            });
          }
          const initial = {
            ...response.data[0],
            visibilities: vis,
          };
          formik.setValues(initial);
        } else {
          navigate("/agenda/add");
        }
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      category: "",
      eventDate: today,
      begin: "",
      end: "",
      title: "",
      description: "",
      team: "",
      formType: "public",
      visibilities: [],
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Escolha uma categoria."),
      eventDate: Yup.number().required("Selecione a data de início."),
      begin: Yup.number().required("Selecione o horário de início."),
      end: Yup.number()
        .required("Selecione o horário de término.")
        .moreThan(
          Yup.ref("begin"),
          "Horário de término não pode ser menor que a horário de início."
        ),
      title: Yup.string().required("Digite um título para o evento"),
      description: Yup.string(),
      formType: Yup.string(),
      team: Yup.string(),
      visibilities: Yup.array().when("formType", {
        is: "private",
        then: Yup.array().min(1, "Selecione 1"),
      }),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      //send to API
      if (action === "add") {
        saveEvent({
          data: values,
        });
      } else {
        editEvent({
          data: values,
        });
      }
    },
  });

  // console.log(formik.errors);
  // console.log(formik.isValid);

  const [loadVisibilities, loadVisibilitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/visibility",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response);
        setVisibilities(response.data);
      }
    },
  });

  const [loadCategories, loadCategoriesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/category",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response);
        setCategories(response.data);
      }
    },
  });

  const handleDelete = (eventId) => {
    const confirm = window.confirm("Deseja apagar esse evento?");

    if (confirm) {
      deleteEvent({
        data: {
          id: eventId,
        },
      });
    }
  };

  useEffect(() => {
    async function load() {
      await loadCategories({
        params: {
          id: "890798c6-a166-4b87-a059-11d4bbf6a1ac",
          idUserHasProfile: user.profiles[0].idUserHasProfile,
        },
      });
      await loadVisibilities();
      if (action === "edit") {
        // console.log("id", id);
        await loadEvent({
          params: {
            id,
          },
        });
      }
      console.log("agora aqui");
    }
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {(loadCategoriesInfo.loading ||
        loadVisibilitiesInfo.loading ||
        saveEventInfo.loading ||
        editEventInfo.loading ||
        loadEventInfo.loading ||
        deleteEventInfo.loading) && <FullScreenLoading />}
      {(loadCategoriesInfo.status ||
        loadVisibilitiesInfo.status ||
        saveEventInfo.status ||
        editEventInfo.status ||
        loadEventInfo.status ||
        deleteEventInfo.status) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>Agenda</Title>

        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}

          <Item>
            <InputSelect
              columns={1}
              name="category"
              error={
                formik.touched.category &&
                formik.errors.category &&
                formik.errors.category
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              placeholder="Categoria"
            >
              <option value="" disabled defaultValue>
                Selecione a categoria
              </option>
              {categories.length > 0 &&
                categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.category}
                  </option>
                ))}
            </InputSelect>
          </Item>
          <Item>
            <InputDate
              label="Data"
              name="eventDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.eventDate}
              setField={formik.setFieldValue}
              error={
                formik.touched.eventDate &&
                formik.errors.eventDate &&
                formik.errors.eventDate
              }
            />
          </Item>
          <Item>
            <InputTime
              label="Horário de Início"
              name="begin"
              value={formik.values.begin}
              setField={formik.setFieldValue}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.begin &&
                formik.errors.begin &&
                formik.errors.begin
              }
            />
            <InputTime
              label="Horário de Término"
              name="end"
              value={formik.values.end}
              setField={formik.setFieldValue}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.begin && formik.errors.end && formik.errors.end
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Título do evento"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Descrição do evento"
              columns={90}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description &&
                formik.errors.description &&
                formik.errors.description
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Equipe envolvida"
              columns={90}
              name="team"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.team}
              error={
                formik.touched.team && formik.errors.team && formik.errors.team
              }
            />
          </Item>
          <Item>
            <Place>Visibilidade</Place>
            <InputRadio
              id="public"
              name="formType"
              text="Público"
              onChange={formik.getFieldProps("formType").onChange}
              value="public"
              checked={formik.getFieldProps("formType").value === "public"}
            />
            <InputRadio
              id="private"
              name="formType"
              text="Restrito"
              onChange={formik.getFieldProps("formType").onChange}
              value="private"
              checked={formik.getFieldProps("formType").value === "private"}
            />
          </Item>
          {formik.values.formType === "private" && (
            <Item>
              {visibilities.length > 0 &&
                visibilities.map((vis) => (
                  <InputCheck
                    id={vis.id}
                    key={vis.id}
                    value={vis.id}
                    name="visibilities"
                    text={vis.description}
                    onChange={formik.handleChange}
                    checked={formik.values.visibilities.includes(vis.id)}
                  />
                ))}
            </Item>
          )}
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default ScheduleForm;
