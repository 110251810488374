import React, { useEffect, useState } from "react";
import { format, isBefore, subMonths, startOfMonth } from "date-fns";
import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import {
  Container,
  // Search,
  IconBox,
  ListContent,
  ListItem,
  Info,
  Button,
  Update,
  Link,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
// import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

import { images, icons } from "../../constants";

const Home = () => {
  // const [search, setSearch] = useState("");
  const [listNews, setListNews] = useState([]);
  const { user } = useAuth();
  const [controlPage, setControlPage] = useState({
    mainCategory:{name:"",new:false,index:0},
    category:{name:"",new:false,index:0},
    segment:{name:"",new:false,index:0},
  });

  // eslint-disable-next-line
  const [loadNovidades, loadNovidadesInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/search/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        response.data.data.forEach((mainCategory) => {
          mainCategory.new = false;
          mainCategory.qtd = mainCategory.categories.length;
          // console.log("mainCategory.categories",mainCategory.categories)
          mainCategory.categories.forEach((category)=>{
            category.new = false;
            if(category.contents){
              category.qtd=category.contents.length;
              category.seg=false;
            }else{
              category.qtd=category.segments.length;
              category.seg=true;
            }
            category.contents?.forEach((item)=>{
              if(isBefore(new Date(user.dados.lastAccess),new Date(item.updated_at))){
                category.new = true;
                mainCategory.new = true;
                item.new = true;
              }
            });
            category.segments?.forEach((item)=>{
              if(isBefore(new Date(user.dados.lastAccess),new Date(item.updated_at))){
                category.new = true;
                mainCategory.new = true;
                item.new = true;
              }
            });
            category.segments?.forEach((segment)=>{
              segment.new = false;
              segment.qtd = segment.contents.length;
              segment.contents.forEach((item)=>{
                if(isBefore(new Date(user.dados.lastAccess),new Date(item.updated_at))){
                  category.new = true;
                  mainCategory.new = true;
                  segment.new = true;
                  item.new = true;
                }
              });
            });
          });
        });
        console.log("Novidades: ", response.data);
        setListNews(response.data.data); 
      }
    },
  });

  const handleControl = (mainCategory, category={name:"",new:false,index:0}, segment={name:"",new:false,index:0}) => {
    if(listNews.length === 1){
      mainCategory={
        name:listNews[0].mainCategoryName,
        new:listNews[0].new,
        index:0,
      }
    }
    if(mainCategory.name!=="" && listNews[mainCategory.index].qtd===1){
      category={
        name:listNews[mainCategory.index].categories[0].categoryName,
        new:listNews[mainCategory.index].categories[0].new,
        index:0,
      }
    }
    if(mainCategory.name!=="" && category.name!=="" && listNews[mainCategory.index].categories[category.index].seg){
      if(listNews[mainCategory.index].categories[category.index].qtd===1){
        segment={
          name:listNews[mainCategory.index].categories[category.index].segments[0].segmentName,
          new:listNews[mainCategory.index].categories[category.index].segments[0].new,
          index:0,
        }
      }
    }
    setControlPage({
      mainCategory, 
      category, 
      segment,
    });
  };

  // const handleSearch = (event)=>{
  //   setSearch(event.target.value);
  // }

  const navigate = useNavigate();

  const handleView = (category,id) => {
    switch(category){
      case "Avaliações":
        navigate(`/avaliacoes/${id}`);
        break;
      case "Formações":
        navigate(`/formacoes/${id}`);
        break;
      case "Documentos":
        navigate(`/documentos/${id}`);
        break;
      case "Agenda":
        navigate(`/agenda/${id}`);
        break;
      case "Biblioteca":
        navigate(`/biblioteca/${id}`);
        break;
      case "Videoteca":
        navigate(`/videoteca/${id}`);
        break;
      case "Recursos Educacionais Digitais":
        navigate(`/reds/${id}`);
        break;   
      default :
        alert("Categoria não reconhecida pelo sistema");
        break;
    }
  };

  const handleIcon = (category) => {
    let icon = "";
    switch(category){
      case "Avaliações":
        icon = icons.indicadores_purple;
        break;
      case "Formações":
        icon = icons.formacoes_purple;
        break;
      case "Documentos":
        icon = icons.documentos_purple;
        break;
      case "Agenda":
        icon = icons.agenda_purple;
        break;
      case "Biblioteca":
        icon = icons.biblioteca_purple;
        break;
      case "Videoteca":
        icon = icons.videoteca_purple;
        break;  
      case "Recursos Educacionais Digitais":
        icon = icons.reds_purple;
        break;  
      default :
        alert("Categoria não reconhecida pelo sistema");
        break;
    }
    return icon;
  };

  const verifyMainCategory = (mainCategory) => {
    return mainCategory!=="Videoteca" && mainCategory!=="Biblioteca";
  }

  useEffect(() => {
    loadNovidades({
      params: {
        idUserHasProfile:user.profiles[0]?.idUserHasProfile,
        // idCategory:"",
        // limit:0,
        // initial:0,
        lastAccess:subMonths(startOfMonth(new Date()),1),
        // lastAccess:startOfYear(new Date()),
      },
    });
    console.log("User",user);
    // console.log("Last Access", new Date(user.dados.lastAccess))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleControl(
      {name:"",new:false,index:0},
      {name:"",new:false,index:0},
      {name:"",new:false,index:0},
    );
    // eslint-disable-next-line
  }, [listNews]);

  useEffect(() => {
    console.log("controlPage",controlPage)
    // eslint-disable-next-line
  }, [controlPage]);

  return (
    <Container>
      {loadNovidadesInfo.loading && <FullScreenLoading />}
      <Menu />
      <Banner
        icon={images.iconNovidades}
      >
        Novidades
      </Banner>

      {/* <Search>
        <InputSearch
          value={search}
          onChange={handleSearch}
          onClick={handleSearch}
        />
      </Search> */}

      <ContentBox>
        <div 
                key={"help"} 
                reduct={0}
                height={10} 
                >
          Aprenda como fazer o acompanhamento da sua formação acessando o link: <Link onClick={()=>{navigate(`/ajudaformacao`);}}>
            {`${window.location.protocol}//${window.location.hostname}/ajudaformacao`}
          </Link>
        </div>
        {/* Lista Main Category */}
        {controlPage.mainCategory.name===""?
          <ListContent>
            {listNews.map((mainCategory,index)=>(
              <ListItem 
                key={mainCategory.mainCategory} 
                onClick={()=>{handleControl(
                  {
                    name:mainCategory.mainCategoryName,
                    new:mainCategory.new,
                    index
                  })}} 
                reduct={0}
                divide={listNews.length}
                height={10}
                roundborder={true} 
                selected={false}>
                  <IconBox width={5}><img src={handleIcon(mainCategory.mainCategoryName)} alt=""/></IconBox>
                  {mainCategory.mainCategoryName}
                  <MdArrowDropDown fontSize={36}/>
                  {mainCategory.new && <IconBox><img src={icons.novo_white} alt=""/></IconBox>}
              </ListItem>
            ))}            
          </ListContent>:
          <ListContent>
            <ListItem 
                onClick={()=>{handleControl({name:"",new:false})}} 
                reduct={0} 
                roundborder={true} 
                selected={true}>
                  {controlPage.mainCategory.name}
                  {listNews.length>1 && <MdArrowDropUp fontSize={36}/>}
                  {controlPage.mainCategory.new && <IconBox><img src={icons.novo} alt=""/></IconBox>}
              </ListItem>
          </ListContent>}
        {/* Fim Lista Main Category */}

        {/* Lista Category */}
        {(controlPage.mainCategory.name!=="" && verifyMainCategory(controlPage.mainCategory.name)) && 
        (controlPage.category.name===""?
          <ListContent>
            {listNews[controlPage.mainCategory.index].categories.map((category,index)=>(
              <ListItem 
                key={category.category} 
                onClick={()=>{handleControl(
                    {
                      name:controlPage.mainCategory.name,
                      new:controlPage.mainCategory.new,
                      index:controlPage.mainCategory.index
                    },
                    {
                      name:category.categoryName,
                      new:category.new,
                      index,
                    })}} 
                reduct={0}
                divide={listNews[controlPage.mainCategory.index].categories.length} 
                height={10}
                roundborder={true} 
                selected={false}>
                  {category.categoryName}
                  <MdArrowDropDown fontSize={36}/>
                  {category.new && <IconBox><img src={icons.novo_white} alt=""/></IconBox>}
              </ListItem>
            ))}            
          </ListContent>:
          <ListContent>
            <ListItem 
                onClick={()=>{handleControl(
                    {
                      name:controlPage.mainCategory.name,
                      new:controlPage.mainCategory.new,
                      index:controlPage.mainCategory.index
                    },
                    {
                      name:"",
                      new:false,
                      index:0
                    })}} 
                reduct={0} 
                roundborder={true} 
                selected={true}>
                  {controlPage.category.name}
                  {listNews[controlPage.mainCategory.index].categories.length>1 && <MdArrowDropUp fontSize={36}/>}
                  {controlPage.category.new && <IconBox><img src={icons.novo} alt=""/></IconBox>}
              </ListItem>
          </ListContent>)}
        {/* Fim Lista Category */}

        {/* Lista Segment */}
        {(controlPage.mainCategory.name==="Formações" && controlPage.category.name!=="" && controlPage.category.name!=="Formação Complementar") && 
        (controlPage.segment.name===""?
          <ListContent>
            {listNews[controlPage.mainCategory.index].categories[controlPage.category.index].segments.map((segment,index)=>(
              <ListItem 
                key={segment.segment} 
                onClick={()=>{handleControl(
                    {
                      name:controlPage.mainCategory.name,
                      new:controlPage.mainCategory.new,
                      index:controlPage.mainCategory.index
                    },
                    {
                      name:controlPage.category.name,
                      new:controlPage.category.new,
                      index:controlPage.category.index,
                    },
                    {
                      name:segment.segmentName,
                      new:segment.new,
                      index,
                    })}} 
                reduct={0} 
                divide={listNews[controlPage.mainCategory.index].categories[controlPage.category.index].segments.length} 
                height={10}
                roundborder={true} 
                selected={false}>
                  {segment.segmentName===null?"Outros":segment.segmentName}
                  <MdArrowDropDown fontSize={36}/>
                  {segment.new && <IconBox><img src={icons.novo_white} alt=""/></IconBox>}
              </ListItem>
            ))}            
          </ListContent>:
          <ListContent>
            <ListItem 
                onClick={()=>{handleControl(
                    {
                      name:controlPage.mainCategory.name,
                      new:controlPage.mainCategory.new,
                      index:controlPage.mainCategory.index
                    },
                    {
                      name:controlPage.category.name,
                      new:controlPage.category.new,
                      index:controlPage.category.index,
                    },
                    {
                      name:"",
                      new:"",
                      index:0,
                    })}} 
                visible={true} 
                reduct={0} 
                roundborder={true} 
                selected={true}>
                  {controlPage.segment.name===null?"Outros":controlPage.segment.name}
                  {listNews[controlPage.mainCategory.index].categories[controlPage.category.index].segments?.length>1 && <MdArrowDropUp fontSize={36}/>}
                  {controlPage.segment.new && <IconBox><img src={icons.novo} alt=""/></IconBox>}
              </ListItem>
          </ListContent>)}
        {/* Fim Lista Segment */}

        {/* Lista Content */}
        {
          (controlPage.mainCategory.name!=="" && controlPage.category.name!=="" && controlPage.mainCategory.name!=="Formações") && 
            <ListContent>
              {listNews[controlPage.mainCategory.index].categories[controlPage.category.index].contents.map((conteudo)=>(
                <ListItem key={conteudo.id} reduct={10} notpointer={true}>
                  <Info>
                    <title>{conteudo.title}{conteudo.new && <IconBox><img src={icons.novo_white} alt=""/></IconBox>}</title>
                    {controlPage.mainCategory.name==="Avaliação" && <p>Período de aplicação: {format(new Date(conteudo.begin),"dd/MM/yyyy")} - {format(new Date(conteudo.end),"dd/MM/yyyy")}</p>}                      
                    {controlPage.mainCategory.name==="Agenda" && <p>Data: {format(new Date(conteudo.eventDate),"dd/MM/yyyy")}</p>}
                    {controlPage.mainCategory.name==="Agenda" && <p>Horario: {format(new Date(conteudo.begin),"HH:mm")} - {format(new Date(conteudo.end),"HH:mm")}</p>}
                  </Info>
                  <Button>
                    <ReadMoreButton onClick={()=>{handleView(controlPage.mainCategory.name,conteudo.id)}} text="Leia +" />
                  </Button>
                  <Update>Atualizado em: {format(new Date(conteudo.updated_at),"dd/MM/yyyy - HH:MM")}</Update>
                </ListItem>
              ))}
            </ListContent>          
        }
        {
          (controlPage.mainCategory.name!=="" && controlPage.category.name!=="" && controlPage.segment.name!=="") && 
            <ListContent>
              {listNews[controlPage.mainCategory.index].categories[controlPage.category.index].segments[controlPage.segment.index].contents.map((conteudo)=>(
                <ListItem reduct={10} notpointer={true}>
                  <Info>
                    <title>{conteudo.title}{conteudo.new && <IconBox><img src={icons.novo_white} alt=""/></IconBox>}</title>
                    <p>Período de inscrição: {format(new Date(conteudo.subscriptionBegin),"dd/MM/yyyy")} - {format(new Date(conteudo.subscriptionEnd),"dd/MM/yyyy")}</p>
                  </Info>
                  <Button>
                    <ReadMoreButton onClick={()=>{handleView(controlPage.mainCategory.name,conteudo.id)}} text="Leia +" />
                  </Button>
                  <Update>Atualizado em: {format(new Date(conteudo.updated_at),"dd/MM/yyyy - HH:MM")}</Update>
                </ListItem>
              ))}
            </ListContent>          
        }
        {/* Fim Lista Content */}
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default Home;
