import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const ListContent = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
`;

export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction: ${(props) => props.row ? css`row` : css`column`};
  align-items:${(props) => props.align ? css`${props.align}` : css`center`};
  justify-content:${(props) => props.info ? css`space-between` : css`center`};
  ${(props) => props.justify && css`justify-content:${props.justify};`}
  ${(props) => props.height && css`min-height: ${props.height}rem;`}
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  cursor: ${(props) => props.pointer ?css`pointer`:css`auto`};
  padding: ${(props) => props.padV ? css`${props.padV}rem`: css`1rem`} ${(props) => props.padW ? css`${props.padW}rem`:css`0rem`};
  ${(props) => props.border && css`border: 1px solid var(--primary);`}
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  color:var(--secondary);
  ${(props) => props.selected ? 
    css`
      background-color: var(--purple);
      
    `:
    css`
      background: linear-gradient(
        to right,
        green 0%,
        green ${props.porcento}%,
        red ${props.porcento}%,
        red 100%
      );
    `
  }
  div.row{
    display: ${(props) => props.visible ? css`flex` : css`none`};
    width: 100%;    
    flex-direction: row;
    justify-content: space-around;
  }
`;
