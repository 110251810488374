import styled, { css } from "styled-components";
import { images } from "../../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 10rem;
  width: 100%;
  height: 21rem;
  ${(props) =>
    props.highContrast
      ? css`
          background-image: url(${images.bannerBackgroundHighContrast});
        `
      : css`
          background-image: url(${images.bannerBackground});
        `}

  background-size: cover;
  padding: 0 10rem 0 25rem;

  @media (max-width: 800px) {
    padding: 0;
    height: 16rem;
  }

  @media (max-width: 555px) {
    /* padding: 0 3rem 0 10rem; */
    height: 16rem;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

export const TitleBanner = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--size) * 4);
  font-weight: 600;
  font-family: var(--title);
  text-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0.5, 0.5);
  color: var(--secondary);

  @media (max-width: 555px) {
    flex: initial;
    font-size: calc(var(--size) * 2);
    margin-bottom: 1.6rem;
  }
`;

export const IconBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;

  @media (max-width: 800px) {
    width: 10rem;
  }

  @media (max-width: 480px) {
    width: 7rem;
  }
`;

export const IconEdit = styled.img`
  cursor: pointer;
  width: calc(var(--size) * 4);
  margin: 0 0 0 2rem;

  :hover {
    transition: width 0.2s;
    width: calc(var(--size) * 4.5);
  }

  @media (max-width: 555px) {
    width: 2rem;
  }
`;
