import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Video = styled.video`
  width: 95%;
  max-width: 80rem;
  height: 40rem;
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* font-family: var(--familyTitle); */
  font: var(--title);
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* font-family: var(--familyTitle); */
  font: var(--body);
`;

export const Manual = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  gap: 3rem;
  margin: 3rem 0 2rem 0;
`

export const User = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  gap: 3rem;
`

export const LinkManual = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  background: var(--softPurple);
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  
  :hover {
   background-color: var(--purple);
   transition: 0.5s;
  }
`