import React, { useState, useEffect} from 'react';
import ReactEcharts from "echarts-for-react";

import {
  Container,
  // Title,
  // Item,
  ButtonClose,
} from "./styles";

import ReadMoreButton from "../../../components/UI/ReadMoreButton"

const Grafico = ({
  dataEscola=[],
  fullscreen=false,
  visible=false,
  dimension='Escola',
  title={text:"",top:"9%"},
  closeFullscreen=()=>{},
  clickOnGrafico=()=>{}
}) => {
  const [dataApi, setDataApi] = useState([]);
  const [option, setOption] = useState({});
  const [seriesApi, setSeriesApi] = useState([]);
  const [graphicStyle, setGraphicStyle] = useState({});

  useEffect(()=>{
    setGraphicStyle({width:'100%', 'height':'600px'});
  },[]);

  useEffect(()=>{
    setSeriesApi([
      {
        type:'bar',
        color:'green',
        markline:{
          lineStyle:{
            color:'green',
            dashOffset: 5,
            opacity:1
          }
        }
        // markLine: {
        //   data: [{ type: 'average', name: 'Média' }]
        // }
      },
      // {
      //   type:'bar',
      //   color:'red',
      //   // markLine: {
      //   //   data: [{ type: 'average', name: 'Média' }]
      //   // }
      // },
    ]);

    //o tipo de gráfico deve ser definido no inicio da carga da página não podendo variar dentro da página
    //para definir o tipo de gráfico talvez possa ser interessante já vir diretamente pelo endereço

    setDataApi({
      dimensions:[
        dimension,
        {
          name:'% de acertos',
          displayName:'% Acertos'
        }
      ], 
      /*
        dimensions:[eixoX,eixo1,...,eixoN] 
        o primeiro valor do vetor são os valores utilizados para o eixo x
        podendo ser usado o nome do parametro do vetor utilizado
        os valores seguintes são os que fazem referencia aos valores para serem utilizados em relação ao eixo X
      */
      source: dataEscola,
    });
  },[dataEscola,dimension]);

  // useEffect(()=>{
  // },[dataApi]);

  // const axisPointerText = (params) => {
  //   console.log(params);
  //   return params.value;
  // };

  const xAxisLabelFormat = (value,index) => {
    return value.slice(0,29);
    // return value;
  }

  // const yAxisLabelFormat = (value,index) => {
  //   return value + "%";
  // }

  const xAxisRotate = () => {
    if(dataEscola.length<5){
      return 0;
    }

    if(dataEscola.length<15){
      return 45;
    }

    if(dataEscola.length<21){
      return 60;
    }

    return 90;
  }

  // const roundValor = (value,precision) =>{
  //   return (Math.round(value*Math.pow(10,precision))/Math.pow(10,precision));
  // }

  const LabelFormatter = (value,index) => {
    return `
      ${dataEscola[0]["Edição E-Aprendi"]} <br/>
      ${value[0].name}<br/>
      ${value[0].marker} Acertos: ${value[0].value["% de acertos"]}%<br/>
    `;
  }

  useEffect(()=>{
    setSeriesApi([
      {
        type:'bar',
        color:'green',
        markline:{
          lineStyle:{
            color:'green',
            dashOffset: 5,
            opacity:1
          }
        }
        // markLine: {
        //   data: [{ type: 'average', name: 'Média' }]
        // }
      },
      // {
      //   type:'bar',
      //   color:'red',
      //   // markLine: {
      //   //   data: [{ type: 'average', name: 'Média' }]
      //   // }
      // },
    ]);

    //o tipo de gráfico deve ser definido no inicio da carga da página não podendo variar dentro da página
    //para definir o tipo de gráfico talvez possa ser interessante já vir diretamente pelo endereço

    setDataApi({
      dimensions:[
        dimension,
        {
          name:'% de acertos',
          displayName:'% Acertos'
        }
      ], 
      /*
        dimensions:[eixoX,eixo1,...,eixoN] 
        o primeiro valor do vetor são os valores utilizados para o eixo x
        podendo ser usado o nome do parametro do vetor utilizado
        os valores seguintes são os que fazem referencia aos valores para serem utilizados em relação ao eixo X
      */
      source: dataEscola,
    });
  },[dataEscola,dimension]);

  useEffect(()=>{
    setOption({
      title:{
        show:true,
        text: `${title.text}`,
        left:'center',
      },
      xAxis:{
        type: 'category',
        axisLabel: {
          interval: 0, 
          rotate: xAxisRotate(), 
          fontSize: 10,
          formatter: xAxisLabelFormat,
        },
        axisPointer:{
          handle:{
            show:true,
            margin: 220,
          },
        },
      },
      yAxis:{
        type:'value',
      },
      grid: {height:'43%',right:'2%',left:'5%', top:title.top}, //tentar fazer os ajustes com variaveis 
      legend:{show: true, left:'1%', bottom:'2%', orient:'horizontal', itemWidth: 30},
      tooltip:{
        trigger:'axis',
        formatter: LabelFormatter
      },
      axisPointer:{
        type: 'line',
        // label:{
        //   formatter:axisPointerText,
        // },
      },
      dataset: dataApi,
      series: seriesApi,
      toolbox:{
        show:true,
        feature:{
          saveAsImage: {},
          dataZoom: {
            yAxisIndex: 'none'
          },
        }
      }
    });
    console.log("title",title);
    // eslint-disable-next-line
  },[title]);

  function onChartClick(param, echarts) {
    clickOnGrafico(param);
    // console.log('OnChartClick',param, echarts);

  };

  function onChartReady(echarts) {
    console.log('OnChartReady', echarts);
  };

  function onChartMouseOver(param) {
    // console.log('mouseOver',param);
  };

  function onChartLegendselectchanged(param, echarts) {
    // console.log('onChartLegendselectchanged',param, echarts);
  };

  return (
    <Container fullscreen={fullscreen} visible={visible}>
        <ReactEcharts 
          option={option} 
          style={graphicStyle} 
          opts={{renderer:'canvas'}} 
          onChartReady={onChartReady}
          onEvents={{
            'click': onChartClick,
            'legendselectchanged': onChartLegendselectchanged,
            'mouseover': onChartMouseOver
          }}
        />
        {fullscreen && <ButtonClose><ReadMoreButton onClick={()=>{closeFullscreen(false);document.getElementsByClassName("enabled")[0].attributes.style.value="display: block; left: initial; right: 0px; top: 50%; bottom: initial; transform: translateY(calc(-50% - 10px));";}} text={"Sair da Tela Cheia"}/></ButtonClose>}
    </Container>
  );
};

export default Grafico;
