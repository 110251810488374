import styled from "styled-components";

export const Feed = styled.div`
  width: 100%;
  max-width: 117rem;
  background-color: var(--secondary);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 1rem 2rem;
  gap: 2rem;
  /* border: 1px solid red; */

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Thumb = styled.img`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: calc(var(--size) * 10);
  /* border-radius: 50%; */
`;

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-self: center;
  /* border: 1px solid green; */
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-left: 0.4rem solid var(--gray);
  padding-left: 0.6rem;
  color: var(--purple);
  font: var(--title);
`;
export const InfoTitle = styled.span`
  cursor: pointer;
`;

export const InfoDate = styled.span``;

export const InfoText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  color: var(--primary);
  font: var(--body);
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* border: 1px solid black; */

  > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;

export const IconEdit = styled.img`
  cursor: pointer;
  width: calc(var(--size) * 3);
  margin: 0 0 1rem 0;
`;
