import styled, { css } from "styled-components";

export const Container = styled.label`
  display: flex;
  width: ${(props) => `${props.width}rem`};
  height: ${(props) => `${props.height}rem`};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: var(--softGray);
  border-radius: 1rem;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 4px var(--gray);
  max-width: 70%;
  overflow: hidden;

  input {
    display: none;
  }

  ${(props) =>
    props.disabled
      ? css`
          filter: blur(5px);
        `
      : css`
          cursor: pointer;
          &:hover {
            background-color: var(--darkGray);
          }
        `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;
export const Text = styled.span`
  color: var(--gray);
  font: var(--body);
`;
