import React from "react";
import {
  MdOutlineErrorOutline,
  MdOutlineCheckCircleOutline,
  MdClose,
} from "react-icons/md";
import { useTheme } from "../../../../hooks/useTheme";
import {
  Container,
  Input,
  InfoMessage,
  InputContainer,
  InputLabel,
  TagContainer,
  Tag,
  RemoveTagButton,
} from "./styles";

const InputTags = ({
  tags = [],
  placeholder = "Tags",
  columns = 1,
  disabled = false,
  error = false,
  success = false,
  onClick = () => {},
  onChange = () => {},
  deleteItem = () => {},
  deleteField,
  ...rest
}) => {
  const { themeFile } = useTheme();
  const handleSize = (columns) => {
    switch (columns) {
      case 1:
        return 30;
      case 2:
        return 60;
      case 3:
        return 90;
      default:
        return columns;
    }
  };

  const handleDelete = (tag) => {
    const newTags = tags.filter((old) => old !== tag);
    deleteItem(deleteField, newTags);
  };

  return (
    <Container columns={handleSize(columns)}>
      <InputLabel>{placeholder}</InputLabel>
      <InputContainer>
        {tags.length > 0 &&
          tags.map((tag, index) => (
            <TagContainer key={index}>
              <Tag>{tag}</Tag>
              <RemoveTagButton type="button" onClick={() => handleDelete(tag)}>
                <MdClose
                  size={themeFile.SIZES.base * 16}
                  color={themeFile.COLORS.black}
                />
              </RemoveTagButton>
            </TagContainer>
          ))}
        <Input
          type="text"
          placeholder="Digite o termo e aperte Enter"
          onChange={onChange}
          {...rest}
        />
        {error && !success && (
          <MdOutlineErrorOutline
            size={themeFile.SIZES.base * 16}
            color={themeFile.COLORS.hardRed}
          />
        )}
        {!error && success && (
          <MdOutlineCheckCircleOutline
            size={themeFile.SIZES.base * 16}
            color={themeFile.COLORS.green}
          />
        )}
      </InputContainer>
      {error && !success && <InfoMessage>*&nbsp;{error}</InfoMessage>}
    </Container>
  );
};

export default InputTags;
