const formatCPF = (text) => {
  let mod = (text.length-2)%3;
  let cpf = "";
  for(let i=0; i<(text.length-3);i++){
    if(((i%3)===mod)&&(i!==0)){
      cpf+="."
    }
    cpf+=text[i];
  }
  cpf+=text[text.length-3];
  cpf+="-"
  cpf+=text[text.length-2];
  cpf+=text[text.length-1];
  return cpf;
};
  
export default formatCPF;