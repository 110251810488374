import React from "react";
import { Container } from "./styles";

const ConfirmButton = ({
  text,
  disabled = false,
  onClick = () => {},
  type = "button",
  ...rest
}) => {
  return (
    <Container disabled={disabled} onClick={onClick} type={type} {...rest}>
      {text}
    </Container>
  );
};

export default ConfirmButton;
