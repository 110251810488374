import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Title,
  Description,
  Meetings,
  TagsContainer,
  Tag,
  ItemResultSearchVideo,
} from "./styles";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images } from "../../../constants";
import Banner from "../../../components/UI/Banner";

import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";
import ellipsis from "../../../utils/ellipsis";
import Visibility from "../../../components/UI/Visibility";

const VideotecaView = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const navigate = useNavigate();

  const [documento, setDocumento] = useState({});

  const isEditor = () => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === "ff1be56d-68f5-4e45-8af5-85cb9bcd804b"
    );
    return editor > -1 ? true : false;
  };

  const [loadDocument, loadDocumentInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/videolibrary",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data.length>0){
          setDocumento(response.data[0]);
        }
        else{
          if(user){
            navigate(`/videoteca`)
          }
          else{
            navigate(`/login/redirect/*videoteca*${id}`)
          }
        }
        console.log("DOC", response);
      }
    },
  });

  useEffect(() => {
    loadDocument({
      params: {
        idVideo: id,
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loadDocumentInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconDocumentos}
        url={`/videoteca/editar/${documento.id}`}
        editorId={"ff1be56d-68f5-4e45-8af5-85cb9bcd804b"}
      >
        {documento.title && ellipsis(documento.title, 20)}
      </Banner>
      <ContentBox>
        <Title>{documento.title}</Title>
        <ItemResultSearchVideo>
          <iframe
            width="100%"
            height="100%"
            src={documento.link?.replace("watch?v=", "embed/")}
            title={documento.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </ItemResultSearchVideo>
        <Description>{documento.description}</Description>
        <Meetings>
          {documento.documents?.map((d, n) => (
            <span key={n}>
              <a
                href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/course/${d.url}`}
                target="_blank"
                rel="noreferrer"
              >
                {d.originalName || `Documento ${n + 1}`}
              </a>
            </span>
          ))}
        </Meetings>
        <Meetings>
          <TagsContainer>
            {documento.keywords?.map((res, ind) => (
              <Tag key={ind}>{res}</Tag>
            ))}
          </TagsContainer>

          <div>
            {documento.abilities?.map((a, i) => (
              <p key={i}>
                <strong>Habilidade:&nbsp;</strong>
                {a.value}
              </p>
            ))}
            {documento.ods?.map((o, j) => (
              <p key={j}>{o.od}</p>
            ))}
          </div>
        </Meetings>
        {isEditor && (
          <Visibility visibility={documento.visibilities} align="center" />
        )}
        <CancelButton
          text="Voltar"
          onClick={() => {
            navigate(-1);
          }}
        />
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default VideotecaView;
