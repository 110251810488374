import React from "react";
import { Label } from "./styles";

const InputCheck = ({
  text,
  name,
  id,
  value,
  checked,
  alterColor = false,
  disabled = false,
  onClick = () => {},
  onChange = () => {},
  ...rest
}) => {
  return (
    <Label htmlFor={id} disabled={disabled} onClick={onClick} alterColor={alterColor} {...rest} >
      <input
        type="checkbox"
        name={name}
        id={id}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <span className="checkmark"></span>
      {text}
    </Label>
  );
};

export default InputCheck;
