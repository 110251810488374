import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
// import { InView } from "react-intersection-observer";
import {
  Banner,
  Container,
  Description,
  // DivStayTunned,
  // Feed,
  Title,
  IconBanner,
  // IconWirelles,
  // News,
  // Search,
  // TitleTunned,
  // LoadingContainer,
} from "./styles";
import { useTheme } from "../../hooks/useTheme";
// import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import { images } from "../../constants";
// import useApi from "../../hooks/useApi";
// import LoadingItem from "../../components/UI/LoadingItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
// import SearchMessage from "../../components/UI/SearchMessage";
// import { useAuth } from "../../hooks/useAuth";

const Landing = () => {
  const { themeFile } = useTheme();
  // const [newsLanding, setNewsLanding] = useState([]);
  // const [itemSearch, setItemSearch] = useState([]);
  // const [search, setSearch] = useState("");
  // const [time, setTime] = useState("");
  // const [total, setTotal] = useState(0);
  // const [initialLoading, setInitialLoading] = useState(true);
  // const { user } = useAuth();

  // const handleNews = (item) => {
  //   return (
  //     search.length < 3 && (
  //       <CardItem
  //         key={item.id}
  //         id={item.id}
  //         date={item.eventDate}
  //         begin={item.begin}
  //         end={item.end}
  //         title={item.title}
  //         text={item.description}
  //         mainCategory={item.mainCategoryName}
  //       />
  //     )
  //   );
  // };

  // const handleItemSearch = (item) => {
  //   return (
  //     search && (
  //       <CardItem
  //         key={item.id}
  //         id={item.id}
  //         date={item.eventDate}
  //         title={item.title}
  //         text={item.description}
  //         mainCategory={item.mainCategory}
  //       />
  //     )
  //   );
  // };

  // const [liveSearch, liveSearchInfo] = useApi({
  //   debounceDelay: 1000,
  //   url: "canalPedagogico/search",
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       if (response.data.data.length > 0) {
  //         let result = [];
  //         setTotal(parseInt(response.data.total));
  //         response.data.data.forEach((item) => {
  //           const found = itemSearch.findIndex((news) => news.id === item.id);
  //           if (found === -1) {
  //             return result.push(item);
  //           }
  //         });
  //         console.log("result", result);
  //         setItemSearch([...itemSearch, ...result]);
  //         // setTime(response.config.time);
  //       }
  //     }
  //   },
  // });

  // const handleSearch = (event) => {
  //   setSearch(event.target.value);
  //   setTotal(0);
  //   if (event.target.value.length > 2) {
  //     setItemSearch([]);
  //     liveSearch({
  //       params: {
  //         term: event.target.value.trim(),
  //         initial: 0,
  //         limit: 10,
  //       },
  //       debounced: true,
  //     });
  //   } else {
  //     if (event.target.value.length === 0) {
  //       setItemSearch([]);
  //       setInitialLoading(true);
  //       loadAgenda({
  //         params: {
  //           initial: 0,
  //           limit: 10,
  //         },
  //       });
  //     }
  //   }
  // };

  // eslint-disable-next-line
  // const [loadAgenda, loadAgendaInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "canalPedagogico/publicCalendar",
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log("News Agenda: ", response.data);
  //       setInitialLoading(false);
  //       if (response.data.length > 0) {
  //         let result = [];
  //         response.data.forEach((item) => {
  //           const found = newsLanding.findIndex((news) => news.id === item.id);
  //           if (found === -1) {
  //             return result.push(item);
  //           }
  //         });
  //         setNewsLanding([...newsLanding, ...result]);
  //       }
  //     }
  //   },
  // });

  // const loadMore = (InviewObj, entry) => {
  //   if (InviewObj) {
  //     if (search.length < 3) {
  //       // console.log("esta aqui");
  //       // console.log(newsLanding.length);
  //       loadAgenda({
  //         params: {
  //           initial: newsLanding.length + 1,
  //           limit: 10,
  //           fromToday: true,
  //         },
  //       });
  //     } else {
  //       liveSearch({
  //         params: {
  //           term: search,
  //           initial: itemSearch.length + 1,
  //           limit: 10,
  //         },
  //       });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   loadAgenda({
  //     params: {
  //       initial: 0,
  //       limit: 10,
  //       fromToday: true,
  //     },
  //   });
  //   // eslint-disable-next-line
  // }, []);

  return (
    <Container>
      <Menu />  
      <Title>Tudo o que você precisa, em um só lugar.</Title>

      {/* <Title>Página temporariamente em manutenção.</Title>
        <center>
          <img src={images.manut} width="200"></img>
        </center> */}
      

      <Description>
        Seja bem-vindo ao canal pedagógico, um espaço que visa promover a
        comunicação sobre as diversas ações no âmbito pedagógico da Secretaria
        Municipal de Educação de Mogi das Cruzes. No menu superior esquerdo você
        pode conhecer algumas ações do departamento pedagógico, além de contar
        com uma área restrita aos profissionais da rede municipal. Você poderá
        acompanhar a agenda de eventos do auditório do Cemforpe e do
        departamento, as novidades da biblioteca, a produção de materiais,
        avaliações, formações, entre outros.
      </Description>
      <Banner highContrast={themeFile.COLORS.highContrast}>
        <IconBanner>
          <Player
            autoplay={true}
            loop={true}
            controls={false}
            src={images.iconPessoas}
            style={{ width: "100%" }}
          />
        </IconBanner>
      </Banner>
      
      <Description>
        Este espaço foi construído pelo Departamento Pedagógico, os conteúdos aqui exibidos são criados a partir das diversas equipes e projetos
        existentes procurando estreitar a comunicação entre todos os profissionais da rede. Faça seu login através do menu superior esquerdo e
        aproveite todos os conteúdos disponíveis para você. Lembrando que o login deve ser feito somente com a conta corporativa, não é 
        necessário nenhum cadastro prévio. Qualquer problema com seu login, por favor, envie um e-mail para canalpedagogico@se-pmmc.com.br.
      </Description>

      <Footer />
    </Container>
  );
};

export default Landing; 
