import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: var(--purple);
  border-radius: 3rem;
  font: var(--body);
  padding: 1.4rem 4rem;
  color: var(--secondary);
  transition: all 0.2s ease;

  ${(props) =>
    props.disabled
      ? css`
          background-color: var(--darkGray);
          color: var(--extraDarkGray);
        `
      : css`
          cursor: pointer;

          &:hover {
            padding: 1.4rem 5rem;
          }
        `}
`;

export const Input = styled.input`
  display: none;
`;
