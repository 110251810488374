import styled, { css } from "styled-components";

export const Container = styled.div`
  display: ${(props)=>(props.visible?css`flex`:css`none`)};
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  flex-wrap:nowrap;
  margin: 0.5rem 4rem 2rem 4rem;
  border: 1px solid var(--primary);
  /* background-color: var(--softGray); */
  background-color: var(--secondary);
  border-radius: 1rem;
  padding: 1.2rem 2rem 1.2rem 2rem;
  width: 95%;

  @media (max-width: 600px) {
   margin: 0.5rem 3rem 3rem 3rem;
  }
  @media (max-width: 768px) {}
  @media (max-width: 992px) {}
  @media (max-width: 1300px) {
   width: 88%;
  }
`;

export const DocTitle = styled.div`
 display: flex;
 flex-direction:row;
 align-items:flex-start;
 justify-content:flex-start;
 flex-wrap:nowrap;
 /* margin-bottom: 0.5rem; */
 width:100%;
 margin: 0.5rem 0;
 
 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;

export const DocTitleText = styled.div`
 display: flex;
 flex-direction:row;
 align-items:flex-start;
 justify-content:flex-start;
 
 width:95%;

 font: var(--title);
 
 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;

export const DocText = styled.div`
 display: flex;
 flex-direction:row;
 align-items:flex-start;
 justify-content:flex-start;
 flex-wrap:wrap;
 /* margin-bottom: 0.5rem; */
 width:100%;
 padding-left: 1rem;
 font: var(--body);
 
 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;


export const Editar = styled.a`
  width:2rem;
  margin-left: 1rem;
`;

export const Link = styled.a`
`;

export const DocDescritpion = styled.div`
 margin: 0.5rem 0;
 font: var(--body);
 
 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;

export const File = styled.a`
 display: flex;
 flex-direction:row;
 align-items:center;
 justify-content: flex-start;
 flex-wrap:wrap;
 /* font-size: calc(var(--size) * 1.8); */
 font-weight: 400;
 margin: 0 0 0 1.2rem;
 /* border: solid 1px black; */
 cursor: pointer;

 span{
  margin-left: 0.8rem;
 }
 
 @media (max-width: 600px) {
  margin: 0.5rem 0rem 0.5rem 0rem;
 }
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;

export const Tag = styled.span`
  /* padding: 0.2rem 0.4rem; */
  font-weight: 500;
  /* background-color: var(--softPurple); */
  /* border-radius: 0.6rem; */
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin: 1rem 0;
`;

export const Download = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  margin-left: 3rem;
  img {
    width: 17rem;
  }
`;

export const BlocoPrincipal = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap:nowrap;
  align-items:space-between;
  margin: 0.5rem 0 0.5rem 0;
  width: 100%;
`;

export const BlocoConteudo = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap:wrap;
  align-items:center;
  justify-content:flex-start;
  width: 80%;
`;

export const BlocoButton = styled.div`
  display: flex;
  flex-direction:column;
  flex-wrap:nowrap;
  align-items:flex-end;
  justify-content:center;
  width: 20%;
  height: auto;
  min-width: 20rem;
  margin-right: 1rem;
`;