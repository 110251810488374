import React from "react";
import { useNavigate } from "react-router";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import { Container, Title } from "./styles";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";

const LibraryAdminForm = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>O que você deseja fazer?</Title>
        <ConfirmButton
          text="Cadastrar Novidades"
          type="button"
          onClick={() => navigate("/biblioteca/news/add")}
        />
        <ConfirmButton
          text="Enviar mensagem para grupo Gnuteca"
          type="button"
          onClick={() => navigate("/biblioteca/info/add")}
        />
        <CancelButton text="Voltar" />
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default LibraryAdminForm;
