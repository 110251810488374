import styled, { css } from "styled-components";

export const Main = styled.div`
  ${(props) =>
    props.isOpen
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `};

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

// export const Container = styled.div`
//   display: flex;
//   font: var(--familyBody);
//   position: relative;
//   width: ${(props) => props.width}rem;
//   height: ${(props) => props.height}rem;
//   background-color: var(--secondary);
//   color: var(--black);
//   margin-top: 1rem;
// `;

// export const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
//   font: var(--familyBody);
//   width: ${(props) => props.width}rem;
//   height: ${(props) => props.height}rem;
//   background-color: var(--secondary);
//   padding: 2rem;
//   gap: 1.5rem;
//   overflow-y: auto;
// `;

export const Container = styled.div`
  display: flex;
  font: var(--familyBody);
  position: relative;
  background-color: var(--secondary);
  color: var(--black);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font: var(--familyBody);
  min-width: 20rem;
  max-width: 120rem;
  min-height: 10rem;
  max-height: 95vh;
  background-color: var(--secondary);
  padding: 2rem;
  gap: 1.5rem;
  overflow-y: auto;
  overflow-x: auto;
`;

export const IconClose = styled.button`
  position: absolute;
  background: unset;
  top: 1rem;
  right: 1rem;
  color: var(--hardRed);
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
  ${(props) => !(props.buttonClose) && css`display: none;`}

  &:hover {
    transform: scale(1.1) rotate(180deg);
  }
`;
