import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import locale from "date-fns/locale/pt-BR";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";

import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import {
  Container,
  Area,
  Search,
  ListItem,
  ItemCourse,
  ListInfo,
  ListButton,
} from "./styles";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import InputText from "../../components/UI/Inputs/InputText";
import ReadMoreButton from "../../components/UI/ReadMoreButton";
import Modal from "../../components/UI/Modal";

// import { images } from "../../constants";
// import { useTheme } from "../../hooks/useTheme";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

const CoursesSubscriptionManager = () => {
  const [viewType, setViewType] = useState("input");
  const [userSearchEmail, setUserSearchEmail] = useState("");
  const [searchParams, setSearchParams] = useState({params:{}});
  const [userSearchRgf, setUserSearchRgf] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [userListInfo, setUserListInfo] = useState([]);
  const [userCourseList, setUserCourseList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  // const { themeFile } = useTheme();
  const [subscription, setSubscription] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalSubscribe, setModalSubscribe] = useState(false);
  const [modalSubscribeMessage, setModalSubscribeMessage] = useState("");
  const [modalConfirm, setModalConfirm] = useState({open:false, message:"", execute:()=>{}});

  const { user } = useAuth();
  const navigate = useNavigate();

  const getEditorId = (type) => {
    let teste = user?.profiles[0]?.editor?.find(
      (item) =>
        item.category === "Formação Elementar" ||
        item.category === "Formação Complementar"
    );
    return teste?.id ? teste.id : false;
  };

  const editorId = getEditorId();

  const [loadUserCourses, loadUserCoursesInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/subscription/byEmailRGF",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("UserCourses", response.data);
        if (response.data.length > 0) {
          // console.log("", response.data)
          setUserCourseList(response.data);
          setViewType("result");
          loadUser({params:{idUserHasProfile:response.data[0]["Identificação do usuário"]}})
        }
        else{
          setModalErrorMessage(`Usuário não encontrado (${searchParams.params?.rgf?`RGF: ${searchParams.params?.rgf}`:""}${searchParams.params?.email?`Email: ${searchParams.params?.email}`:""})`);
          setModalError(true);
        }
      }
    },
  });

  const [loadUser, loadUserInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/profile/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if (response.data.length > 0) {
          console.log("dados usuario", response.data)
          setUserInfo(response.data[0]);
          setUserListInfo(response.data);
        }
      }
    },
  });

  const [getCourses, getCoursesInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/byTerm/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("Course List",response.data);
        setCoursesList(response.data);
      }
    },
  });

  const [subscribe, loadSubscribeInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/subscription",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        setModalConfirm({open:false, message:"", execute:()=>{}});
        setModalSubscribe(true);
        setModalSubscribeMessage("Inscrição realizada com sucesso! inscrição enviada para o e-mail do inscrito!")
        loadUserCourses(searchParams);
      }
    },
    onError: (error)=>{
      setModalSubscribe(true);
      setModalSubscribeMessage("Erro ao realizar a incrição, por favor, tente novamente!")
    }
  });

  const [deleteSubscribe, loadDeleteSubscribeInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/subscription",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        setModalConfirm({open:false, message:"", execute:()=>{}});
        setModalSubscribe(true);
        setModalSubscribeMessage("inscrição deletada com sucesso! exclusão de inscrição enviada para o e-mail do inscrito!")
        loadUserCourses(searchParams);
      }
    },
    onError: (error)=>{
      setModalSubscribe(true);
      setModalSubscribeMessage("Erro ao deletar a incrição, por favor, tente novamente!")
    }
  });

  // const search = (query) => {
  //   loadUserCourses(query);
  // };

  useEffect(() => {
    // console.log(searchParams);
    // console.log("if",(searchParams.params?.rgf?searchParams.params?.rgf.trim()!=="":false || searchParams.params?.email?searchParams.params?.email.trim()!=="":false))
    if((searchParams.params?.rgf || searchParams.params?.email)&&
       (searchParams.params?.rgf?searchParams.params?.rgf.trim()!=="":false || searchParams.params?.email?searchParams.params?.email.trim()!=="":false)){
      loadUserCourses(searchParams);
    }
    //eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    getCourses();
    //eslint-disable-next-line
  }, []);

  const controlList = (typeChange=0, segmentChange=0)=>{
    let list = [];
    coursesList.forEach((type)=>{
      if(segmentChange!==0){
        if(type.courseType===typeChange){
          type.segments.forEach((segment)=>{
            if(segment.segment===segmentChange){
              if(segment.open){
                segment.open=false;
              }
              else{
                segment.open=true;
              }
            }
          })
        }
      }
      else{
        if(type.courseType===typeChange){
          if(type.open){
            type.open=false;
          }
          else{
            type.open=true;
          }
        }
      }
      list.push(type);
    })
    setCoursesList(list);
  };

  return (
    <Container>
      {(loadSubscribeInfo.loading ||
        loadDeleteSubscribeInfo.loading ||
        loadUserCoursesInfo.loading ||
        loadUserInfo.loading ||
        getCoursesInfo.loading
        ) && <FullScreenLoading />}

      <Accessibility />
      <Menu />
      <Banner>GERENCIAMENTO DE INSCRIÇÕES DOS CURSOS</Banner>
      <ContentBox>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate(-1);}}
        >
          <MdArrowLeft fontSize={36}/> Voltar para gestão de formação
        </ListItem>
        <ListItem onClick={()=>{if(viewType!=="input"){setViewType("input");}}} visible={true} reduct={0} roundborder={true} selected={viewType==="input"}>Buscar Usuário {viewType!=="input"&&<MdArrowDropUp fontSize={36}/>}</ListItem>
        <Area visible={viewType==="input"}>
          <Search>
            <InputText
              placeholder="E-mail"
              columns={80}
              onChange={(value)=>{setUserSearchEmail(value.target.value)}}
              value={userSearchEmail}
            />
            <div><ReadMoreButton onClick={()=>{setSearchParams({params:{email:userSearchEmail}})}} text="Buscar Usuario por E-mail"/></div>
          </Search>
          <Search>
            <InputText
              placeholder="RGF"
              columns={20}
              onChange={(value)=>{setUserSearchRgf(value.target.value)}}
              value={userSearchRgf}
            />
            <div><ReadMoreButton onClick={()=>{setSearchParams({params:{rgf:userSearchRgf}})}} text="Buscar Usuario por RGF"/></div>
          </Search>
        </Area>

        <Area visible={viewType==="result"}>
          <ListItem reduct={0} visible={true} roundborder={true} selected={true}> Dados do Usuário </ListItem>
          <ListInfo reduct={10} visible={true} roundborder={false}>
            <p>Nome: {userInfo.name}</p>
            <p>email: {userInfo.email}</p>
            <p>Perfil: {userInfo.profileName}</p>
            <p>RGF{userInfo.rgf2 && " - 1"}: {userInfo.rgf1} - HTPF:{userInfo.htpf1?"sim":"não"}</p>
            {userInfo.rgf2 && <p>RGF - 2: {userInfo.rgf2} - HTPF:{userInfo.htpf2?"sim":"não"}</p>}
            {userInfo.lastAccess && <p>Último acesso: {format(new Date(userInfo.lastAccess), "dd/MM/yyyy", {locale})}</p>}
          </ListInfo>
          <ListItem reduct={0} visible={true} roundborder={true} selected={true}> Cursos </ListItem>
            {userCourseList[0]?.Curso!==null?
            userCourseList.map((course)=>(<>
              {course["ID do Curso"]!==null && <ItemCourse reduct={10} visible={true} roundborder={false}>
                <div>
                  <p>{course.Curso}</p>
                  <p>Inscrito em:{` ${course["Data da inscrição"]}`}</p>
                </div>
                <div>
                  {editorId && <ReadMoreButton onClick={()=>{setModalConfirm({open:true, message:`Deletar a incrição do ${userInfo.name} do curso ${course.Curso}?`, execute:()=>{deleteSubscribe({data:{id:course["ID da inscrição"],idUser:course["Identificação do usuário"]}});}});}} text="Deletar Inscrição"/>}
                  {!editorId && <ReadMoreButton onClick={()=>{navigate(`/formacoes/${course['ID do Curso']}`)}} text="+ Leia Mais"/>}
                </div>
              </ItemCourse>}
            </>))
            :"Usuario não está inscrito em nenhum curso"}
          {editorId && <>
            <ListItem onClick={()=>{setSubscription(!subscription)}} reduct={0} visible={true} roundborder={true} selected={subscription}> Inscrever Usuário em Curso {subscription?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>
            {
              coursesList.map((type)=>(type.segments.length>0 &&
                <Area key={type.courseType} visible={subscription}>
                  <ListItem key={type.courseType} onClick={()=>{controlList(type.courseType)}} visible={true} reduct={0} roundborder={true} selected={type.open}>{type.courseTypeName} {type.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>
                  {type.segments.map((segment)=>(
                    <Area visible={type.open} key={segment.segment}>
                      {type.courseTypeName!=="Formação Complementar" && <ListItem key={segment.segment} onClick={()=>{controlList(type.courseType,segment.segment)}} visible={type.open} reduct={2} roundborder={true} selected={segment.open}>{segment.segmentName===null?"OUTROS":segment.segmentName} {segment.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>}
                      {segment.courses.map((course)=>(
                        <ItemCourse
                          key={course.id} 
                          visible={(type.courseTypeName==="Formação Complementar")?type.open:segment.open} 
                          reduct={type.courseTypeName==="Formação Complementar"?2:4} 
                          roundborder={false}>
                          <div>{course.title}</div> 
                          <div>
                            {course.hasSubscription && <ReadMoreButton onClick={()=>{setModalConfirm({open:true, message:`Inscrever ${userInfo.name} no curso ${course.title}?`, execute:()=>{subscribe({data:{id:course.id,idUser:userInfo.idUserHasProfile}});}});}} text="Increver no Curso" />}
                          </div>
                        </ItemCourse>
                      ))}
                    </Area>))}
                </Area>))
            }
          </>}
        </Area>
        <Modal open={modalConfirm.open} buttonClose={false}>
          {modalConfirm.message}
          <ListButton>
            <ReadMoreButton
              text="Sim"
              type="button"
              onClick={()=>{modalConfirm.execute();}}
            />
            <ReadMoreButton
              text="Não"
              type="button"
              onClick={()=>{setModalConfirm({open:false, message:"", execute:()=>{}});}}
            />
          </ListButton>
        </Modal>
        <Modal open={modalSubscribe} buttonClose={false}>
          {modalSubscribeMessage}
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{setModalSubscribe(false);}}
          />
        </Modal>
        <Modal open={modalError} buttonClose={false}>
          {modalErrorMessage}
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{setModalError(false);}}
          />
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesSubscriptionManager;