import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { differenceInSeconds } from "date-fns";

import axios from "axios";

import useDebouncePromise from "./useDebouncePromise";

const initialRequest = {
  error: null,
  data: null,
  loading: false,
  timeout: false,
};

export default function useApi(config) {
  const [requestInfo, setRequestInfo] = useState(initialRequest);

  const debouncedAxios = useDebouncePromise(axios, config.debounceDelay);

  async function call(localData) {
    setRequestInfo({
      ...initialRequest,
      loading: true,
      timeout: false,
    });

    config.startAt = new Date().getTime();

    let response = null;

    const data = localStorage.getItem(`[@${process.env.REACT_APP_NAME}Token]`)
      ? localStorage.getItem(`[@${process.env.REACT_APP_NAME}Token]`)
      : undefined;

    let headers;

    const type = config.multi ? "multipart/form-data" : "application/json";

    if (data) {
      headers = {
        Authorization: `Bearer ${data}`,
        "Content-type": type,
      };
    }

    // console.log(headers);

    const fn = localData && localData.debounced ? debouncedAxios : axios;

    const baseURL = config.baseURL
      ? config.baseURL
      : process.env.REACT_APP_API_URL;
    // console.log(baseURL);

    delete config.baseURL;

    if (config.toast) {
      response = await toast.promise(
        fn({
          baseURL,
          headers,
          ...config,
          ...localData,
        }),
        {
          pending: config.pending,
          success: {
            render({ data }) {
              console.log(data);
              console.log(data.data.current_user_url);
              return `${config.successMessage}`;
            },
          },
          error: {
            render({ data }) {
              console.log("poisiton", toast.POSITION);
              console.log(data.response);
              return `${data.response.statusText}`;
            },
            icon: "🤦🚜",
          },
        },
        {
          position: config.position ? config.position : "top-right",
        }
      );
    } else {
      try {
        response = await fn({
          baseURL,
          headers,
          ...config,
          ...localData,
        });
        response.config.endAt = new Date().getTime();
        response.config.time = differenceInSeconds(
          response.config.endAt,
          config.startAt
        );
        setRequestInfo({
          ...initialRequest,
          data: response.data,
        });
      } catch (error) {
        console.log(error);
        console.log(error.response);
        console.log(error.code);
        if (error.code && error.code === "ECONNABORTED") {
          setRequestInfo({
            ...initialRequest,
            error: "Sistema indisponível no momento.",
            status: 500,
          });
        } else {
          console.log(error.response);
          setRequestInfo({
            ...initialRequest,
            error: error.response?.data.message,
            status: error.response?.status,
          });
        }
        if (config.onError) {
          config.onError(error);
        }
        else {
          setTimeout(()=>{document.location.reload(false)},7500);
        }
      }
    }

    if (config.onCompleted) {
      if (response) {
        config.onCompleted(response);
      }
    }

    return response;
  }

  return [call, requestInfo];
}
