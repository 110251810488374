import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  padding: 0 2rem;
  top: 0;
  ${(props) =>
    props.highContrast
      ? css`
          background-color: var(--purple);
        `
      : css`
          background-color: var(--purple);
        `};
  z-index: 9;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  border-radius: 0 0 3rem 3rem;

  @media (min-width: 0px) {
    justify-content: space-between;
    background: var(--secondary);
    border-radius: 0;
  }
`;

export const List = styled.nav`
  position: relative;
  padding-top: 6rem;
  transition: left 0.3s ease-in-out;
  width: 85%;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: var(--purple);
  position: fixed;
  z-index: -1;
  gap: 1.1rem;
  justify-content: flex-start;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  padding-left: 5rem;
  min-width: 25rem;
  padding-bottom: 2rem;
  border-radius: 0 3rem 3rem 0;
  top: 0;

  bottom: 0;

  .notifyResp {
    display: none;
  }

  @media (min-width: 0px) {
    ${(props) =>
      !props.isOpen
        ? css`
            left: 0;
          `
        : css`
            left: -30rem;
          `}
  }

  @media (max-width: 450px) {
    gap: 1rem;
  }
`;

export const ListScroll = styled.nav`
  direction: rtl;
  transition: left 0.3s ease-in-out;
  width: 85%;
  max-width: 30rem;
  height: 93vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: fixed;
  z-index: -1;
  gap: 1.1rem;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-y: auto;
  top: -3rem;
  bottom: 0;
  padding-left: 5rem;

  div{
    direction: ltr;
  }

  .notifyResp {
    display: none;
  }

  @media (min-width: 0px) {
    ${(props) =>
      !props.isOpen
        ? css`
            left: 0;
          `
        : css`
            left: -30rem;
          `}
  }

  @media (max-width: 450px) {
    gap: 1rem;
  }
`;

export const CloseContainer = styled.button`
  background: unset;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1) rotate(180deg);
  }
`;

export const CenterIcon = styled.div`
  display: flex;
  height: 90%;
  align-self: center;
  z-index: -3;

  > a {
    text-decoration: none;
    border: 0;
    height: 100%;

    > div {
      height: 100%;
    }
  }
`;

export const BurgerMenu = styled.button`
  display: none;
  cursor: pointer;
  margin-left: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  z-index: -4;

  span {
    font: var(--body);
    color: var(--purple);
  }

  @media (min-width: 0px) {
    /* width: 4rem;
    height: 4rem; */
    background: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 540px) {
    > span {
      display: none;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: unset;
  position: relative;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (min-width: 0px) {
    flex-direction: row;
    :nth-child(1) {
      margin-top: 8rem;
    }
  }
`;

export const SubItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: unset;
  position: relative;
  flex-wrap: wrap;
  margin-top: -0.5rem;
  /* gap: 0.1rem; */
  padding-left: 3rem;

  @media (min-width: 0px) {
    flex-direction: row;
    :nth-child(1) {
      margin-top: 4rem;
    }
  }
`;

export const SubItem2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: unset;
  position: relative;
  flex-wrap: wrap;
  margin-top: -0.5rem;
  /* gap: 0.1rem; */
  padding-left: 7rem;

  @media (min-width: 0px) {
    flex-direction: row;
    :nth-child(1) {
      margin-top: 4rem;
    }
  }
`;

export const ItemNotify = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: unset;
  cursor: pointer;
  position: relative;
  flex-wrap: wrap;

  @media (min-width: 0px) {
    > span {
      display: none;
    }
  }
`;

export const ResponsiveNotification = styled.div`
  display: none;

  @media (min-width: 0px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: unset;
    cursor: pointer;
    position: relative;
    flex-wrap: wrap;
  }
`;

export const Empty = styled.h3``;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.number > 0 ? "flex-start" : "center")};
  position: absolute;
  width: 30rem;
  height: ${(props) => (props.number > 0 ? "30rem" : "5rem")};
  bottom: ${(props) => (props.number > 0 ? "-31rem" : "-6rem")};
  border-radius: 1rem;
  background-color: var(--white);
  /* border: 1px solid black; */
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  overflow-y: ${(props) => (props.number > 0 ? "scroll" : "auto")};
`;

export const NotificationsButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 0;
`;

export const NotificationChange = styled.button`
  padding: 0.8rem 1.6rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: ${(props) => (props.active ? "var(--white)" : "var(--black)")};
  background-color: ${(props) =>
    props.active ? "var(--hardBlue)" : "var(--gray)"};

  &:hover {
    transform: scale(1.1);
  }
`;

export const NotificationsBoxContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const NotificationsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const NotificationItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 0.8rem;
  background: ${(props) => (!props.read ? "var(--gray)" : "var(--white)")};
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0.1rem 0;

  &:hover {
    filter: opacity(60%);
  }
`;

export const NotificationAvatar = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
`;

export const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  margin-left: 1rem;
`;

export const NotificationText = styled.span`
  width: 100%;
  text-align: left;
  font-size: calc(var(--size) * 1.4);
  font-family: var(--fontFamily);
  font-weight: var(--regularFont);
`;

export const NotificationTime = styled.span`
  width: 100%;
  text-align: left;
  margin-top: 0.8rem;
  font-size: calc(var(--size) * 1.2);
  font-family: var(--fontFamily);
  font-weight: var(--regularFont);
`;

export const LogoButton = styled.button`
  background: unset;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-bottom: -5rem;
  align-self: flex-end;

  &:hover {
    transform: scale(1.1);
  }

  @media (min-width: 0px) {
    ${(props) =>
      !props.isOpen &&
      css`
        display: none;
      `}
  }
`;

export const Logo = styled.img`
  width: 10rem;
`;

export const Name = styled.span`
  font-size: calc(var(--size) * 1.3);
  font-family: var(--fontFamily);
  font-weight: var(--regularFont);
  color: var(--secondary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;

  @media (min-width: 0px) {
    margin-left: 0.6rem;
  }
`;

export const Icon = styled.img`
  width: 2.8rem;
  cursor: pointer;
  ${(props) =>
    props.rounded &&
    css`
      border-radius: 50%;
    `}

  ${(props) =>
    props.size > 2 &&
    css`
      display: none;
    `}
`;

export const Notifications = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--hardRed);
  position: absolute;
  width: calc(var(--size) * 2);
  height: calc(var(--size) * 2);
  color: var(--secondary);
  border-radius: 50%;
  font-size: calc(var(--size) * 0.9);
  font-family: var(--fontFamily);
  font-weight: var(--boldFont);
  top: -0.5rem;
  left: -0.5rem;

  ${(props) =>
    props.isOpen &&
    css`
      left: 4rem;
      top: 0.5rem;
    `}
`;

export const Profile = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: unset;
  cursor: pointer;
  position: relative;
  flex-wrap: wrap-reverse;

  @media (min-width: 0px) {
    margin-bottom: 5rem;
    flex-direction: row-reverse;
  }
`;

export const AvatarName = styled.span`
  font-size: calc(var(--size) * 1.6);
  font-family: var(--fontFamily);
  font-weight: var(--mediumFont);
  margin-right: 0.8rem;
  color: var(--white);

  @media (min-width: 0px) {
    margin: 0 0 0 0.8rem;
  }
`;

export const AvatarImage = styled.img`
  width: 5rem;
  border-radius: 50%;
`;

export const LogoButtonResponsive = styled.button`
  display: none;
  background: unset;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-bottom: -5rem;
  align-self: flex-end;

  > img {
    width: 100%;
  }

  @media (min-width: 0px) {
    display: flex;
    margin-right: 1rem;
    width: 7rem;
    margin-bottom: 0;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  width: 4rem;
  z-index: -3;
`;

export const HideItem = styled.div`
  display: flex;
  align-self: center;

  @media (min-width: 0px) {
    display: none;
  }
`;

export const Version = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transform: translate(-50%);
  gap: 0.6rem;

  p {
    color: var(--secondary);
    font-size: calc(var(--size) * 1.2);
    font-family: var(--fontFamily);
    font-weight: var(--regularFont);
  }
`;
