import React from "react";
import Global from "./GlobalStyle";
import { AuthProvider } from "./hooks/useAuth";
import { ThemeProvider } from "./hooks/useTheme";
import { NotifyProvider } from "./hooks/useNotify";
import Theme from "./components/Theme";
import AppRoutes from "./routes";
import Terms from "./components/UI/Terms";

const App = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <NotifyProvider>
          <Global />
          <Theme>
            <AppRoutes />
            <Terms />
          </Theme>
        </NotifyProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
