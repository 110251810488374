import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { MdOutlineAddCircleOutline, MdClose } from "react-icons/md";
import * as Yup from "yup";
import ReactCrop from "react-image-crop";
import getCroppedImg from "../../../utils/getCroppedImg";
import "react-image-crop/dist/ReactCrop.css";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import useApi from "../../../hooks/useApi";
import { useTheme } from "../../../hooks/useTheme";
import {
  Container,
  Title,
  Form,
  Item,
  Controls,
  CoverUrlBox,
  CoverUrlCropped,
  ModalCoverUrl,
  ModalCoverUrlContent,
  SummaryBox,
  SummaryTitle,
  SummaryItem,
  SearchBox,
  SearchItem,
  LoadingContainer,
  Place,
  SubItem,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputTextArea from "../../../components/UI/Inputs/InputTextArea";
import InputTags from "../../../components/UI/Inputs/InputTags";
import InputRadio from "../../../components/UI/Inputs/InputRadio";
import InputCheck from "../../../components/UI/Inputs/InputCheck";
import AttachPlaceholder from "../../../components/UI/AttachPlaceholder";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import LoadingItem from "../../../components/UI/LoadingItem";
import { useAuth } from "../../../hooks/useAuth";

const REDsForm = ({ action }) => {
  const { themeFile } = useTheme();
  const [showModalCoverUrl, setShowModalCoverUrl] = useState(false);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 1 / 1,
  });
  //state result para uso de imagem jpg
  const [result, setResult] = useState("");
  const { id } = useParams();
  const [abilitiesFound, setAbilitiesFound] = useState([]);
  const [visibilities, setVisibilities] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [schoolGrades, setSchoolGrades] = useState([]);

  const [abilitiesSelect, setAbilitiesSelect] = useState([]);

  const navigate = useNavigate();
  const { user } = useAuth();

  /**
   * file
   * type:"f81a0134-33f1-4d03-b624-813e30e15054"
   * title: nome do Reds
   * description: descriçção
   * contentText: não tem utilidade especifica
   * url: Link
   * abilities: [] de abilidades
   * subjects: [] disciplinas
   * schoolGrades: [] ano series
   * keywords: [] tags
   * formType: public or private
   * visibilities: [] visibilidade
   */
  const formik = useFormik({
    initialValues: {
      file: null,
      fileUpdate: false,
      type:"f81a0134-33f1-4d03-b624-813e30e15054",
      title: "",
      description: "",
      contentText: "",
      url:"",
      abilities: [],
      subjects: [],
      schoolGrades: [],
      keywords: [],
      item: "",
      hasAdvertising: false,
      formType: "public",
      visibilities: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Digite um título."),
      description: Yup.string().required("Digite uma descrição."),
      url: Yup.string().required("Digite uma url."),
      formType: Yup.string(),
      contentText: Yup.string(),
      type: Yup.string(),
      fileUpdate: Yup.boolean(),
      hasAdvertising: Yup.boolean(),
      abilities: Yup.array().of(Yup.string()),
      subjects: Yup.array().of(Yup.string()),
      schoolGrades: Yup.array().of(Yup.string()),
      keywords: Yup.array().of(Yup.string()),
      visibilities: Yup.array().of(Yup.string()),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values);
      if (action === "add") {
        saveItem({
          data: values,
        });
      } else {
        editItem({
          data: values,
        });
      }
    },
  });

  const [searchAbilities, searchAbilitiesInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/ability",
    // timeout: 5000,
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response);
        setAbilitiesFound(response.data);
      }
    },
  });

  const handleSearchAbilities = (term) => {
    if (term.length > 2) {
      searchAbilities({
        params: {
          term,
        },
        debounced: true,
      });
    } else {
      setAbilitiesFound([]);
    }
  };

  const handleRemoveAbilities = (idAbility) => {
    const newArray = abilitiesSelect.filter(
      (item) => item.id !== idAbility
    );
    setAbilitiesSelect(newArray);
  };

  const handleAddAbilities = (ab) => {
    let newArray = [...abilitiesSelect];
    newArray.push(ab);
    setAbilitiesSelect(newArray);
  };

  const ifAbilityExists = (idAbility) => {
    if (abilitiesSelect.length > 0) {
      const found = abilitiesSelect.findIndex(
        (item) => item.id === idAbility
      );
      return found > -1 ? true : false;
    } else {
      return false;
    }
  };

  const handleAttach = (file) => {
    // console.log("entrou no handleFile");
    const type = file.target.files[0].type.split("/");
    if (type[0] === "image") {
      setFile(URL.createObjectURL(file.target.files[0]));
      setShowModalCoverUrl(true);
    }
    file.target.value = null;
  };

  const handleCrop = () => {
    let preview;
    if (crop.height !== 0) {
      preview = getCroppedImg(crop, image);
      setResult(preview);
    } else {
      const full = {
        ...crop,
        width: image.width,
        height: image.height,
      };
      preview = getCroppedImg(full, image);
      setResult(preview);
    }
    setUploaded(true);
    const cover = {
      mime: preview ? preview.split(";")[0].split(":")[1] : "",
      encode: preview ? preview.split(";")[1].split(",")[0] : "",
      data: preview ? preview.split(",")[1] : "",
    };
    formik.setFieldValue("file", cover);
    formik.setFieldValue("fileUpdate", true);
  };

  const handleUpdateCover = () => {
    setShowModalCoverUrl(false);
  };

  const checkBase64 = (image) => {
    if (!image) return false;
    const check = image.split(";");
    if (check.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/reds",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("salvou", response);
        // setVisibilities(response.data);
        navigate("/reds");
      }
    },
    onError: (error)=>{
      console.log("erro",error)
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    url: "/canalPedagogico/reds",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("editou", response);
        // setVisibilities(response.data);
        navigate("/reds");
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "/canalPedagogico/reds",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/reds");
        // console.log("deletou", response);
        // setVisibilities(response.data);
      }
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/reds",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("item", response);
        // setVisibilities(response.data);
        if (response.data.length > 0) {
          console.log("response: ", response.data[0]);
          formik.setFieldValue("id", response.data[0].id);
          formik.setFieldValue("title", response.data[0].title);
          formik.setFieldValue("description", response.data[0].description);
          formik.setFieldValue("url", response.data[0].url);
          formik.setFieldValue("formType", response.data[0].formType);
          formik.setFieldValue("keywords", response.data[0].keywords);
          formik.setFieldValue("hasAdvertising", response.data[0].hasAdvertising);
          if (response.data[0].image_preview) {
            setResult(
              `${process.env.REACT_APP_CDN_URL}canalPedagogico/reds/${response.data[0].image_preview}`
            );
          }
          let abilities = [];
          let schoolGrades = [];
          let subjects = [];
          let visibilities = [];

          response.data[0].abilities.forEach((ability)=>{
            abilities.push({id:ability.idevaluation_endpoint,value:ability.endpoint_value});
          });

          response.data[0]?.schoolGrades.forEach((grade)=>{
            schoolGrades.push(grade.idschool_grade_list);
          });

          response.data[0]?.subjects.forEach((sub)=>{
            subjects.push(sub.idsubject);
          });

          response.data[0]?.visibilities.forEach((vis)=>{
            visibilities.push(vis.idvisibility);
          });

          setAbilitiesSelect(abilities);

          formik.setFieldValue("schoolGrades", schoolGrades);
          formik.setFieldValue("subjects", subjects);
          formik.setFieldValue("visibilities", visibilities);

        } else {
          navigate("/reds");
        }
      }
    },
  });

  const [loadVisibilities, loadVisibilitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/visibility",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("tempo de req", response.config.time);
        setVisibilities(response.data);
      }
    },
  });

  const [loadSubjects, loadSubjectsInfo] = useApi({
    debounceDelay: 0,
    url: "subjects",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("Subjects", response.data);
        setSubjects(response.data);
      }
    },
  });

  const [loadSchoolGrades, loadSchoolGradesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/schoolGradeList",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("SchoolGrades", response.data);
        setSchoolGrades(response.data);
      }
    },
  });

  const handleDelete = (itemId) => {
    const confirm = window.confirm("Deseja apagar esse item?");

    if (confirm) {
      deleteItem({
        data: {
          id: itemId,
        },
      });
    }
  };

  const handleAddKeywords = () => {
    const item = formik.values.item;
    if (item && !formik.values.keywords.includes(item)) {
      // console.log("pode add");
      let newArray = [...formik.values.keywords];
      newArray.push(item);
      // console.log(newArray);
      formik.setFieldValue("keywords", newArray);
      formik.setFieldValue("item", "");
    } else {
      formik.setErrors({
        item: "Palavra-chave já adicionada.",
      });
    }
  };
  
  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleAddKeywords();
    }
  };

  // console.log(formik.errors);
  // console.log(formik.isValid);

  useEffect(() => {
    if (action === "edit") {
      // console.log("id", id);
      loadItem({
        params: {
          id,
        },
      });
    }
    loadVisibilities();
    loadSubjects();
    loadSchoolGrades();
    console.log("agora aqui");
    console.log(user);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("formik.values", formik.values);
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    let ability = [];
    abilitiesSelect.forEach((ab)=>{
      ability.push(ab.id);
    });
    formik.setFieldValue("abilities", ability);
    // eslint-disable-next-line
  }, [abilitiesSelect]);

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading ||
        loadVisibilitiesInfo.loading) && <FullScreenLoading />}
      {/* {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ||
        saveItemInfo.status ||
        loadVisibilitiesInfo.status) && <Error errorCode={401} />} */}
      <Accessibility />
      <Menu />
      <CoverUrlBox>
        {showModalCoverUrl && (
          <ModalCoverUrl>
            <CancelButton
              text="X"
              onClick={() => setShowModalCoverUrl(false)}
            />
            <ModalCoverUrlContent>
              <ReactCrop
                src={file}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
              />
              <ConfirmButton text="Cortar" type="button" onClick={handleCrop} />
              <CoverUrlCropped
                src={
                  checkBase64(result)
                    ? result
                    : process.env.REACT_APP_CDN_URL +
                      "/canalPedagogico/reds" +
                      result
                }
                alt="Nova Capa"
              />
              <ConfirmButton
                text="Salvar"
                type="button"
                disabled={!uploaded}
                onClick={handleUpdateCover}
              />
            </ModalCoverUrlContent>
          </ModalCoverUrl>
        )}
      </CoverUrlBox>
      <ContentBox>
        <Title>Recurso Educacional Digital</Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}
          <Item>
            <AttachPlaceholder
              onChange={(file) => handleAttach(file)}
              src={result}
            />
          </Item>
          <Item>
            <InputText
              placeholder="Título"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>
          <Item>
            <InputTextArea
              placeholder="Descrição"
              columns={90}
              cols={60}
              rows={7}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description &&
                formik.errors.description &&
                formik.errors.description
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Url"
              columns={90}
              name="url"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              error={
                formik.touched.url &&
                formik.errors.url &&
                formik.errors.url
              }
            />
          </Item>
          <Item>
            <InputCheck
              name="hasAdvertising"
              text={"Tem propaganda"}
              onChange={formik.handleChange}
              checked={formik.values.hasAdvertising}/>
          </Item>
          <Item>
            <SummaryBox>
              <SummaryTitle>Objetivo de aprendizagem / habilidades relacionadas</SummaryTitle>
              {abilitiesSelect.length > 0 ? (
                abilitiesSelect.map((ab) => (
                  <SummaryItem key={ab.id}>
                    <span>{ab.value}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveAbilities(ab.id)}
                    >
                      <MdClose
                        size={themeFile.SIZES.base * 16}
                        color={themeFile.COLORS.hardRed}
                      />
                    </button>
                  </SummaryItem>
                ))
              ) : (
                <SummaryItem>
                  <span>Nenhum objetivo de aprendizagem / habilidades adicionada ainda.</span>
                </SummaryItem>
              )}
            </SummaryBox>
            <InputText
              placeholder="Buscar objetivo de aprendizagem / habilidades"
              columns={3}
              name="abilityItem"
              onChange={(e) => {
                formik.handleChange(e);
                handleSearchAbilities(e.target.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.abilityItem}
              error={
                formik.touched.abilityItem &&
                formik.errors.abilityItem &&
                formik.errors.abilityItem
              }
            />
            {(abilitiesFound.length > 0 || searchAbilitiesInfo.loading) && (
              <SearchBox>
                {searchAbilitiesInfo.loading && (
                  <LoadingContainer>
                    <LoadingItem />
                  </LoadingContainer>
                )}
                {abilitiesFound.length > 0 &&
                  abilitiesFound.map(
                    (ab) =>
                      !ifAbilityExists(ab.id) && (
                        <SearchItem key={ab.id}>
                          <span>{ab.value}</span>
                          <button
                            type="button"
                            onClick={() => handleAddAbilities(ab)}
                          >
                            <MdOutlineAddCircleOutline
                              size={themeFile.SIZES.base * 24}
                              color={themeFile.COLORS.purple}
                            />
                          </button>
                        </SearchItem>
                      )
                  )}
              </SearchBox>
            )}
          </Item>
          <Item nowrap={true}>
            <SubItem reduct={77}>
              Campos de experiência / Área do Conhecimento: 
            </SubItem>
            <SubItem reduct={23}>
            {subjects.length > 0 &&
                subjects.map((sub) => (
                  <div><InputCheck
                    id={sub.idSubject}
                    key={sub.idSubject}
                    value={sub.idSubject}
                    name="subjects"
                    text={sub.subjectName}
                    onChange={formik.handleChange}
                    checked={formik.values.subjects.includes(sub.idSubject)}
                  /></div>
                ))}
            </SubItem>
          </Item>
          <Item nowrap={true}>
            <SubItem reduct={95}>
              Ano:
            </SubItem>
            <SubItem reduct={5}>
              {schoolGrades.length > 0 &&
                  schoolGrades.map((sg) => (
                    <div><InputCheck
                      id={sg.id}
                      key={sg.id}
                      value={sg.id}
                      name="schoolGrades"
                      text={sg.title}
                      onChange={formik.handleChange}
                      checked={formik.values.schoolGrades.includes(sg.id)}
                    /></div>
                  ))}
            </SubItem>
          </Item>
          <Item>
            <InputTags
              tags={formik.values.keywords}
              placeholder="Tags"
              deleteItem={formik.setFieldValue}
              deleteField="keywords"
              columns={90}
              onKeyDown={handleEnter}
              name="item"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.item}
              autoComplete="off"
              error={formik.errors.item && formik.errors.item}
            />
          </Item>
          <Item>
            <Place>Visibilidade</Place>
            <InputRadio
              id="public"
              name="formType"
              text="Público"
              onChange={formik.getFieldProps("formType").onChange}
              value="public"
              checked={formik.getFieldProps("formType").value === "public"}
            />
            <InputRadio
              id="private"
              name="formType"
              text="Restrito"
              onChange={formik.getFieldProps("formType").onChange}
              value="private"
              checked={formik.getFieldProps("formType").value === "private"}
            />
          </Item>
          {formik.values.formType === "private" && (
            <Item>
              {visibilities.length > 0 &&
                visibilities.map((vis) => (
                  <InputCheck
                    id={vis.id}
                    key={vis.id}
                    value={vis.id}
                    name="visibilities"
                    text={vis.description}
                    onChange={formik.handleChange}
                    checked={formik.values.visibilities.includes(vis.id)}
                  />
                ))}
            </Item>
          )}
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
      </ContentBox>
      <Footer />
    </Container>
  );
};

 /**
   * subjects: [] disciplinas
   * schoolGrades: [] ano series
   */

export default REDsForm;
