import React, {useEffect} from "react";
import { Outlet } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";

const AuthRoute = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const location = useLocation();

  // console.log(user);

  // eslint-disable-next-line
  const [verifyLogin, getVerifyLoginInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/verifyLogin",
    method: "get",
    onCompleted: (response) => {
      if(!response.data.loginValido){
        logout({
          navigate,
        });
      }
    },
  });

  useEffect(()=>{
    if(user){
      verifyLogin();
    }
    // eslint-disable-next-line
  },[location]);

  return <Outlet />;
};

export default AuthRoute;
