import styled, { css } from "styled-components";

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: var(--secondary);
  border-radius: 3rem;
  font-family: var(--fontFamily);
  font-size: calc(var(--size) * 1.6);
  font-weight: var(--regularFont);
  padding: 1.4rem 3rem;
  color: var(--hardRed);
  transition: all 0.2s ease;
  border: 0.2rem solid var(--hardRed);

  ${(props) =>
    props.disabled
      ? css`
          color: var(--extraDarkGray);
          border: 0.2rem solid var(--extraDarkGray);
          background-color: var(--gray);
        `
      : css`
          cursor: pointer;

          &:hover {
            padding: 1.4rem 4rem;
          }
        `}
`;
