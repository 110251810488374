import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -2.5rem;
  position: relative;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 2rem 0 0 0;
  font: var(--title);
`;

export const DivDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  flex-wrap: nowrap;
  width: 83%;
  line-height: 3rem;
  font: var(--body);
`;

export const DivLink = styled.div`
  display: flex;
  /* font-size: calc(var(--size) * 3); */
  font-weight: var(--boldFont);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  @media (max-width: 1000px) {
    padding: 1.5rem;
  }
`;

export const DivClick = styled.div`
  display: flex;
  font: var(--title);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  :hover {
    cursor: pointer;
    color: var(--purple);
  }
`;

export const LogoGnuteca = styled.img`
  padding: 1rem;
  width: 15rem;
`;

export const DivStayTunned = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
`;

export const TitleTunned = styled.div`
  display: flex;
  font-size: calc(var(--size) * 2);
  color: var(--purple);
  font: var(--title);
`;

export const IconWirelles = styled.img`
  display: flex;
`;

export const IconEdit = styled.img`
  cursor: pointer;
  width: calc(var(--size) * 4);
  margin: 0 0 0 2rem;
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 2rem;
  background-color: var(--gray);
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
`;

export const DivEditMsg = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 83%;
  font: var(--title);

  :hover {
    font-size: calc(var(--size) * 1.9);
    transition: font-size 0.3s;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LinkDoc = styled.a`
  display: flex;
  justify-content: start;
  width: 100% ;
  transition: transform 0.3s;
  cursor: pointer;
  font-size: calc(var(--size) * 1.5);
  /* font: var(--body); */
  outline: none;
  text-decoration: none;
  color: var(--primary);

  :hover {
    /* transform: scale(0.9); */
    color: var(--purple);
    font-weight: bold;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-align: justify;
  font: var(--body);
`;