import styled from "styled-components";

export const Container = styled.div`
  --primary: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.primary};
  --secondary: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.secondary};
  --black: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.black};
  --white: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.white};
  --softBlue: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.softBlue};
  --softGray: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.softGray};
  --gray: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.gray};
  --darkGray: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.darkGray};
  --extraDarkGray: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.extraDarkGray};
  --green: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.green};
  --softBlue: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.softBlue};
  --hardBlue: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.hardBlue};
  --darkBlue: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.darkBlue};
  --softRed: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.softRed};
  --hardRed: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.hardRed};
  --degradePrimary: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.degradePrimary};
  --degradeSecondary: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.degradeSecondary};
  --softYellow: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.softYellow};
  --yellow: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.yellow};
  --lightRed: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.lightRed};
  --softPurple: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.softPurple};
  --purple: ${(props) =>
    props.themeSchema.COLORS && props.themeSchema.COLORS.purple};
  --size: ${(props) => props.themeSchema.SIZES.base}rem;
  --familyTitle: ${(props) =>
    props.themeSchema.FONTS && props.themeSchema.FONTS.familyTitle};
  --familyBody: ${(props) =>
    props.themeSchema.FONTS && props.themeSchema.FONTS.familyBody};
  --title: ${(props) =>
    props.themeSchema.FONTS && props.themeSchema.FONTS.title};
  --body: ${(props) => props.themeSchema.FONTS && props.themeSchema.FONTS.body};

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;
