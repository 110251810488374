import React, { useEffect, useState } from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Menu from "../../components/UI/Menu";
import { Container, Title, Body, Video, Manual, LinkManual } from "./styles";
import Footer from "../../components/UI/Footer";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";
import showcase from "../../assets/videos/showcase.mp4";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";


const Help = () => {
  
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    if (user) {
      const editor = user?.profiles[0]?.editor?.findIndex(
        (editor) => editor.id === "890798c6-a166-4b87-a059-11d4bbf6a1ac" 
          || "0e3b686a-9b75-4de0-a2b7-28ec578e8119" 
          || "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3" 
          || "f0d87522-fcc7-4f8b-aec3-3e64fce9915a"
          || "46150c88-042a-4220-b81e-bb6c399fa275"
      );
      setIsEditor(editor > -1 ? true : false);
    }
  }, [user]);
  
  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconAjuda}>AJUDA</Banner>
      <ContentBox>
        <Video controls>
          <source src={showcase} type="video/mp4" />
        </Video>
        <Manual>
          {user && <LinkManual onClick={()=>navigate("/manualusuario")}>Manual Profissional da Rede</LinkManual>}
        
          {isEditor && <LinkManual onClick={()=>navigate("/manualgestor")}>Manual Técnico Pedagógico</LinkManual>}

          <LinkManual onClick={()=>navigate("/ajudaformacao")}>Manual Acompanhamento das Formações</LinkManual>
        </Manual>
        <Title>Encontrou algum erro? Tem alguma sugestão?</Title>
        <Body>
          Entre em contato com a gente através do e-mail&nbsp;
          <a href="mailto:canalpedagogico@se-pmmc.com.br">
            canalpedagogico@se-pmmc.com.br
          </a>
        </Body>
        <Body>
          Essa ferramenta está em constante evolução e aprimoramento, e sua
          opinião é muito importante neste processo.
        </Body>

      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Help;