import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
  /* background-color: red; */
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -2.5rem;
  position: relative;
  width: 100%;
`;

export const ContainerAgenda = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 65vh;

  .DayPicker-Day--mark {
    color: var(--secondary);
    background-color: var(--purple);
  }

  .DayPicker-Day {
    padding: 0.1em 0.5em 0.1em 0.5em;
  }

  /* .DayPicker-Day--mark:hover{
  color: var(--secondary);
  background-color: var(--purple);
 } */

  /* background-color: black; */

  @media (max-width: 412px) {
    .DayPicker-Month {
      margin: 0 0.5em;
      margin-top: 0.5em;
    }
    .DayPicker {
      font-size: 2.1rem;
    }
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Left = styled.div`
  display: ${(props) => (props.visible ? css`flex` : css`none`)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 45%;

  .DayPicker {
    font-size: 2.8rem;
  }

  @media (max-width: 1200px) {
    width: 50rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    .DayPicker {
      width: 100%;
      font-size: 2rem;
    }
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: ${(props) => (props.liveSearch ? css`95%` : css`55%`)};
  ${(props) =>
    props.liveSearch &&
    css`
      margin-top: 2rem;
      margin-bottom: 2rem;
    `};
  border-radius: 1.5rem;
  background-color: var(--softGray);
  /* font-size: calc(var(--size) * 2.1); */
  color: var(--primary);
  padding: 2rem 0rem;
  margin-right: 2rem;
  ${(props) =>
    !props.liveSearch &&
    css`
      max-height: 66rem;
    `};
  min-height: 25rem;

  ${(props) =>
    props.liveSearch
      ? css`
          overflow-y: none;
        `
      : css`
          overflow-y: scroll;
        `};

  @media (max-width: 1200px) {
    width: 50rem;
    margin-right: 0rem;
    margin-top: 2rem;
    overflow-y: unset;
    max-height: none;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
`;

export const Calendar = styled.div`
  margin: 0rem 2rem;
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0rem 0rem 0rem;

  font: var(--body);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0.5rem 0rem;
  padding: 0.3rem;
  width: 22rem;
  text-align: center;
  border: ${(props) =>
    props.selected ? css`none` : css`solid 0.1rem var(--primary)`};
  border-radius: 1.5rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};

  @media (max-width: 412px) {
    width: 90%;
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const DateSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  font: var(--title);
  color: var(--black);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;
