//ONLY REFERENCE
export const COLORS = {
  // global colors
  primary: "#000",
  secondary: "#fff",
  black: "#000",
  white: "#fff",
  softGray: "#EFEFEF",
  gray: "#A4A4A4",
  darkGray: "#777",
  extraDarkGray: "#3D3C3C",
  green: "#31FC52",
  softBlue: "#4C78BA",
  hardBlue: "#1351AE",
  darkBlue: "#1C3C6E",
  softRed: "#CB6D6D",
  hardRed: "#f00",
  degradePrimary: "#1B0D20",
  degradeSecondary: "#7E4192",
  softYellow: "#FDFD88",
  yellow: "#FAFF00",
  lightRed: "#ffe0e0",
  highContrast: false,
  softPurple: "#BD96CA",
  purple: "#7E4192",
};

export const COLORS_HIGH_CONTRAST = {
  // global high contrast colors
  primary: "#fff",
  secondary: "#000",
  black: "#000",
  white: "#fff",
  softGray: "#EFEFEF",
  gray: "#A4A4A4",
  darkGray: "#A4A4A4",
  extraDarkGray: "#000",
  green: "#FAFF00",
  softBlue: "#FAFF00",
  hardBlue: "#FAFF00",
  darkBlue: "#FAFF00",
  softRed: "#FAFF00",
  hardRed: "#FAFF00",
  degradePrimary: "#FAFF00",
  degradeSecondary: "#FAFF00",
  softYellow: "#FAFF00",
  yellow: "#FAFF00",
  lightRed: "#FAFF00",
  highContrast: true,
  softPurple: "#A4A4A4",
  purple: "#FAFF00",
};

export const COLORS_DARK = {
  // global dark theme colors
  primary: "#fff",
  secondary: "#1B1B1B",
  softGray: "#EFEFEF",
  gray: "#A4A4A4",
  darkGray: "#777",
  extraDarkGray: "#3D3C3C",
  green: "#31FC52",
  softBlue: "#4C78BA",
  hardBlue: "#1351AE",
  darkBlue: "#1C3C6E",
  softRed: "#CB6D6D",
  hardRed: "#f00",
  degradePrimary: "#1B0D20",
  degradeSecondary: "#7E4192",
  softYellow: "#FDFD88",
  yellow: "#FAFF00",
  lightRed: "#ffe0e0",
  highContrast: false,
  softPurple: "#BD96CA",
  purple: "#7E4192",
};

export const SIZES = {
  // global size
  base: 1,
};

export const FONTS = {
  // global font attributes
  familyTitle: "Raleway",
  familyBody: "Open Sans",
  title: "600 calc(var(--size) * 1.8)/2 Raleway",
  body: "400 calc(var(--size) * 1.6)/2 Open Sans",
};

const appTheme = { COLORS, COLORS_HIGH_CONTRAST, COLORS_DARK, SIZES, FONTS };

export default appTheme;
