import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  background-color: var(--secondary);
`;

export const LogoPlatform = styled.img`
  width: calc(var(--size) * 30);
  background-color: var(--white);
  padding: 1rem;
  border-radius: 1rem;

  @media (max-width: 725px) {
    width: calc(var(--size) * 15);
  }
`;

export const BoxLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
  margin-bottom: 3rem;
  background-color: var(--white);
  border-radius: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ButtonLogo = styled.img`
  width: calc(var(--size) * 25 * ${(props) => props.size && props.size});
  padding: 2rem;
  transition: transform 0.3s ease;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
  }
`;

export const Text = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-align: justify;
  line-height: 2.5rem;
  font: var(--body);
  color: var(--primary);
`;

export const TextModal = styled.div`
  text-align: justify;
  font: var(--body);
  color: var(--primary);

  @media (max-width: 725px) {
    font-size: calc(var(--size) * 1.5);
  }
`;
