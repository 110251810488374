const textToVectorIsLink = (text) => {
    // Gera um vetor com o texto dizendo se a parte do texto é link ou não
    // eslint-disable-next-line
    let regex = /((?:https\:\/\/)|(?:http\:\/\/))([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\??)[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~]+)/gi ;
    let temp = text.replace(regex,"<LINK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!>$&<LINK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!>")
    let tempsplit = temp.split("<LINK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!>");
    let textVector = [];
    tempsplit.forEach(temp=>{
      if(temp.match(regex)===null){
        textVector.push({text:temp,isLink:false});
      }
      else{
        textVector.push({text:temp,isLink:true});
      }
    })
    return textVector;
  };
  
  export default textToVectorIsLink;