import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { icons } from "../../../constants";
import { Container } from "./styles";

const FullScreenLoading = ({ progress = false }) => {
  return (
    <Container>
      <Player
        autoplay={true}
        loop={true}
        controls={false}
        src={icons.loading}
        style={{
          width: "15rem",
        }}
      />
      {progress && <span>{progress}%</span>}
    </Container>
  );
};

export default FullScreenLoading;
