import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 30rem;
  gap: 1rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 4px var(--gray);
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const Thumbnail = styled.div`
  align-self: center;
  width: calc(var(--size) * 5);
  height: calc(var(--size) * 5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const Feed = styled.div`
  width: 100%;
  max-width: 117rem;
  background-color: var(--secondary);
  border-radius: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 1rem 2rem;
  gap: 2rem;

  ${(props) =>
    props.type===2 &&
    css`
      background-color: var(--lightRed);
      /* opacity: 0.5; */
    `}

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Thumb = styled.img`
  align-self: center;
  width: calc(var(--size) * 5);
  height: calc(var(--size) * 5);
  /* border-radius: 50%; */
`;

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 90%;
  align-self: center;
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-left: 0.4rem solid var(--gray);
  
  ${(props) =>
    props.type===2 &&
    css`
      border-left: 0.4rem solid var(--darkGray);
    `}
  padding-left: 0.6rem;
  color: var(--purple);
  font: var(--title);
`;

export const InfoTitle = styled.span``;

export const InfoDate = styled.span``;

export const InfoText = styled.span`
  display: flex;
  flex-direction: column;
  color: var(--primary);
  flex-wrap: nowrap;
  margin-top: 0.1rem;
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  button {
    margin-top: 1rem;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const IconEdit = styled.img`
  cursor: pointer;
  width: calc(var(--size) * 4);
  margin: 0 0 1rem 0;

  :hover {
    transition: width 0.2s;
    width: calc(var(--size) * 4.5);
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button{
      width: 10rem;
    }
  }
`;