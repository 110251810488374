import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const Delete = styled.button`
  background: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--hardRed);
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 500px) {
    position: initial;
    align-self: center;
  }

  > svg {
    transition: transform 0.2s ease;
  }

  &:hover {
    > svg {
      transform: scale(1.1) rotate(20deg);
    }
  }
`;
