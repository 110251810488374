import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  color: var(--primary);
  background-color: var(--secondary);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -2.5rem;
  position: relative;
  width: 100%;
`;

export const LoadingItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button{
      width: 10rem;
    }
  }
`;

export const LinkDoc = styled.a`
  transition: transform 0.3s ease;
  cursor: pointer;
  font-size: calc(var(--size) * 1.5);
  margin: -0.5rem 0 -0.5rem 0;
  /* font: var(--body); */
  outline: none;
  text-decoration: none;
  color: var(--primary);

  :hover {
    transform: scale(0.9);
  }
`
export const Loading = styled.div`
  width:3rem;
  margin-left: 0.8rem;
`;

export const PageMode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0rem 0rem 0rem;

  font: var(--body);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const PageModeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0.5rem 1rem;
  padding: 0.3rem;
  width: 22rem;
  text-align: center;
  border: ${(props) =>
    props.selected ? css`none` : css`solid 0.1rem var(--primary)`};
  border-radius: 1.5rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};

  @media (max-width: 412px) {
    width: 90%;
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const ListContent = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
`;

export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
`;

export const Certificate = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  align-items:center;
  justify-content:space-between;
  flex-direction:row;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  padding: 1rem;
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
`;