import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Title,
  Description,
  File,
  Link,
  Tag,
  // TagsContainer,
  Text,
  Image,
  Ability,
} from "./styles";

// import { MdInsertDriveFile } from "react-icons/md";

import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images, icons } from "../../../constants";
import Banner from "../../../components/UI/Banner";

import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";
import Visibility from "../../../components/UI/Visibility";
import ellipsis from "../../../utils/ellipsis";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";

const DocumentosView = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const [reds, setReds] = useState({});

  const navigate = useNavigate();

  const isEditor = () => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === "f81a0134-33f1-4d03-b624-813e30e15054"
    );
    return editor > -1 ? true : false;
  };

  const [loadRed, loadRedInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/reds/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("reds",response.data)
        setReds(response.data);
      }},
  });

  useEffect(() => {
    loadRed({
      params: {
        id
      },
    });
    //eslint-disable-next-line
  }, []);

  const redirect = (link)=>{
    window.open(link, '_blank')
  }

  return (
    <Container>
      {loadRedInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner 
        url={`/reds/edit/${id}`}
        // definir endereço de edição
        editorId="f81a0134-33f1-4d03-b624-813e30e15054"
        icon={images.reds_banner}>
        Recurso Educacional Digital
      </Banner>
      <ContentBox>
        {reds.length>0 ? <>
          <Title>{reds[0].title}</Title>
          <Image><img src={`${process.env.REACT_APP_CDN_URL}canalPedagogico/reds/${reds[0].image_preview}`} /></Image>
          <ReadMoreButton onClick={()=>{redirect(reds[0].url);}} text={"Clique aqui para acessar o Recurso Educacional Digital"}/>
          
          <Description>Descrição: <p>{reds[0]?.description}</p></Description>
          <Text>Tem propaganda: {reds[0].hasAdvertising?"sim":"não"} {reds[0].hasAdvertising && <img src={icons.reds_ads}/>}</Text>
          <Text>Recomendado para turmas de: {reds[0].schoolGrades?.map((schoolGrade,index)=>(<Tag key={schoolGrade.idschool_grade_list}>{schoolGrade.school_grade}</Tag>))}</Text>
          <Text>Campos de experiência / Área do Conhecimento recomendadas: {reds[0].subjects?.map((subject,index)=>(<Tag key={subject.idsubject}>{subject.subject_name}</Tag>))}</Text>
          <Text>Objetivo de aprendizagem / Habilidades: <Ability>{reds[0].abilities?.map((ability,index)=>(<li key={ability.idevaluation_endpoint}>{ability.endpoint_value}</li>))}</Ability></Text>
          <Text>Palavras chaves: {reds[0].keywords?.map((key,index)=>(<Tag key={index}>{key}</Tag>))}</Text>
          {isEditor() && (
            <Visibility visibility={reds[0].visibilities} align={"center"} />
          )}
        </>:<>
          <Title>Recurso Educacional Digital indisponível no sistema</Title>
        </>}

        <CancelButton
          text="Voltar"
          onClick={() => {
            navigate(-1);
          }}
        />
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default DocumentosView;
