import styled, { css } from "styled-components";

export const Container = styled.div`
  display: ${(props)=>(props.visible?css`flex`:css`none`)};
  flex-direction:row;
  align-items:flex-start;
  justify-content:flex-start;
  flex-wrap: wrap;
  margin: 0.5rem 4rem 2rem 4rem;
  border: 1px solid var(--primary);
  /* background-color: var(--softGray); */
  background-color: var(--secondary);
  border-radius: 1rem;
  padding: 1.2rem 2rem 1.2rem 2rem;
  width: 95%;

  @media (max-width: 600px) {
   margin: 0.5rem 3rem 3rem 3rem;
  }
  @media (max-width: 768px) {}
  @media (max-width: 992px) {}
  @media (max-width: 1300px) {
   width: 88%;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  width:13rem;
  height:100%;
  align-items:center;
  justify-content:center;
  img {
    width: 90%;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width:85%;
  flex-direction:row;
  align-items:flex-start;
`;

export const ContainerButtons = styled.div`
  display: flex;
  width:15%;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`;

export const ISBN = styled.div`
  display: flex;
  width:100%;
  font: var(--body);
`;

export const Situacao = styled.div`
  display: flex;
  width:49%;
  font: var(--body);
  @media (max-width: 600px) {
    width:100%;
  }
`;

export const Title = styled.div`
  display: flex;
  width:100%;
  font: var(--title);
`;

export const Editora = styled.div`
  display: flex;
  width:100%;
  font: var(--body);
`;

export const Disciplina = styled.div`
  display: flex;
  width:49%;
  font: var(--body);
  @media (max-width: 600px) {
    width:100%;
  }
`;

export const Exemplar = styled.div`
  display: flex;
  width:49%;
  font: var(--body);
  @media (max-width: 600px) {
    width:100%;
  }
`;

export const Unidade = styled.div`
  display: flex;
  width:49%;
  font: var(--body);
  @media (max-width: 600px) {
    width:100%;
  }
`;

export const Ano = styled.div`
  display: flex;
  width:49%;
  font: var(--body);
  @media (max-width: 600px) {
    width:100%;
  }
`;

export const Solicitacoes = styled.div`
  display: flex;
  width:100%;
  font: var(--body);
`;

