import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Title = styled.h1``;

export const Description = styled.div``;

export const Meetings = styled.div``;

export const Date = styled.div``;

export const Place = styled.div``;

export const Tag = styled.span`
  padding: 0.2rem 0.4rem;
  background-color: var(--softPurple);
  border-radius: 0.6rem;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 2rem;
`;

export const ItemResultSearchVideo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* border: 1px solid black; */
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
  height: 60rem;

  @media (max-width: 500px) {
    height: 30rem;
  }
`;
