import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import useApi from "../../../hooks/useApi";
import { Container, Title, Form, Item, Controls } from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputTextArea from "../../../components/UI/Inputs/InputTextArea";
import InputRadio from "../../../components/UI/Inputs/InputRadio";
import InputCheck from "../../../components/UI/Inputs/InputCheck";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";

const LibraryInfoForm = ({ action }) => {
  const [unities, setUnities] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/info",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/biblioteca");
        // console.log("salvou", response);
        // setVisibilities(response.data);
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/info",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/biblioteca");
        // console.log("apagou", response);
        // setVisibilities(response.data);
      }
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/info",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/biblioteca");
        // console.log("editou", response);
        // setVisibilities(response.data);
      }
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/info",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("buscou", response);
        if (response.data.length > 0) {
          // formik.setFieldValue("description", response.data[0].description);
          formik.setValues(response.data[0]);
        } else {
          navigate("/biblioteca/info/add");
        }
      }
    },
  });

  const [loadUnities, loadUnitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/unity",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("unities", response);
        const hasLibrary = response.data.filter(
          (unity) => unity.haslibrary === true
        );
        // console.log(hasLibrary);
        setUnities(hasLibrary);
        formik.setFieldValue("allUnities", hasLibrary);
        // setVisibilities(response.data);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      unities: "all",
      selectedUnities: [],
      allUnities: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Digite um título."),
      description: Yup.string().required("Digite uma descrição."),
      selectedUnities: Yup.array().when("unities", {
        is: "select",
        then: Yup.array().min(1, "Selecione 1"),
      }),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      if (action === "add") {
        saveItem({
          data: values,
        });
      } else {
        //edit
        editItem({
          data: values,
        });
      }
    },
  });

  // console.log(formik.errors);
  // console.log(formik.isValid);

  const handleDelete = (itemId) => {
    const confirm = window.confirm("Deseja apagar esse item?");

    if (confirm) {
      deleteItem({
        data: {
          id: itemId,
        },
      });
    }
  };

  useEffect(() => {
    async function load() {
      await loadUnities();
      if (action === "edit") {
        console.log("id", id);
        await loadItem({
          params: {
            id,
          },
        });
      }
      // console.log("agora aqui");
    }
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {(loadUnitiesInfo.loading ||
        loadItemInfo.loading ||
        saveItemInfo.loading ||
        deleteItemInfo.loading ||
        editItemInfo.loading) && <FullScreenLoading />}
      {(loadUnitiesInfo.status ||
        loadItemInfo.status ||
        saveItemInfo.status ||
        deleteItemInfo.status ||
        editItemInfo.status) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>Informativo para escolas com sistema implantado</Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}
          <Item>
            <InputText
              placeholder="Título"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>
          <Item>
            <InputTextArea
              placeholder="Descrição"
              columns={90}
              cols={60}
              rows={7}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description &&
                formik.errors.description &&
                formik.errors.description
              }
            />
          </Item>
          <Item>
            <InputRadio
              id="all"
              name="unities"
              text="Todas"
              onChange={formik.getFieldProps("unities").onChange}
              value="all"
              checked={formik.getFieldProps("unities").value === "all"}
            />
            <InputRadio
              id="select"
              name="unities"
              text="Selecionar escolas"
              onChange={formik.getFieldProps("unities").onChange}
              value="select"
              checked={formik.getFieldProps("unities").value === "select"}
            />
          </Item>
          <Item>
            {formik.values.unities === "select" &&
              unities.length > 0 &&
              unities.map((unity) => (
                <InputCheck
                  id={unity.id}
                  key={unity.id}
                  value={unity.id}
                  name="selectedUnities"
                  text={unity.name}
                  onChange={formik.handleChange}
                  checked={formik.values.selectedUnities.includes(unity.id)}
                />
              ))}
          </Item>
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default LibraryInfoForm;
