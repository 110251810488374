import React from "react";
import { useNavigate } from "react-router";

import { 
  Container, 
  // ContainerImg, 
  ContainerInfo, 
  ContainerButtons,
  Title,
  // Editora,
  // Disciplina,
  // Exemplar,
  Unidade,
  Disponivel,
  // Solicitacoes,
  // Solicitacao,
  Text,
  Text2,
} from "./styles";
import ReadMoreButton from "../../../../components/UI/ReadMoreButton";

import { useAuth } from "../../../../hooks/useAuth";

const Stock = ({
  ticket={
    bookTitle:"",
    unityName:"",
    stockBalance:0,
    totalRequested:0,
    dateSearch:"",
    destinedTo:"",
  },
  myunity=null,
  visible=true,
  category=null,
  ...rest
}) => {
  // console.log(ticket);

  const { user, accessRight } = useAuth();

  const navigate = useNavigate();

  return (
    <Container visible={visible} {...rest} >
      {/* <ContainerImg><img src={`${process.env.REACT_APP_CDN_URL}canalPedagogico/libraryNews/${ticket.cover}`}/></ContainerImg> */}
      <ContainerInfo>
        <Title>Titulo: {ticket.bookTitle}</Title>
        <Text2>Exemplar: {ticket.destinedTo}</Text2>
        <Text2>Ano: {ticket?.schoolGrade}</Text2>
        {/* <Editora>Editora: {ticket.book?.publisherName}</Editora>
        <Disciplina>Disciplina: {ticket.book?.subjectName}</Disciplina>
        <Exemplar>Exemplar: {ticket.book?.destinedTo}</Exemplar> */}
        <Unidade>Unidade Origem: {ticket.unityName}</Unidade>
        <Disponivel>Disponivel: {ticket.stockBalance}</Disponivel>
        <Disponivel>Aguardando aprovação: {(ticket.totalRequested*-1)}</Disponivel>
        {ticket.dateSearch && <Text>Atualizado em: {ticket.dateSearch}</Text>}
      </ContainerInfo>
      <ContainerButtons>
        {accessRight("books",category).SP_rights.can_request_books && ticket.stockBalance>0 && <ReadMoreButton
          text="Solicitar Reserva"
          type="button"
          onClick={()=>{navigate(`/bookrequest/${category}/add/${ticket.idBook}/${ticket.idUnity}`);}}
        />}
      </ContainerButtons>
    </Container>
  );
};

export default Stock;