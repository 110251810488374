import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";

const PrivateRoute = () => {
  const { user } = useAuth();
  const location = useLocation();
  // console.log(user);

  return user ? <Outlet /> : <Navigate to={`/login/redirect/${location.pathname.replaceAll('/','*')}`} />;
};

export default PrivateRoute;
