import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 90%;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 2rem;

  /* background-color: ${(props) => props.backgroundColor};
 color: ${(props) => props.color}; */

  ${(props) =>
    props.type === "2457a23b-101f-485a-a4a1-c7fa7ab7926b" &&
    css`
      //Formação elementar
      color: var(--black);
      background-color: var(--softYellow);
    `}

  ${(props) =>
    props.type === "4fd7b9f6-7de6-4c93-b430-92b202da88f6" &&
    css`
      //Formação complementar
      color: var(--black);
      background-color: var(--green);
    `}

 ${(props) =>
    props.type === "c6fd3cff-dee0-4a06-b1f0-821692257687" &&
    css`
      //Evento deped
      color: var(--black);
      background-color: var(--softPurple);
    `}

 ${(props) =>
    props.type === "c89c92e0-f70c-450f-9307-d2ea062c5a4b" &&
    css`
      //Evento auditorio
      color: var(--black);
      background-color: var(--softBlue);
    `}

 ${(props) =>
    props.type === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3" &&
    css`
      //avaliações
      color: var(--black);
      background-color: var(--softRed);
    `}

 @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Mark = styled.div`
  margin-top: 1.5rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1rem;
  margin-right: 1.2rem;
  background-color: var(--primary);
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;

  /* font: var(--title); */

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const TitleText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 95%;

  font: var(--title);
  /* font: var(--title); */

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 2rem;
  font: var(--body);

  span {
    font-weight: 800;
    margin-right: 1rem;
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const SubTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  font: var(--body);
  font-weight: 800;
  margin-right: 1rem;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  font: var(--body);
  margin-right: 1rem;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const MoreInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  font: var(--body);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Formador = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 2rem;

  font: var(--body);

  span {
    font-weight: 800;
    margin-right: 1rem;
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Contato = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 2rem;

  font: var(--body);

  span {
    font-weight: 800;
    margin-right: 1rem;
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Editar = styled.a`
  width: 2rem;
  margin-left: 0.8rem;
`;

export const Link = styled.a`
  color: var(--primary);
`;

export const MeetingsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;


