import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import {
  Contact,
  Container,
  ContentText,
  Images,
  Map,
  Text,
  Title,
  ViewImages,
} from "./styles";
import Banner from "../../components/UI/Banner";
import images from "../../constants/images";

const School = () => {
  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.ambiental_banner}>ESCOLA AMBIENTAL</Banner>
      <ContentBox>
        <ContentText>
          <Title>QUEM SOMOS? </Title>
            <Text>  
            A Escola Ambiental de Mogi das Cruzes foi inaugurada em 5 de junho de 2006 e funciona como um centro de pesquisa e formação
            de professores das redes municipal, estadual e particular, em parceria com universidades, instituições e ONGs. O objetivo
            é assegurar que as escolas formulem projetos de educação ambiental e desenvolvam ações concretas de preservação 
            para formar cidadãos críticos, preocupados com o destino coletivo e dispostos a enfrentar os desafios do mundo.
            </Text>
            <ViewImages>
              <Images src={images.escola1} />
              <Images src={images.escola2} />
            </ViewImages>
            <Text>
            A área e o prédio onde a escola foi implantada foi repassado pelo DAEE à Prefeitura, que investiu pouco mais de R$ 212 mil
             para as adequações, reforma e compra de equipamento. O prédio possui laboratório, biblioteca, videoteca, sala de reunião, 
             duas salas de aula, viveiro e dependências administrativas. 
            </Text>
          </ContentText>

        <Map
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1087.0783035282311!2d-46.25769739793795!3d-23.5870767518195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce76f2a9bf2fa5%3A0xc5359e55e1963a94!2sEscola%20Ambiental%20de%20Mogi%20das%20Cruzes!5e0!3m2!1spt-BR!2sbr!4v1701785919699!5m2!1spt-BR!2sbr"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />

        <Contact>Rod. Eng. Cândido do Rego Chaves, 4300 - Km 50 - Varinhas</Contact>
        <Contact>CEP: 08751-001, Mogi das Cruzes - SP.</Contact>
        <Contact>Segunda a sexta-feira, das 8 às 17 horas.</Contact>
        <Contact>Telefone: (11) 4729-5674</Contact>
        <Contact>E-mail: escolaambiental@se-pmmc.com.br</Contact>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default School;
