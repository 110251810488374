import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
// import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
// import axios from "axios";
import {
  Container,
  Title,
  Form,
  Item,
  Controls,
  // Text,
  Description,
  ListButton,
  Icon,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputSelect from "../../../components/UI/Inputs/InputSelect";
// import InputSelect from "../../../components/UI/Inputs/InputSelect";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";

// import AttachPlaceholder from "../../../components/UI/AttachPlaceholder";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import Modal from "../../../components/UI/Modal";

import {icons} from "../../../constants";

const TradeBookFormRequest = ({ action }) => {
  //configura pagina para actions
  /* 
    disporAdd
    solicitarAdd
    aprovarEdit

    corrigir action no routes aprovarEdit
  */
  const { user } = useAuth();

  const { category, id, idBook, idUnity } = useParams();

  const [ISBNValido, setISBNValido] = useState(false);
  // const [ISBNCheckSumValido, setISBNCheckSumValido] = useState(false);
  const [ISBNMessage, setISBNMessage] = useState("");
  const [limite, setLimite] = useState(-1);

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [modalError, setModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const [listBook, setListBook] = useState([]);
  const [bookInfo, setBookInfo] = useState({});

  const [modalNewBook, setModalNewBook] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      ISBN:"",
      idBook:"",
      quantityRequested:null, //usado para reserva
      quantityApproved:null, //usado para disponibilização e aprovação
      idUnityOrigin:"",
      idUnity:"",
      requestNote:"", //usado para reserva
      approveNote:"", //usado para disponibilização e aprovação
      unityName:"",
      bookTitle:"",
      unityOriginName:"",
    },
    validationSchema: Yup.object({
      ISBN: Yup.string(),
      quantityRequested: Yup.mixed().test('Valido',(d)=>{return `Valor inválido insira um valor entre 1-${limite}`},(value)=>{
        if((action === "requestAdd")||(action === "requestEdit")){return ((value>0) && (value<=limite))}
        return true}),
      quantityApproved: Yup.mixed().test('Valido',(d)=>{return `Valor inválido insira um valor entre 0-${limite}`},(value)=>{
        if(action === "approveEdit"){return ((value>=0) && (value<=limite))} 
        if((action === "postAdd")){return (value>0)}
        return true}),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      let newValues = {
        ...values,
        quantityRequested:values.quantityRequested===null?null:values.quantityRequested*-1,
        quantityApproved:values.quantityRequested===null?
          values.quantityApproved:
          values.quantityApproved===null?
            null:
            values.quantityApproved*-1,
      }
      
      if ((action === "postAdd")||(action==="requestAdd")) {
        saveItem({
          data: newValues,
        });  
      }
      else {
        editItem({
          data: newValues,
        });  
      }

    },
  });

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books/movements/",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("salvou", response);
        // navigate("/pnld");
        setModal(true);
      }
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books/movements/",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("editou", response);
        // navigate("/pnld");
        setModal(true);
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books/movements/",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("deletou", response);
        // navigate("/pnld");
        setModalMessage("Remoção da Solicitação de Livro realizado com sucesso");
        setModal(true);
      }
    },
    onError: (error)=>{
      console.log("error",error.response);
      if(error.response.status === 403){
        setModalErrorMessage(error.response.data.message);
        setModalError(true);
      }
    }
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books/movements/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        const newMove = {
          ...response.data[0],
          quantityRequested:response.data[0].quantityRequested===null?null:response.data[0].quantityRequested*-1,
          quantityApproved:response.data[0].quantityRequested===null?
                response.data[0].quantityApproved:
                response.data[0].quantityApproved===null?
                  null:
                  response.data[0].quantityApproved*-1,
        };
        loadStock({
          params:{
            idBook:response.data[0].idBook,
            idUnity:response.data[0].idUnityOrigin,
            idCategory:category
          }
        });
        console.log("newMove", newMove);
        formik.setValues(newMove);
      }
    },
  });

  const [loadStock, loadStockInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/books/stockBalance/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if (response.data.length > 0) {
          console.log("loadStock", response.data)
          formik.setFieldValue("unityOriginName", response.data[0].unityName);
          setLimite(response.data[0].stockBalance);
        }
      }
      console.log("response", response)
    },
  });

  const [loadBook, loadBookInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if (response.data.length > 0) {
          console.log("loadBook",response.data)
          console.log("book",response.data[0])
          setBookInfo(response.data[0]);
          formik.setFieldValue("bookTitle", response.data[0].title);
          formik.setFieldValue("idBook", response.data[0].idBook);
          if(!ISBNValido){
            if(response.data[0].active){
              setISBNValido(true);
            }
            else{
              setISBNValido(false);
              setISBNMessage("Disponibilização desse livro foi inativado no Sistema");
            }
          }
        }
        else{
          setModalNewBook(true);
          // setISBNMessage("Livro não localizado no sistema");
        }
      }
    },
  });

  const [loadListBook, loadListBookInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        response.data.sort((a,b)=>{
          if (a.schoolGrade < b.schoolGrade) return -1;
          if (a.schoolGrade > b.schoolGrade) return 1;
          return 0;})
        console.log("lista de livros: ", response.data);
        // response.data.sort((a,b)=>{return a.schoolGrade - b.schoolGrade});
        setListBook(response.data.sort((a,b)=>{return a.schoolGrade - b.schoolGrade}));
      }
    },
  });

  const handleDelete = () => {
    const confirm = window.confirm("Deseja apagar esse item?");

    if (confirm) {
      deleteItem({
        data: {
          idMovement: id,
        },
      });
    }
  };


  useEffect(() => {
    if (action === "postAdd") {
      formik.setFieldValue("idUnity", user.dados.unities[0]?.idUnity);
      setModalMessage("Disponibilização de livro criado com sucesso");
    }
    if (action === "requestAdd") {
      formik.setFieldValue("idUnityOrigin", idUnity);
      formik.setFieldValue("idUnity", user.dados.unities[0]?.idUnity);
      setModalMessage("Solicitação de livro criado com sucesso");
    }
    if (action === "requestEdit") {
      setModalMessage("Solicitação de livro atualizado com sucesso");
    }
    if (action === "approveEdit") {
      setISBNValido(true);
      setModalMessage("Aprovação realizada com sucesso");
    }
    if (idUnity){
      loadStock({
        params:{
          idBook,
          idUnity,
          idCategory:category
        }
      });
    }
    console.log("idBook",idBook);
    if (idBook){
      loadBook({
        params:{
          idBook
        }
      });
    }

    if(id){
      loadItem({
        params:{
          idMovement:id
        }
      });
    }

    console.log(user);

    // eslint-disable-next-line
  }, []);

  const validateISBN = (isbn) => {
      isbn = isbn.replace(/[^\d]/g, '');
      // Calculate the checksum for ISBN-10
      if (isbn.length <= 10) {
        let sum = 0;
        for (let i = 0; i < 9; i++) {
          sum += parseInt(isbn[i]) * (10 - i);
        }
        if(isNaN(isbn[9])){
          sum += 10 * 1;
        }else{
          sum += parseInt(isbn[9]) * 1;
        }
        console.log("Sum isbn", sum);
        console.log("resto sum", sum%11);
        if ((sum % 11) === 0) {
          setISBNMessage("");
          loadIsbnBook();
          return true;
        } else {
          setISBNMessage("ISBN inválido para ISBN-10");
          return false;
        }
      }
      // Calculate the checksum for ISBN-13
      if (isbn.length === 13) {
        let sum = 0;
        for (let i = 0; i < 12; i++) {
          sum += parseInt(isbn[i]) * (i % 2 === 0 ? 1 : 3);
        }
        var checkDigit = (10 - (sum % 10)) % 10;
        if (checkDigit === parseInt(isbn[12])) {
          setISBNMessage("");
          loadIsbnBook();
          return true;
        } else {
          setISBNMessage("ISBN inválido para ISBN-13");
          return false;
        }
      }
  };

  const loadIsbnBook = () => {
    loadBook({
      params:{
        isbn:formik.values.ISBN,
      }
    });
  }

  useEffect(() => {
    setISBNValido(false);
    if(formik.values.ISBN){
      if(formik.values.ISBN.length===10||formik.values.ISBN.length===13){
        // Remove any non-digit characters from the ISBN
        console.log("valida o ISBN")
        // setISBNCheckSumValido(validateISBN(formik.values.ISBN));
        validateISBN(formik.values.ISBN);
      }
      else{
        // setISBNCheckSumValido(false);
        setISBNMessage("");
      }
    }
    else{
      formik.setFieldValue("isbn", "");
    }
    // eslint-disable-next-line
  }, [formik.values.ISBN]);

  useEffect(() => {
    console.log(formik.values.idBook);
    // eslint-disable-next-line
  }, [formik.values.idBook]);

  useEffect(() => {
    console.log("formik",formik.values)
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    if(category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593"){
      loadListBook({
        params:{
          idCategory:category,
          active:true,
        }
      });
    }
    // eslint-disable-next-line
  }, [category]);

  // useEffect(() => {
  //   console.log("formik",formik.values);
  //   console.log("formik",formik);
  // }, [formik.values]);

  useEffect(() => {
    console.log("loadStockInfo",loadStockInfo);
  }, [loadStockInfo]);

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        loadStockInfo.loading ||
        loadBookInfo.loading ||
        loadListBookInfo.loading ||
        saveItemInfo.loading) && <FullScreenLoading />}
      {/* {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ||
        loadStockInfo.status ||
        loadBookInfo.status ||
        saveItemInfo.status) && <Error errorCode={401} />} */}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>
          <div>
            {action==="postAdd" && <Icon src={icons.pilha_livros} alt="Disponibilização de Livros" />}
            {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>
              {action==="postAdd" && "Disponibilização Livro PNLD" }
              {action==="requestAdd" && "Solicitação Livro PNLD" }
              {action==="requestEdit" && "Solicitação Livro PNLD (Editar)" }
              {action==="approveEdit" && "Aprovação da solicitação - Livro PNLD" }
            </>}

            {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>
              {action==="postAdd" && "Disponibilização Livro do Estado" }
              {action==="requestAdd" && "Solicitação Livro do Estado" }
              {action==="requestEdit" && "Solicitação Livro do Estado (Editar)" }
              {action==="approveEdit" && "Aprovação da solicitação - Livro do Estado" }
            </>}
          </div>
        </Title>

        <Description>
          {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>
            {action==="postAdd" && "Área destinada a disponibilizar livros do PNLD, indique o ISBN para localizar as informações do livro e na sequência preencha os campos necessários. " }
            {action==="requestAdd" && "Área destinada a solicitar livros do PNLD, indique o ISBN para localizar as informações do livro. " }
            {action==="requestEdit" && "Área destinada a editar a solicitação de livros do PNLD. " }
            {action==="approveEdit" && "Área destinada a aprovação de requisições de livros do PNLD. Indique a quantidade aprovada e utilize o campo observação se necessário." }
          </>}

          {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>
          </>}
        </Description>

        <Form onSubmit={formik.handleSubmit}>
          {action === "requestEdit" && user.profiles[0]?.idUserHasProfile === formik.values.idUserHasProfile && (
            <DeleteButton text="Apagar" onClick={() => handleDelete()} />
          )}
          {/* Area Disponibilização */}
          {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>
            {action==="postAdd" && <>
              {!ISBNValido && 
              <Item>
                <InputText
                  placeholder="ISBN"
                  columns={90}
                  name="ISBN"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ISBN}
                  error={
                    formik.touched.ISBN &&
                    formik.errors.ISBN &&
                    formik.errors.ISBN
                  }
                />
                {ISBNMessage}
              </Item>
              }
              { ISBNValido && (
              <>
                <Item>
                  ISBN: {formik.values.ISBN}
                </Item>
                <Item>
                  Titulo: {formik.values.bookTitle}
                </Item>
                <Item>
                  Unidade Origem: {user.dados.unities[0]?.unityName}
                </Item>
                <Item>
                  <InputText
                    placeholder="Quantidade a disponibilizar"
                    columns={25}
                    name="quantityApproved"
                    type="number"
                    min="1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantityAproved}
                    error={
                      formik.touched.quantityAproved &&
                      formik.errors.quantityAproved &&
                      formik.errors.quantityAproved
                    }
                  />
                </Item>
              </>
              )}
            </>}
          </>}

          {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>
            {action==="postAdd" && <>
            { !formik.values.idBook && <Item>
                <InputSelect 
                    onChange={(e)=>{
                                  formik.setFieldValue("idBook", e.target.value);
                                  loadBook({
                                    params:{
                                      idBook:e.target.value
                                    }
                                  });
                              }}
                    value={formik.values.idBook}
                    placeholder="Livro:"
                    columns={3}
                  >
                    <option value="" defaultValue>Selecione o livro a ser disponibilizado</option>
                      {listBook.map((book) => (
                                        <option key={book.idBook} value={book.idBook}>
                                          {book.schoolGrade} - {book.title} - {book.destinedTo}
                                        </option>
                                      ))
                      }
                </InputSelect>
              </Item>}
              { formik.values.idBook && (
              <>
                <Item>
                  Titulo: {formik.values.bookTitle}
                </Item>
                <Item>
                  Ciclo: {bookInfo.schoolGrade}
                </Item>
                <Item>
                  Unidade Origem: {user.dados.unities[0]?.unityName}
                </Item>
                <Item>
                  <InputText
                    placeholder="Quantidade a disponibilizar"
                    columns={25}
                    name="quantityApproved"
                    type="number"
                    min="1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantityAproved}
                    error={
                      formik.touched.quantityAproved &&
                      formik.errors.quantityAproved &&
                      formik.errors.quantityAproved
                    }
                  />
                </Item>
              </>
              )}
            </>}
          </>}

          {/* Fim Area Disponibilização */}

          {/* Area Solicitação */}
          {((action==="requestAdd")||(action==="requestEdit")) && <> 
            <Item>
              Titulo: {formik.values.bookTitle}
            </Item>
            <Item>
              Unidade Origem: {formik.values.unityOriginName}
            </Item>
            <Item>
              Unidade Destino: {user.dados.unities[0]?.unityName}
            </Item>
            <Item>
              <InputText
                  placeholder={`Quantidade: (max. ${limite})`}
                  columns={30}
                  name="quantityRequested"
                  type="number"
                  min="1"
                  max={limite}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.quantityRequested}
                  error={
                    formik.touched.quantityRequested &&
                    formik.errors.quantityRequested &&
                    formik.errors.quantityRequested
                  }
              />  
            </Item>
            <Item>
              <InputText
                placeholder="Observação: "
                columns={90}
                name="requestNote"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.requestNote}
                error={
                  formik.touched.requestNote &&
                  formik.errors.requestNote &&
                  formik.errors.requestNote
                }
              />
            </Item>
          </>}
          {/* Fim Area Solicitação */}

          {/* Area Aprovação */}
          {action==="approveEdit" && <>
            <Item>
              Titulo: {formik.values.bookTitle}
            </Item>

            <Item>
              Unidade Origem: {formik.values.unityOriginName}
            </Item>
            <Item>
              Unidade Destino: {formik.values.unityName}
            </Item>
            
            <Item>
              Quantidade Solicitada: {(formik.values.quantityRequested)}
            </Item>

            <Item>
              Observação Solicitante:<br/>
              {formik.values.requestNote}
            </Item>

            <Item>
              <InputText
                  placeholder={`Quantidade aprovada: (max. ${limite})`}
                  columns={25}
                  name="quantityApproved"
                  type="number"
                  min="0"
                  max={limite}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.quantityApproved}
                  error={
                    formik.touched.quantityApproved &&
                    formik.errors.quantityApproved &&
                    formik.errors.quantityApproved
                  }
              />  
            </Item>
            <Item>
              <InputText
                placeholder="Observação: "
                columns={90}
                name="approveNote"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.approveNote}
                error={
                  formik.touched.approveNote &&
                  formik.errors.approveNote &&
                  formik.errors.approveNote
                }
              />
            </Item>
          </>} 
          {/* Fim Area Aprovação */}

          
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
        <Modal open={modal} buttonClose={false}>
          {modalMessage}
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{
              navigate(-1);
            }}
          />
        </Modal>
        <Modal open={modalError} buttonClose={false}>
          {modalErrorMessage}
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{navigate(`/book/${category}`);}}
          />
        </Modal>

        <Modal open={modalNewBook} buttonClose={false}>
          <div>Livro não localizado no sistema deseja cadastrar esse livro no sistema?</div>
          <ListButton>
            <ReadMoreButton
              text="Sim"
              type="button"
              onClick={()=>{navigate(`/bookregister/${category}/add/${formik.values.ISBN}`)}}
            />
            <ReadMoreButton
              text="Não"
              type="button"
              onClick={()=>{setModalNewBook(false)}}
            />
          </ListButton>
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default TradeBookFormRequest;
