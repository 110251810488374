// EXTERNAL REACT LIBRARIES IMPORTS
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  // MdArrowLeft,
  // MdArrowRight,
  MdInfoOutline,
  // MdEdit,
} from "react-icons/md";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";

// UI
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Menu from "../../components/UI/Menu";
import Footer from "../../components/UI/Footer";
import InputSearch from "../../components/UI/Inputs/InputSearch";
import VideoItem from "../../components/UI/VideoItem";
// import LoadingItem from "../../components/UI/LoadingItem";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import { useTheme } from "../../hooks/useTheme";

//STYLES
import {
  Container,
  VideosComplementaresContainer,
  VideosContainer,
  VideoItemContainer,
  // TituloVideosComplementares,
  // SliderContainer,
  SearchBox,
  Search,
  SearchResultBox,
  SearchResult,
  ItemResultSearch,
  ItemResultSearchVideo,
  ItemResultSearchInfo,
  TagsContainer,
  Tag,
  IconEdit,
} from "./styles";

//ASSETS
import { images, icons } from "../../constants";

//HOOKS
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import SearchMessage from "../../components/UI/SearchMessage";

// function SampleNextArrow(props) {
//   const { onClick } = props;
//   return (
//     <MdArrowRight
//       onClick={onClick}
//       size={70}
//       style={{
//         cursor: "pointer",
//         position: "absolute",
//         right: -47,
//         top: "35%",
//       }}
//     />
//   );
// }

// function SamplePreviosArrow(props) {
//   const { onClick } = props;
//   return (
//     <MdArrowLeft
//       onClick={onClick}
//       size={70}
//       style={{
//         cursor: "pointer",
//         position: "absolute",
//         left: -47,
//         top: "35%",
//       }}
//     />
//   );
// }

// const settings = {
//   arrows: true,
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   nextArrow: <SampleNextArrow />,
//   prevArrow: <SamplePreviosArrow />,
//   responsive: [
//     {
//       breakpoint: 1100,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         initialSlide: 1,
//       },
//     },
//     {
//       breakpoint: 780,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         initialSlide: 1,
//       },
//     },
//   ],
// };

const VideoLibrary = () => {
  // STATES
  // const [sliderRef, setSliderRef] = useState(null);
  // const sliderRef = useRef(null);
  const [videosList, setVideosList] = useState([]);
  const [buscaVideos, setBuscaVideos] = useState([]);
  const [inputSearchVideos, setInputSearchVideos] = useState("");
  const [isEditor, setIsEditor] = useState(false);
  const [time, setTime] = useState(0);
  const [total, setTotal] = useState(0);
  // const [initialLoading, setInitialLoading] = useState(true);

  // CONSTANTS
  const { user } = useAuth();
  const { themeFile } = useTheme();
  // const navigate = useNavigate();

  //  FUNCTIONS

  const [getVideos, getVideosInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/videolibrary/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        let result = [];
        response.data.forEach((item) => {
          const found = videosList.findIndex((video) => video.id === item.id);
          if (found === -1) {
            return result.push(item);
          }
        });
        setVideosList([...videosList, ...result]);
      }
    },
  });

  const [searchVideos, searchVideosInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/videolibrary/byTerm",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        let result = [];
        response.data.forEach((item) => {
          const found = buscaVideos.findIndex((video) => video.id === item.id);
          if (found === -1) {
            return result.push(item);
          }
        });
        setTotal(parseInt(response.data.length));
        setBuscaVideos([...buscaVideos, ...result]);
        setTime(response.config.time);
      }
    },
  });

  const handleChangeInputSearchVideos = (e) => {
    setTotal(0);
    setTime(0);
    setInputSearchVideos(e.target.value);
    if (e.target.value.length > 2) {
      setBuscaVideos([]);
      setVideosList([]);
      searchVideos({
        params: {
          term: e.target.value.trim(),
        },
        debounced: true,
      });
    }
    if (e.target.value.length === 0) {
      getVideos();
    }
  };

  const getEditorId = () => {
    let teste = user?.profiles[0]?.editor?.find(
      (item) => item.category === "Videoteca"
    );
    return teste?.id ? teste.id : false;
  };

  const editorId = getEditorId();

  const handleDetails = (item) => {
    setBuscaVideos((old) => [...old, item]);
  };

  useEffect(() => {
    getVideos();
    let teste = user?.profiles[0]?.editor?.find(
      (item) => item.category === "Videoteca"
    );
    teste && setIsEditor(true);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {getVideosInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconVideoteca}
        url="/videoteca/add"
        editorId={editorId}
      >
        VIDEOTECA
      </Banner>
      <SearchBox>
        <InputSearch
          value={inputSearchVideos}
          onChange={handleChangeInputSearchVideos}
          placeholder="Digite o tema do vídeo"
          loading={searchVideosInfo.loading}
        />
      </SearchBox>
      <ContentBox>
        {inputSearchVideos.length > 2 ? (
          <Search marginBottom={buscaVideos.length === 0 ? 0 : 3}>
            <SearchResult>
              <SearchResultBox>
                <SearchMessage total={total} time={time} />
                {buscaVideos.map((item, key) => (
                  <ItemResultSearch key={key}>
                    <ItemResultSearchVideo>
                      <iframe
                        width="100%"
                        height="100%"
                        src={item.link?.replace("watch?v=", "embed/")}
                        title={item.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </ItemResultSearchVideo>
                    <ItemResultSearchInfo>
                      <h2>
                        <Link to={`/videoteca/${item.id}`}>{item.title}</Link>
                      </h2>
                      <p>{item.description.substr(0, 70) + "..."}</p>
                      <TagsContainer>
                        {item.keywords.map((res, ind) => (
                          <Tag key={ind}>{res}</Tag>
                        ))}
                        <>
                          <Popup
                            contentStyle={{ width: "50%" }}
                            trigger={
                              <button
                                style={{
                                  background: "transparent",
                                }}
                              >
                                <MdInfoOutline
                                  size={32}
                                  style={{
                                    cursor: "pointer",
                                    color: "var(--primary)",
                                  }}
                                />
                              </button>
                            }
                            position="top center"
                            nested
                            closeOnDocumentClick
                          >
                            <div>
                              <strong>{item.title}</strong>
                              {item.abilities.map((a, i) => (
                                <p key={i}>
                                  <strong>Habilidade:&nbsp;</strong>
                                  {a.value}
                                </p>
                              ))}
                              {item.ods.map((o, j) => (
                                <p key={j}>{o.od}</p>
                              ))}
                            </div>
                          </Popup>
                          {isEditor && (
                            <Link to={`/videoteca/editar/${item.id}`}>
                              <IconEdit
                                src={
                                  themeFile.COLORS.highContrast
                                    ? icons.edit
                                    : icons.editBlack
                                }
                              />
                            </Link>
                          )}
                        </>
                      </TagsContainer>
                    </ItemResultSearchInfo>
                  </ItemResultSearch>
                ))}
              </SearchResultBox>
            </SearchResult>
          </Search>
        ) : (
          <VideosComplementaresContainer>
            {/* <TituloVideosComplementares>
              <h2>VÍDEOS COMPLEMENTARES</h2>
            </TituloVideosComplementares> */}
            <VideosContainer>
              {/* <SliderContainer> */}
              {/* <Slider ref={sliderRef} {...settings}> */}
              {videosList?.map((item, key) => (
                <VideoItemContainer
                  key={key}
                  onClick={() => handleDetails(item)}
                >
                  <VideoItem
                    url={item.link}
                    title={
                      <Link to={`/videoteca/${item.id}`}>{item.title}</Link>
                    }
                    tags={item.keywords}
                    icon={
                      <>
                        <Popup
                          contentStyle={{ width: "30%" }}
                          trigger={
                            <button
                              style={{
                                background: "transparent",
                                display: "flex",
                              }}
                            >
                              <MdInfoOutline
                                size={32}
                                style={{
                                  cursor: "pointer",
                                  color: "var(--primary)",
                                }}
                              />
                            </button>
                          }
                          position="top center"
                          nested
                          closeOnDocumentClick
                        >
                          <div>
                            <strong>{item.title}</strong>
                            {item.abilities.map((a, i) => (
                              <p key={i}>
                                <strong>Habilidade:&nbsp;</strong>
                                {a.value}
                              </p>
                            ))}
                            {item.ods.map((o, i) => (
                              <p key={i}>{o.od}</p>
                            ))}
                          </div>
                        </Popup>

                        {isEditor && (
                          <Link to={`/videoteca/editar/${item.id}`}>
                            <IconEdit
                              src={
                                themeFile.COLORS.highContrast
                                  ? icons.edit
                                  : icons.editBlack
                              }
                            />
                          </Link>
                        )}
                      </>
                    }
                  />
                </VideoItemContainer>
              ))}
              {/* </Slider>
              </SliderContainer> */}
            </VideosContainer>
          </VideosComplementaresContainer>
        )}
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default VideoLibrary;
