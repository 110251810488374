import React from "react";
import { Container } from "./styles";

const ReadMoreButton = ({
  text = "+ Leia Mais",
  disabled = false,
  alert = false,
  onClick = () => {},
  ...rest
}) => {
  return (
    <Container disabled={disabled} alert={alert} onClick={onClick} {...rest}>
      {text}
    </Container>
  );
};

export default ReadMoreButton;
