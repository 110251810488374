import styled, { css } from "styled-components";

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: var(--purple);
  border-radius: 2rem;
  font-family: var(--fontFamily);
  font-size: calc(var(--size) * 1.3);
  font-weight: var(--regularFont);
  padding: 1rem 2.6rem;
  color: var(--secondary);
  min-width: calc(var(--size) * 18);

  ${(props) =>
    props.disabled
      ? css`
          background-color: var(--darkGray);
          color: var(--extraDarkGray);
        `
      : css`
          cursor: pointer;
        `}

  ${(props) =>
    props.alert
      ? css`
          background-color: var(--darkGray);
          color: var(--secondary);
        `
      : css`
          cursor: pointer;
        `}
`;
