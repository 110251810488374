import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem 1rem;
  max-width: 60rem;
  width: 100%;
  height: 8rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 35px 35px 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 1rem;

  @media (max-width: 500px) {
    flex-direction: row-reverse;
    padding: 2rem;
    gap: 1rem;
    height: 6rem;
    border-radius: 0px 17px 17px 17px;
  }
`;

export const LogoSearch = styled.button`
  width: 4rem;
  background: unset;

  @media (max-width: 500px) {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: var(--body);
  font-size: calc(var(--size) * 1.8);

  /* border: 1px solid black; */
  flex: 1;

  @media (max-width: 500px) {
    cursor: pointer;
    font-size: calc(var(--size) * 1.6);
  }
`;

export const Button = styled.button`
  width: 134px;
  height: 63px;
  background: linear-gradient(
    180deg,
    var(--degradeSecondary) 0%,
    var(--degradePrimary) 100%
  );
  border-radius: 30px;
  cursor: pointer;
  font: var(--title);
  color: var(--secondary);

  @media (max-width: 500px) {
    display: none;
  }
`;
