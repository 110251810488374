import React, { createContext, useState, useContext, useEffect } from "react";

import { io } from "socket.io-client";

import useApi from "../hooks/useApi";

import { useTheme } from "../hooks/useTheme";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { themeFile, setThemeFile } = useTheme();
  const [selectedUser, setSelectedUser] = useState({});
  const [update, setUpdate] = useState(false);
  const [socket, setSocket] = useState(null);
  const [sessionID, setSessionID] = useState(() => {
    const socketStorage = localStorage.getItem(
      `[@${process.env.REACT_APP_NAME}SessionID]`
    );

    if (socketStorage) {
      return socketStorage;
    }

    return null;
  });

  const [user, setUser] = useState(() => {
    const userStorage = localStorage.getItem(
      `[@${process.env.REACT_APP_NAME}User]`
    );
    if (userStorage) {
      return JSON.parse(userStorage);
    }
    return null;
  });

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem(
      `[@${process.env.REACT_APP_NAME}Token]`
    );

    if (tokenStorage) {
      return tokenStorage;
    }

    return null;
  });

  const [protectedToken, setProtectedToken] = useState(() => {
    const tokenStorage = localStorage.getItem(
      `[@${process.env.REACT_APP_NAME}ProtectedToken]`
    );

    if (tokenStorage) {
      return tokenStorage;
    }

    return null;
  });

  const [saveSettings, saveSettingsInfo] = useApi({
    debounceDelay: 0,
    url: "settings/v1",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response);
        console.log(saveSettingsInfo);
      }
    },
  });

  const [sessionLogin, sessionLoginInfo] = useApi({
    debounceDelay: 0,
    url: "login/v2",
    method: "post",
    timeout: 7000,
    onCompleted: (response) => {
      if (!response.error) {
        console.log("login",response);
        const navigate = response.config.navigate;
        const { name, profiles } = response.data;
        const dados = response.data;
        const authorizedProfiles = profiles.filter(
          (item) => item.product === "3001c006-6b82-4136-a467-7ed4a276f582"
        );
        // const authorizedProfiles = profiles.filter(
        //   (item) => item.product === "05f2b2ba-ec15-4b79-a25e-1d6d59a5e121"
        // );

        // const newProfiles = authorizedProfiles.map((item) => ({
        //   ...item,
        //   enabled: false,
        // }));

        const userPack = {
          name,
          profiles: authorizedProfiles,
          dados,
        };

        setUser(userPack);
        localStorage.setItem(
          `[@${process.env.REACT_APP_NAME}User]`,
          JSON.stringify(userPack)
        );

        //set first profile for active profile
        if(userPack.profiles.length>0){
          setToken(userPack.profiles[0].token);
          localStorage.setItem(
            `[@${process.env.REACT_APP_NAME}Token]`,
            userPack.profiles[0].token
          );
          userPack.profiles[0].enabled = true;
          setSelectedUser(userPack);
          updateUser(userPack);

          //setar o tema caso exista
          if (userPack.profiles[0].settings) {
            //AJUSTE TEMPORÁRIO - ARRUMAR
            // setThemeFile(JSON.parse(userPack.profiles[0].settings));
            console.log("theme", themeFile);
            setThemeFile(themeFile);
          } else {
            //setTheme based os settings object
            //=================================
            saveSettings({
              data: {
                settings: JSON.stringify(themeFile),
              },
            });
          }
        

          console.log("userPack", userPack.profiles[0].token);
          //set the socket connection
          const socketConn = io(process.env.REACT_APP_SOCKET_URL, {
            auth: { token: userPack.profiles[0].token },
          });
          setSocket(socketConn);

          console.log("ok", socketConn);

          //end set profile for active profile

          //don't analize if have more profiles
          navigate(response.config.redirect.replaceAll('*','/'));
        }

        // //If had more than one profile
        // if (authorizedProfiles.length > 1) {
        //   navigate(`/alternarperfil`);
        // } else {
        //   //Only one profile
        //   navigate(`/meuespaco`);
        // }
      }
    },
  });

  const [sessionLogout, sessionLogoutInfo] = useApi({
    debounceDelay: 0,
    url: "logout/v1",
    method: "delete",
    onCompleted: (response) => {
      console.log(response);
      if (!response.error) {
        console.log(response);
        const navigate = response.config.navigate;
        localStorage.removeItem(`[@${process.env.REACT_APP_NAME}User]`);
        localStorage.removeItem(`[@${process.env.REACT_APP_NAME}Token]`);
        localStorage.removeItem(`[@${process.env.REACT_APP_NAME}InfoPage]`);
        // localStorage.removeItem(`[@${process.env.REACT_APP_NAME}SessionID]`);
        socket?.disconnect();
        setSocket(null);
        updateUser(null);
        navigate(`/`);
      }
    },
  });

  const [infoPage, setInfoPage] = useState(() => {
    const userStorage = localStorage.getItem(
      `[@${process.env.REACT_APP_NAME}InfoPage]`
    );
    if (userStorage) {
      return JSON.parse(userStorage);
    }
    return null;
  });
  
  const updateInfoPage = (info) => {
    let newInfo = {...infoPage, ...info};
    // console.log("infoPage",infoPage);
    // console.log("newInfo",newInfo);
    if (info !== null) {
      localStorage.setItem(
        `[@${process.env.REACT_APP_NAME}InfoPage]`,
        JSON.stringify(newInfo)
      );
      // console.log("atualizou Info", newInfo);
    }
    setInfoPage(newInfo);
  };

  const switchProfile = (profileName, navigate) => {
    console.log("Switch Profile Scripts");
    user.profiles.forEach((item) => {
      if (profileName === item.name) {
        setToken(item.token);
        localStorage.setItem(
          `[@${process.env.REACT_APP_NAME}Token]`,
          item.token
        );
        //setar o tema caso exista
        if (item.settings) {
          // setThemeFile(JSON.parse(item.settings));
          setThemeFile(themeFile);
        } else {
          saveSettings({
            data: {
              settings: JSON.stringify(themeFile),
            },
          });
        }

        //set the socket connection
        const socketConn = io(process.env.REACT_APP_SOCKET_URL, {
          auth: { token: item.token },
        });
        setSocket(socketConn);

        const userOK = {
          name: user.name,
          dados: user.dados,
          profiles: [
            {
              ...item,
            },
            ...user.profiles.filter((teste)=>{return teste!==item})
          ],
        };
        
        updateUser(userOK);

        document.location.reload(false)
      }
    });

    //     setSelectedUser(userOK);

    //     return {
    //       ...item,
    //       enabled: true,
    //     };
    //   } else {
    //     return {
    //       ...item,
    //       enabled: false,
    //     };
    //   }
    // });

    // const newUser = {
    //   ...user,
    //   profiles: newProfiles,
    // };

    // updateUser(newUser);

    // navigate(`/painel`);
  };

  const login = ({ username, password, navigate, redirect="/home2" }) => {
    sessionLogin({
      data: {
        username,
        password,
      },
      navigate,
      redirect,
    });
  };

  const loginGoogle = ({ googleData, navigate, redirect="/home2" }) => {
    sessionLogin({
      data: googleData,
      navigate,
      redirect,
    });
  };

  const logout = ({ navigate }) => {
    sessionLogout({
      navigate,
    });
  };

  const updateUser = (user) => {
    if (user !== null) {
      localStorage.setItem(
        `[@${process.env.REACT_APP_NAME}User]`,
        JSON.stringify(user)
      );
      console.log("atualizou", user);
    }
    setUser(user);
  };

  const protectedLogin = ({ code, navigate }) => {
    if (code === "deped@canal22") {
      setProtectedToken("82cb0e19-5f81-409e-9468-4802dad104f7");
      localStorage.setItem(
        `[@${process.env.REACT_APP_NAME}ProtectedToken]`,
        "82cb0e19-5f81-409e-9468-4802dad104f7"
      );
      navigate("/");
    } else {
      window.alert("Acesso não permitido.");
    }
  };

  const accessRight = (area=null,category=null) => {
    if(user!==null){
      // if(!(user.profiles[0].hasOwnProperty("specialPermissions"))){
      //   localStorage.removeItem(`[@${process.env.REACT_APP_NAME}User]`);
      //   localStorage.removeItem(`[@${process.env.REACT_APP_NAME}Token]`);
      //   localStorage.removeItem(`[@${process.env.REACT_APP_NAME}InfoPage]`);
      //   socket?.disconnect();
      //   setSocket(null);
      //   updateUser(null);
      //   return {SP_erro:true,SP_msgErro:"Storage with data outdate",SP_rights:{}};  
      // }
      const specialPermissions = user.profiles[0]?.specialPermissions;
      const getArea = specialPermissions?.filter((sp)=>{return sp.area===area});
      if(getArea?.length>0){
          const getCategory = getArea[0]?.accessRights?.filter((ar)=>{return ar.idcategory===category});
          if(getCategory?.length>0){
              return {SP_erro:false,SP_msgErro:"",SP_rights:getCategory[0]};
          }
          else{
              console.log("Erro em useAuth accessRights (category)",category,getArea,getCategory);
              return {SP_erro:true,SP_msgErro:"categoria não localizada na lista de Permissões",SP_rights:{}};
          }
      }
      else {
          console.log("Erro em useAuth accessRights (Area)",area,specialPermissions,getArea);
          return {SP_erro:true,SP_msgErro:"Area não localizada na lista de Permissões",SP_rights:{}};
      }
    }
    else{
      return {SP_erro:true,SP_msgErro:"Usuario não logado",SP_rights:{}};
    }
  }

  const isEditor = (idCategory) => {
    if(user!==null){
      let editor = user.profiles[0].editor.findIndex(
        (editor) => editor.id === idCategory
      )
      return editor > -1 ? true : false; 
    }
    return false;
  };

  useEffect(() => {
    if (update) {
      // console.log(user);
    }
    setUpdate(true);

    // return () => socket?.disconnect();
  }, [user, update]);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        sessionLoginInfo,
        sessionLogoutInfo,
        socket,
        sessionID,
        selectedUser,
        protectedToken,
        setSessionID,
        setSocket,
        switchProfile,
        protectedLogin,
        login,
        loginGoogle,
        logout,
        updateUser,
        infoPage,
        updateInfoPage,
        accessRight,
        isEditor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  // console.log(context);

  if (!context) {
    throw new Error("Must be used within a provider.");
  }

  return context;
};
