import thumbnail from "../assets/images/thumbnail.png";
import logoPMMCsvgBlack from "../assets/images/logo-pmmc-preto.svg";
import logoPMMCsvgWhite from "../assets/images/logo-pmmc-branco.svg";
import logoPMMCsvgTransparent from "../assets/images/logo-pmmc-transp.svg";
import logoPMMCBrasaosvgWhite from "../assets/images/logo-pmmc-brasao-branco.svg";
import logoPMMCBrasaosvgBlack from "../assets/images/logo-pmmc-brasao-pb.svg";
import loadingSpinner from "../assets/images/loading_ca.json";
import gnutecaLogo from "../assets/images/gnutecaLogo.png";
import iconAgenda from "../assets/images/icon_agenda.json";
import iconAjuda from "../assets/images/icon_ajuda.json";
import iconAvaliacao from "../assets/images/icon_avaliacao.json";
import iconBiblioteca from "../assets/images/icon_biblioteca.json";
import iconConheca from "../assets/images/icon_conheca.json";
import iconDocumentos from "../assets/images/icon_documentos.json";
import iconFormacoes from "../assets/images/icon_formacoes.json";
import iconNotificacao from "../assets/images/icon_notificacao.json";
import iconPessoas from "../assets/images/icon_pessoas.json";
import iconPlataformas from "../assets/images/icon_plataformas.json";
import iconVideoteca from "../assets/images/icon_videoteca.json";
import iconMuve from "../assets/images/icon_muve.json";
import bannerBackground from "../assets/images/banner_bg_canal_pedagogico.svg";
import bannerBackgroundHighContrast from "../assets/images/banner_bg_canal_pedagogico_acessibilidade.svg";
import iconPerfil from "../assets/images/icon_perfil.json";
import iconLogin from "../assets/images/icon_login.json";
import cemforpe from "../assets/images/cemforpe2.jpg";
import simulados from "../assets/images/simulados.svg";
import migo from "../assets/images/migo.svg";
import eaprendi from "../assets/images/e-aprendi.svg";
import jornada from "../assets/images/jornada.svg";
import educacaoHumanizada from "../assets/images/educacao_humanizada.svg";
import matific from "../assets/images/matific.png";
import file from "../assets/images/file.png";
import shieldLock from "../assets/images/shield-lock.png";
import spy from "../assets/images/spy.png";
import apagar from "../assets/images/apagar.png";
import iconClip from "../assets/images/paper-clip.png";
import logoCidadeLeitora from "../assets/images/logo_cidadeleitora.png";
import icon_cidadeleitora from "../assets/images/icon_cidadeleitora.json";
import icon_pnld from "../assets/images/icon_pnld.json";
import icon_musica from "../assets/images/icon_musica.json";
import icon_seguranca from "../assets/images/icon_seguranca.json";
import note from "../assets/images/note.png";
import escola1 from "../assets/images/escolaambiental1.jpeg";
import escola2 from "../assets/images/escolaambiental2.png";
import manut from "../assets/images/manut.jpg";
import iconNovidades from "../assets/images/novidades.json";
import livrosEstado from "../assets/images/livros-estado.json";
import certificadoPng from "../assets/images/certificado.png";
import certificadoSvg from "../assets/images/certificado.svg";

import certificadoPng_Rgb from "../assets/images/certificado-rgb.png";
import certificado_telas from "../assets/images/certificado-telas.svg";
import certificado_valido from "../assets/images/certificado-valido.svg";
import certificado_cancelado from "../assets/images/certificado-cancelado.svg";

import certificadoPng_Rgb_logo from "../assets/images/certificado-rgb-logo.png";
import certificado_telas_logo from "../assets/images/certificado-telas-logo.svg";
import certificado_valido_logo from "../assets/images/certificado-valido-logo.svg";
import certificado_cancelado_logo from "../assets/images/certificado-cancelado-logo.svg";

import reds_banner from "../assets/images/reds.json";
import ambiental_banner from "../assets/images/ambiental-banner.json";


// eslint-disable-next-line
export default {
  thumbnail,
  logoPMMCsvgBlack,
  logoPMMCsvgWhite,
  logoPMMCsvgTransparent,
  loadingSpinner,
  gnutecaLogo,
  iconAgenda,
  iconAvaliacao,
  iconBiblioteca,
  iconConheca,
  iconDocumentos,
  iconFormacoes,
  iconNotificacao,
  iconPessoas,
  iconPlataformas,
  iconVideoteca,
  iconPerfil,
  iconLogin,
  bannerBackground,
  cemforpe,
  simulados,
  migo,
  eaprendi,
  jornada,
  educacaoHumanizada,
  matific,
  iconAjuda,
  bannerBackgroundHighContrast,
  file,
  shieldLock,
  spy,
  iconClip,
  apagar,
  iconMuve,
  logoCidadeLeitora,
  icon_cidadeleitora,
  icon_musica,
  icon_pnld,
  icon_seguranca,
  note,
  escola1,
  escola2,
  manut,
  iconNovidades,
  livrosEstado,
  certificadoPng,
  certificadoSvg,
  certificadoPng_Rgb,
  certificado_telas,
  certificado_valido,
  certificado_cancelado,
  certificadoPng_Rgb_logo,
  certificado_telas_logo,
  certificado_valido_logo,
  certificado_cancelado_logo,
  logoPMMCBrasaosvgWhite,
  logoPMMCBrasaosvgBlack,
  reds_banner,
  ambiental_banner,
};
