import React from "react";
import {
  MdOutlineErrorOutline,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { useTheme } from "../../../../hooks/useTheme";
import {
  Container,
  Input,
  InfoMessage,
  InputContainer,
  InputLabel,
} from "./styles";

const InputSelect = ({
  placeholder = "Nome",
  columns = 1,
  disabled = false,
  defaultValue,
  onChange,
  error = false,
  success = false,
  children,
  onClick = () => {},
  ...rest
}) => {
  const { themeFile } = useTheme();
  const handleSize = (columns) => {
    switch (columns) {
      case 1:
        return 30;
      case 2:
        return 60;
      case 3:
        return 90;
      default:
        return columns;
    }
  };

  return (
    <Container columns={handleSize(columns)}>
      <InputLabel>{placeholder}</InputLabel>
      <InputContainer>
        <Input onChange={onChange} {...rest}>
          {children}
        </Input>
        {error && !success && (
          <MdOutlineErrorOutline
            size={themeFile.SIZES.base * 16}
            color={themeFile.COLORS.hardRed}
          />
        )}
        {!error && success && (
          <MdOutlineCheckCircleOutline
            size={themeFile.SIZES.base * 16}
            color={themeFile.COLORS.green}
          />
        )}
      </InputContainer>
      {error && !success && <InfoMessage>*&nbsp;{error}</InfoMessage>}
    </Container>
  );
};

export default InputSelect;
