import styled, { css } from "styled-components";

export const Container = styled.div`
  display: ${(props)=>(props.visible?css`flex`:css`none`)};
  width: 100vw;
  align-items:center;
  justify-content:center;
`;

export const ContainerTable = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  margin-top: 2rem;
  max-width: 100vw;
  min-height: 80vh;
  overflow-x:auto;
  padding: 0 4rem;
`;

export const Table = styled.table`
  display: ${(props) =>props.visible ? css`block` : css`none`};
  word-wrap: normal;
  text-align:center;
  border-collapse: collapse;
  font-size: 13px;
  th, td {
    padding: 2px 4px;
  }
  .spacer{
    height: 20px;
  }
  .border{
    border: 1px solid var(--primary);
  }
  th.name{
    min-width:300px; 
    max-width:600px;
  }
  .cpf{
    min-width:110px;
  }
  .rgf{
    min-width:70px;
  }
  .email{
    min-width:300px;
    max-width:600px;
  }
  .activity{
    min-width:130px;
    max-width:140px;
  }
  .thc{
    min-width:90px;
    max-width:90px;
  }
  .hd{
    min-width:90px;
    max-width:90px;
  }
  .LSL{
    background-color: #FFFF00;
    color: #000000;
  }
  .P{
    background-color:#FF9900;
    color: #000000;
  }
  .TRE{
    background-color:#00FFFF;
    color: #000000;
  }
  .AF{
    background-color:#351C75;
    color: #FFFFFF;
  }
  .GN{
    background-color:#000000;
    color: #FFFFFF;
  }
  .AP{
    background-color:#00FF00;
    color: #000000;
  }
  .I{
    background-color:#5B0F00;
    color: #FFFFFF;
  }
  .AA{
    background-color:#CC0000;
    color: #FFFFFF;
  }
  .LM{
    background-color:#45818E;
    color: #FFFFFF;
  }
  .LSD{
    background-color:#674EA7;
    color: #FFFFFF;
  }
  .LA{
    background-color:#6AA84F;
    color: #FFFFFF;
  }
  .LP{
    background-color:#46BDC6;
    color: #000000;
  }
  .ASV{
    background-color:#C27BA0;
    color: #000000;
  }
`;