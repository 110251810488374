import React, { useEffect, useState } from "react";
import {
  MdOutlineErrorOutline,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { useTheme } from "../../../../hooks/useTheme";
import {
  Container,
  Input,
  InfoMessage,
  InputContainer,
  InputLabel,
} from "./styles";

const InputText = ({
  placeholder = "Nome",
  type="text",
  columns = 1,
  disabled = false,
  error = false,
  success = false,
  value,
  onClick = () => {},
  onChange = () => {},
  ...rest
}) => {
  const { themeFile } = useTheme();
  const [filled, setFilled] = useState(false);
  const handleSize = (columns) => {
    switch (columns) {
      case 1:
        return 30;
      case 2:
        return 60;
      case 3:
        return 90;
      default:
        return columns;
    }
  };

  useEffect(() => {
    if (value !== "") {
      setFilled(true);
    } else {
      setFilled(false);
    }
    // console.log(filled);
  }, [value]);

  return (
    <Container columns={handleSize(columns)} filled={filled} error={error}>
      <InputLabel className="placeholder">{placeholder}</InputLabel>
      <InputContainer>
        <Input
          type={type}
          value={value}
          // placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
        {error && !success && (
          <MdOutlineErrorOutline
            size={themeFile.SIZES.base * 16}
            color={themeFile.COLORS.hardRed}
          />
        )}
        {!error && success && (
          <MdOutlineCheckCircleOutline
            size={themeFile.SIZES.base * 16}
            color={themeFile.COLORS.green}
          />
        )}
      </InputContainer>
      {error && !success && <InfoMessage>*&nbsp;{error}</InfoMessage>}
    </Container>
  );
};

export default InputText;
