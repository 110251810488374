import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.columns && `${props.columns}rem`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  background-color: transparent;
  margin: calc(var(--size) * 1.4) 0;

  &:focus-within {
    span {
      transform: translate(calc(var(--size) * 1), -50%) scale(0.8);
      transition: transform 0.25s;
      background-color: var(--secondary);
      color: var(--primary);
    }
  }

  ${(props) =>
    props.filled &&
    css`
      span {
        transform: translate(calc(var(--size) * 1), -50%) scale(0.8);
        transition: transform 0.25s;
        background-color: var(--secondary);
        color: var(--primary);
      }
      &:focus-within {
        span {
          transform: translate(calc(var(--size) * 1), -50%) scale(0.8);
          transition: transform 0.25s;
          background-color: var(--secondary);
          color: var(--primary);
        }
      }
    `}

  ${(props) =>
    props.error &&
    css`
      span {
        transform: translate(calc(var(--size) * 1), -120%) scale(0.8);
        transition: transform 0.25s;
        background-color: var(--secondary);
        color: var(--primary);
      }

      &:focus-within {
        span {
          transform: translate(calc(var(--size) * 1), -120%) scale(0.8);
          transition: transform 0.25s;
          background-color: var(--secondary);
          color: var(--primary);
        }
      }
    `}
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  font-family: var(--fontFamily);
  font-size: calc(var(--size) * 1.6);
  font-weight: var(--regularFont);
  flex: 1;
  background-color: transparent;
  z-index: 2;
  width: 100%;
  font: var(--body);
`;

export const InfoMessage = styled.div`
  width: 100%;
  color: var(--hardRed);
  margin-top: calc(var(--size) * 0.5);
  font: var(--body);
`;

export const InputLabel = styled.span`
  /* width: 100%; */
  font: var(--body);
  color: var(--gray);
  position: absolute;
  bottom: 50%;
  left: 0;
  transform: translate(1rem, 50%);
  transform-origin: left;
  transition: transform 0.25s;
  z-index: 1;
  padding: 0 0.4rem;
`;

export const InputContainer = styled.div`
  padding: 1rem;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  border: calc(var(--size) * 0.1) solid var(--primary);
  border-radius: 1rem;
`;
