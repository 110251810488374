import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { BsTrash } from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import filesize from "filesize";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import { useTheme } from "../../../hooks/useTheme";
import {
  Container,
  Title,
  Form,
  Item,
  Place,
  Controls,
  DocumentUrl,
  DocumentFile,
  DocumentName,
  DocumentSize,
  DocumentDeleteButton,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputCheck from "../../../components/UI/Inputs/InputCheck";
import InputRadio from "../../../components/UI/Inputs/InputRadio";
import InputTags from "../../../components/UI/Inputs/InputTags";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import InputSelect from "../../../components/UI/Inputs/InputSelect";
import InputFile from "../../../components/UI/Inputs/InputFile";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";

const DocumentsForm = ({ action }) => {
  const { themeFile } = useTheme();
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);
  const [visibilities, setVisibilities] = useState([]);
  const [schoolYear, setSchoolYear] = useState([]);
  const [calendarYear, setCalendarYear] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      type: "",
      title: "",
      description: "",
      formType: "public",
      keywords: [],
      item: "",
      bim: 1,
      anoCalendario: "",
      ano: {
        id: "",
        description: "",
      },
      document: {
        id: "",
      },
      isOd: false,
      visibilities: [],
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Escolha um tipo de documento."),
      title: Yup.string().required("Digite o título do documento."),
      document: Yup.object({
        id: Yup.string().required("Nenhum anexo encontrado."),
      }),
      bim: Yup.number().when("isOd", {
        is: true,
        then: Yup.number()
          .required("Digite o bimestre.")
          .min(1, "Digite um número entre 1 e 5.")
          .max(5, "Digite um número entre 1 e 5."),
      }),
      ano: Yup.object().when("isOd", {
        is: true,
        then: Yup.object({
          id: Yup.string().required("Selecione o ano."),
        }),
      }),
      formType: Yup.string(),
      anoCalendario: Yup.string().when("isOd", {
        is: true,
        then: Yup.string().required("Selecione o ano de criação."),
      }),
      visibilities: Yup.array().when("formType", {
        is: "private",
        then: Yup.array().min(1, "Selecione 1"),
      }),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      let saveData = { ...values };
      delete saveData.item;
      console.log("toSave", saveData);

      const form = new FormData();
      form.append("id", values.id);
      form.append("title", values.title);
      form.append("type", values.type);
      form.append("description", values.description);
      form.append("formType", values.formType);
      form.append("isOd", values.isOd);
      form.append("anoCalendario", values.anoCalendario);
      form.append("bim", values.bim);
      form.append("ano", JSON.stringify(values.ano));
      form.append("file", values.document.file);
      form.append("keywords", JSON.stringify(values.keywords));
      form.append("visibilities", JSON.stringify(values.visibilities));

      if (action === "add") {
        saveItem({
          data: form,
        });
      } else {
        form.append("fileUpdate", values.fileUpdate);
        editItem({
          data: form,
        });
      }
    },
  });

  console.log(formik.errors);
  // console.log(formik.isValid);

  const handleAddKeywords = () => {
    const item = formik.values.item;
    if (item && !formik.values.keywords.includes(item)) {
      let newArray = [...formik.values.keywords];
      newArray.push(item);
      console.log(newArray);
      formik.setFieldValue("keywords", newArray);
      formik.setFieldValue("item", "");
    } else {
      formik.setErrors({
        item: "Palavra-chave já adicionada.",
      });
    }
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleAddKeywords();
    }
  };

  const handleFileType = (fileType) => {
    switch (fileType[0]) {
      case "image":
        return {
          type: "image",
          extension: fileType[1],
        };
      case "application":
        if (fileType[1] === "pdf") {
          return {
            type: "pdf",
            extension: fileType[1],
          };
        }
        break;
      case "video":
        return {
          type: "video",
          extension: fileType[1],
        };

      default:
        break;
    }
  };

  const handleDeleteDocument = () => {
    formik.setFieldValue("document", { id: "" });
  };

  const handleAttachFiles = (event) => {
    const file = event.target.files[0];

    const fileType = handleFileType(file.type.split("/"));
    event.target.value = null;

    const newName = `documento_${uuidv4()}.${fileType.extension}`;

    const newFile = {
      id: uuidv4(),
      originalName: file.name,
      newName,
      file,
      sizeInBytes: file.size,
      formatSize: filesize(file.size),
      fileType,
    };

    if (action !== "add") {
      formik.setFieldValue("fileUpdate", true);
    }

    console.log(newFile);
    formik.setFieldValue("document", newFile);
  };
  // console.log(formik.values.eventData);

  const [loadVisibilities, loadVisibilitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/visibility",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("tempo de req", response.config.time);
        setVisibilities(response.data);
      }
    },
  });

  const [loadCategories, loadCategoriesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/category",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        setCategories(response.data);
      }
    },
  });

  const [loadSchoolYear, loadSchoolYearInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/schoolYearList",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("schoolyear", response);
        setSchoolYear(response.data);
      }
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/documents",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("loadItem", response);
        if (response.data.length > 0) {
          //tratar visibilidades
          let vis = [];
          if (response.data[0].visibilities.length > 0) {
            response.data[0].visibilities.forEach((oldVis) => {
              vis.push(oldVis.idvisibility);
            });
          }
          const newDocument = {
            ...response.data[0],
            document: response.data[0].document[0],
            isOd:
              response.data[0].type === "3c0e3d5b-3456-4e3c-a89f-9cb3cf41cc82"
                ? true
                : false,
            item: "",
            fileUpdate: false,
            visibilities: vis,
            anoCalendario: response.data[0].anoCalendario
              ? response.data[0].anoCalendario
              : "",
          };
          console.log("newDocs", newDocument);
          formik.setValues(newDocument);
        } else {
          navigate("/documentos/add");
        }
      }
    },
  });

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    baseURL: process.env.REACT_APP_CDN_API,
    multi: true,
    url: "canalPedagogico/documents",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("salvou", response);
        navigate("/documentos");
      }
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    baseURL: process.env.REACT_APP_CDN_API,
    multi: true,
    url: "canalPedagogico/documents",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/documentos");
        // console.log("editou", response);
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/documents",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/documentos");
        // console.log("apagou", response);
      }
    },
  });

  const handleDelete = (itemId) => {
    const confirm = window.confirm("Deseja apagar esse item?");

    if (confirm) {
      deleteItem({
        data: {
          idDoc: itemId,
        },
      });
    }
  };

  useEffect(() => {
    async function load() {
      let years = [];
      for (let index = new Date().getFullYear() + 1; index > 2020; index--) {
        years.push(index);
      }
      setCalendarYear(years);
      await loadCategories({
        params: {
          id: "46150c88-042a-4220-b81e-bb6c399fa275",
          idUserHasProfile: user.profiles[0].idUserHasProfile,
        },
      });
      await loadVisibilities();
      await loadSchoolYear();
      if (action === "edit") {
        await loadItem({
          params: {
            idDoc: id,
          },
        });
      }
    }
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading ||
        loadVisibilitiesInfo.loading ||
        loadCategoriesInfo.loading ||
        loadSchoolYearInfo.loading) && <FullScreenLoading />}
      {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ||
        saveItemInfo.status ||
        loadVisibilitiesInfo.status ||
        loadCategoriesInfo.status ||
        loadSchoolYearInfo.status) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>Documentos</Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}
          <Item>
            <InputSelect
              columns={1}
              name="type"
              error={
                formik.touched.type && formik.errors.type && formik.errors.type
              }
              onChange={(e) => {
                formik.handleChange(e);
                if (e.target.value === "3c0e3d5b-3456-4e3c-a89f-9cb3cf41cc82") {
                  formik.setFieldValue("isOd", true);
                } else {
                  formik.setFieldValue("isOd", false);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.type}
              placeholder="Tipo de documento"
            >
              <option value="" disabled defaultValue>
                Selecione o tipo
              </option>
              {categories.length > 0 &&
                categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.category}
                  </option>
                ))}
            </InputSelect>
          </Item>
          {formik.values.isOd ? (
            <>
              <Item>
                <InputText
                  placeholder="Título da OD"
                  columns={90}
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  error={
                    formik.touched.title &&
                    formik.errors.title &&
                    formik.errors.title
                  }
                />
              </Item>
              <Item>
                <InputSelect
                  columns={1}
                  name="anoCalendario"
                  error={
                    formik.errors.anoCalendario && formik.errors.anoCalendario
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.anoCalendario}
                  placeholder="Ano de criação"
                >
                  <option value="" disabled defaultValue>
                    Selecione o ano de criação
                  </option>
                  {calendarYear.length > 0 &&
                    calendarYear.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </InputSelect>
              </Item>
              <Item>
                <InputSelect
                  columns={1}
                  name="bim"
                  error={
                    formik.touched.bim && formik.errors.bim && formik.errors.bim
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bim}
                  placeholder="Bimestre"
                >
                  <option value="" disabled defaultValue>
                    Selecione o bimestre
                  </option>
                  <option value="1">1º Bimestre</option>
                  <option value="2">2º Bimestre</option>
                  <option value="3">3º Bimestre</option>
                  <option value="4">4º Bimestre</option>
                  <option value="5">Anual</option>
                </InputSelect>
              </Item>
              <Item>
                <InputSelect
                  columns={1}
                  name="ano.id"
                  error={formik.errors.ano && formik.errors.ano.id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ano.id}
                  placeholder="Ano/Ciclo"
                >
                  <option value="" disabled defaultValue>
                    Selecione o ano
                  </option>
                  {schoolYear.length > 0 &&
                    schoolYear.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    ))}
                </InputSelect>
              </Item>
            </>
          ) : (
            <>
              <Item>
                <InputText
                  placeholder="Título do documento"
                  columns={90}
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  error={
                    formik.touched.title &&
                    formik.errors.title &&
                    formik.errors.title
                  }
                />
              </Item>
              <Item>
                <InputText
                  placeholder="Breve descrição"
                  columns={90}
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={
                    formik.touched.description &&
                    formik.errors.description &&
                    formik.errors.description
                  }
                />
              </Item>
            </>
          )}
          <Item>
            <InputTags
              tags={formik.values.keywords}
              placeholder="Palavras-chave"
              deleteItem={formik.setFieldValue}
              deleteField="keywords"
              columns={90}
              onKeyDown={handleEnter}
              name="item"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.item}
              autoComplete="off"
              error={formik.errors.item && formik.errors.item}
            />
          </Item>
          <Item>
            <Place>Documento</Place>
            <InputFile
              accept="application/pdf"
              id="inputDocument"
              text="Anexar Documento"
              onChange={handleAttachFiles}
            />
            {!formik.values.document?.url ? (
              formik.values.document?.id && (
                <DocumentFile>
                  <DocumentName>
                    {formik.values.document?.originalName}
                  </DocumentName>
                  <DocumentSize>
                    {formik.values.document?.formatSize}
                  </DocumentSize>
                  <DocumentDeleteButton onClick={handleDeleteDocument}>
                    <BsTrash
                      size={themeFile.SIZES.base * 22}
                      color={themeFile.COLORS.hardRed}
                    />
                  </DocumentDeleteButton>
                </DocumentFile>
              )
            ) : (
              <DocumentFile>
                <DocumentUrl
                  href={`${process.env.REACT_APP_CDN_URL}canalPedagogico/documents/${formik.values.document?.url}`}
                  target="_blank"
                >
                  {formik.values.document?.originalName}
                </DocumentUrl>
                <DocumentDeleteButton onClick={handleDeleteDocument}>
                  <BsTrash
                    size={themeFile.SIZES.base * 22}
                    color={themeFile.COLORS.hardRed}
                  />
                </DocumentDeleteButton>
              </DocumentFile>
            )}
          </Item>
          <Item>
            <Place>Visibilidade</Place>
            <InputRadio
              id="public"
              name="formType"
              text="Público"
              onChange={formik.getFieldProps("formType").onChange}
              value="public"
              checked={formik.getFieldProps("formType").value === "public"}
            />
            <InputRadio
              id="private"
              name="formType"
              text="Restrito"
              onChange={formik.getFieldProps("formType").onChange}
              value="private"
              checked={formik.getFieldProps("formType").value === "private"}
            />
          </Item>
          {formik.values.formType === "private" && (
            <Item>
              {visibilities.length > 0 &&
                visibilities.map((vis) => (
                  <InputCheck
                    id={vis.id}
                    key={vis.id}
                    value={vis.id}
                    name="visibilities"
                    text={vis.description}
                    onChange={formik.handleChange}
                    checked={formik.values.visibilities.includes(vis.id)}
                  />
                ))}
            </Item>
          )}
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default DocumentsForm;
