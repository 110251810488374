import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  gap: 1rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 4px var(--gray);
  /* transition: all 0.2s ease; */
  background: var(--secondary);
  /* margin: 0.5rem; */

  /* &:hover {
    transform: scale(1.05);
  } */
`;

export const Title = styled.span`
  font-family: var(--fontFamily);
  font-size: calc(var(--size) * 1.6);
  font-weight: var(--mediumFont);
  padding: 0 1rem;
  text-align: center;
`;
export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin-bottom: 1rem;
`;
export const Tag = styled.span`
  padding: 0.2rem 0.4rem;
  background-color: var(--softPurple);
  border-radius: 0.6rem;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
