import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:space-around;
  width: 100%;
  ${(props)=>(props.fullscreen&&css`
    position: absolute;
    height:100%;
    background-color: #FFFFFF;
    top: 0;
    z-index: 10;
  `)};

  ${(props)=>(props.visible?
    css`
      display: flex;
    `:
    css`
      display: none;
    `
  )};
`;

export const ButtonClose = styled.div`
  width: 10rem;
`;