import React, { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAuth } from "../../../hooks/useAuth";
import { Container, IconBanner, IconEdit, TitleBanner } from "./styles";
import { Link } from "react-router-dom";
import { icons } from "../../../constants/";
import { useTheme } from "../../../hooks/useTheme";

const Banner = ({ children, icon, url, editorId = false, canEdit = false }) => {
  const { user } = useAuth();
  const { themeFile } = useTheme();

  const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    if (user) {
      const editor = user?.profiles[0]?.editor?.findIndex(
        (editor) => editor.id === editorId
      );
      setIsEditor(editor > -1 ? true : false);
    }
  }, [user, editorId]);

  return (
    <Container highContrast={themeFile.COLORS.highContrast}>
      <TitleBanner>
        {children}
        {(isEditor || canEdit) && (
          <Link to={url}>
            <IconEdit
              src={themeFile.COLORS.highContrast ? icons.editBlack : icons.edit}
            />
          </Link>
        )}
      </TitleBanner>
      <IconBanner>
        <Player
          autoplay={true}
          loop={true}
          controls={false}
          src={icon}
          style={{ width: "100%" }}
        />
      </IconBanner>
    </Container>
  );
};

export default Banner;
