import React from "react";
import { 
  Container, 
  DocTitle, 
  DocTitleText, 
  Editar, 
  DocDescritpion, 
  File, 
  // TagsContainer, 
  // Tag, 
  Link, 
  DocText, 
  // Download,
  BlocoPrincipal,
  BlocoButton,
  BlocoConteudo,
} from "./styles";
import Visibility from "../../../../components/UI/Visibility";
import {icons} from "../../../../constants"

// implementação de conversão da url no texto em link
import textToVectorIsLink from "../../../../utils/textToVectorIsLink";

// import {
//   MdInsertDriveFile,
// } from "react-icons/md";

const Document = ({
  doc={},
  isEditor=false,
  search=false,
  visible=false,
}) => {

  // implementação de conversão da url no texto em link
  let temp = textToVectorIsLink(doc?.description);
  
  return (
    <Container visible={visible} >
      <BlocoPrincipal>
        <BlocoConteudo>
          <DocTitle>
            <DocTitleText>
              <Link href={`/documentos/${doc?.id}`}>{search && `${doc?.typename} - `}{doc?.title}</Link>
              {isEditor && (
                <Editar href={`/documentos/editar/${doc?.id}`}>
                  <img src={icons.editBlack} alt="" />
                </Editar>
              )}
            </DocTitleText>
          </DocTitle>

      
          {/* implementação de conversão da url no texto em link */}
          <DocDescritpion>{temp.map((desc,index)=>desc.isLink?<Link href={desc.text} key={index}>{desc.text}</Link>:desc.text)}</DocDescritpion>
          
          {/* <DocDescritpion>{doc?.description}</DocDescritpion> */}

          {/* {doc?.keywords.length>0 && (
            <TagsContainer>
              Palavras Chaves: 
              {doc?.keywords.map((keyword,index)=>(
                <Tag key={index}>{keyword} {index+1<doc?.keywords.length && ","}</Tag>
              ))}
            </TagsContainer>
          )} */}

          {doc?.type === "3c0e3d5b-3456-4e3c-a89f-9cb3cf41cc82" && 
            <DocText>{doc?.anoCalendario} - {doc?.bim === "5" ? "Anual"  : `${doc?.bim} ºBimestre`} - {doc?.ano?.description} </DocText>
          }
                      
          {isEditor && 
            <Visibility visibility={doc?.visibilities}
          />}
          
        </BlocoConteudo>
        <BlocoButton>
          {doc?.document?.length > 0 && (
            <File
              href={`${process.env.REACT_APP_CDN_URL}canalPedagogico/documents/${doc?.document[0].url}`}
              target="_blank"
            >
              <img src={icons.btn_download} alt="download"/>
              {/* <MdInsertDriveFile /> Download */}
              {/* <span>{doc?.document[0].originalName}</span> */}
            </File>
          )}
        </BlocoButton>
      </BlocoPrincipal>

    </Container>
  );
};

export default Document;