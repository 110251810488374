import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import * as ExcelJS from "exceljs";
import { saveAs } from 'file-saver';

import { 
  Container,
} from "./styles";

import ReadMoreButton from "../../../../components/UI/ReadMoreButton";

import useApi from "../../../../hooks/useApi";

const ReportDownload = ({
                id=null,
                loading=()=>{},
              }) => {
  const [listInfoSubscribe, setListInfoSubscribe] = useState({data:[],loaded:false});
  const [listActivities, setListActivities] = useState({data:[],loaded:false});
  const [listUserActivities, setListUserActivities] = useState({data:[],loaded:false});
  const [listJustify, setListJustify] = useState({data:[],loaded:false});
  const [listModule, setListModule] = useState({data:[],loaded:false});
  const [course, setCourse] = useState({data:{},loaded:false});

  // eslint-disable-next-line
  const [loadCourse, loadCourseInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("course by user", response.data[0]);
        setCourse({data:response.data[0],loaded:true});
      }
      else{
        loading(false);
      }
    },
    onError: (error)=>{ 
      loading(false);
      // document.location.reload(false);
    },
  });

  // eslint-disable-next-line
  const [listSubscribe, ListSubscribeInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/subscription/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listSubscribe", response.data);
        setListInfoSubscribe({data:response.data,loaded:true});
      }
      else{
        loading(false);
      }
    },
    onError: (error)=>{ 
      loading(false);
      // document.location.reload(false);
    },
  });
  
  // eslint-disable-next-line
  const [loadListActivities, LoadListActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listActivities", response.data);
        let module = [];
        response.data.forEach((activity)=>{
          let index = module.findIndex((module)=>{return module.module===activity.module});
          if(index >= 0){
            module[index].size+=1;
          }
          else{
            module.push({module:activity.module,size:1});
          }
        });
        console.log("modulos", module);
        setListModule({data:module,loaded:true});
        setListActivities({data:response.data,loaded:true});
      }
      else{
        loading(false);
      }
    },
    onError: (error)=>{ 
      loading(false);
      // document.location.reload(false);
    },
  });

  // eslint-disable-next-line
  const [loadListUserActivities, LoadListUserActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/course",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listUserActivities", response.data);
        setListUserActivities({data:response.data,loaded:true});
      }
      else{
        loading(false);
      }
    },
    onError: (error)=>{ 
      loading(false);
      // document.location.reload(false);
    },
  });

  // eslint-disable-next-line
  const [getListJustify, getListJustifyInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/activitiesJustifications",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listJustify", response.data);
        setListJustify({data:response.data,loaded:true});
      }
    },
    onError: (error)=>{ 
      loading(false);
      // document.location.reload(false);
    },
  });

  const download = () => {
    console.log("download id:",id);
    loading(true);

    setCourse({data:{},loaded:false});
    setListInfoSubscribe({data:[],loaded:false});
    setListModule({data:[],loaded:false});
    setListActivities({data:[],loaded:false});
    setListUserActivities({data:[],loaded:false});
    setListJustify({data:[],loaded:false});

    loadCourse({
      params:{
        id,
      }
    });
    listSubscribe({
      params: {
        id,
      },
    });
    loadListActivities({
      params: {
        idContent:id,
      },
    });
    loadListUserActivities({
      params: {
        idContent:id,
      },
    });
    getListJustify();
  };

  const getLabelJustify = (idJustify) => {
    return listJustify.data.find((justify)=>{return justify.idactivities_justifications===idJustify})?.label;
  };

  const handleDownload = async () => {

    const workbook = new ExcelJS.Workbook()

    //função para pegar a Letra da coluna apartir de numeros
    const numberToLeter = (number)=>{
      if(number>=26){
        return `${String.fromCharCode(65+((((number-number%26))/26)-1))}${String.fromCharCode(65+(number%26))}`;
      }else{
        return String.fromCharCode(65+number);
      }
    };

    let formatVet = [];

    workbook.creator = "Canal Pedagógico";
    workbook.lastModifiedBy = "Canal Pedagógico";
    workbook.created = new Date();
    workbook.modified = new Date();

    console.log("Teste Download Course",course.data)
    let worksheet = workbook.addWorksheet(`${course.data?.title.trim()}`,{
      pageSetup:{paperSize: 9, orientation:'landscape'}
    }); //tamanho A4 orientação paisagem
    worksheet.pageSetup.margins = {
      left: 0.7, right: 0.7,
      top: 0.75, bottom: 0.75,
      header: 0.3, footer: 0.3
    };

    //Define largura das colunas
    let columns = [{key:"numero",width:7},{key:"Nome",width:52},{key:"CPF",width:17},{key:"RGF",width:8},{key:"email",width:52}];
    listActivities.data.forEach((activity,index)=>{
      columns.push({key:`atividade${index+1}`,width:13});
    });
    columns.push({key:"cumprido",width:13});
    columns.push({key:"desconto",width:13});
    worksheet.columns = columns;

    //Linha de cabeçalho com dados do curso
    worksheet.addRow([`Curso - ${course.data.title.trim()} - ${course.data.courseWorkload} horas\nFormadores:${course.data.teacher}`]);
    //mescla as celulas
    worksheet.mergeCells(`A1:${numberToLeter(7+listActivities.data?.length)}1`);
    //marca para formatar a celula
    formatVet.push({
      cell:"A1", 
      bold:true, 
      size:13,
      align: "center",
    });

    //define altura da linha
    worksheet.getRow(1).height = 36.0;

    //cria a primeira linha de head da tabela
    worksheet.addRow([]);
    let temp = ["NOME","","CPF","RGF","E-MAIL"];
    formatVet.push({
      cell:"A3", 
      bold:true, 
      align: "center",
      border:true,
    });
    formatVet.push({
      cell:"C3", 
      bold:true, 
      align: "center",
      border:true,
    });
    formatVet.push({
      cell:"D3", 
      bold:true, 
      align: "center",
      border:true,
    });
    formatVet.push({
      cell:"E3", 
      bold:true, 
      align: "center",
      border:true,
    });
    let formatMerge = [];
    let initialMergeColumn = 0;
    
    listModule.data.forEach((module)=>{
      temp.push(`${module.module!==null?`Module - ${module.module===0?"único":module.module}`:``}`)
      for(let i=1;i<module.size;i++){
        temp.push(``);
      }
      formatMerge.push(`${numberToLeter(5+initialMergeColumn)}3:${numberToLeter(5+initialMergeColumn+module.size-1)}3`);
      formatVet.push({
        cell:`${numberToLeter(5+initialMergeColumn)}3`, 
        bold:true, 
        align: "center",
        border:true,
        size: 8,
      });
      initialMergeColumn += module.size;
    });

    temp.push("TOTAL DE HORAS CUMPRIDAS");
    formatVet.push({
      cell:`${numberToLeter(5+listActivities.data.length)}3`, 
      bold:true, 
      align: "center",
      border:true,
      size: 8,
    });

    temp.push("TOTAL DE HORAS ABONADAS");
    formatVet.push({
      cell:`${numberToLeter(5+listActivities.data.length+1)}3`, 
      bold:true, 
      align: "center",
      border:true,
      size: 8,
    });

    temp.push("HORAS A DESCONTAR (RH)");
    formatVet.push({
      cell:`${numberToLeter(5+listActivities.data.length+2)}3`, 
      bold:true, 
      align: "center",
      border:true,
      size: 8,
    });

    worksheet.addRow(temp);

    formatMerge.forEach((merge)=>{
      worksheet.mergeCells(merge);
    });

    temp = ["","","","",""];
    listActivities.data.forEach((activity,index)=>{
      temp.push(`${activity.title}\n(${activity.activityWorkload} horas)\n${format(parseInt(activity.activityEnd),"dd/MM")}`)
      formatVet.push({
        cell:`${numberToLeter(5+index)}4`, 
        bold:true, 
        align: "center",
        border:true,
        size: 8,
      });
    });
    worksheet.addRow(temp);
    worksheet.mergeCells(`A3:B4`);
    worksheet.mergeCells(`C3:C4`);
    worksheet.mergeCells(`D3:D4`);
    worksheet.mergeCells(`E3:E4`);
    worksheet.mergeCells(`${numberToLeter(5+listActivities.data.length)}3:${numberToLeter(5+listActivities.data.length)}4`);
    worksheet.mergeCells(`${numberToLeter(6+listActivities.data.length)}3:${numberToLeter(6+listActivities.data.length)}4`);
    worksheet.mergeCells(`${numberToLeter(7+listActivities.data.length)}3:${numberToLeter(7+listActivities.data.length)}4`);
    
    worksheet.getRow(4).height = 70.0;

    //formatVeta o head
    // {
    //   cell:"A1",      -  obrigatório
    //   border:true,
    //   size:10,              - padrão é 10
    //   align: "center",        - center,right,left - configuração padrão left
    //   bold:true,
    //   color:"FF000000"       -padrão letra preta
    //   bgColor:"FFFFFFFF",    -padrão fundo branco
    //   colorTheme: A1
    // }

    // worksheet.getCell('A3').fill = {
    //   type: 'pattern',
    //   pattern:'solid',
    //   fgColor:{argb:'00FFFF'},
    // };

    let total = 0;
    listInfoSubscribe.data.forEach((subscribe,index)=>{
      let row = [index+1];
      let cumprido = 0;
      let abonado = 0;
      row.push(subscribe.name);
      row.push(subscribe.cpf);
      row.push(subscribe.rgf);
      row.push(subscribe.email);

      formatVet.push({
        cell:`A${5+index}`, 
        bold:true, 
        align: "center",
        border:true,
      });
      formatVet.push({
        cell:`B${5+index}`, 
        border:true,
      });
      formatVet.push({
        cell:`C${5+index}`, 
        align: "center",
        border:true,
      });
      formatVet.push({
        cell:`D${5+index}`, 
        align: "center",
        border:true,
      });
      formatVet.push({
        cell:`E${5+index}`, 
        border:true,
      });

      listActivities.data.forEach((activityList,indexActivity)=>{
        if(index===0){
          total+=activityList.activityWorkload;
        }
        let userActivity = listUserActivities.data.find((activity)=>{return activity.idcontent_activity === activityList.idContentActivity && activity.iduser_subscription===subscribe.idUserSubscription});
        if (userActivity){
          if(userActivity?.hours_taken===0){
            row.push(0);
            formatVet.push({
              cell:`${numberToLeter(5+indexActivity)}${5+index}`, 
              align: "center",
              border:true,
            });
          }
          if(userActivity?.hours_taken===1){
            cumprido+=activityList.activityWorkload;
            row.push(activityList.activityWorkload);
            formatVet.push({
              cell:`${numberToLeter(5+indexActivity)}${5+index}`, 
              align: "center",
              border:true,
            });
          }
          if(userActivity?.hours_taken===2){
            abonado+=activityList.activityWorkload;
            row.push(getLabelJustify(userActivity?.idactivities_justifications));
            formatVet.push({
              cell:`${numberToLeter(5+indexActivity)}${5+index}`, 
              align: "center",
              border:true,
              bold:true,
              colorTheme:getLabelJustify(userActivity?.idactivities_justifications),
            });
          }
        }
        else{
          row.push("");
          formatVet.push({
            cell:`${numberToLeter(5+indexActivity)}${5+index}`, 
            align: "center",
            border:true,
          });
        }
      });
      row.push(cumprido);
      formatVet.push({
        cell:`${numberToLeter(5+listActivities.data.length)}${5+index}`, 
        align: "center",
        border:true,
      });
      row.push(abonado);
      formatVet.push({
        cell:`${numberToLeter(5+listActivities.data.length+1)}${5+index}`, 
        align: "center",
        border:true,
      });
      row.push(total-cumprido-abonado);
      formatVet.push({
        cell:`${numberToLeter(5+listActivities.data.length+2)}${5+index}`, 
        align: "center",
        border:true,
      });
      worksheet.addRow(row);
    });

    console.log(listJustify);

    worksheet.addRow([]);
    listJustify.data.forEach((justify,index)=>{
      worksheet.addRow([`${justify.label} - ${justify.description}`,""]);
      worksheet.mergeCells(`A${5+listInfoSubscribe.data.length+1+index}:B${5+listInfoSubscribe.data.length+1+index}`);
      formatVet.push({
        cell:`A${5+listInfoSubscribe.data.length+1+index}`, 
        align: "center",
        border:true,
        bold:true,
        colorTheme:justify.label,
      });
    });

    

    worksheet = formatExcelJS(worksheet,formatVet);

    //define area de impressão
    worksheet.pageSetup.printArea = `A1:${numberToLeter(5+listActivities.data.length+1)}${5+listInfoSubscribe.data.length+listJustify.data.length}`;

    workbook.xlsx.writeBuffer().then((buffer)=>{
      const blob = new Blob([buffer],{
          type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      saveAs(blob,`${course.data?.title.trim()} - relatório.xlsx`);
    });

    loading(false);

  };

  const formatExcelJS = (ws,vetor=[]) => {
    // exemplo de json que vai dentro do vetor
    //
    // {
    //   cell:"A1",      -  obrigatório
    //   border:true,
    //   size:10,
    //   align: center,        - center,right,left - configuração padrão left
    //   bold:true,
    //   color:"FF000000"
    //   bgColor:"FFFFFFFF",
    //   colorTheme: A1
    // }
    const colorTheme = [
      {
        padrao:"LSL",
        bgColor:"FFFFFF00",
        color:"FF000000",
      },
      {
        padrao:"P",
        bgColor:"FFFF9900",
        color:"FF000000",
      },
      {
        padrao:"TRE",
        bgColor:"FF00FFFF",
        color:"FF000000",
      },
      {
        padrao:"AF",
        bgColor:"FF351C75",
        color:"FFFFFFFF",
      },
      {
        padrao:"GN",
        bgColor:"FF000000",
        color:"FFFFFFFF",
      },
      {
        padrao:"AP",
        bgColor:"FF00FF00",
        color:"FF000000",
      },
      {
        padrao:"I",
        bgColor:"FF5B0F00",
        color:"FFFFFFFF",
      },
      {
        padrao:"AA",
        bgColor:"FFCC0000",
        color:"FFFFFFFF",
      },
      {
        padrao:"LM",
        bgColor:"FF45818E",
        color:"FFFFFFFF",
      },
      {
        padrao:"LSD",
        bgColor:"FF674EA7",
        color:"FFFFFFFF",
      },
      {
        padrao:"LA",
        bgColor:"FF6AA84F",
        color:"FFFFFFFF",
      },
      {
        padrao:"LP",
        bgColor:"FF46BDC6",
        color:"FF000000",
      },
      {
        padrao:"ASV",
        bgColor:"FFC27BA0",
        color:"FF000000",
      },
      {
        padrao:"EX",
        bgColor:"FFFFFFFF",
        color:"00000000",
      }
    ]

    vetor.forEach((config)=>{
      // console.log("Formata: ",config);
      if(config.cell){  
        ws.getCell(config.cell).alignment = {
          vertical:'middle',
          horizontal:config.align?config.align:'left',
          wrapText:true
        };
        if(config.border){
          ws.getCell(config.cell).border = {top:{style:'thin'},left:{style:'thin'},bottom:{style:'thin'},right:{style:'thin'}};
        }
        if(config.colorTheme){
          let theme = colorTheme.find((theme)=>{return theme.padrao===config.colorTheme});
          ws.getCell(config.cell).font = {
            size:config.size?config.size:10,
            bold:config.bold?true:false,
            color:{argb:theme.color?theme.color:"FF000000"}
          };
          ws.getCell(config.cell).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:theme.bgColor?theme.bgColor:"FFFFFF"}
          }
        }
        else{
          ws.getCell(config.cell).font = {
            size:config.size?config.size:10,
            bold:config.bold?true:false,
            color:{argb:config.color?config.color:"FF000000"}
          };
          if(config.bgColor) {
            ws.getCell(config.cell).fill = {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:config.bgColor?config.bgColor:"FFFFFF"}
            }
          }
        }
      }
    });
    return ws;
  };

  useEffect(() => {
    if(course.loaded && listInfoSubscribe.loaded && listModule.loaded && listActivities.loaded && listUserActivities.loaded && listJustify.loaded){
      handleDownload();
    }
    // eslint-disable-next-line
  }, [course, listInfoSubscribe, listModule, listActivities, listUserActivities, listJustify]);
  
  return (
    <Container>
      <ReadMoreButton onClick={()=>{download();}} text="Download Relatório" />
    </Container>
  );
};

export default ReportDownload;