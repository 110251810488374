import styled from "styled-components";

export const Box = styled.div`
  width: 100%;
  height: 10rem;
  position: fixed;
  bottom: 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  z-index: 999;

  @media (max-width: 800px) {
    bottom: 6rem;
  }

  @media (max-width: 500px) {
    bottom: 8rem;
  }

  @media (max-width: 300px) {
    bottom: 12rem;
  }
`;

export const TermsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: var(--secondary);
  border: 0.2rem solid var(--primary);
  padding: 1rem 2rem;
  border-radius: 1rem;
  gap: 1rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
export const TermsDescription = styled.p`
  color: var(--primary);

  @media (max-width: 800px) {
    font-size: 1.4rem;
  }
`;
