import React from "react";
import { MdAttachFile } from "react-icons/md";
import { useTheme } from "../../../../hooks/useTheme";
import { Container, Label, Input } from "./styles";

const InputFile = ({
  text,
  disabled = false,
  icon = false,
  accept,
  id,
  onChange = () => {},
  ...rest
}) => {
  const { themeFile } = useTheme();

  return (
    <Container>
      <Label htmlFor={id} disabled={disabled}>
        {icon ? (
          icon
        ) : (
          <MdAttachFile
            size={themeFile.SIZES.base * 20}
            color={themeFile.COLORS.secondary}
          />
        )}
        <span>{text}</span>
      </Label>
      <Input
        type="file"
        name={text}
        id={id}
        accept={accept}
        onChange={onChange}
      />
    </Container>
  );
};

export default InputFile;
