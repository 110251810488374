import React from "react";
import { useNavigate } from "react-router";

import { 
  Container, 
  ContainerInfo, 
  ContainerButtons,
  Title,
  Unidade,
  Text,
  Text2,
  Item,
} from "./styles";

import {
  format,
} from "date-fns";

import ReadMoreButton from "../../../../components/UI/ReadMoreButton";

import { useAuth } from "../../../../hooks/useAuth";

const Ticket = ({
  ticket={
    id:0,
    title:"",
    editora:"",
    disciplina:"",
    unidadeOrigem:{id:"",name:""},
    unidade:[],
    cover:"",
    disponibilizado:0,
    reservadoAprovado:0,
  },
  myunity=null,
  visible=true,
  category=null,
  infoPage={infoPage:{}, updateInfoPage:()=>{}},
  ...rest
}) => {
  // console.log(ticket);

  const { user, accessRight } = useAuth();

  const navigate = useNavigate();

  return (
    <Container visible={visible} {...rest} >
      {/* <ContainerImg><img src={`${process.env.REACT_APP_CDN_URL}canalPedagogico/libraryNews/${ticket.cover}`}/></ContainerImg> */}
      <ContainerInfo>
        <Title>Titulo: {ticket?.bookTitle}</Title>
        {/* <Editora>Editora: {ticket.book?.publisherName}</Editora>
        <Disciplina>Disciplina: {ticket.book?.subjectName}</Disciplina> */}
        <Text2>Exemplar: {ticket?.destinedTo}</Text2>
        <Text2>Ano: {ticket?.schoolGrade}</Text2>

        {ticket.idUnityOrigin ? <>
          <Text>Quantidade Solicitada: {(ticket?.quantityRequested*-1)}</Text>
          <Unidade>Unidade Destino: {ticket?.unityName}</Unidade>
          <Text>Observação Solicitante: {ticket?.requestNote}</Text>
          <Text>Quantidade Aprovada: {ticket?.quantityApproved===null?"Aguardando aprovação":(ticket.quantityApproved*-1)}</Text>
          <Unidade>Unidade Origem: {ticket?.unityOriginName}</Unidade>
          <Text>Observação Aprovação: {ticket?.approveNote}</Text>
          <Text2>Criado em: {format(new Date(ticket?.createdAt), "dd/MM/yyyy - HH:mm")}</Text2>
          {ticket.quantityApproved===null?
          <Text2>Atualizado em: {format(new Date(ticket?.updatedAt), "dd/MM/yyyy - HH:mm")}</Text2>:
          <Text2>Aprovado em: {format(new Date(ticket?.updatedAt), "dd/MM/yyyy - HH:mm")}</Text2>}
        </>:
        <>
          <Text>Quantidade Disponibilizada: {(ticket?.quantityApproved)}</Text>
          <Text>Disponibilizado em: {format(new Date(ticket?.createdAt), "dd/MM/yyyy - HH:mm")}</Text>
        </>
        }
      </ContainerInfo>
      <ContainerButtons>
        {(myunity===ticket?.idUnityOrigin && 
          ticket?.quantityApproved===null && 
          accessRight("books",category).SP_rights.can_approve_books) &&
          <Item><ReadMoreButton
            text="Aprovar Reserva"
            type="button"
            onClick={()=>{
              //captura e armazena a posição do Scroll na página
              infoPage.updateInfoPage({BookControl:{...infoPage.infoPage.BookControl,scroll:window.scrollY}});
              navigate(`/bookapprove/${category}/edit/${ticket?.idMovement}`);
            }}
          /></Item>}
        {(myunity===ticket?.idUnity && 
          ticket?.quantityApproved===null && 
          accessRight("books",category).SP_rights.can_request_books) &&<Item><ReadMoreButton
          text="Editar Reserva"
          type="button"
          onClick={()=>{
            infoPage.updateInfoPage({BookControl:{...infoPage.infoPage.BookControl,scroll:window.scrollY}});
            navigate(`/bookrequest/${category}/edit/${ticket?.idMovement}`);
          }}
        /></Item>}
      </ContainerButtons>
    </Container>
  );
};

export default Ticket;