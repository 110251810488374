import React from "react";
import { useNavigate } from "react-router-dom";
import { 
  Container, 
  // ContainerImg, 
  ContainerInfo, 
  ContainerButtons,
  Title,
  Editora,
  Disciplina,
  Exemplar,
  // Unidade,
  Ano,
  // Solicitacoes,
  ISBN,
  Situacao,
} from "./styles";
import ReadMoreButton from "../../../../components/UI/ReadMoreButton";
import { useAuth } from "../../../../hooks/useAuth";

const Book = ({
  book={
    idBook:"",
    isbn:"",
    title:"",
    publisherName:"",
    subjectName:"",
    schoolGrade:"",
    destinedTo:"",
    active:false,
  },
  myunity=null,
  visible=true,
  category=null,
  ...rest
}) => {
  const navigate = useNavigate();
  const { user, accessRight } = useAuth();
  return (
    <Container visible={visible} {...rest} >
      {/* <ContainerImg><img src={`${process.env.REACT_APP_CDN_URL}canalPedagogico/libraryNews/${ticket.cover}`}/></ContainerImg> */}
      <ContainerInfo>
        <Title>Titulo: {book.title}</Title>
        <Editora>Editora: {book.publisherName}</Editora>
        {book.isbn && <ISBN>ISBN: {book.isbn} </ISBN>}
        <Disciplina>Disciplina: {book.subjectName}</Disciplina>
        <Ano>Ano: {book.schoolGrade}</Ano>
        <Exemplar>Exemplar: {book.destinedTo}</Exemplar>
        <Situacao>Situação: {book.active?"Ativo":"Inativo"}</Situacao>
      </ContainerInfo>
      <ContainerButtons>
        {accessRight("books",category).SP_rights.can_register_books && <ReadMoreButton
          text="Editar Livro"
          type="button"
          onClick={()=>{navigate(`/bookregister/${category}/edit/${book.idBook}`);}}
        />}
      </ContainerButtons>
    </Container>
  );
};

export default Book;