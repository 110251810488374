import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import locale from "date-fns/locale/pt-BR";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";
import * as XLSX from "xlsx";

import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton";

import {
  Container,
  ListContent,
  ListItem,
  Table,
} from "./styles";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
// import { images } from "../../constants";

import useApi from "../../hooks/useApi";


const CoursesHtpf = () => {
  const [htpfList, setHtpfList] = useState([]);
  const navigate = useNavigate();

  const [getHtpf, getHtpfInfo] = useApi({
    url: "canalPedagogico/htpf",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("HTPF", response.data);
        setHtpfList(response.data);
      }
    },
    onError: ()=>{},
  });

  useEffect(() => {
    getHtpf();
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      {(getHtpfInfo.loading) && <FullScreenLoading />}
      
      <Accessibility />
      <Menu />
      <Banner>HTPF</Banner>
      <ContentBox>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate(`/formacoes/manager`);}}
        >
          <MdArrowLeft fontSize={36}/> Voltar para gestão de formação
        </ListItem>

        <ReadMoreButton onClick={()=>{navigate(`/htpfPreview`)}} text={"Pré-visualização do HTPF"}/>

        {htpfList.map((htpf)=>(
          <ListItem key={htpf.idhtpf}
                    border={true} 
                    roundborder={false} 
                    pointer={true} 
                    row={false} 
                    reduct={0} 
                    visible={true} 
                    selected={false} 
                    onClick={()=>{navigate(`/htpfDetail/${htpf.idhtpf}`)}}
          >
            <p>Ano: {htpf.baseYear}</p>
            <p>Criado por: {htpf.generatedByName}</p>
            <p>Criado em: {format(parseInt(htpf.createdAt), " dd/MM/yyyy ", {locale})}</p>
            {htpf.reviewed && <p>Revisado por: {htpf.reviewedByName}</p>}
            {htpf.reviewed && <p>Revisado em: {format(parseInt(htpf.reviewedAt), " dd/MM/yyyy ", {locale})}</p>}
            {htpf.processedAt && <p>Processado em: {format(parseInt(htpf.processedAt), " dd/MM/yyyy ", {locale})}</p>}
            <p>Situação: {htpf.reviewed?(htpf.processedAt?"HTPF Processado no SGE":
                                                           "Aguardando Processamento no SGE")
                                        :"Aguardando aprovação para processamento"}</p>
          </ListItem>
        ))}

      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesHtpf;