import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Container,
  Description,
  ListContent,
  ListItem,
  Unidade,
  UnidadeItem,
  ListVazio,
  Separador,
} from "./styles";

import Accessibility from "../../components/UI/Accessibility";
import Menu from "../../components/UI/Menu";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";
import { images } from "../../constants";
import Banner from "../../components/UI/Banner";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import Stock from "./Components/Stock";
import Movement from "./Components/Movement";

const TradeBookControl = ({ action, mode }) => {
  const { user, infoPage, updateInfoPage, accessRight } = useAuth();
  const [ciclos, setCiclos] = useState([]);
  const [stock, setStock] = useState([]);
  const [move, setMove] = useState([]);
  const navigate = useNavigate();
  const { category, id } = useParams();

  const [controlList, setControlList] = useState([
    {name:"Solicitado por outra unidade",open:false},
    {name:"Solicitação da Unidade",open:false},
    {name:"Disponibilizado pela unidade",open:false},
  ]);

  const [myUnity, setMyUnity] = useState([]);

  const changeOpenCicle = (changeCiclo) => {
    let newVetor = [];
    ciclos.forEach((ciclo)=>{
      if(ciclo.id === changeCiclo){
        ciclo.open=!ciclo.open;
      }
      newVetor.push(ciclo);
    })
    setCiclos(newVetor);
  };

  const changeOpenMyUnity = (change) => {
    let newVetor = [];
    controlList.forEach((item)=>{
      if(item.name === change){
        item.open=!item.open;
      }
      newVetor.push(item);
    })
    setControlList(newVetor);
  };

  const [schoolGradeList, loadSchoolGradeListInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/schoolGradeList",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data?.length>0){
          let newCiclos = []
          response.data.forEach((temp)=>{
            temp.open = false;
            newCiclos.push(temp);
          });
          setCiclos(newCiclos);
        }
        console.log("schoolGradeList",response);
      }
    },
  });

  //eslint-disable-next-line
  const [loadStocks, loadStocksInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/books/stockBalance/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("loadStock", response.data)
        setStock(response.data);
      }
    },
  });

  const [loadMove, loadMoveInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/books/movements/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("loadMove", response.data)
        if (response.data.length > 0) {
          console.log("loadMove", response.data)
          setMove(response.data);
        }
      }
    },
  });

  const loadchange = () => {
    if(action==="myunity"){
      loadMove({
        params:{
          idCategory:category
        }
      });
    }
    else{
      schoolGradeList({
        params: {
          showedInBooksRegister:true
        },
      });
      
      loadStocks({
        params:{
          idCategory:category
        }
      });
    }
  };

  useEffect(() => {
    loadchange();
    setMyUnity(user.dados.unities);
    console.log("infopage",infoPage);
    if(infoPage?.BookControl?.category===category && infoPage?.BookControl?.id===id){
      setControlList(infoPage.BookControl.controlList);
    }
    // setCiclos([]);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(infoPage?.BookControl?.scroll){
      window.scrollTo(0,infoPage?.BookControl?.scroll);
    }
    //eslint-disable-next-line
  }, [move]);

  useEffect(() => {
    console.log("teste ", id ," != ", infoPage?.BookControl?.id, " - ", infoPage?.BookControl?.id!==id)
    if(infoPage?.BookControl?.category!==category || infoPage?.BookControl?.id!==id){
      setControlList([
        {name:"Solicitado por outra unidade",open:false},
        {name:"Solicitação da Unidade",open:false},
        {name:"Disponibilizado pela unidade",open:false},
      ]);
    }
    //eslint-disable-next-line
  }, [category, id]);

  useEffect(() => {
    loadchange();
    setMyUnity(user.dados.unities);
    // setCiclos([]);
    //eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    loadchange();
    // setCiclos([]);
    //eslint-disable-next-line
  }, [action]);

  useEffect(() => {
    updateInfoPage({...infoPage,BookControl:{...infoPage?.BookControl,category,id,controlList}})
    // setCiclos([]);
    //eslint-disable-next-line
  }, [controlList,category]);

  // useEffect(() => {
  //   setInitialLoading(false);
  //   let newStock = [];
  //   stock.forEach((temp)=>{
  //     temp.book = books.filter((temp2)=>{return temp.idBook === temp2.idBook})[0];
  //     newStock.push(temp);
  //   })
  //   setStock(newStock);
  //   console.log("newStock",newStock);
  //   // setCiclos([]);
  //   //eslint-disable-next-line
  // }, [stock,books]);

  return (
    <Container>
      {(loadSchoolGradeListInfo.loading ||
        loadStocksInfo.loading ||
        loadMoveInfo.loading) && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={
          (category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && images.icon_pnld) ||
          (category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && images.livrosEstado) 
        }
        url={`/bookpost/${category}/add`}
        canEdit={accessRight("books",category).SP_rights.can_make_books_available}
      >
        {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>Requisições PNLD</>}
        {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>Requisições Livros do Estado</>}
      </Banner>
      <ContentBox>
        <Description>
        {/* Descrição Livros PNLD */}
        {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>
        Este espaço é destinado ao pedido ou controle requisições de livros do PNLD, verifique na aba Geral os livros disponíveis e faça sua requisição ou
        utilize a aba da sua lotação para gerenciar pedidos. Para disponibilizar livros utilize o botão de edição, caso esteja
        disponível para o seu usuário. O Programa Nacional do Livro e do Material Didático (PNLD) é destinado a avaliar e a disponibilizar obras didáticas, 
        pedagógicas e literárias, entre outros materiais de apoio à prática educativa, de forma sistemática, regular e gratuita, 
        às escolas públicas de educação básica das redes federal, estaduais, municipais e distrital e também às instituições 
        de educação infantil comunitárias, confessionais ou filantrópicas sem fins lucrativos e conveniadas com o Poder Público. Este 
        espaço é destinado à disponibilização e requisição de livros para usuários autorizados.
        </>}

        {/* Descrição Livros do Estado */}
        {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>
        Este espaço é destinado ao pedido ou controle das requisições de livros do Estado
        </>}
        
        </Description>
        {accessRight("books",category).SP_rights.can_request_books ? 
          <>
          <Unidade>
            <UnidadeItem key={0} onClick={()=>{navigate(`/book/${category}`)}} selected={id?false:true}>Geral</UnidadeItem> 
            {myUnity.map((unity)=>(
              <UnidadeItem key={unity.idUnity} onClick={()=>{navigate(`/book/${category}/myunity/${unity.idUnity}`)}} selected={unity.idUnity===id}>{unity.unityName}</UnidadeItem>
            ))}
          </Unidade> 
          {/* Pagina com unidade = geral */}

          {!id && category && ciclos.map((ciclo,indexCiclo)=>(
            // <ListContent>
            //   <ListItem key={indexCiclo} onClick={()=>{changeOpenCicle(ciclo.Ano)}}>{ciclo.Ano}{ciclo.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>
            //   <TableContent visible={ciclo.open}>
            //     <table>
            //       <thead>
            //         <tr>
            //         <th>
            //           Titulo
            //         </th>
            //         <th>
            //           Editora
            //         </th>
            //         <th>
            //           Disciplina
            //         </th>
            //         <th>
            //           Exemplar
            //         </th>
            //         <th>
            //           Unidade Origem
            //         </th>
            //         <th>
            //           Disponivel
            //         </th>
            //         <th>
            //           Solicitar
            //         </th>
            //         </tr>
            //       </thead>
            //       <tbody>
            //       {jsonTickets.tickets.filter((ticket)=>{return ticket.Ano===ciclo.Ano}).map((item)=>(
            //         <tr>
            //           <td>
            //             {item.title}
            //           </td>
            //           <td>
            //             {item.editora}
            //           </td>
            //           <td>
            //             {item.disciplina}
            //           </td>
            //           <td>
            //             {item.exemplar}
            //           </td>
            //           <td>
            //             {item.unidadeOrigem.name}
            //           </td>
            //           <td>
            //             {item.disponibilizado-item.reservadoAprovado}/{item.disponibilizado}
            //           </td>
            //           <td>
            //             <div><ReadMoreButton
            //               text="Solicitar Reserva"
            //               type="button"
            //               onClick={()=>{}}
            //             /></div>
            //           </td>
            //         </tr>
            //       ))}
            //       </tbody>
            //     </table>
            //   </TableContent>
            // </ListContent>
            <ListContent key={`${ciclo.id}`} >
              {stock.filter((ticket)=>{return ticket.idSchoolGradeList===ciclo.id}).length>0 && <ListItem onClick={()=>{changeOpenCicle(ciclo.id)}}>{ciclo.title}{ciclo.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>}
              {stock.filter((ticket)=>{return ticket.idSchoolGradeList===ciclo.id}).map((item,index)=>(
                <Stock ticket={item} key={`ticketid-${index}`} visible={ciclo.open}  category={category}/>
              ))}
            </ListContent>
          ))}

          {!id && stock.length===0 && <div>Não foi disponibilizado nenhum livro no momento.</div>}

          {/* Pagina com unidade definida */}
          
          {id && controlList.map((item,index)=>
                        <ListContent key={`myUnity-${index}`}>
                          <ListItem onClick={()=>{changeOpenMyUnity(item.name)}}>
                                {item.name}{item.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
                          </ListItem>
                          {/* Lista as Solicitações para a Unidade priorizando as solicitações mais antigas*/}
                          {item.name==="Solicitado por outra unidade" && move
                              .filter((ticket)=>{
                                return ticket.idUnityOrigin===id && ticket.quantityApproved===null;
                              })
                              .toReversed()
                              .map((item2)=>(<Movement ticket={item2} key={`${item2.idMovement}`} visible={item.open} myunity={id} category={category} infoPage={{infoPage, updateInfoPage}}/>))
                          }

                          {
                            item.name==="Solicitado por outra unidade" && 
                            move.filter((ticket)=>{return ticket.idUnityOrigin===id && ticket.quantityApproved!==null;}).length>0 &&
                            (<Separador visible={item.open}>Solicitações Aprovadas</Separador>)
                          }                      

                          {item.name==="Solicitado por outra unidade" && move
                              .filter((ticket)=>{
                                return ticket.idUnityOrigin===id && ticket.quantityApproved!==null;
                              })
                              .toReversed()
                              .map((item2)=>(<Movement ticket={item2} key={`${item2.idMovement}`} visible={item.open} myunity={id} category={category} infoPage={{infoPage, updateInfoPage}}/>))
                          }

                          {/* Lista as Solicitações que a Unidade solicitou*/}

                          {item.name==="Solicitação da Unidade" && move
                              .filter((ticket)=>{return ticket.idUnity===id && ticket.idUnityOrigin!==null;})
                              .map((item2)=>(<Movement ticket={item2} key={`${item2.idMovement}`} visible={item.open} myunity={id} category={category} infoPage={{infoPage, updateInfoPage}}/>))
                          }

                          {/* Lista os materiais que a Unidade disponibilizou*/}

                          {item.name==="Disponibilizado pela unidade" && move
                              .filter((ticket)=>{return ticket.idUnity===id && ticket.idUnityOrigin===null;})
                              .map((item2)=>(<Movement ticket={item2} key={`${item2.idMovement}`} visible={item.open} myunity={id} category={category}/>))
                          }

                          {/* Vetor de movimento vazio */}
                          {move.filter((ticket)=>{
                                                    if(item.name==="Solicitado por outra unidade")
                                                      {
                                                        return ticket.idUnityOrigin===id
                                                      }
                                                    if(item.name==="Solicitação da Unidade")
                                                      {
                                                        return ticket.idUnity===id && ticket.idUnityOrigin!==null;
                                                      }
                                                    if(item.name==="Disponibilizado pela unidade")
                                                      {
                                                        return ticket.idUnity===id && ticket.idUnityOrigin===null;
                                                      }
                                                    return false;
                                                  }).length===0 && <ListVazio visible={item.open}>
                                                                      {item.name==="Solicitado por outra unidade" && "Não houve solicitação para a unidade"}
                                                                      {item.name==="Solicitação da Unidade" && "Unidade não solicitou nenhum material"}
                                                                      {item.name==="Disponibilizado pela unidade" && "Unidade não disponibilizou nenhum material"}
                                                  </ListVazio>
                          }         
                        </ListContent>
          )}
        </>
        :
        <div>
          *Não há conteúdos disponíveis para o seu usuário.
        </div>
      }
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default TradeBookControl;
