import React, { useEffect, useState } from "react";
import {
  MdViewList,
  MdViewModule,
  MdFilterAlt,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { InView } from "react-intersection-observer";

import {
  Container,
  Search,
  ListContent,
  ListItem,
  Info,
  Button,
  Editar,
  ListMode,
  ListFilter,
  SelectAbility,
  Ability,
  Image,
  ImageBlock,
  Title,
  Ad,
  FilterBox,
  FilterList,
  LoadingMore,
  TextFilter,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
import InputSearch from "../../components/UI/Inputs/InputSearch";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton";
import InputSelect from "../../components/UI/Inputs/InputSelect";
import InputCheck from "../../components/UI/Inputs/InputCheck";
import Modal from "../../components/UI/Modal";
import Visibility from "../../components/UI/Visibility";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import LoadingItem from "../../components/UI/LoadingItem";

import ellipsis from "../../utils/ellipsis";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

import { images, icons } from "../../constants";

const Home = () => {
  const { user, infoPage, updateInfoPage } = useAuth();
  const navigate = useNavigate();
  const [reds, setReds] = useState([]);
  const [params, setParams] = useState({terms:"",idEvaluationEndpoint:"",idEvaluationEndpointText:"", idSchoolGradeList:"",textSchoolGrade:"",idSubject:"",textSubject:"",noAdvertising:false});
  const [mode, setMode] = useState({grade:true});
  const [abilities, setAbilities] = useState([]);
  const [schoolGrades, setSchoolGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [modalAbility, setModalAbility] = useState({open:false,filter:""});
  const [filterOpen, setFilterOpen] = useState(false);

  const handleMode = (item,value) => {
    if(item==="style"){
      updateInfoPage({...infoPage,reds:{...infoPage?.reds,grade:!mode.grade}});
      setMode({...mode, grade:!mode.grade});
    }
  };

  // eslint-disable-next-line
  const [load, loadInfo] = useApi({
    debounceDelay: 2000,
    url: "/canalPedagogico/reds/byTerm/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response);
        setReds([...reds,...response.data.reds]);
      }
    },
    onError: (error)=>{
      console.log("error: ", error)
    }
  });

  const [newLoad, newLoadInfo] = useApi({
    debounceDelay: 2000,
    url: "/canalPedagogico/reds/byTerm/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("redsByterm",response);
        setSchoolGrades(response.data.schoolGradeList);
        setSubjects(response.data.subject);
        setAbilities(response.data.evaluationEndpoint);
        setReds(response.data.reds);
      }
    },
    onError: (error)=>{
      console.log("error: ", error)
    }
  });

  // const [loadAbilities, loadAbilitiesInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "canalPedagogico/ability",
  //   // timeout: 5000,
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log("abilities", response.data);
  //       setAbilities(response.data);
  //     }
  //   },
  // });

  // const [loadSubjects, loadSubjectsInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "subjects",
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log("Subjects", response.data);
  //       setSubjects(response.data);
  //     }
  //   },
  // });

  // const [loadSchoolGrades, loadSchoolGradesInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "canalPedagogico/schoolGradeList",
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log("SchoolGrades", response.data);
  //       setSchoolGrades(response.data);
  //     }
  //   },
  // });

  const isEditor = () => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === "f81a0134-33f1-4d03-b624-813e30e15054"
    )
    if( editor > -1 ) {
      return true;
    } else {
      return false;
    }};

  useEffect(() => {
    // loadAbilities();
    // loadSubjects();
    // loadSchoolGrades();
    if(infoPage?.reds.hasOwnProperty("grade")){
      // console.log("reconheceu registro em grade")
      setMode({...mode,grade:infoPage.reds.grade})
    }
    if(infoPage?.reds.hasOwnProperty("params")){
      // console.log("reconheceu registro em params")
      setParams(infoPage.reds.params);
    }
    else{
      newLoad({params:{...params,
                      initial: 0,
                      limit: 3,
      },debounced: true});
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log("params",params,Date.now());
    updateInfoPage({...infoPage,reds:{...infoPage?.reds,params}});
    newLoad({params:{...params,
                    initial: 0,
                    limit: 12,
      },debounced: true});
    // eslint-disable-next-line
  }, [params]);

  const clearFilter = () => {
    setParams({terms:"",idEvaluationEndpoint:"",idEvaluationEndpointText:"", idSchoolGradeList:"",textSchoolGrade:"",idSubject:"",textSubject:"",noAdvertising:false});
  };

  const redirect = (link)=>{
    window.open(link, '_blank')
  }

  const loadMore = (InviewObj, entry) => {
    if (InviewObj) {
      load({params:{...params,
                    initial: reds.length,
                    limit: 12,
      },debounced: true})
    }
  };

  return (
    <Container>
      {(newLoadInfo.loading) && <FullScreenLoading />}
      <Menu />
      <Banner 
        url="/reds/add"
        editorId="f81a0134-33f1-4d03-b624-813e30e15054"
        icon={images.reds_banner}>
        Recurso Educacional Digital
      </Banner>

      <Search>
        <InputSearch
          value={params.term}
          onChange={(e)=>{setParams({...params,term:e.target.value});}}
          loading={loadInfo.loading}
        />
      </Search>
      <FilterBox isOpen={filterOpen} onClick={()=>{setFilterOpen(!filterOpen)}}>
        <MdFilterAlt fontSize={45}/>
      </FilterBox>
      <FilterList isOpen={filterOpen}>
        {abilities.length > 0 && <SelectAbility>
          <span>Objetivo de aprendizagem / Habilidades</span>
          <div onClick={()=>{setModalAbility({...modalAbility, open:!modalAbility.open})}}>{params.idEvaluationEndpointText===""?"Selecione um objetivo ou habilidade":`${ellipsis(params.idEvaluationEndpointText,87)}`}</div>
        </SelectAbility>}
        {subjects.length > 0 && <InputSelect
            columns={1}
            onChange={(e)=>{setParams({...params,idSubject:e.target.value,textSubject:e.target.selectedOptions[0]?.text});}}
            value={params.idSubject}
            placeholder="Campos de experiência / Área do Conhecimento"
          >
            <option value="" disabled defaultValue>
              Selecione um campo ou área
            </option>
            {subjects.map((item) => (
                <option key={item.idsubject} value={item.idsubject}>
                  {item.subject_name}
                </option>
              ))}
            <option value="">
              REMOVER FILTRO
            </option>
        </InputSelect>}
        {schoolGrades.length > 0 && <InputSelect
            columns={1}
            onChange={(e)=>{setParams({...params, idSchoolGradeList:e.target.value, textSchoolGrade:e.target.selectedOptions[0]?.text});}}
            value={params.idSchoolGradeList}
            placeholder="Ano da Turma"
          >
            <option value="" disabled defaultValue>
              Selecione o ano da turma
            </option>
            {schoolGrades.map((item) => (
                <option key={item.idschool_grade_list} value={item.idschool_grade_list}>
                  {item.school_grade}
                </option>
              ))}
            <option value="">
              REMOVER FILTRO
            </option>
        </InputSelect>}
        <div className="check">
          <div>
          <InputCheck
          name="hasAdvertising"
          text="Somente recursos sem anúncios"
          onChange={()=>{setParams({...params,noAdvertising:(!params.noAdvertising)})}}
          alterColor={true}
          checked={params.noAdvertising}/>
          </div>
        </div>
        
      </FilterList>

      <ContentBox>
        {/* <ListFilter>
              <SelectAbility>
                <span>Habilidades</span>
                <div onClick={()=>{setModalAbility({...modalAbility, open:!modalAbility.open})}}>{params.idEvaluationEndpointText===""?"Selecione a habilidade":`${params.idEvaluationEndpointText}`}</div>
              </SelectAbility>
              {subjects.length > 0 && <InputSelect
                  columns={1}
                  onChange={(e)=>{setParams({...params,idSubject:e.target.value});}}
                  value={params.idSubject}
                  placeholder="Disciplina"
                >
                  <option value="" disabled defaultValue>
                    Selecione a disciplina
                  </option>
                  {subjects.map((item) => (
                      <option key={item.idSubject} value={item.idSubject}>
                        {item.subjectName}
                      </option>
                    ))}
                  <option value="">
                    REMOVER FILTRO
                  </option>
              </InputSelect>}
              {schoolGrades.length > 0 && <InputSelect
                  columns={1}
                  onChange={(e)=>{setParams({...params, idSchoolGradeList:e.target.value});}}
                  value={params.idSchoolGradeList}
                  placeholder="Ano da Turma"
                >
                  <option value="" disabled defaultValue>
                    Selecione o ano da turma
                  </option>
                  {schoolGrades.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  <option value="">
                    REMOVER FILTRO
                  </option>
              </InputSelect>}
              <div className="check">
                <div>
                <InputCheck
                name="hasAdvertising"
                text="Somente recursos sem anúncios"
                onChange={()=>{setParams({...params,noAdvertising:(!params.noAdvertising)})}}
                checked={params.noAdvertising}/>
                </div>
              </div>
        </ListFilter> */}
        {(params.terms!==""||
          params.idEvaluationEndpoint!==""||
          params.idSchoolGradeList!==""||
          params.idSubject!==""||
          params.noAdvertising
        ) && <TextFilter>
          <span><p>Filtros Aplicados:</p></span>
          {params.terms!=="" && <span className="tags">Termo Buscado:</span>}
          {params.idEvaluationEndpoint!=="" && <span className="tags">{params.idEvaluationEndpointText}</span>}
          {params.idSubject!=="" && <span className="tags">{params.textSubject}</span>}
          {params.idSchoolGradeList!=="" && <span className="tags">{params.textSchoolGrade}</span>}
          {params.noAdvertising && <span className="tags">Não pode conter anuncios.</span>}
          {<span className="button"><ReadMoreButton text={"Remover Filtros"} onClick={()=>{clearFilter();}}/></span>}
        </TextFilter>}
        <ListMode>{mode.grade?<div onClick={()=>{handleMode("style")}}>Vizualizar em lista <MdViewList size={28}/></div>:<div onClick={()=>{handleMode("style")}}>Vizualizar em grade <MdViewModule size={28}/></div>}</ListMode>
        <ListContent 
          reduct={0}
          row={true}
          visible={true}>

          {reds.map((red)=>(
          <ListItem
            key={red.id}
            visible={true}
            reduct={mode.grade?70:0}
            roundborder={mode.grade?70:0}
            direction={mode.grade?"grade":"row"}
            pointer={true}>
            <ImageBlock onClick={()=>{redirect(red.url);}}>{red.hasAdvertising && <Ad><span><img src={icons.reds_ads}/></span></Ad>}<Image><img src={`${process.env.REACT_APP_CDN_URL}canalPedagogico/reds/${red.image_preview}`}/></Image></ImageBlock>
            <Info mode={mode.grade?"grade":"row"}>
              <Title mode={mode.grade?"grade":"row"}>
                <span onClick={()=>{redirect(red.url);}}>{red.title}</span> 
                {isEditor() && <Editar href={`/reds/edit/${red.id}`}><img src={icons.editBlack} alt="" /></Editar>}
              </Title>
              {!mode.grade && <p>
                {red.description}
              </p>}
              {isEditor() && !mode.grade && <Visibility visibility={red.visibilities}/>}
              {!mode.grade && <Button><ReadMoreButton onClick={()=>{navigate(`/reds/${red.id}`)}}/></Button>}
            </Info>
          </ListItem>))}

          {reds.length > 0 && (
              <InView
                as="div"
                onChange={(inView, entry) => loadMore(inView, entry)}
              ></InView>
            )}

        </ListContent>
        {loadInfo.loading && <LoadingMore>
          <span>
            <LoadingItem />
          </span>
        </LoadingMore>}
        <Modal 
          open={modalAbility.open} 
          buttonClose={true} 
          close={()=>{setModalAbility({...modalAbility, open:!modalAbility.open})}}>

          <InputSearch
            value={modalAbility.filter}
            onChange={(e)=>{setModalAbility({...modalAbility, filter:e.target.value})}}
          />
          
          <Ability>
            {abilities.filter((item)=>{return modalAbility.filter===""?true:item.endpoint_value.indexOf(modalAbility.filter)>=0}).length > 0 ?
                    <>
                      {abilities.filter((item)=>{return modalAbility.filter===""?true:item.endpoint_value.indexOf(modalAbility.filter)>=0}).map((item) => (
                      <div className="ability" key={item.idevaluation_endpoint} value={item.idevaluation_endpoint}>
                        <div className="text">{item.endpoint_value}</div>
                        <div className="button">
                          <ReadMoreButton 
                            onClick={()=>{
                              setParams({...params,idEvaluationEndpoint:item.idevaluation_endpoint,idEvaluationEndpointText:item.endpoint_value});
                              setModalAbility({...modalAbility, open:!modalAbility.open});}} 
                            text={"Selecionar"}/>
                        </div>
                      </div>))}
                      <div className="ability" key={""} value={""}>
                        <div className="text">Remover Filtro</div>
                        <div className="button">
                          <ReadMoreButton 
                            onClick={()=>{
                              setParams({...params,idEvaluationEndpoint:"",idEvaluationEndpointText:""});
                              setModalAbility({...modalAbility, open:!modalAbility.open});}} 
                            text={"Selecionar"}/>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="ability center">Não há habilidades sobre: {modalAbility.filter}</div>
                      <div className="ability" key={""} value={""}>
                        <div className="text">Remover Filtro</div>
                        <div className="button">
                          <ReadMoreButton 
                            onClick={()=>{
                              setParams({...params,idEvaluationEndpoint:"",idEvaluationEndpointText:""});
                              setModalAbility({...modalAbility, open:!modalAbility.open});}} 
                            text={"Selecionar"}/>
                        </div>
                      </div>
                    </>
                    }
          </Ability>
        </Modal>
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default Home;
