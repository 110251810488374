import React from "react";
import { BsSpeedometer } from "react-icons/bs";
import { useTheme } from "../../../hooks/useTheme";
import { Container } from "./styles";

const SearchMessage = ({ total = 0, time = 0, message = false }) => {
  const { themeFile } = useTheme();

  return (
    <Container>
      {message ? (
        <p>{message}</p>
      ) : (
        <>
          <BsSpeedometer
            size={themeFile.SIZES.base * 24}
            color={themeFile.COLORS.secondary}
          />
          <p>
            {total > 1 ? "Foram" : "Foi"} encontrado
            {total > 1 && "s"} {total} resultado
            {total > 1 && "s"} em {time} segundo
            {time > 1 && "s"}!
          </p>
        </>
      )}
    </Container>
  );
};

export default SearchMessage;
