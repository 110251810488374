import React, { useState, useEffect} from 'react';

import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";

import { Container, ListContent, ListItem, Test } from './styles';
import Accessibility from "../../components/UI/Accessibility";
import Menu from "../../components/UI/Menu";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
// import {escola} from './percentual_por_escola.js';

import { images } from "../../constants";
import Banner from '../../components/UI/Banner';
import Grafico from './Grafico';

import useApi from "../../hooks/useApi";



const AvaliacoesGraficos = () => { 
  const [listTest, setListTest] = useState([]);
  const [controlListTest, setControlListTest] = useState(false);
  const [controlListGrafico, setControlListGrafico] = useState(false);
  const [controlFullScreen, setControlFullScreen] = useState(false);
  const [titleGrafico, setTitleGrafico] = useState({text:"",top:"9%"});
  const [dataTest, setDataTest] = useState([]);

  const [controlGraphic, setControlGraphic] = useState({
    type: "Escola",
    idReports:[],
    idUnity:"",
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "eaprendi/reportsList",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("load", response);
        let adjustReport = [];
        response.data.forEach((report)=>{
          let pos = adjustReport.findIndex((aReport)=>{
            return aReport.year===report.inicioAplicacao.split("/")[2];
          });
          if(pos>=0){
            adjustReport[pos].list.push(report)
          }
          else{
            adjustReport.push({
              year:report.inicioAplicacao.split("/")[2],
              open:false,
              list:[report],
            });
          }          
        });
        console.log("adjustReport",adjustReport);
        setListTest(adjustReport.sort((reportA,reportB)=>{return reportB.year-reportA.year}));
      }
    },
  });

  const [loadResult, loadResultInfo] = useApi({
    debounceDelay: 0,
    url: "eaprendi/results",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("loadResults", response);
        setDataTest(response.data.dados);
      }
    },
  });

  useEffect(()=>{
    if(dataTest.length>0){
      switch(controlGraphic.type){
        case "Turma":
          setTitleGrafico({text:`${dataTest[0]["Edição E-Aprendi"]}\n\nEscola:${dataTest[0]?.Escola}`,top:"15%"});//+6% para cada \n\n espaçamento duplo para não ficar grudado
          break;
        case "Questão":
          setTitleGrafico({text:`${dataTest[0]["Edição E-Aprendi"]}\n\nEscola:${dataTest[0]?.Escola} - ${dataTest[0]?.Turma}`,top:"15%"});//+6% para cada \n\n espaçamento duplo para não ficar grudado
          break;
        default:
          setTitleGrafico({text:`${dataTest[0]["Edição E-Aprendi"]}`,top:"9%"});//+6% para cada \n\n espaçamento duplo para não ficar grudado
      }
    }
  },[dataTest,controlGraphic]);

  useEffect(()=>{
    loadItem();
    // eslint-disable-next-line
  },[]);

  useEffect(()=>{
    if(controlListTest){
      setControlListGrafico(false);  
    }
  },[controlListTest]);

  const changeOpenYear = (year) => {
    let newVetor = [];
    listTest.forEach((temp)=>{
      if(temp.year === year){
        temp.open=!temp.open;
      }
      newVetor.push(temp);
    })
    setListTest(newVetor);
  };

  const openResult = (type,idReports) => {
    loadResult({params:{type,idReports:JSON.stringify(idReports)}})
    setControlGraphic({
      type:"Escola",
      idReports
    });
    setControlListTest(false);
    setControlListGrafico(true);
  };

  const clickGrafico = (params) => {
    console.log("click teste",params);
    if(params.value?.idClass){
      setControlGraphic({
        ...controlGraphic,
        type:"Questão",
        idUnity:params.value.idUnity,
      });
      loadResult({params:{type:"turma_questao",idReports:JSON.stringify(controlGraphic.idReports),idUnity:params.value.idUnity,idClass:params.value?.idClass}})
      setTitleGrafico({text:`${listTest[0]["Edição E-Aprendi"]}\n\n`,top:"15%"});
    }
    else{
      // setTitleGrafico({text:`${listTest[0]["Edição E-Aprendi"]}\n\nEscola:${listTest[0].Escola}`,top:"15%"});
      setControlGraphic({
        ...controlGraphic,
        type:"Turma",
        idUnity:params.value.idUnity,
      });
      loadResult({params:{type:"turma",idReports:JSON.stringify(controlGraphic.idReports),idUnity:params.value.idUnity}})
      setTitleGrafico({text:`${listTest[0]["Edição E-Aprendi"]}\n\nEscola:`,top:"15%"});
    }
  };

  useEffect(()=>{
    console.log("controlGraphic",controlGraphic);
  },[controlGraphic]);

  return (
    <Container>
      {(loadItemInfo.loading || loadResultInfo.loading) && (
        <FullScreenLoading />
      )}
      {!controlFullScreen && <Accessibility />}
      <Menu />
      <Banner icon={images.iconAvaliacao}>
        GRÁFICOS
      </Banner>
      <ContentBox>
        <ListContent visible={true}>
          <ListItem key={"ListTest"} onClick={()=>{setControlListTest(!controlListTest)}} visible={true} reduct={0} roundborder={true} selected={controlListTest}>  
            Lista de Avaliações {controlListTest ? <MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
          </ListItem>
          {listTest.map((year)=>(
            <ListContent key={year.year} visible={controlListTest}>
              <ListItem onClick={()=>{changeOpenYear(year.year)}} visible={controlListTest} reduct={3} roundborder={true} selected={year.open}>  
                {year.year} {year.open ? <MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
              </ListItem>
              <ListContent visible={year.open}>
                {year.list.map((test)=>(
                  <Test key={test.idRelatorio} reduct={6} visible={year.open}>
                    <div>{test.tituloRelatorio}</div> <ReadMoreButton onClick={()=>{openResult("escola",[test.idRelatorio]);}} text={"abrir resultado"}/>
                  </Test>
                ))}
              </ListContent>
            </ListContent>
           ))}
        </ListContent>
        <ListContent visible={true}>
          <ListItem key={"Grafico"} onClick={()=>{setControlListTest(false);setControlListGrafico(!controlListGrafico);}} visible={true} reduct={0} roundborder={true} selected={controlListGrafico}>  
            Gráfico {controlListGrafico ? <MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
          </ListItem>
          <ListItem key={"RenderGrafico"} visible={controlListGrafico} reduct={3} roundborder={false}>  
            { dataTest.length>0?
              <Grafico 
                dataEscola={dataTest} 
                dimension={controlGraphic.type}
                fullscreen={controlFullScreen} 
                closeFullscreen={setControlFullScreen} 
                visible={controlListGrafico} 
                clickOnGrafico={clickGrafico}
                title={titleGrafico}/>:
              "Avaliação não foi selecionada"
            }
          </ListItem>
          { dataTest.length>0 && 
            <ListItem key={"GraficoFullScreen"} visible={controlListGrafico} reduct={3} onClick={()=>{setControlFullScreen(true);document.getElementsByClassName("enabled")[0].attributes.style.value="display: none";}} roundborder={true} selected={controlListGrafico}>  
              Abrir gráfico em tela cheia 
            </ListItem>
          }
        </ListContent>
      </ContentBox>
      <Footer />
    </Container>
  );
}

export default AvaliacoesGraficos;
