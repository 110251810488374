import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  flex-wrap:nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Title = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  font: var(--title);
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font: var(--body);
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font: var(--body);
`;

export const Link = styled.a``;

export const Tag = styled.span`
  padding: 0.2rem 0.4rem;
  background-color: var(--softPurple);
  border-radius: 0.6rem;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
`;

export const Text = styled.div`
 display: flex;
 flex-direction:row;
 align-items:flex-start;
 justify-content:flex-start;
 flex-wrap:wrap;
 margin-bottom: 0.5rem;
 font: var(--body);
 
 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;