import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth } from "../../hooks/useAuth";

const PrivateRouteSchedule = () => {
  const { user } = useAuth();
  const editor = user?.profiles[0]?.editor?.findIndex(
    (edit) => edit.id === "0e3b686a-9b75-4de0-a2b7-28ec578e8119"
  );

  return editor > -1 ? <Outlet /> : <Navigate to="/login/redirect/*formacoes" />;
};

export default PrivateRouteSchedule;
