import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import {
  Container,
  Description,
  DivDescription,
  DivLink,
  LogoGnuteca,
  DivStayTunned,
  IconWirelles,
  TitleTunned,
  Feed,
  Search,
  DivClick,
} from "./styles";
import BookItem from "../../components/UI/BookItem";
import InputSearch from "../../components/UI/Inputs/InputSearch";
import { images, icons } from "../../constants";
import Banner from "../../components/UI/Banner";
import useApi from "../../hooks/useApi";
import { useEffect } from "react";
import { useState } from "react";
import InView from "react-intersection-observer";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import { useTheme } from "../../hooks/useTheme";

const BookRoom = () => {
  const [search, setSearch] = useState("");
  const [newsLibrary, setNewsLibrary] = useState([]);
  const { themeFile } = useTheme();
  const [initialLoading, setInitialLoading] = useState(true);

  const openGnuteca = () => {
    window.open("https://biblioteca.sme-mogidascruzes.sp.gov.br/");
  };

  const [loadLibrary, loadLibraryInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/publicNews/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("Library", response.data);
        if (response.data.length > 0) {
          let result = [];
          response.data.forEach((item) => {
            const found = newsLibrary.findIndex((news) => news.id === item.id);
            if (found === -1) {
              return result.push(item);
            }
          });
          setNewsLibrary([...newsLibrary, ...result]);
        }
        setInitialLoading(false);
      }
    },
  });

  const [searchLibrary, searchLibraryInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/library/publicNews/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("Library", response.data);
        if (response.data.length > 0) {
          let result = [];
          response.data.forEach((item) => {
            const found = newsLibrary.findIndex((news) => news.id === item.id);
            if (found === -1) {
              return result.push(item);
            }
          });
          setNewsLibrary([...newsLibrary, ...result]);
        }
      }
    },
  });

  const handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value.length > 2) {
      setNewsLibrary([]);
      console.log("pode fazer a busca");
      searchLibrary({
        params: {
          term: event.target.value.trim(),
          initial: 0,
          limit: 10,
        },
        debounced: true,
      });
    }

    if (event.target.value.length === 0) {
      setNewsLibrary([]);
      searchLibrary({
        params: {
          term: event.target.value,
          initial: 0,
          limit: 10,
        },
        debounced: true,
      });
    }
  };

  useEffect(() => {
    loadLibrary({
      params: {
        term: search,
        initial: 0,
        limit: 10,
      },
    });
    // eslint-disable-next-line
  }, []);

  const loadMore = (InviewObj, entry) => {
    if (InviewObj) {
      console.log("carregar mais");
      loadLibrary({
        params: {
          term: search,
          initial: newsLibrary.length + 1,
          limit: 10,
        },
      });
    }
  };

  return (
    <Container>
      {(initialLoading || loadLibraryInfo.loading) && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconBiblioteca}
        url="/biblioteca/admin"
        editorId="f0d87522-fcc7-4f8b-aec3-3e64fce9915a"
      >
        BIBLIOTECA
      </Banner>
      <Search>
        <InputSearch
          placeholder="Qual lançamento você procura?"
          value={search}
          onChange={handleSearch}
          onClick={handleSearch}
          loading={searchLibraryInfo.loading}
        />
      </Search>
      <ContentBox>
        <DivDescription>
          <Description>
            A biblioteca do CEMFORPE, localizada no bloco didático, é uma
            unidade de informação especializada em publicações de temática
            educacional. Nela, o aspirante ou profissional da educação
            encontrará diversas obras que vão da teoria à prática que podem ser
            consultadas no próprio espaço, ou emprestadas a partir de um breve
            cadastro. Aberta de segunda às sextas-feiras das 8h às 11h, depois
            das 12h30 às 17h30.



          <br />

          </Description>
          <DivLink>
            <LogoGnuteca src={images.gnutecaLogo} alt="Gnuteca" />
            GNUTECA
            <DivClick onClick={openGnuteca}>Clique para abrir</DivClick>
          </DivLink>
        </DivDescription>
        <DivStayTunned>
          <IconWirelles
            src={
              themeFile.COLORS.highContrast
                ? icons.wireless_white
                : icons.wireless
            }
          />
          <TitleTunned>Fique ligado</TitleTunned>
        </DivStayTunned>
        <Feed>
          {newsLibrary?.length > 0 &&
            newsLibrary?.map((item) => (
              <BookItem
                key={item.id}
                id={item.id}
                image={
                  process.env.REACT_APP_CDN_URL +
                  "canalPedagogico/libraryNews/" +
                  item.image
                }
                title={item.title}
                description={item.description}
                url={`/biblioteca/news/editar/${item.id}`}
                editorId="f0d87522-fcc7-4f8b-aec3-3e64fce9915a"
                mainCategory="Biblioteca"
              />
            ))}
          {newsLibrary?.length > 0 && (
            <InView
              as="div"
              onChange={(inView, entry) => loadMore(inView, entry)}
            ></InView>
          )}
        </Feed>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default BookRoom;
