import React from "react";

import { MdClose } from "react-icons/md";
// import { images } from "../../../constants";

import { Main, Container, IconClose, Content } from "./styles";

const Modal = ({
  children,
  open = false,
  buttonClose = true,
  close,
  // width = 100,
  // height = 70,
  ...rest
}) => {
  return (
    <Main isOpen={open} {...rest}>
      <Container>
        <IconClose onClick={close} buttonClose={buttonClose}>
          <MdClose size={42} />
        </IconClose>
        <Content>{children}</Content>
      </Container>
    </Main>
  );
};

export default Modal;
