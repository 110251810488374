import accessHand from "../assets/icons/accessHand.svg";
import increaseFont from "../assets/icons/increaseFont.svg";
import decreaseFont from "../assets/icons/decreaseFont.svg";
import highContrast from "../assets/icons/highContrast.svg";
import highContrast2 from "../assets/icons/highContrast2.svg";
import help_black from "../assets/icons/help_black.svg";
import help_purple from "../assets/icons/help_purple.svg";
import help from "../assets/icons/help.svg";
import logout_black from "../assets/icons/logout_black.svg";
import logout from "../assets/icons/logout.svg";
import agenda_black from "../assets/icons/agenda_black.svg";
import agenda_purple from "../assets/icons/agenda_purple.svg";
import agenda from "../assets/icons/agenda.svg";
import biblioteca from "../assets/icons/biblioteca.svg";
import biblioteca_black from "../assets/icons/biblioteca_black.svg";
import biblioteca_purple from "../assets/icons/biblioteca_purple.svg";
import bell from "../assets/icons/bell.svg";
import bellBlack from "../assets/icons/bellBlack.svg";
import conheca from "../assets/icons/conheca.svg";
import conheca_black from "../assets/icons/conheca_black.svg";
import documentos from "../assets/icons/documentos.svg";
import documentos_black from "../assets/icons/documentos_black.svg";
import documentos_purple from "../assets/icons/documentos_purple.svg";
import errorIcon from "../assets/icons/errorIcon.svg";
import eventos from "../assets/icons/eventos.svg";
import formacoes_black from "../assets/icons/formacoes_black.svg";
import formacoes_purple from "../assets/icons/formacoes_purple.svg";
import formacoes from "../assets/icons/formacoes.svg";
import indicadores_black from "../assets/icons/indicadores_black.svg";
import indicadores_purple from "../assets/icons/indicadores_purple.svg";
import indicadores from "../assets/icons/indicadores.svg";
import login_black from "../assets/icons/login_black.svg";
import login from "../assets/icons/login.svg";
import logoCanalJSON from "../assets/icons/logo_canal_pedagogico.json";
import logoCanalJSONHighContrast from "../assets/icons/canal_pedagogico_logo_high_contrast.json";
import logoAllAnimated from "../assets/icons/logo_todos_icones_novo.json";
import loading from "../assets/icons/canal_pedagogico_loading.json";
import cadeado from "../assets/icons/icon_cadeado.json";
import lupa from "../assets/icons/lupa.svg";
import lupaBlack from "../assets/icons/lupaBlack.svg";
import lupaPurple from "../assets/icons/lupaPurple.svg";
import plataforma_black from "../assets/icons/plataforma_black.svg";
import plataforma_purple from "../assets/icons/plataforma_purple.svg";
import plataforma from "../assets/icons/plataforma.svg";
import videoteca from "../assets/icons/videoteca.svg";
import videoteca_black from "../assets/icons/videoteca_black.svg";
import videoteca_purple from "../assets/icons/videoteca_purple.svg";
import wireless from "../assets/icons/wireless.svg";
import wireless_white from "../assets/icons/wireless_white.svg";
import logoStatic from "../assets/icons/logo_canal_pedagogico.svg";
import edit from "../assets/icons/pen-to-square-solid.svg";
import editBlack from "../assets/icons/pen-to-square-solid-black.svg";
import iconMenu from "../assets/icons/menu.png";
import iconGoogle from "../assets/icons/icon-google.png";
import iconDelete from "../assets/icons/icon-delete.png";
import iconAdd from "../assets/icons/icon-add.png";
import museum from "../assets/icons/landmark.svg";
import museum_black from "../assets/icons/landmark_black.svg";
import iconProject_white from "../assets/icons/project_white.svg";
import iconProject_black from "../assets/icons/project_black.svg";
import pnld_white from "../assets/icons/pnld_white.svg";
import pnld_black from "../assets/icons/pnld-bk.svg";
import item_black from "../assets/icons/item_black.svg";
import item_white from "../assets/icons/item_white.svg";
import musica from "../assets/icons/musica.svg";
import musica_black from "../assets/icons/musica-bk.svg";
import cidadeleitora from "../assets/icons/cidade-leitora.svg";
import cidadeleitora_black from "../assets/icons/cidade-leitora-bk.svg";
import seguranca from "../assets/icons/seguranca.svg";
import pnld from "../assets/icons/pnld.svg";
import novo_white from "../assets/icons/novo-white.svg";
import novo from "../assets/icons/novo.svg";
import school_black from "../assets/icons/school-black.svg";
import school_white from "../assets/icons/school-white.svg";
import book from "../assets/icons/book.svg";
import book_black from "../assets/icons/book_black.svg";
import book_state from "../assets/icons/book_state.svg";
import book_state_black from "../assets/icons/book_state_black.svg";
import pen_request from "../assets/icons/pen_request.svg";
import pen_request_black from "../assets/icons/pen_request_black.svg";
import btn_download from "../assets/icons/btn-download.svg";
import pilha_livros from "../assets/icons/pilha-livros.svg";
import add_livro from "../assets/icons/add-livro.svg";
import atividade_justificado from "../assets/icons/atividade-justificado.png";
import atividade_falta from "../assets/icons/atividade-falta.png";
import atividade_feito from "../assets/icons/atividade-feito.png";
import acompanhamento_eaprendi from "../assets/icons/lupa-eaprendi.svg";
import acompanhamento_eaprendi_black from "../assets/icons/lupa-eaprendi-bk.svg";
import livros_estado from "../assets/icons/livros-sp.svg";
import livros_estado_black from "../assets/icons/livros-sp-bk.svg";
import livro_cadastro from "../assets/icons/livro-cadastro.svg";
import livro_cadastro_black from "../assets/icons/livro-cadastro-bk.svg";
import formacao_gestao from "../assets/icons/formacoes-gestao.svg";
import formacao_gestao_black from "../assets/icons/formacoes-gestao-bk.svg";
import reds from "../assets/icons/reds_white.svg";
import reds_black from "../assets/icons/reds_black.svg";
import reds_purple from "../assets/icons/reds_purple.svg";
import reds_ads from "../assets/icons/reds-ads.svg";

// eslint-disable-next-line
export default {
  help_black,
  help_purple,
  agenda_purple,
  indicadores_purple,
  biblioteca_purple,
  documentos_purple,
  formacoes_purple,
  lupaPurple,
  plataforma_purple,
  videoteca_purple,
  logout_black,
  agenda_black,
  biblioteca_black,
  documentos_black,
  formacoes_black,
  indicadores_black,
  login_black,
  plataforma_black,
  videoteca_black,
  accessHand,
  increaseFont,
  decreaseFont,
  highContrast,
  highContrast2,
  agenda,
  biblioteca,
  bell,
  bellBlack,
  conheca,
  conheca_black,
  documentos,
  errorIcon,
  eventos,
  formacoes,
  indicadores,
  login,
  logoCanalJSON,
  logoAllAnimated,
  lupa,
  lupaBlack,
  plataforma,
  videoteca,
  wireless,
  loading,
  logoStatic,
  help,
  logout,
  cadeado,
  edit,
  editBlack,
  logoCanalJSONHighContrast,
  wireless_white,
  iconMenu,
  iconGoogle,
  iconAdd,
  iconDelete,
  museum,
  museum_black,
  iconProject_white,
  iconProject_black,
  pnld_black,
  pnld_white,
  item_black,
  item_white,
  pnld,
  musica,
  musica_black,
  cidadeleitora,
  cidadeleitora_black,
  seguranca,
  novo_white,
  novo,
  school_black,
  school_white,
  book,
  book_black,
  book_state,
  book_state_black,
  pen_request,
  pen_request_black,
  btn_download,
  pilha_livros,
  add_livro,
  atividade_justificado,
  atividade_falta,
  atividade_feito,
  acompanhamento_eaprendi,
  acompanhamento_eaprendi_black,
  livros_estado,
  livros_estado_black,
  livro_cadastro,
  livro_cadastro_black,
  formacao_gestao,
  formacao_gestao_black,
  reds,
  reds_black,
  reds_purple,
  reds_ads,
};
