import MontserratLight from "../assets/fonts/Montserrat-Light.TTF";
import MontserratMediumItalic from "../assets/fonts/Montserrat-Medium-Italic.ttf";
import MontserratMedium from "../assets/fonts/Montserrat-Medium.TTF";
import MontserratSemiBold from "../assets/fonts/Montserrat-SemiBold.TTF";
import MontserratRegular from "../assets/fonts/Montserrat-Regular.ttf";
import ShareTechMonoRegular from "../assets/fonts/ShareTechMono-Regular.ttf";
import Montserrat from "../assets/fonts/Montserrat.ttf";
import OpenSans from "../assets/fonts/OpenSans.ttf";

// eslint-disable-next-line
export default {
    MontserratLight,
    MontserratMediumItalic,
    MontserratMedium,
    MontserratSemiBold,
    MontserratRegular,
    Montserrat,
    OpenSans,
    ShareTechMonoRegular,
};
