import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Cover, Title, Description } from "./styles";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images } from "../../../constants";
import Banner from "../../../components/UI/Banner";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";
import ellipsis from "../../../utils/ellipsis";

const LibraryView = () => {

  const { user } = useAuth();

  const { id } = useParams();

  const navigate = useNavigate();

  const [item, setItem] = useState({});

  // const isEditor = (category) => {
  //   let editor = user?.profiles[0]?.editor?.findIndex(
  //     (editor) => editor.id === category
  //   );
  //   return editor > -1 ? true : false;
  // };

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/news",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("LIB", response);
        if (response.data.length > 0) {
          setItem(response.data[0]);
        } else {
          if(user){
            navigate(`/biblioteca`)
          }
          else{
            navigate(`/login/redirect/*biblioteca*${id}`) 
          }
        }
      }
    },
  });

  useEffect(() => {
    loadItem({
      params: {
        id,
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loadItemInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconBiblioteca}
        url={`/biblioteca/news/editar/${item.id}`}
        editorId="f0d87522-fcc7-4f8b-aec3-3e64fce9915a"
      >
        {item.title && ellipsis(item.title, 20)}
      </Banner>
      <ContentBox>
        <Cover
          src={
            item.image
              ? process.env.REACT_APP_CDN_URL +
                "canalPedagogico/libraryNews/" +
                item.image
              : images.iconBiblioteca
          }
        />
        <Title>{item.title}</Title>
        <Description>{item.description}</Description>
        <CancelButton
          text="Voltar"
          onClick={() => {
            navigate(-1);
          }}
        />
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default LibraryView;
