import React, { useState, useEffect } from "react";
import { format } from "date-fns";

import { 
  Container, 
  ContainerTable,
  Table,
} from "./styles";
import useApi from "../../../../hooks/useApi";

const ReportTable = ({
                 id=null,
                 tablevisible=false,
                 loading=()=>{},
              }) => {
  const [listInfoSubscribe, setListInfoSubscribe] = useState({data:[],loaded:false});
  const [listActivities, setListActivities] = useState({data:[],loaded:false});
  const [listUserActivities, setListUserActivities] = useState({data:[],loaded:false});
  const [listJustify, setListJustify] = useState({data:[],loaded:false});
  const [listModule, setListModule] = useState({data:[],loaded:false});
  const [course, setCourse] = useState({data:{},loaded:false});

  const [loadCourse, loadCourseInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("course by user", response.data[0]);
        setCourse({data:response.data[0],loaded:true});
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const [listSubscribe, ListSubscribeInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/subscription/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listSubscribe", response.data);
        setListInfoSubscribe({data:response.data,loaded:true});
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });
  
  const [loadListActivities, LoadListActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listActivities", response.data);
        let module = [];
        response.data.forEach((activity)=>{
          let index = module.findIndex((module)=>{return module.module===activity.module});
          if(index >= 0){
            module[index].size+=1;
          }
          else{
            module.push({module:activity.module,size:1});
          }
        });
        console.log("modulos", module);
        setListModule({data:module,loaded:true});
        setListActivities({data:response.data,loaded:true});
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const [loadListUserActivities, LoadListUserActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/course",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listUserActivities", response.data);
        setListUserActivities({data:response.data,loaded:true});
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const [getListJustify, getListJustifyInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/activitiesJustifications",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listJustify", response.data);
        setListJustify({data:response.data,loaded:true});
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const getLabelJustify = (idJustify) => {
    return listJustify.data.find((justify)=>{return justify.idactivities_justifications===idJustify})?.label;
  };

  const getStatusActivity = (idActivity,idUserSubscription) => {
    let temp = listUserActivities.data.find((activity)=>{return activity.idcontent_activity === idActivity && activity.iduser_subscription===idUserSubscription});
    if(temp){
      if(temp.hours_taken===1){
        return listActivities.data.find((activity)=>{return activity.idContentActivity === idActivity}).activityWorkload;
      }
      if(temp.hours_taken===0){
        return 0;
      }
      if(temp.hours_taken===2){
        return getLabelJustify(temp.idactivities_justifications);
      }
    }
    else{
      return "";
    }
  };

  const getHoursActivity = (idUserSubscription) => {
    let total = 0;
    let cumprido = 0;
    let abonado = 0;
    listActivities.data.forEach((activity)=>{
      total+=activity.activityWorkload;
      let temp = listUserActivities.data.find((userActivity)=>{return userActivity.idcontent_activity === activity.idContentActivity && userActivity.iduser_subscription===idUserSubscription});
      // console.log(`${idUserSubscription} - ${activity.idContentActivity} - total:${total} - cumprido:${cumprido}`);
      if(temp){
        if(temp.hours_taken===1){
          cumprido+=activity.activityWorkload;
        }
        if(temp.hours_taken===2){
          abonado+=activity.activityWorkload;
        }
      }
    });
    return {cumprido,abonado,desconto:total-cumprido-abonado,total};
  };

  useEffect(() => {
    if(tablevisible===true && id!==null){
      setCourse({data:{},loaded:false});
      setListInfoSubscribe({data:[],loaded:false});
      setListModule({data:[],loaded:false});
      setListActivities({data:[],loaded:false});
      setListUserActivities({data:[],loaded:false});
      setListJustify({data:[],loaded:false});

      loadCourse({
        params:{
          id,
        }
      });
      listSubscribe({
        params: {
          id,
        },
      });
      loadListActivities({
        params: {
          idContent:id,
        },
      });
      loadListUserActivities({
        params: {
          idContent:id,
        },
      });
      getListJustify();
    }
    // eslint-disable-next-line
  }, [tablevisible]);

  useEffect(() => {
    loading((loadCourseInfo.loading||ListSubscribeInfo.loading||LoadListActivitiesInfo.loading||LoadListUserActivitiesInfo.loading||getListJustifyInfo.loading));
    // eslint-disable-next-line
  }, [loadCourseInfo, ListSubscribeInfo, LoadListActivitiesInfo, LoadListUserActivitiesInfo, getListJustifyInfo]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [course, listInfoSubscribe, listModule, listActivities, listUserActivities, listJustify]);

  return (
    //Configurar o visible
    <Container visible={tablevisible} >
      {course.loaded && listInfoSubscribe.loaded && listModule.loaded && listActivities.loaded && listUserActivities.loaded && listJustify.loaded && <ContainerTable>
        {/* configurar o visible */}
        <Table id="tableCourses" visible={tablevisible}> 
          {/* capturar dados do Curso */}
          <thead>
          <tr><td colSpan={`${7+listActivities?.data.length}`}>Curso - {course.data?.title} - {course.data?.courseWorkload} horas <br/> Formadores: {course.data?.teacher}</td></tr>
          <tr><td className="spacer"></td></tr>
          <tr>
            <th colSpan="2" rowSpan={listModule.data.length===0?"1":"2"} className="border name">NOME</th>
            <th rowSpan={listModule.data.length===0?"1":"2"} className="border cpf">CPF</th>
            <th rowSpan={listModule.data.length===0?"1":"2"} className="border rgf">RGF</th>
            <th rowSpan={listModule.data.length===0?"1":"2"} className="border email">E-MAIL</th>
            {listModule.data.map((module,index)=>(
              <th colSpan={module.size} className="border activity" key={`Modulo${index}`}>
                {module.module!==null && <>Modulo {module.module===0?"único":module.module}</>}
              </th>
            ))}
            <th rowSpan={listModule.data.length===0?"1":"2"} className="border thc">TOTAL DE HORAS CUMPRIDAS</th>
            <th rowSpan={listModule.data.length===0?"1":"2"} className="border thc">TOTAL DE HORAS ABONADAS</th>
            <th rowSpan={listModule.data.length===0?"1":"2"} className="border hd">HORAS A DESCONTAR (RH)</th>
          </tr>
          <tr>
            {listActivities.data.map((activity)=>(
              <th className="border activity" key={activity.idContentActivity}>
                atividade: {activity.title}<br/>
                ({activity.activityWorkload} horas)<br/>
                {format(parseInt(activity.activityEnd),"dd/MM")}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {listInfoSubscribe.data.map((subscribe,index)=>(
            <tr key={subscribe.idUserSubscription}>
              <th className="border">{index+1}</th>
              <td className="border">{subscribe.name}</td>
              <td className="border">{subscribe.cpf}</td>
              <td className="border">{subscribe.rgf}</td>
              <td className="border">{subscribe.email}</td>
              {listActivities.data.map((activity)=>(
                <td 
                className={`border activity${typeof getStatusActivity(activity.idContentActivity,subscribe.idUserSubscription) === "string" && ` ${getStatusActivity(activity.idContentActivity,subscribe.idUserSubscription)}`}`} 
                key={`${subscribe.idUserSubscription}-${activity.idContentActivity}`}>
                  {getStatusActivity(activity.idContentActivity,subscribe.idUserSubscription)}
                </td>
              ))}
              <td className="border">{getHoursActivity(subscribe.idUserSubscription).cumprido}</td>
              <td className="border">{getHoursActivity(subscribe.idUserSubscription).abonado}</td>
              <td className="border">{getHoursActivity(subscribe.idUserSubscription).desconto}</td>
            </tr>
          ))}
          <tr><td className="spacer"></td></tr>
          {listJustify.data.map((justify)=>(
            <tr><td className={`border ${justify.label}`}>{justify.label}</td><td className="border">{justify.description}</td></tr>
          ))}
          </tbody>
        </Table>
      </ContainerTable>}
    </Container>
  );
};

export default ReportTable;