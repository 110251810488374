import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { startOfDay } from "date-fns";
// import {isBefore, isAfter, format} from "date-fns"
// import { BsTrash } from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { useTheme } from "../../../hooks/useTheme";
import useApi from "../../../hooks/useApi";
// import { useAuth } from "../../../hooks/useAuth";
// import { v4 as uuidv4 } from "uuid";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import {
  Container,
  Title,
  Form,
  Item,
  Controls,
  ListButton,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import InputDate from "../../../components/UI/Inputs/InputDate";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import Modal from "../../../components/UI/Modal";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";

const CourseActivitiesForm = ({ action }) => {
  // const { user } = useAuth();
  const { idcourse, typeregister, id } = useParams();
  const navigate = useNavigate();

  const [courseInfo, setCourseInfo] = useState({});
  const [courseListInfo, setCourseListInfo] = useState([]);
  const [queueList, setQueueList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [modalQueuelist, setModalQueuelist] = useState(false);
  const [retry, setRetry] = useState(0);
  const [modalMessage, setModalMessage] = useState({open:false,text:"",confirm:false,execute:()=>{}});

  const formik = useFormik({
    initialValues: {
      idContentActivity:"",
      idContent: "",
      title: "",
      description: "",
      activityWorkload: 0,
      activityBegin: "",
      activityEnd: "",
      module: 0,
    },
    validationSchema: Yup.object({
      idContent:Yup.string().required("Digite o codigo do curso dessa atividade"),
      title: Yup.string().required("Digite o título."),
      description: Yup.string().required("Digite a descrição."),
      activityWorkload: Yup.number().required("Digite a Carga Horaria da atividade"),      
      activityBegin: Yup.number().test(
        "test-begin",
        "Data de inicio da atividade deve ser menor ou igual da atividade",
        (activityBegin,other)=>{
        const { activityEnd } = other.parent;
        if ((activityBegin > activityEnd)) {
          return false;
        } else {
          return true;
        }
        }).required("Selecione a data de inicio da atividade"),
      activityEnd: Yup.number().test(
        "test-end",
        "Data de fim da atividade deve ser maior ou igual da atividade",
        (activityEnd,other)=>{
        const { activityBegin } = other.parent;
        if ((activityBegin > activityEnd)) {
          return false;
        } else {
          return true;
        }
        }).required("Selecione a data de fim da atividade"),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log("TOSAVE", values);

      if (action === "add"){
        if(typeregister==="segment"){
          let queue = [];
          courseListInfo.forEach((course)=>{
            queue.push({
              queue:createForm(values,course.id),
              status:"aguardando",
              text:course.title,
              erro:0,
            });
          });
          setQueueList(queue);
          setModalQueuelist(true);
        }
        else{
          let form = createForm(values,values.idContent);
          saveItem({
            data:form,
          });
        }
      }

      if (action === "edit"){
        let form = createForm(values,values.idContent);
        editItem({
          data:form,
        });
      }
    },
  });
  
  const createForm = (values,idContent)=>{
    // console.log("Criando json: values:",values);

    let json = {
      idContentActivity: values.idContentActivity,
      idContent: idContent,
      title: values.title,
      description: values.description,
      activityWorkload: values.activityWorkload,
      activityBegin:values.activityBegin,
      activityEnd:values.activityEnd,
      module:values.module,
    };

    return json;
  };

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("saveItem",response);
        setModalMessage({open:true,text:"Atividade criada com sucesso!",confirm:false})
        // navigate(`/coursemanager/${idcourse}`);
      }
    },
    onError: (error)=>{
      setModalMessage({open:true,text:"Erro ao criar a atividade tente novamente",confirm:false})
      console.log("error",error);
    },
  });


  // SaveItem versão com lista de queue
  const [saveItemList, saveItemListInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        let tempList = [];
        queueList.forEach((queue)=>{
          if(queue.status==="iniciado"){
            tempList.push({...queue,status:"pronto"});
          }
          else{
            tempList.push(queue);
          }
        });
        setQueueList(tempList);
      }
    },
    onError: (error)=>{
      let tempList = [];
      queueList.forEach((queue)=>{
        if(queue.status==="iniciado"){
          tempList.push({...queue,status:"Erro",erro:queue.erro+1});
        }
        else{
          tempList.push(queue);
        }
      });
      setQueueList(tempList);
    },
  });

  useEffect(()=>{
    setDisabled(true);
    // console.log("queueList",queueList);
    let pending = queueList.filter((queue)=>{return queue.status === "aguardando" })
    let ready = queueList.filter((queue)=>{return queue.status === "iniciado" })
    if(ready.length>0){
      setTimeout(()=>{
        saveItemList({
          data:ready[0].queue,
        });
      },2000);
    }
    else{
      if(pending.length>0){
        let tempList = [];
        let firstReady=true;
        queueList.forEach((queue)=>{
          if(queue.status==="aguardando" && firstReady){
            tempList.push({...queue,status:"iniciado"})
            firstReady=false;
          }
          else{
            tempList.push(queue)
          }
        });
        setQueueList(tempList);
      }
      else{
        setRetry(retry+1);  
      }
    }
    // eslint-disable-next-line
  },[queueList]);

  useEffect(()=>{
    if(retry<=3 && queueList.filter((queue)=>{return queue.status === "Erro" }).length>0){
      let tempList = [];
      queueList.forEach((queue)=>{
        if(queue.status==="Erro"){
          tempList.push({...queue,status:"aguardando"})
        }
        else{
          tempList.push(queue)
        }
      });
      setTimeout(()=>{
        setQueueList(tempList);
      },5000);
    }
    else{
      setDisabled(false);
    }
    // eslint-disable-next-line
  },[retry]);

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("editItem",response);
        setModalMessage({open:true,text:"Atividade editada com sucesso!",confirm:false})
        // navigate(`/coursemanager/${idcourse}`);
      }
    },
    onError: (error)=>{
      setModalMessage({open:true,text:"Erro ao atualizar a atividade tente novamente",confirm:false})
      console.log("error",error);
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities/oneActivity",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("load", response);
        if (response.data.length > 0) {
          formik.setFieldValue("idContentActivity", response.data[0].idContentActivity);
          formik.setFieldValue("idContent", response.data[0].idContent);
          formik.setFieldValue("module", response.data[0].module);
          formik.setFieldValue("title", response.data[0].title);
          formik.setFieldValue("description", response.data[0].description);
          formik.setFieldValue("activityWorkload", response.data[0].activityWorkload);    
          formik.setFieldValue("activityBegin", startOfDay(new Date(checkInt(response.data[0].activityBegin))).getTime());    
          formik.setFieldValue("activityEnd", startOfDay(new Date(checkInt(response.data[0].activityEnd))).getTime());    
        }
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate(`/coursemanager/${idcourse}`);
        // console.log("deletou", response);
      }
    },
  });

  const [loadCourse, loadCourseInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("course by user", response.data[0]);
        setCourseInfo(response.data[0]);
        if(!(response.data[0].isEditor||response.data[0].isTutor)){
          navigate(`/home2`);
        }
      }
    },
  });

  const [loadCourseList, loadCourseListInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("course by user List Segment", response.data);
        setCourseListInfo(response.data);
        if(!(response.data[0].isEditor)){
          navigate(`/home2`);
        }
      }
    },
  });

  const handleDelete = (itemId) => {
    setModalMessage({
      open:true,
      text:"Deseja apagar essa atividade?",
      confirm:true,
      execute:()=>{
        deleteItem({
                    data: {
                            idContent:idcourse,
                            idContentActivity:id,
                          },
                  });
      }
    });
  };

  const checkInt = (value) => {
    if (value !== "" && !isNaN(value)) {
      return parseInt(value);
    }
    return value;
  };

  useEffect(() => {
    if (action === "add"){
      loadCourse({
        params:{
          id:idcourse,
        }
      });
      
      formik.setFieldValue("idContent", idcourse);
    }
    
    if(action==="edit"){
      loadItem({
        params:{
          idContent:idcourse,
          idContentActivity:id,
        }
      });
    }
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(typeregister==="segment"){
      loadCourseList({
        params:{
          idCategory:courseInfo.segment,
        }
      });
    }
    // eslint-disable-next-line
  }, [courseInfo]);

  const clearForm = ()=>{
    formik.setFieldValue("idContentActivity", "");
    formik.setFieldValue("idContent", idcourse);
    formik.setFieldValue("title", "");
    formik.setFieldValue("description", "");
    formik.setFieldValue("activityWorkload", 0);    
    formik.setFieldValue("activityBegin", "");    
    formik.setFieldValue("activityEnd", "");
    formik.setFieldValue("module", 0);
  };

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading ||
        saveItemListInfo.loading ||
        loadCourseInfo.loading ||
        loadCourseListInfo.loading) && <FullScreenLoading />}
      {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>
          {typeregister==="course" && <>Criar atividade para o curso: {courseInfo.title}</>}
          {typeregister==="segment" && <>Criar atividade para o segmento: {courseInfo.categoryName}</>}
        </Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}

          <Item>
            <InputText
              placeholder="Modulo (0=modulo único)"
              columns={20}
              name="module"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.module}
              error={
                formik.touched.module &&
                formik.errors.module &&
                formik.errors.module
              }
            />
          </Item>
          
          <Item>
            <InputText
              placeholder="Titulo da atividade"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>

          <Item>
            <InputText
              placeholder="Descrição"
              columns={90}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description &&
                formik.errors.description &&
                formik.errors.description
              }
            />
          </Item>

          <Item>
            <InputText
              placeholder="Carga Horária"
              columns={20}
              name="activityWorkload"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.activityWorkload}
              error={
                formik.touched.activityWorkload &&
                formik.errors.activityWorkload &&
                formik.errors.activityWorkload
              }
            />
          </Item>

          <Item>
            <InputDate
              label="Inicio das atividades"
              name={`activityBegin`}
              value={checkInt(formik.values.activityBegin)}
              setField={formik.setFieldValue}
              // onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              // isArray="meetings"
              error={
                formik.errors.activityBegin
              }
            />
            <InputDate
              label="Fim das atividades"
              name={`activityEnd`}
              value={checkInt(formik.values.activityEnd)}
              setField={formik.setFieldValue}
              // onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              // isArray="meetings"
              error={
                formik.errors.activityEnd
              }
            />
          </Item>

          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
        <Modal open={modalQueuelist} buttonClose={false}>
          {queueList.map((queue)=>(<div key={queue.text}>Criando atividade para o Curso: {queue.text} - Status: {queue.status}{queue.status==="Erro" && <>({queue.erro}/3)</>}</div>))}
          <ListButton>
              <ReadMoreButton
                text="Cadastrar nova Atividade"
                type="button"
                onClick={()=>{
                  clearForm();
                  setModalQueuelist(false);
                }}
                disabled={disabled}
              />
              <ReadMoreButton
                text="Finalizar cadastro de Atividades"
                type="button"
                onClick={()=>{navigate(`/coursemanager/${idcourse}`);}}
                disabled={disabled}
              />
            </ListButton>
        </Modal>
        <Modal open={modalMessage.open} buttonClose={false}>
          <div>{modalMessage.text}</div>
          {modalMessage.confirm ? <>
            <ListButton>
              <ReadMoreButton
                text="Sim"
                type="button"
                onClick={()=>{modalMessage.execute();}}
              />
              <ReadMoreButton
                text="Não"
                type="button"
                onClick={()=>{setModalMessage({open:false,text:"",confirm:false,execute:()=>{}});}}
              />
            </ListButton>
          </>:<>
          {action === "add" && <>
            <ListButton>
              <ReadMoreButton
                text="Cadastrar nova Atividade"
                type="button"
                onClick={()=>{
                  clearForm();
                  setModalMessage({open:false,text:"",confirm:false,execute:()=>{}});
                }}
              />
              <ReadMoreButton
                text="Finalizar cadastro de Atividades"
                type="button"
                onClick={()=>{navigate(`/coursemanager/${idcourse}`);}}
              />
            </ListButton>
          </>}
          {action==="edit" && <>
            <ReadMoreButton
              text="Ok"
              type="button"
              onClick={()=>{navigate(`/coursemanager/${idcourse}`);}}
            />
          </>}
          </>}
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CourseActivitiesForm;
