import React, { useEffect, useState } from "react";
import { startOfDay } from "date-fns";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { BsTrash } from "react-icons/bs";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import filesize from "filesize";
import { useTheme } from "../../../hooks/useTheme";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import {
  Container,
  Title,
  Form,
  Item,
  Place,
  Controls,
  Separator,
  DocumentContainer,
  DocumentFile,
  DocumentUrl,
  DocumentName,
  DocumentSize,
  DocumentDeleteButton,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputCheck from "../../../components/UI/Inputs/InputCheck";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import InputRadio from "../../../components/UI/Inputs/InputRadio";
import InputFile from "../../../components/UI/Inputs/InputFile";
import InputDate from "../../../components/UI/Inputs/InputDate";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";

const begin = startOfDay(new Date()).getTime();
const end = startOfDay(new Date()).getTime();

const TestsForm = ({ action }) => {
  // let today = new Date();
  // today.setHours(0, 0, 0, 0);
  // let tomorrow = new Date();
  // tomorrow.setDate(tomorrow.getDate() + 1);
  // tomorrow.setHours(0, 0, 0, 0);

  // const end = tomorrow.getTime();
  const { themeFile } = useTheme();
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);
  const [visibilities, setVisibilities] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      testType: "",
      begin,
      end,
      formType: "public",
      title: "",
      platform: "",
      tips: [],
      documents: [],
      deletedIds: [],
      visibilities: [],
    },
    validationSchema: Yup.object({
      formType: Yup.string().required("Escolha uma opção!"),
      title: Yup.string().required("Digite um título!"),
      platform: Yup.string().required(
        "Digite a plataforma onde será realizada a avaliação!"
      ),
      // begin: Yup.number()
      //   .required("Selecione a data final.")
      //   .lessThan(
      //     Yup.ref("end"),
      //     "Data de início da avaliação não pode ser maior que a data final."
      //   ),
      // end: Yup.number()
      //   .required("Selecione a data final.")
      //   .moreThan(
      //     Yup.ref("begin"),
      //     "Data de término da avaliação não pode ser menor que a data final."
      //   ),
      begin: Yup.mixed()
        .test(
          "test-begin",
          "Data inicial deve ser maior que a data final",
          function checkEnd(begin) {
            console.log("begin-begin", begin);
            const { end } = this.parent;
            console.log("begin-end", end);
            if (begin <= end) {
              return true;
            } else {
              return false;
            }
          }
        )
        .required("Insira a data"),
      end: Yup.mixed()
        .test(
          "test-end",
          "Data final deve ser maior que a data inicial",
          function checkEnd(end) {
            console.log("end-end", end);
            const { begin } = this.parent;
            console.log("end-begin", begin);
            if (end >= begin) {
              return true;
            } else {
              return false;
            }
          }
        )
        .required("Insira a data"),
      visibilities: Yup.array().when("formType", {
        is: "private",
        then: Yup.array().min(1, "Selecione 1"),
      }),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      let auxArray = [];
      if (formik.values.documents.length > 0) {
        formik.values.documents.map((doc) => {
          const newAux = {
            originalName: doc.originalName,
            category: "documents",
          };
          return auxArray.push(newAux);
        });
      }
      if (formik.values.tips.length > 0) {
        formik.values.tips.map((doc) => {
          const newAux = {
            originalName: doc.originalName,
            category: "tips",
          };
          return auxArray.push(newAux);
        });
      }
      // console.log(auxArray);
      const form = new FormData();
      form.append("title", values.title);
      form.append("id", values.id);
      form.append("platform", values.platform);
      form.append("begin", values.begin);
      form.append("end", values.end);
      form.append("testType", values.testType);
      form.append("formType", values.formType);
      values.documents.map((item) => {
        return form.append("file", item.file);
      });
      values.tips.map((item) => {
        return form.append("file", item.file);
      });
      form.append("visibilities", JSON.stringify(values.visibilities));
      form.append("deletedIds", JSON.stringify(values.deletedIds));
      form.append("auxArray", JSON.stringify(auxArray));

      if (action === "add") {
        saveItem({
          data: form,
        });
      } else {
        editItem({
          data: form,
        });
      }
    },
  });

  // console.log(formik.errors);
  // console.log(formik.isValid);

  const handleFileType = (fileType) => {
    switch (fileType[0]) {
      case "image":
        return {
          type: "image",
          extension: fileType[1],
        };
      case "application":
        if (fileType[1] === "pdf") {
          return {
            type: "pdf",
            extension: fileType[1],
          };
        }
        break;
      case "video":
        return {
          type: "video",
          extension: fileType[1],
        };

      default:
        break;
    }
  };

  const handleAttachFiles = (event, type) => {
    const file = event.target.files[0];

    // console.log(type);

    const existsOnTips = formik.values.tips.filter(
      (item) => item.originalName === file.name
    );

    const existsOnDocs = formik.values.documents.filter(
      (item) => item.originalName === file.name
    );

    if (existsOnTips.length > 0 || existsOnDocs.length > 0) return false;

    const fileType = handleFileType(file.type.split("/"));
    event.target.value = null;

    let newName =
      type === "tips"
        ? `orientacoes_${uuidv4()}.${fileType.extension}`
        : `material_${uuidv4()}.${fileType.extension}`;

    const newFile = {
      id: uuidv4(),
      originalName: file.name,
      newName,
      file,
      sizeInBytes: file.size,
      formatSize: filesize(file.size),
      fileType,
    };

    let newArray =
      type === "tips" ? [...formik.values.tips] : [...formik.values.documents];
    newArray.push(newFile);
    // console.log(newArray);
    formik.setFieldValue(type === "tips" ? "tips" : "documents", newArray);
  };

  const handleDetachFiles = (fileId, type) => {
    if (type === "tips") {
      const newArray = [
        ...formik.values.tips.filter((item) => item.id !== fileId),
      ];
      formik.setFieldValue("tips", newArray);
      if (action === "edit") {
        const deletedIds = [...formik.values.deletedIds, fileId];
        formik.setFieldValue("deletedIds", deletedIds);
      }
    } else {
      const newArray = [
        ...formik.values.documents.filter((item) => item.id !== fileId),
      ];
      formik.setFieldValue("documents", newArray);
      if (action === "edit") {
        const deletedIds = [...formik.values.deletedIds, fileId];
        formik.setFieldValue("deletedIds", deletedIds);
      }
    }
  };

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/evaluation",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/avaliacoes");
        // console.log("deletou", response);
      }
    },
  });

  const handleDelete = (itemId) => {
    const confirm = window.confirm("Deseja apagar esse item?");

    if (confirm) {
      deleteItem({
        data: {
          id: itemId,
        },
      });
    }
  };

  const [loadVisibilities, loadVisibilitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/visibility",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        setVisibilities(response.data);
      }
    },
  });

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    baseURL: process.env.REACT_APP_CDN_API,
    multi: true,
    url: "canalPedagogico/evaluation",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("salvou", response);
        navigate("/avaliacoes");
      }
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    baseURL: process.env.REACT_APP_CDN_API,
    multi: true,
    url: "canalPedagogico/evaluation",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("editou", response);
        navigate("/avaliacoes");
      }
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/evaluation",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("load", response);
        if (response.data.length > 0) {
          // new visibilities
          let vis = [];
          if (response.data[0].visibilities.length > 0) {
            response.data[0].visibilities.forEach((oldVis) => {
              vis.push(oldVis.idvisibility);
            });
          }
          const initial = {
            ...response.data[0],
            deletedIds: [],
            visibilities: vis,
          };
          formik.setValues(initial);
        } else {
          navigate("/avaliacoes/add");
        }
      }
    },
  });

  const [loadCategories, loadCategoriesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/category",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        setCategories(response.data);
      }
    },
  });

  const checkInt = (value) => {
    if (value !== "" && !isNaN(value)) {
      return parseInt(value);
    }
    return value;
  };

  useEffect(() => {
    // formik.setFieldValue("begin", begin);
    // formik.setFieldValue("end", end);
    async function load() {
      await loadCategories({
        params: {
          id: "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3",
          idUserHasProfile: user.profiles[0].idUserHasProfile,
        },
      });
      await loadVisibilities();
      if (action === "edit") {
        await loadItem({
          params: {
            id,
          },
        });
      }
    }
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading ||
        loadVisibilitiesInfo.loading ||
        loadCategoriesInfo.loading) && <FullScreenLoading />}
      {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ||
        saveItemInfo.status ||
        loadVisibilitiesInfo.status ||
        loadCategoriesInfo.status) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>Avaliações</Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}
          <Item>
            {categories.length > 0 &&
              categories.map((cat) => (
                <InputRadio
                  key={cat.id}
                  id={cat.id}
                  name="testType"
                  text={cat.category}
                  onChange={formik.getFieldProps("testType").onChange}
                  value={cat.id}
                  checked={formik.getFieldProps("testType").value === cat.id}
                />
              ))}
          </Item>
          <Item>
            <InputDate
              label="Início"
              name="begin"
              value={checkInt(formik.values.begin)}
              setField={formik.setFieldValue}
              // onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                // formik.touched.begin &&
                formik.errors.begin && formik.errors.begin
              }
            />
            <InputDate
              label="Fim"
              name="end"
              value={checkInt(formik.values.end)}
              setField={formik.setFieldValue}
              // onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                // formik.touched.end &&
                formik.errors.end && formik.errors.end
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Título"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Plataforma"
              columns={90}
              name="platform"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.platform}
              error={
                formik.touched.platform &&
                formik.errors.platform &&
                formik.errors.platform
              }
            />
          </Item>
          <Item>
            <Place>Orientações</Place>
            <InputFile
              accept="*"
              id="tips"
              text="Anexar"
              onChange={(event) => handleAttachFiles(event, "tips")}
            />
            {formik.values.tips.length > 0 && (
              <DocumentContainer>
                {formik.values.tips.map((tip) => (
                  <DocumentFile key={tip.id}>
                    {tip.url ? (
                      <DocumentUrl
                        href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/evaluation/${tip.url}`}
                        target="_blank"
                      >
                        {tip.originalName}
                      </DocumentUrl>
                    ) : (
                      <DocumentName>{tip.originalName}</DocumentName>
                    )}
                    <DocumentSize>{tip.formatSize}</DocumentSize>
                    <DocumentDeleteButton
                      onClick={() => handleDetachFiles(tip.id, "tips")}
                    >
                      <BsTrash
                        size={themeFile.SIZES.base * 22}
                        color={themeFile.COLORS.hardRed}
                      />
                    </DocumentDeleteButton>
                  </DocumentFile>
                ))}
              </DocumentContainer>
            )}
          </Item>
          <Separator />
          <Item>
            <Place>Materiais de Apoio</Place>
            <InputFile
              accept="*"
              id="documents"
              text="Anexar"
              onChange={(event) => handleAttachFiles(event, "documents")}
            />
            {formik.values.documents.length > 0 && (
              <DocumentContainer>
                {formik.values.documents.map((doc) => (
                  <DocumentFile key={doc.id}>
                    {doc.url ? (
                      <DocumentUrl
                        href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/evaluation/${doc.url}`}
                        target="_blank"
                      >
                        {doc.originalName}
                      </DocumentUrl>
                    ) : (
                      <DocumentName>{doc.originalName}</DocumentName>
                    )}
                    <DocumentSize>{doc.formatSize}</DocumentSize>
                    <DocumentDeleteButton
                      onClick={() => handleDetachFiles(doc.id, "documents")}
                    >
                      <BsTrash
                        size={themeFile.SIZES.base * 22}
                        color={themeFile.COLORS.hardRed}
                      />
                    </DocumentDeleteButton>
                  </DocumentFile>
                ))}
              </DocumentContainer>
            )}
          </Item>
          <Item>
            <Place>Visibilidade</Place>
            <InputRadio
              id="public"
              name="formType"
              text="Público"
              onChange={formik.getFieldProps("formType").onChange}
              value="public"
              checked={formik.getFieldProps("formType").value === "public"}
            />
            <InputRadio
              id="private"
              name="formType"
              text="Restrito"
              onChange={formik.getFieldProps("formType").onChange}
              value="private"
              checked={formik.getFieldProps("formType").value === "private"}
            />
          </Item>
          {formik.values.formType === "private" && (
            <Item>
              {visibilities.length > 0 &&
                visibilities.map((vis) => (
                  <InputCheck
                    id={vis.id}
                    key={vis.id}
                    value={vis.id}
                    name="visibilities"
                    text={vis.description}
                    onChange={formik.handleChange}
                    checked={formik.values.visibilities.includes(vis.id)}
                  />
                ))}
            </Item>
          )}
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default TestsForm;
