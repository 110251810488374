import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-align: justify;
  font: var(--body);
`;

export const ButtonAcessar = styled.b`
  display: flex;
  font: var(--body);
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font: var(--title);
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: justify;
  line-height: 0.8rem;
  font-family: var(--familyBody);
  font-size: calc(var(--size) * 1.3);
`;

export const Map = styled.iframe`
  width: 100%;
  max-width: 45rem;
  height: 25rem;
  border-radius: 1rem;
  margin: 1rem;
`;

export const Video = styled.video`
display: flex;
flex-direction:row;
align-items:center;
justify-content:center;
flex-wrap:nowrap;
border-radius: 1rem;
border: solid 0.1rem;
max-width: 100%;
`

export const LinkDoc = styled.a`
  transition: transform 0.3s ease;
  cursor: pointer;
  font-size: calc(var(--size) * 2.3);
  font: var(--body);
  outline: none;
  text-decoration: none;
  color: var(--primary);

  :hover {
    transform: scale(0.9);
  }
`

export const ViewImages = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  gap: 3rem;
  padding: 2rem 0 2rem 0;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const Images = styled.img`
  width: calc(var(--size) * 40);
  border-radius: 1rem;
  border: solid 0.1rem;
`
