import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format, isAfter, isBefore } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/";
import {
  Feed,
  Thumb,
  // Thumbnail,
  Info,
  InfoHeader,
  InfoTitle,
  InfoDate,
  InfoText,
  IconEdit,
  BoxButtons,
  // BoxButtons2,
  Message,
} from "./styles";
import { icons } from "../../../constants";
import ReadMoreButton from "../ReadMoreButton";
// import DeleteButton from "../DeleteButton";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import { useTheme } from "../../../hooks/useTheme";

import Modal from "../Modal"

const NewsItem = ({
  id,
  title,
  date,
  begin,
  end,
  local,
  text,
  url,
  editorId = false,
  mainCategory,
  subscriptionByChannel = false,
  subscriptionEnabled = null,
  idUserSubscription = null,
  subscriptionStatusMessage = "",
  subscriptionBegin,
  subscriptionEnd,
  titleText,
  subscriptionCancel = ()=>{}
}) => {
  const { user } = useAuth();

  // const location = useLocation();

  const navigate = useNavigate();

  const { themeFile } = useTheme();

  const [isEditor, setIsEditor] = useState(false);

  const [subscriptionText, setSubscriptionText] = useState("");

  const [ModalOpenAlert, setModalOpenAlert] = useState(false);
  const [ModalOpenUnsubscribe, setModalOpenUnsubscribe] = useState(false);

  const handleView = () => {
    navigate(`/formacoes/${id}`);
  };

  const handleModalAlert = () => {
    setModalOpenAlert((oldModalOpenAlert) => !oldModalOpenAlert);
  };

  const handleModalUnsubscribe = () => {
    // console.log("ModalOpenUnsubscribe",ModalOpenUnsubscribe);
    setModalOpenUnsubscribe((oldModalOpenUnsubscribe) => !oldModalOpenUnsubscribe);
  };

  

  const handleSubscription = () => {
    if(subscriptionText === "Fazer inscrição"){
      navigate(`/inscricaoformacoes/${id}`);  
    }
    if(subscriptionText === "Inscrição indisponível"){
      // alert(subscriptionStatusMessage);
      handleModalAlert();
    }
    if(subscriptionText === "Excluir inscrição"){
      // console.log("excluir inscrição");
      if(idUserSubscription){
        handleModalUnsubscribe();
      }
    }
  };

  const handleUnsubscribe = ()=>{
    handleModalUnsubscribe();
    if(idUserSubscription){
      subscriptionCancel(idUserSubscription);
    }
  };

  const handleIcon = (categoryName) => {
    switch (categoryName) {
      case "Agenda":
        return themeFile.COLORS.highContrast
          ? icons.agenda
          : icons.agenda_purple;
      case "Formações":
        return themeFile.COLORS.highContrast
          ? icons.formacoes
          : icons.formacoes_purple;
      case "Avaliações":
        return themeFile.COLORS.highContrast
          ? icons.indicadores
          : icons.indicadores_purple;
      case "Biblioteca":
        return themeFile.COLORS.highContrast
          ? icons.biblioteca
          : icons.biblioteca_purple;
      default:
        break;
    }
  };

  useEffect(() => {
    if (user) {
      const editor = user?.profiles[0]?.editor?.findIndex(
        (editor) => editor.id === editorId
      );
      setIsEditor(editor > -1 ? true : false);
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if(subscriptionEnabled){
      if(!(isAfter(new Date(), new Date(parseInt(subscriptionEnd)))) && !(isBefore(new Date(), new Date(parseInt(subscriptionBegin))))){
        setSubscriptionText("Fazer inscrição");
      }
      else{
        setSubscriptionText("Inscrição indisponível");  
      }
    }
    else{
      setSubscriptionText("Inscrição indisponível");
      if(idUserSubscription){
        if(isAfter(new Date(), new Date(parseInt(subscriptionEnd)))){
          setSubscriptionText("Inscrito");
        }
        else{
          setSubscriptionText("Excluir inscrição");
        }
      }
    }
    //eslint-disable-next-line
  }, []);

  const typeSub = () => {
    if(subscriptionText === "Inscrição indisponível"){
      return 0;
    }
    if(subscriptionText === "Fazer inscrição"){
      return 1;
    }
    if(subscriptionText === "Inscrito"){
      return 2;
    }
    if(subscriptionText === "Excluir inscrição"){
      return 2;
    }
  }

  return (
    <Feed type={typeSub()}>
      <Thumb src={handleIcon(mainCategory)} alt="Feed" />
      <Info>
        <InfoHeader type={typeSub()}>
          <InfoTitle>{title}</InfoTitle>
          {date && (
            <InfoDate>
              {`${" Início do curso: "} ${format(parseInt(date), "dd/MM/yyyy", {
                locale: ptBR,
              })}`}
              {/* {begin &&
                `${" - "} ${format(parseInt(begin), "HH'h'mm", {
                  locale: ptBR,
                })}`}
              {end &&
                `${" até "} ${format(parseInt(end), "HH'h'mm", {
                  locale: ptBR,
                })}`}
              {(local.indexOf("http") !== -1) ? <> - Link: <Link to={local}> {local}</Link></> : ` - ${local}`} */}
            </InfoDate>
          )}
        </InfoHeader>
        <InfoText>{text}</InfoText>
      </Info>
      <BoxButtons>
        {isEditor && (
          <Link to={url}>
            <IconEdit src={icons.editBlack} />
          </Link>
        )}
        <ReadMoreButton onClick={handleView} text="+ Leia Mais" />
        {(user && !(isEditor) && subscriptionByChannel) && <ReadMoreButton onClick={handleSubscription} text={subscriptionText} alert={subscriptionText==="Inscrição indisponível"}/>}
      </BoxButtons>
      <Modal  open={ModalOpenAlert} close={handleModalAlert} buttonClose = {false} width={50} height={15}>
          <Message>
            <div>{subscriptionStatusMessage}</div>
            <ReadMoreButton onClick={handleModalAlert} text="Ok" />
          </Message>
      </Modal>
      <Modal  open={ModalOpenUnsubscribe} close={handleModalUnsubscribe} buttonClose = {false} width={100} height={15}>
          <Message>
            <div>Deseja mesmo cancelar sua inscrição em: {titleText} ?</div>
            <div>
              <ReadMoreButton text="SIM" onClick={handleUnsubscribe}/>
              <ReadMoreButton text="NÃO" onClick={handleModalUnsubscribe}/>
            </div>
          </Message>
      </Modal>
    </Feed>
  );
};

export default NewsItem;
