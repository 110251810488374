import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  width: calc(var(--size) * 24);
  max-width: 100%;

  --meridiem-bg-color: var(--purple);
  --meridiem-text-color: var(--purple);
  --top-selected-color: var(--purple);
  --hand-line-color: var(--softPurple);
  --hand-circle-center: var(--softPurple);
  --hand-circle-outer: var(--softPurple);
  --hand-minute-circle: var(--purple);
  --main-font-family: var(--familyBody);
  --numbers-text-color: var(--black);

  .react-timekeeper {
    width: calc(var(--size) * 24) !important;
  }
`;

export const Clock = styled.div`
  ${(props) =>
    props.isOpen
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `};
  right: 0;
  justify-content: center;
  margin-bottom: 0.2rem;
  /* font-size: calc(var(--size) * ${(props) => props.calendarFontSize}); */
`;

export const DateInput = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: calc(var(--size) * 2); */
  background-color: var(--white);
  font-size: calc(var(--size) * ${(props) => props.fontSize});
  width: 100%;
  border: calc(var(--size) * 0.1) solid var(--primary);
  border-radius: 1rem;
  background-color: var(--secondary);
  overflow: hidden;
`;

export const SelectDate = styled.input`
  color: var(--${(props) => (props.dateSet ? "black" : "gray")});
  padding: 1rem;
  font: var(--body);
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  z-index: 1;
  border: 0;
`;

export const DateIcon = styled.div`
  align-items: center;
  justify-content: center;
  padding: calc(var(--size) * 1);
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 5;
  transform: translateY(-50%);

  .remove {
    color: red;
    margin-right: 1rem;
  }
`;

export const DateLabel = styled.div`
  font: var(--body);
  color: var(--primary);
  margin: 0;
  padding: 0.3rem;
`;

export const InfoMessage = styled.span`
  width: 100%;
  color: var(--hardRed);
  margin: calc(var(--size) * 0.5);
  font: var(--body);
`;
