import React, { useEffect, useState } from "react";
import { format, subMonths, startOfMonth, isBefore } from "date-fns";
import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Search,
  IconBox,
  ListContent,
  ListItem,
  Info,
  Button,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

import { icons } from "../../constants";

const Home = () => {
  const [search, setSearch] = useState("");
  const [listNews, setListNews] = useState([]);
  const { user } = useAuth();

  // eslint-disable-next-line
  const [loadNovidades, loadNovidadesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/search/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        response.data.data.forEach((mainCategory) => {
          mainCategory.open = false;
          mainCategory.new = false;
          // console.log("mainCategory.categories",mainCategory.categories)
          mainCategory.categories.forEach((category)=>{
            category.open = false;
            category.new = false;
            category.contents?.forEach((item)=>{
              if(isBefore(new Date(user.dados.lastAccess),new Date(item.updated_at))){
                category.new = true;
                mainCategory.new = true;
                item.new = true;
              }
            });
            category.segments?.forEach((item)=>{
              if(isBefore(new Date(user.dados.lastAccess),new Date(item.updated_at))){
                category.new = true;
                mainCategory.new = true;
                item.new = true;
              }
            });
            category.segments?.forEach((segment)=>{
              segment.new = false;
              segment.open = false;
              segment.contents.forEach((item)=>{
                if(isBefore(new Date(user.dados.lastAccess),new Date(item.updated_at))){
                  category.new = true;
                  mainCategory.new = true;
                  segment.new = true;
                  item.new = true;
                }
              });
            });
          });
        });
        console.log("Novidades: ", response.data);
        setListNews(response.data.data); 
      }
    },
  });

  const verifyMainCategory = (mainCategory) => {
    return mainCategory!=="f0d87522-fcc7-4f8b-aec3-3e64fce9915a" && mainCategory!=="ff1be56d-68f5-4e45-8af5-85cb9bcd804b";
  }

  const handleControl = (controlMC, controlC="", controlS="") => {
    // console.log("Controle de categoria",{controlMC,controlC,controlS})
    let newList = [];
    listNews.forEach((mainCategory) => {
      if(mainCategory.mainCategory === controlMC){
        if(controlC===""){
          mainCategory.open = !mainCategory.open;  
        }
      }
      else{
        mainCategory.open = false;
      }
      mainCategory.categories.forEach((category)=>{
        if(category.category===controlC){
          if(controlS===""){
            category.open = !category.open;
          }
        }else{
          category.open = false;
        }
        category.segments?.forEach((segment)=>{
          if(segment.segment===controlS){
            segment.open = !segment.open;
          }
          else{
            segment.open = false;
          }
        });
      });
      newList.push(mainCategory);
    });
    setListNews(newList); 
  };

  const handleSearch = (event)=>{
    setSearch(event.target.value);
  }

  const navigate = useNavigate();

  const handleView = (category,id) => {
    switch(category){
      case "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3":
        navigate(`/avaliacoes/${id}`);
        break;
      case "0e3b686a-9b75-4de0-a2b7-28ec578e8119":
        navigate(`/formacoes/${id}`);
        break;
      case "46150c88-042a-4220-b81e-bb6c399fa275":
        navigate(`/documentos/${id}`);
        break;
      case "890798c6-a166-4b87-a059-11d4bbf6a1ac":
        navigate(`/agenda/${id}`);
        break;
      case "f0d87522-fcc7-4f8b-aec3-3e64fce9915a":
        navigate(`/biblioteca/${id}`);
        break;
      case "ff1be56d-68f5-4e45-8af5-85cb9bcd804b":
        navigate(`/videoteca/${id}`);
        break;  
      default :
        alert("Categoria não reconhecida pelo sistema");
        break;
    }
  };

  useEffect(() => {
    loadNovidades({
      params: {
        idUserHasProfile:user.profiles[0]?.idUserHasProfile,
        // idCategory:"",
        // limit:0,
        // initial:0,
        lastAccess:subMonths(startOfMonth(new Date()),1),
      },
    });
    console.log("User",user);
    // console.log("Last Access", new Date(user.dados.lastAccess))
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Menu />
      <Banner>
        Novidades
      </Banner>

      <Search>
        <InputSearch
          value={search}
          onChange={handleSearch}
          onClick={handleSearch}
        />
      </Search>

      <ContentBox>
        {listNews.map((mainCategory)=>(
          <ListContent key={mainCategory.mainCategory} visible={true}>
            <ListItem key={mainCategory.mainCategory} onClick={()=>{handleControl(mainCategory.mainCategory)}} visible={true} reduct={0} roundborder={true} selected={mainCategory.open}>
              {mainCategory.mainCategoryName}{mainCategory.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}{mainCategory.new && (mainCategory.open?<IconBox><img src={icons.novo} alt="novo"/></IconBox>:<IconBox><img src={icons.novo_white} alt="novo"/></IconBox>)}
            </ListItem>
            {mainCategory.categories.map((category)=>(
              <ListContent key={category.category} visible={verifyMainCategory(mainCategory.mainCategory)?mainCategory.open:true}>
                {verifyMainCategory(mainCategory.mainCategory) && 
                  <ListItem key={category.category} onClick={()=>{handleControl(mainCategory.mainCategory,category.category)}} visible={mainCategory.open} reduct={5} roundborder={true} selected={category.open}>
                    {category.categoryName}{category.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}{category.new && (category.open?<IconBox><img src={icons.novo} alt="novo"/></IconBox>:<IconBox><img src={icons.novo_white} alt="novo"/></IconBox>)}
                  </ListItem>}
                {category.contents?.map((conteudo)=>(
                  <ListItem key={conteudo.id} visible={verifyMainCategory(mainCategory.mainCategory)?category.open:mainCategory.open} reduct={10} roundborder={false} selected={false} notpointer={true}>
                    <Info>
                      <title>{conteudo.title}{conteudo.new && <IconBox><img src={icons.novo_white} alt="novo"/></IconBox>}</title>
                      {mainCategory.mainCategory==="0d8bd0d9-47f5-4bf6-9ddd-044861e242a3" && <p>Período de aplicação: {format(new Date(conteudo.begin),"dd/MM/yyyy")} - {format(new Date(conteudo.end),"dd/MM/yyyy")}</p>}                      
                      {mainCategory.mainCategory==="890798c6-a166-4b87-a059-11d4bbf6a1ac" && <p>Data: {format(new Date(conteudo.eventDate),"dd/MM/yyyy")}</p>}
                      {mainCategory.mainCategory==="890798c6-a166-4b87-a059-11d4bbf6a1ac" && <p>Horario: {format(new Date(conteudo.begin),"HH:mm")} - {format(new Date(conteudo.end),"HH:mm")}</p>}
                    </Info>
                    <Button>
                      <ReadMoreButton onClick={()=>{handleView(mainCategory.mainCategory,conteudo.id)}} text="Leia +" />
                    </Button>
                    {/* {conteudo.title}{conteudo.new && <IconBox><img src={icons.novo_white}/></IconBox>} */}
                  </ListItem>
                ))}
                {category.segments?.map((segmento)=>(
                  <ListContent key={segmento.segment} visible={category.open}>
                    {category.category!=="4fd7b9f6-7de6-4c93-b430-92b202da88f6" && 
                      <ListItem key={segmento.segment} onClick={()=>{handleControl(mainCategory.mainCategory,category.category,segmento.segment)}} visible={category.open} reduct={10} roundborder={true} selected={segmento.open}>
                        {segmento.segment?segmento.segmentName:"Outros"}{segmento.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}{segmento.new && (segmento.open?<IconBox><img src={icons.novo} alt="novo"/></IconBox>:<IconBox><img src={icons.novo_white} alt="novo"/></IconBox>)}
                      </ListItem>}
                    
                    {segmento.contents.map((conteudo)=>(
                      <ListItem key={conteudo.id} visible={category.category!=="4fd7b9f6-7de6-4c93-b430-92b202da88f6"?segmento.open:category.open} reduct={15} roundborder={false} selected={false} notpointer={true}>
                        <Info>
                          <title>{conteudo.title}{conteudo.new && <IconBox><img src={icons.novo_white} alt="novo"/></IconBox>}</title>
                          <p>Período de inscrição: {format(new Date(conteudo.subscriptionBegin),"dd/MM/yyyy")} - {format(new Date(conteudo.subscriptionEnd),"dd/MM/yyyy")}</p>
                        </Info>
                        <Button>
                          <ReadMoreButton onClick={()=>{handleView(mainCategory.mainCategory,conteudo.id)}} text="Leia +" />
                        </Button>
                      </ListItem>
                    ))}
                  </ListContent>
                ))}
              </ListContent>
            ))}
          </ListContent>
        ))}
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default Home;
