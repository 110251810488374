import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  background-color: var(--secondary);
`;

export const BackButton = styled.button`
  margin-top: 6rem;
  background: unset;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1) rotate(180deg);
  }
`;

export const Logo = styled.img`
  width: 8rem;
  margin-bottom: 1rem;
  align-self: center;
`;

export const Form = styled.form`
  width: 50rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 2rem;

  @media (max-width: 700px) {
    padding: 0 1.6rem;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
  text-align: center;
`;

export const Place = styled.span`
  color: var(--primary);
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 40rem;
  padding: 2rem 0 2rem 0;
`;

