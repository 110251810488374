import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  color: var(--primary);
  background-color: var(--secondary);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;


export const CertificadoValida = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  color: var(--primary);
  background-color: var(--secondary);
  ${(props)=>props.canceled? css`box-shadow: 5px 5px var(--softRed);` : css`box-shadow: 5px 5px var(--softBlue);`};};
  font: var(--body);
  span{
    font: var(--title);
  }

  @media (max-width: 600px) {
    img{
      width:0;
    }
    margin-bottom: 5rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const CertificadoInfo = styled.div`
  display: flex;
  position: absolute;
  top: 0rem;
  left: 22rem;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: center;
  width: 46rem;
  padding: 0.5rem;
  color: var(--primary);
  weight: 600;
  /* border: 1px solid black; */

  @media (max-width: 600px) {
    position: relative;
    width: 100%;
    left: 0rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const CertificadoCancelado = styled.div`
  display: none;
  font: var(--title);
  /* border: 1px solid black; */

  @media (max-width: 600px) {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: ${(props)=>props.canceled? css`var(--hardRed);` : css`var(--hardBlue);`};
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;