import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.columns && `${props.columns}rem`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const Input = styled.select`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  font: var(--body);
  flex: 1;
`;

export const InfoMessage = styled.span`
  width: 100%;
  color: var(--hardRed);
  margin-top: calc(var(--size) * 0.5);
  font: var(--body);
`;

export const InputLabel = styled.span`
  width: 100%;
  font-size: calc(var(--size) * 1.6);
  font-family: var(--fontFamily);
  font-weight: var(--boldFont);
  color: var(--gray);
  margin: 0;
  padding: 0.3rem;
`;

export const InputContainer = styled.div`
  padding: 1rem;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  border: calc(var(--size) * 0.1) solid var(--primary);
  border-radius: 1rem;
`;
