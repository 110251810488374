import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  color: var(--primary);
  background-color: var(--secondary);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;


export const ContainerQrCode = styled.div`
  display: flex;
  width: 15rem;
  background-color: var(--secondary);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const ContainerCertificate = styled.div`
  /* top: -300vh; */
  /* position: absolute; */
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary);
  width: 100%;
  height: 75vh;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Page = styled.div`
  margin-top: 0.5px;
  display: flex;
  flex-direction: column;
  width: 1122px;
  height: 793px;
  max-width: 1122px;
  max-height: 793px;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary);
  border: 0.5px solid black;
  box-sizing: border-box;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;