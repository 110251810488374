import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { MdOutlineAddCircleOutline, MdClose } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "../../../hooks/useTheme";
import useApi from "../../../hooks/useApi";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import {
  Container,
  Title,
  Form,
  Item,
  Controls,
  SummaryBox,
  SummaryTitle,
  SummaryItem,
  SearchBox,
  SearchItem,
  LoadingContainer,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputTags from "../../../components/UI/Inputs/InputTags";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import LoadingItem from "../../../components/UI/LoadingItem";

const VideoForm = ({ action }) => {
  const { themeFile } = useTheme();
  const { id } = useParams();
  const [abilitiesFound, setAbilitiesFound] = useState([]);
  const [odsFound, setOdsFound] = useState([]);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      keywords: [],
      item: "",
      link: "",
      abilityItem: "",
      abilities: [],
      odItem: "",
      ods: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Digite o título."),
      description: Yup.string().required("Digite a descrição."),
      link: Yup.string().required("Insira o link do vídeo."),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values);
      let saveData = { ...values };
      delete saveData.item;
      delete saveData.abilityItem;
      delete saveData.odItem;
      // console.log("toSave", saveData);

      if (action === "add") {
        saveItem({
          data: saveData,
        });
      } else {
        const newItem = {
          ...saveData,
          idVideo: id,
        };
        console.log("toEdit", newItem);
        editItem({
          data: newItem,
        });
      }
    },
  });

  // console.log(formik.errors);
  // console.log(formik.values.eventData);

  const handleAddKeywords = () => {
    const item = formik.values.item;
    if (item && !formik.values.keywords.includes(item)) {
      // console.log("pode add");
      let newArray = [...formik.values.keywords];
      newArray.push(item);
      // console.log(newArray);
      formik.setFieldValue("keywords", newArray);
      formik.setFieldValue("item", "");
    } else {
      formik.setErrors({
        item: "Palavra-chave já adicionada.",
      });
    }
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleAddKeywords();
    }
  };

  const [searchAbilities, searchAbilitiesInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/ability",
    // timeout: 5000,
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        setAbilitiesFound(response.data);
      }
    },
  });

  const [searchOds, searchOdsInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/od",
    // timeout: 5000,
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        setOdsFound(response.data);
      }
    },
  });

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/videolibrary",
    // timeout: 5000,
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        navigate("/videoteca");
      }
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/videolibrary",
    // timeout: 5000,
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        navigate("/videoteca");
      }
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/videolibrary",
    // timeout: 5000,
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("video", response);
        if (response.data.length > 0) {
          const initial = {
            ...formik.values,
            ...response.data[0],
          };
          // console.log("initial", initial);
          formik.setValues(initial);
        } else {
          navigate("/videoteca/add");
        }
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/videolibrary",
    // timeout: 5000,
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/videoteca");
        // console.log(response);
      }
    },
  });

  const handleSearchAbilities = (term) => {
    if (term.length > 2) {
      searchAbilities({
        params: {
          term,
        },
        debounced: true,
      });
    } else {
      setAbilitiesFound([]);
    }
  };

  const handleSearchOds = (term) => {
    if (term.length > 2) {
      searchOds({
        params: {
          term,
        },
        debounced: true,
      });
    } else {
      setOdsFound([]);
    }
  };

  const handleRemoveAbilities = (idAbility) => {
    const newArray = formik.values.abilities.filter(
      (item) => item.id !== idAbility
    );
    formik.setFieldValue("abilities", newArray);
  };

  const handleAddAbilities = (ab) => {
    let newArray = [...formik.values.abilities];
    newArray.push(ab);
    formik.setFieldValue("abilities", newArray);
  };

  const ifAbilityExists = (idAbility) => {
    if (formik.values.abilities.length > 0) {
      const found = formik.values.abilities.findIndex(
        (item) => item.id === idAbility
      );
      return found > -1 ? true : false;
    } else {
      return false;
    }
  };

  const handleRemoveOds = (idOd) => {
    const newArray = formik.values.ods.filter((item) => item.id !== idOd);
    formik.setFieldValue("ods", newArray);
  };

  const handleAddOds = (od) => {
    let newArray = [...formik.values.ods];
    newArray.push(od);
    formik.setFieldValue("ods", newArray);
  };

  const ifOdExists = (idOd) => {
    if (formik.values.ods.length > 0) {
      const found = formik.values.ods.findIndex((item) => item.id === idOd);
      return found > -1 ? true : false;
    } else {
      return false;
    }
  };

  const handleDelete = (itemId) => {
    const confirm = window.confirm("Deseja apagar esse vídeo?");

    if (confirm) {
      deleteItem({
        data: {
          idVideo: itemId,
        },
      });
    }
  };

  useEffect(() => {
    async function load() {
      if (action === "edit") {
        await loadItem({
          params: {
            idVideo: id,
          },
        });
      }
    }
    load();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading) && <FullScreenLoading />}
      {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ||
        saveItemInfo.status) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>Videoteca</Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}
          <Item>
            <InputText
              placeholder="Título do vídeo"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Descrição"
              columns={90}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description &&
                formik.errors.description &&
                formik.errors.description
              }
            />
          </Item>
          <Item>
            <InputTags
              tags={formik.values.keywords}
              placeholder="Palavras-chave"
              deleteItem={formik.setFieldValue}
              deleteField="keywords"
              columns={90}
              onKeyDown={handleEnter}
              name="item"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.item}
              autoComplete="off"
              error={formik.errors.item && formik.errors.item}
            />
          </Item>
          <Item>
            <InputText
              placeholder="Link do vídeo"
              columns={3}
              name="link"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.link}
              error={
                formik.touched.link && formik.errors.link && formik.errors.link
              }
            />
          </Item>
          <Item>
            <SummaryBox>
              <SummaryTitle>Habilidades relacionadas</SummaryTitle>
              {formik.values.abilities.length > 0 ? (
                formik.values.abilities.map((ab) => (
                  <SummaryItem key={ab.id}>
                    <span>{ab.value}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveAbilities(ab.id)}
                    >
                      <MdClose
                        size={themeFile.SIZES.base * 16}
                        color={themeFile.COLORS.hardRed}
                      />
                    </button>
                  </SummaryItem>
                ))
              ) : (
                <SummaryItem>
                  <span>Nenhuma habilidade adicionada ainda.</span>
                </SummaryItem>
              )}
            </SummaryBox>
            <InputText
              placeholder="Buscar habilidades"
              columns={3}
              name="abilityItem"
              onChange={(e) => {
                formik.handleChange(e);
                handleSearchAbilities(e.target.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.abilityItem}
              error={
                formik.touched.abilityItem &&
                formik.errors.abilityItem &&
                formik.errors.abilityItem
              }
            />
            {(abilitiesFound.length > 0 || searchAbilitiesInfo.loading) && (
              <SearchBox>
                {searchAbilitiesInfo.loading && (
                  <LoadingContainer>
                    <LoadingItem />
                  </LoadingContainer>
                )}
                {abilitiesFound.length > 0 &&
                  abilitiesFound.map(
                    (ab) =>
                      !ifAbilityExists(ab.id) && (
                        <SearchItem key={ab.id}>
                          <span>{ab.value}</span>
                          <button
                            type="button"
                            onClick={() => handleAddAbilities(ab)}
                          >
                            <MdOutlineAddCircleOutline
                              size={themeFile.SIZES.base * 24}
                              color={themeFile.COLORS.purple}
                            />
                          </button>
                        </SearchItem>
                      )
                  )}
              </SearchBox>
            )}
          </Item>
          <Item>
            <SummaryBox>
              <SummaryTitle>ODs relacionadas</SummaryTitle>
              {formik.values.ods.length > 0 ? (
                formik.values.ods.map((od) => (
                  <SummaryItem key={od.id}>
                    <span>
                      {od.od} - {od.bim} - Bimestre - {od.ano}
                    </span>
                    <button
                      type="button"
                      onClick={() => handleRemoveOds(od.id)}
                    >
                      <MdClose
                        size={themeFile.SIZES.base * 16}
                        color={themeFile.COLORS.hardRed}
                      />
                    </button>
                  </SummaryItem>
                ))
              ) : (
                <SummaryItem>
                  <span>Nenhuma OD adicionada ainda.</span>
                </SummaryItem>
              )}
            </SummaryBox>
            <InputText
              placeholder="Buscar ODs"
              columns={3}
              name="odItem"
              onChange={(e) => {
                formik.handleChange(e);
                handleSearchOds(e.target.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.odItem}
              error={
                formik.touched.odItem &&
                formik.errors.odItem &&
                formik.errors.odItem
              }
            />
            {(odsFound.length > 0 || searchOdsInfo.loading) && (
              <SearchBox>
                {searchOdsInfo.loading && (
                  <LoadingContainer>
                    <LoadingItem />
                  </LoadingContainer>
                )}
                {odsFound.length > 0 &&
                  odsFound.map(
                    (od) =>
                      !ifOdExists(od.id) && (
                        <SearchItem key={od.id}>
                          <span>
                            {od.od} - {od.bim} - Bimestre - {od.ano}
                          </span>
                          <button
                            type="button"
                            onClick={() => handleAddOds(od)}
                          >
                            <MdOutlineAddCircleOutline
                              size={themeFile.SIZES.base * 24}
                              color={themeFile.COLORS.purple}
                            />
                          </button>
                        </SearchItem>
                      )
                  )}
              </SearchBox>
            )}
          </Item>

          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default VideoForm;
