import React, { useEffect, useState } from "react";
// import { format, isBefore, startOfYear, subMonths, startOfMonth } from "date-fns";
// import {
//   MdArrowDropUp,
//   MdArrowDropDown,
// } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import {
  Container,
  // Search,
  // IconBox,
  ListContent,
  ListItem,
  // Info,
  // Button,
  // Update,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
// import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
// import ReadMoreButton from "../../components/UI/ReadMoreButton"
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

// import { images, icons } from "../../constants";

const CoursesManagerMainPage = () => {
  const { user, accessRight } = useAuth();
  const navigate = useNavigate();
  const [tutorEditor, setTutorEditor] = useState(false);

  const getEditorId = (type) => {
    let teste = user?.profiles[0]?.editor?.find(
      (item) =>
        item.category === "Formação Elementar" ||
        item.category === "Formação Complementar"
    );
    // console.log("teste",teste);
    return teste?.id ? teste.id : false;
  };

  const [loadCourse, loadCourseInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("course by user", response.data);
        let temp = response.data.filter((course)=>{
          return course.isEditor || course.isTutor;
        });
        // console.log("temp", temp);
        if((temp[0]?.isEditor||temp[0]?.isTutor)){
          setTutorEditor(true);
        }
      }
    },
  });

  useEffect(() => {
    loadCourse();
    // console.log("Entrou em courses Manager");
    console.log("certificates ", accessRight("certificates").SP_rights, accessRight("certificates").SP_rights.can_generate,"&&",user?.profiles[0].idProfile === "8a833773-7e1d-47d8-9995-e98b3d6fe04a","=",((accessRight("certificates").SP_rights.can_generate) && (user?.profiles[0].idProfile === "8a833773-7e1d-47d8-9995-e98b3d6fe04a")));
    console.log("htpfRights ", accessRight("htpf").SP_rights);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {false && <FullScreenLoading />}
      <Menu />
      <Banner
        // icon={images.iconNovidades}
      >
        Gestão de Formações
      </Banner>

      {/* <Search>
        <InputSearch
          value={search}
          onChange={handleSearch}
          onClick={handleSearch}
        />
      </Search> */}

      <ContentBox>

          <ListContent>
              <ListItem 
                onClick={()=>{navigate("/subscriptionmanagerlist");}} 
                reduct={0}
                divide={4} 
                height={5}
                roundborder={true} 
                selected={true}>
                  Lista de inscritos dos cursos
              </ListItem>
              <ListItem 
                onClick={()=>{navigate("/subscriptionmanager");}} 
                reduct={0}
                divide={4} 
                height={5}
                roundborder={true} 
                selected={true}>
                  Consulta inscrições do Usuário
              </ListItem>
              {tutorEditor && <ListItem 
                onClick={()=>{navigate(`/coursemanager/list`);}} 
                reduct={0}
                divide={4} 
                height={5}
                roundborder={true} 
                selected={true}>
                  Gestão de atividades dos cursos
              </ListItem>}   
              {getEditorId()!==false && <ListItem 
                onClick={()=>{navigate(`/coursereport`);}} 
                reduct={0}
                divide={4} 
                height={5}
                roundborder={true} 
                selected={true}>
                  Gestão de relatório dos cursos
              </ListItem>}
              {accessRight("certificates").SP_rights?.can_generate && <ListItem 
                onClick={()=>{navigate(`/coursecertificatemanager`);}} 
                reduct={0}
                divide={4} 
                height={5}
                roundborder={true} 
                selected={true}>
                  Emitir certificados dos cursos
              </ListItem>}
              {accessRight("htpf").SP_rights?.can_view && <ListItem 
                onClick={()=>{navigate(`/htpf`);}} 
                reduct={0}
                divide={4} 
                height={5}
                roundborder={true} 
                selected={true}>
                  HTPF
              </ListItem>}          
          </ListContent>      
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default CoursesManagerMainPage;
