import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  flex-wrap:nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
  font: var(--body);
`;

export const EventTitle = styled.div`
  display: flex;
  flex-direction: row;
  font: var(--title);
`;

export const EventDate = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EventTime = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EventDesc = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EventEquipe = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
`;
