import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  flex-wrap:nowrap;
  width: 100%;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Grafico = styled.div`
  width: 100%;
  ${(props)=>(props.fullscreen&&css`
    position: absolute;
    height:100%;
    background-color: #FFFFFF;
    top: 0;
    z-index: 10;
  `)};
  ${(props)=>(props.hide?
    css`
      display: none;
    `:
    css`
      display: block;
    `
  )};
`;

export const ListContent = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
`;

export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
`;

export const Test = styled.div`
  display: ${(props)=>(props.visible?css`flex`:css`none`)};
  flex-direction:row;
  align-items:flex-start;
  justify-content:space-between;
  flex-wrap: wrap;
  margin: 0.5rem 4rem 0.5rem 4rem;
  border: 1px solid var(--primary);
  /* background-color: var(--softGray); */
  background-color: var(--secondary);
  border-radius: 1rem;
  padding: 1.2rem 3rem 1.2rem 3rem;
  width: ${(props) => css`${100-props.reduct}%`};
  
  button {
    position: relative;
    left: 1rem;
  }

  @media (max-width: 600px) {
   margin: 0.5rem 3rem 3rem 3rem;
  }
  @media (max-width: 768px) {}
  @media (max-width: 992px) {}
  @media (max-width: 1300px) {
   width: 88%;
  }
`;