import styled, { css } from "styled-components";
import { images } from "../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: var(--secondary);
  min-height: 100vh;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  // margin-top: 10rem;
  width: 100%;
  height: 40rem;
  ${(props) =>
    props.highContrast
      ? css`
          background-image: url(${images.bannerBackgroundHighContrast});
        `
      : css`
          background-image: url(${images.bannerBackground});
        `}
  background-size: cover;

  @media (max-width: 500px) {
    height: 22rem;
  }
`;

export const IconBanner = styled.div`
  display: flex;
  width: 50rem;
  margin-bottom: -1rem;

  @media (max-width: 500px) {
    width: 29rem;
  }
`;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -4rem;
  position: relative;
`;

export const DivStayTunned = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: auto;
  max-width: 118rem;
  width: 100%;
  margin-top: 2rem;

  /* @media (max-width: 1215px){
    margin: 2rem auto auto 2rem;
  } */
`;

export const TitleTunned = styled.div`
  display: flex;
  font-size: calc(var(--size) * 2);
  color: var(--purple);
  font: var(--title);
`;

export const IconWirelles = styled.img`
  /* display: flex; */
`;

export const Description = styled.div`
  width: 100%;
  max-width: 118rem;
  margin: 2rem auto 2rem auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: var(--secondary);
  text-align: justify;
  line-height: calc(var(--size) * 3);
  font-family: var(--familyBody);
  font-size: calc(var(--size) * 1.6);
  color: var(--primary);

  @media (max-width: 1215px) {
    padding: 0 2rem;
  }
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: var(--gray);
  width: 100%;
  padding: 2rem 0 2rem 0;
  min-height: 13rem;

  @media (max-width: 1215px) {
    padding: 2rem;
  }
`;

export const News = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  background-color: var(--gray);
  gap: 1.5rem;
`;

export const Title = styled.h1`
  padding: 6rem 2rem;
  background-color: var(--purple);
  color: var(--secondary);
  text-align: center;
  font-family: var(--familyTitle);
  font-size: calc(var(--size) * 4);
  margin-top: 10rem;

  @media (max-width: 500px) {
    padding: 3rem 1rem;
    font-size: calc(var(--size) * 3);
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 1rem 0;

  > div {
    width: 100%;
    max-width: 10rem;
  }
`;

export const SearchMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding: 0.8rem;
  background-color: var(--purple);
  border-radius: 0.8rem;
  font: var(--body);
  color: var(--secondary);
  margin-bottom: 1rem;

  > p {
    text-align: center;
  }
`;
