import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Menu from "../../components/UI/Menu";
import Footer from "../../components/UI/Footer";
import Banner from "../../components/UI/Banner";
import { images, icons } from "../../constants";
import "./styles.css";

import videoLogin from "../../assets/videos/usuario-login.mp4";
import videoPerfil from "../../assets/videos/usuario-perfil.mp4";
import videoBusca from "../../assets/videos/usuario-inicio-busca.mp4";
import videoDocs from "../../assets/videos/usuario-docs.mp4";
import videoAgenda from "../../assets/videos/usuario-agenda.mp4";
import videoFormacoes from "../../assets/videos/usuario-formacoes.mp4";
import videoAvaliacoes from "../../assets/videos/usuario-avaliacoes.mp4";
import videoBiblioteca from "../../assets/videos/usuario-biblioteca.mp4";
import videoVideoteca from "../../assets/videos/usuario-videoteca.mp4";
import videoPlataformas from "../../assets/videos/usuario-plataformas.mp4";
import videopnld from "../../assets/videos/TutorialPNLDsite.mp4";


const UserManual = () => {
  return (
    <div>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconAjuda}> MANUAL DO USUÁRIO</Banner>
      <ContentBox>
      
      <main class="help">
      <p>Manual destinado ao usuário profissional da rede de ensino de mogi das cruzes, diretores, professores, entre outros.</p>
        <br />
      <article>
        <h3 class="help" id="login">Acesso e Login no sistema</h3>

        <video class="help" controls>
          <source src={videoLogin} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>
        <p>Para acessar o Canal Pedagógico, entre no endereço <a href="http://www.canalpedagogico-se.mogidascruzes.sp.gov.br">www.canalpedagogico-se.mogidascruzes.sp.gov.br</a>.</p>
        <p>Para fazer o login na plataforma, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.login_black} alt="login"/> Login</p>
        <p>Clique em <img class="help-inline-icons" src={icons.iconGoogle} alt="google" /><strong> Fazer login com o Google</strong></p>
        <p>Coloque nos campos solicitados o seu email corporativo e em seguida sua senha.</p>

        <br />
        <h3 class="help" id="perfil">Alterando o perfil</h3>

        <video class="help" controls>
          <source src={videoPerfil} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>
        <p>Você pode personalizar seu perfil alterando seu nome e/ou sua foto.</p>
        <p>Para alterar sua foto, clique no botão <strong>Alterar foto</strong>.</p>
        <p>Escolha sua foto previamente salva em seu computador.</p>
        <p>Redimensione ou movimente a área de corte para sua preferência e clique em <strong>Cortar</strong>.</p>
        <p>Verifique o resultado da imagem e clique em <strong>Salvar</strong>.</p>
        <p>Caso queira, você pode alterar o nome que aparece em seu perfil.</p>
        <p>Após fazer as modificações necessárias, clique em <strong>Salvar</strong>.</p>

        <br />
        <h3 class="help" id="inicio">Página inicial e pesquisa</h3>

        <video class="help" controls>
          <source src={videoBusca} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>

        <p>Não esqueça de ler e concordar com nossos <a class="help" href="https://canalpedagogico.sme-mogidascruzes.sp.gov.br/politicaetermos" rel="noreferrer">Termos de Uso e Política de Privacidade</a>.</p>
        <p>Na página inicial você encontra os eventos mais recentes, além de uma ferramenta de pesquisa em todo o conteúdo da plataforma.</p>
        <p>Para ver mais detalhes sobre o evento, clique em <strong>Saiba mais</strong>.</p>
        <p>Em todas as páginas, existe a possibilidade de fazer pesquisa por itens. Para pesquisar, utilize um termo com no <strong>mínimo 3 caracteres</strong>.</p>
        <p>Pronto! Todos os itens encontrados com base na sua pesquisa serão listados.</p>

        <br />
        <h3 class="help" id="documentos">Visualizar documento</h3>

        <video class="help" controls>
          <source src={videoDocs} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>
        <p>Para ver a lista de documentos, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.documentos_black} alt="documentos"/> Documentos.</p>
        <p>Os documentos são divididos em <strong>Documentos Gerais</strong>, <strong>Indicadores</strong> e <strong>Orientações Didáticas</strong>.</p>
        <p>Para ver mais detalhes, clique em cima do título.</p>
        <p>Clique sobre o nome do arquivo para abrir o documento.</p>
        <p>Você também pode abrir o arquivo a partir da tela inicial de documentos.</p>
        <p class="indent"><strong>Dica:</strong> Lembre-se que você pode efetuar uma busca para encontrar um documento (mín. de 3 caracteres).</p>
        
        <br />
        <h3 class="help" id="agenda">Agenda</h3>

        <video class="help" controls>
          <source src={videoAgenda} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>

        <p>Para ver a agenda dos eventos do auditório e do departamento, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.agenda_black} alt="agenda"/> Agenda.</p>
        <p>No painel do calendário, você pode ver em destaque os dias que existe algum evento.</p>
        <p>Na parte inferior do calendário existe a possibilidade de filtrar os eventos por tipo.</p>
        <p>Para ver mais detalhes dos eventos, clique no título ou em <strong>Saiba mais</strong>.</p>
        <p>Na visão detalhada, clique sobre o nome do arquivo para abrir o documento.</p>

        <br />

        <h3 class="help" id="agenda">Livros</h3>

        <video class="help" controls>
          <source src={videopnld} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>

        <br />

        <h3 class="help" id="formacoes">Formações</h3>

        <video class="help" controls>
          <source src={videoFormacoes} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>

        <p>Para ver as formações disponíveis, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.formacoes_black} alt="formacoes"/> Formações.</p>
        <p>Nesta página, você verá todas as formações com inscrições abertas disponíveis, bem como alguns detalhes como nome, data, horário e local da formação, um breve resumo e o público-alvo.</p>
        <p>Para ver mais detalhes, clique em <strong>+Leia Mais</strong></p>
        <p>Caso você se inscreva em alguma formação, na guia <strong>Minhas inscrições</strong>, será possível acompanhar as inscrições realizadas.</p>

        <br />
        <h3 class="help" id="avaliacoes">Avaliações</h3>

        <video class="help" controls>
          <source src={videoAvaliacoes} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>
        <p>Para ver a lista de avaliações, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.indicadores_black} alt="avaliacoes"/> Avaliações</p>

        <p>Na listagem que aparece, clique no nome do documento de orientação ou do material de apoio para abrir.</p>
        <p>Você também pode clicar no título da avaliação para ver mais detalhes.</p>

        <br />
        <h3 class="help" id="biblioteca">Biblioteca</h3>

        <video class="help" controls>
          <source src={videoBiblioteca} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>
        <p>Para acessar a Biblioteca, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.biblioteca_black} alt="bilblioteca"/> Biblioteca</p>
        <p>Aqui na página da biblioteca, você fica sabendo das novidades do nosso acervo.</p>
        <p>Em pesquisar, você pode fazer a pesquisa por um tema (min. 3 caracteres).</p>
        <p>Para ver mais detalhes, clique no título do livro.</p>
        <p>Você pode ver todo o acervo e reservar livros da Biblioteca através da plataforma <img class="help-inline-icons" src={images.gnutecaLogo} alt="gnuteca" /><strong>GNUTECA</strong>.</p>
      
        <br />
        <h3 class="help" id="videoteca">Videoteca</h3>

        <video class="help" controls>
          <source src={videoVideoteca} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>
        <p>Para ver a lista de vídeos, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.videoteca_black} alt="videoteca"/> Videoteca.</p>
        <p>Em cada card, aparece a miniatura do vídeo, o título e algumas tags/marcadores que servem para saber do que se trata o conteúdo do vídeo.</p>
        <p>Clique na miniatura do vídeo para reproduzir.</p>
        <p>Clique no título para ver mais detalhes.</p>
        <p>Use a pesquisa para procurar por um tema (mín. 3 caracteres).</p>

        <br />
        <h3 class="help" id="plataformas">Plataformas</h3>

        <video class="help" controls>
          <source src={videoPlataformas} type="video/mp4" />
          Seu navegador não suporta o vídeo nesse formato.
        </video>
        <p>Para ver a lista de plataformas, vá até o <img class="help-inline-icons" src={icons.iconMenu} alt="menu" /> e em <img class="help-inline-icons" src={icons.plataforma_black} alt="plataforma"/> Plataformas.</p>
        <p>Aqui você pode acessar e conhecer um pouco mais sobre cada plataforma utilizada na rede de educação.</p>
        <p>Não deixe de conhecer!</p>
      </article>

    </main>

      </ContentBox>
    <Footer />
    </div>
  );
};

export default UserManual;