import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
// import * as XLSX from "xlsx";

import {
  Container,
  // Search,
  IconBox,
  Legend,
  ListContent,
  ListItem,
  // Info,
  // Button,
  // Update,
  // Title,
  // Table,
  JustifyList,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
// import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import InputSelect from "../../components/UI/Inputs/InputSelect";
import Modal from "../../components/UI/Modal";

import useApi from "../../hooks/useApi";
// import { useAuth } from "../../hooks/useAuth";

import { icons } from "../../constants";

const CoursesRegisterActivities = () => {
  // const { user } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const [courseInfo, setCourseInfo] = useState({});
  const [listInfoSubscribe, setListInfoSubscribe] = useState([]);
  const [listActivities, setListActivities] = useState([]);
  const [listUserActivities, setListUserActivities] = useState([]);
  const [listCompleteActivities, setListCompleteActivities] = useState([]);
  const [listCompleteSubscribe, setListCompleteSubscribe] = useState([]);
  const [listJustify, setListJustify] = useState([]);
  
  const [controlPage, setControlPage] = useState({mode:"activity",view:{activity:"",user:""}});
  const [controlPageView, setControlPageView] = useState("");
  const [controlModal, setControlModal] = useState({open:false,data:{}});
  const [modalError, setModalError] = useState({open:false,message:""});
  //mode activity or user

  const [loadCourse, loadCourseInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("course by user", response.data[0]);
        setCourseInfo(response.data[0]);
        if(!(response.data[0].isEditor||response.data[0].isTutor)){
          navigate(`/home2`);
        }
      }
    }
  });

  const [listSubscribe, ListSubscribeInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/subscription/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listSubscribe", response.data);
        setListInfoSubscribe(response.data);
        loadListActivities({
          params: {
            idContent:id,
          },
        });
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const [loadListActivities, LoadListActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listActivities", response.data);
        setListActivities(response.data);
        loadListUserActivities({
          params: {
            idContent:id,
          },
        });
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const [loadListUserActivities, LoadListUserActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/course",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listUserActivities", response.data);
        setListUserActivities(response.data);
        if(response.data.length === 0){
          adjustVetor();
        }
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const [postUserActivities, postUserActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("atividade criada com sucesso");
        loadListUserActivities({
          params: {
            idContent:id,
          },
        });
        setControlModal({open:false,data:{}});
      }
    },
    onError: (error)=>{ 
      setModalError({open:true,message:"Erro ao criar registro de atividade"});
      // document.location.reload(false);
    },
  });

  const [putUserActivities, putUserActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("atividade atualizada com sucesso");
        loadListUserActivities({
          params: {
            idContent:id,
          },
        });
        setControlModal({open:false,data:{}});
      }
    },
    onError: (error)=>{ 
      setModalError({open:true,message:"Erro ao atualizar o registro de atividade"});
      // document.location.reload(false);
    },
  });

  const [getListJustify, getListJustifyInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/activitiesJustifications",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listJustify", response.data);
        setListJustify(response.data);
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const postActivity = (data)=>{
    postUserActivities({data});
  };

  // idUserSubscription,
  // idContentActivity, 
  // hoursTaken,
  // idActivitiesJustifications,

  const putActivity = (data)=>{
    putUserActivities({data});
  };

  // idUserContentActivity, 
  // hoursTaken,
  // idActivitiesJustifications,
  
  useEffect(() => {
    // console.log("Entrou em course Manager");
    loadCourse({
      params:{
        id,
      }
    });
    listSubscribe({
      params: {
        id,
      },
    });
    getListJustify();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    adjustVetor();
    // eslint-disable-next-line
  }, [listUserActivities]);

  useEffect(() => {
    adjustVetor();
    // console.log("mudou modo de pagina",controlPage);
    // eslint-disable-next-line
  }, [controlPage]);

  const adjustVetor = () => {
    let tempAdjust = [];
    if(controlPage.mode==="activity"){
      listActivities.forEach((activity)=>{
        let tempSubs=[];
        listInfoSubscribe.forEach((subscribe)=>{
          tempSubs.push({
            ...subscribe,
            userActivities:listUserActivities.filter((userActivity)=>{
              return userActivity.idcontent_activity===activity.idContentActivity && 
              userActivity.iduser_subscription===subscribe.idUserSubscription
            })
          });
        });
        tempAdjust.push({...activity,listSubscribe:tempSubs})
      });
      setListCompleteActivities(tempAdjust);
      // console.log("listCompleteActivities",listCompleteActivities)
    }
    if(controlPage.mode==="user"){
      listInfoSubscribe.forEach((subscribe)=>{
        let tempActivities=[];
        listActivities.forEach((activity)=>{
          tempActivities.push({
            ...activity,
            userActivities:listUserActivities.filter((userActivity)=>{
              return userActivity.idcontent_activity===activity.idContentActivity && 
              userActivity.iduser_subscription===subscribe.idUserSubscription
            })
          });
        });
        tempAdjust.push({...subscribe,listActivities:tempActivities})
      });
      setListCompleteSubscribe(tempAdjust);
      // console.log("listCompleteSubscribe",listCompleteSubscribe)
    }
  };

  // const handleDownload = () => {
  //   // let maxWidth = [
  //   //   5,
  //   //   10,
  //   //   15,
  //   //   10,
  //   //   10,
  //   //   10
  //   // ]

  //   // listInfoSubscribe.map((sub,index)=>{
  //   //   if(sub.RGF?.length>maxWidth[0])maxWidth[0]=sub.RGF.length;
  //   //   if(sub.Nome?.length>maxWidth[1])maxWidth[1]=sub.Nome.length;
  //   //   if(sub.CPF?.length>maxWidth[2])maxWidth[2]=sub.CPF.length;
  //   //   if(sub.email?.length>maxWidth[3])maxWidth[3]=sub.email.length;
  //   //   if(sub.Escola?.length>maxWidth[4])maxWidth[4]=sub.Escola.length;
  //   //   if(sub.Classe?.length>maxWidth[5])maxWidth[5]=sub.Classe.length;
  //   // });

  //   // // create workbook and worksheet
  //   // const workbook = XLSX.utils.book_new();
  //   // const worksheet = XLSX.utils.table_to_sheet(document.getElementById("tableCourses"));
  //   // maxWidth.forEach((width)=>{worksheet['!cols'].push({ wch: width+2 })});
  //   // console.log(worksheet);

  //   // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  //   // XLSX.writeFile(workbook, `${courseInfo?.title}.xlsx`, { compression: true });
  // };

  const getDescriptionJustify = (id) => {
    return listJustify.find((justify)=>{return justify.idactivities_justifications===id})?.description;
  };


  return (
    <Container>
      {(loadCourseInfo.loading ||
        ListSubscribeInfo.loading ||
        LoadListActivitiesInfo.loading ||
        LoadListUserActivitiesInfo.loading ||
        postUserActivitiesInfo.loading ||
        getListJustifyInfo.loading ||
        putUserActivitiesInfo.loading) && <FullScreenLoading />}
      <Menu />
      <Banner
        // icon={images.iconNovidades}
        url={`/formacoes/editar/${courseInfo?.id}`}
        editorId={"0e3b686a-9b75-4de0-a2b7-28ec578e8119"}
      >
        Registro de atividades do Curso: {courseInfo?.title}
      </Banner>

      {/* <Search>
        <InputSearch
          value={search}
          onChange={handleSearch}
          onClick={handleSearch}
        />
      </Search> */}

      <ContentBox>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate(-1);}}
        >
          <MdArrowLeft fontSize={36}/> Voltar
        </ListItem>
        <ListItem row={true} 
                  visible={true}>
          <ListItem reduct={72}
                    row={true}
                    justify={"space-between"}
                    marTop={0.01}
                    visible={true}>
              <Legend><img src={icons.atividade_feito} alt="Presente"/>Concluído</Legend>
              <Legend><img src={icons.atividade_falta} alt="Falta"/>Não concluído</Legend>
              <Legend><img src={icons.atividade_justificado} alt="Justificada"/>Justificado</Legend>
          </ListItem>
          <ListItem row={true} 
                    marTop={0.01}
                    justify={"flex-end"}
                    reduct={28}
                    visible={true}>
            <InputSelect 
              onChange={(e)=>{setControlPage({...controlPage,mode:e.target.value})}}
              value={controlPage.mode}
              placeholder="Organizar por:">
              <option key={"opt1"} value="activity" defaultValue>Atividade</option>
              <option key={"opt2"} value="user">Inscritos</option>
            </InputSelect>
          </ListItem>
        </ListItem>

        {controlPage.mode === "activity" && <>
          {listCompleteActivities.map((activity)=>(<>
            <ListItem
              border={true}
              roundborder={true} 
              padW={3}
              justify={"flex-start"}
              row={true}
              onClick={()=>{controlPageView===activity.idContentActivity?setControlPageView(""):setControlPageView(activity.idContentActivity)}}
              visible={true}
              pointer={true} 
              selected={controlPageView===activity.idContentActivity}
              key={activity.idContentActivity}>
              {activity.module!==null && <>{activity.module===0?"Modulo único":`Modulo: ${activity.module}` }<br/></>}
              {activity.title} ({'\u0020'}
              {format(new Date(parseInt(activity.activityBegin)), "dd/MM/yyyy", {locale: ptBR,})} -{'\u0020'}
              {format(new Date(parseInt(activity.activityEnd)), "dd/MM/yyyy", {locale: ptBR,})}{'\u0020'})
              {controlPageView===activity.idContentActivity?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
            </ListItem>
            <ListContent
              visible={controlPageView===activity.idContentActivity}
              key={`content-${activity.idContentActivity}`}>
              {activity?.listSubscribe.map((subscribe)=>(
                <ListItem reduct={5} visible={controlPageView===activity.idContentActivity} row={true} border={true} key={subscribe.idUserSubscription}>
                  <ListItem 
                    padW={1}
                    padV={0}
                    reduct={90}
                    visible={controlPageView===activity.idContentActivity}>
                    {subscribe.userActivities.length>0 && <IconBox>
                      {subscribe.userActivities[0].hours_taken===0 && <img src={icons.atividade_falta} alt="Falta"/>}
                      {subscribe.userActivities[0].hours_taken===1 && <img src={icons.atividade_feito} alt="Presente"/>}
                      {subscribe.userActivities[0].hours_taken===2 && <img src={icons.atividade_justificado} alt="Justificado"/>}
                    </IconBox>}
                  </ListItem>
                  <ListItem 
                    padW={1}
                    padV={0}
                    align={"flex-start"}
                    reduct={35}
                    visible={controlPageView===activity.idContentActivity}>
                      {subscribe.name}
                      <br/>
                      {subscribe?.userActivities[0]?.hours_taken===2 && <>Justificativa: {getDescriptionJustify(subscribe?.userActivities[0]?.idactivities_justifications)} </>}
                  </ListItem>
                  <ListItem 
                    reduct={79}
                    visible={controlPageView===activity.idContentActivity}>
                      {subscribe.userActivities.length>0?
                      <>
                        {subscribe.userActivities[0].hours_taken!==1 && <ReadMoreButton
                          text="Realizou Atividade"
                          type="button"
                          onClick={()=>{putActivity({
                                                    idUserContentActivity:subscribe.userActivities[0]?.iduser_content_activity,
                                                    hoursTaken:1
                                        })}}/>}
                        {subscribe.userActivities[0].hours_taken!==0 && <ReadMoreButton
                          text="Não Realizou Atividade"
                          type="button"
                          onClick={()=>{putActivity({
                                                      idUserContentActivity:subscribe.userActivities[0]?.iduser_content_activity,
                                                      hoursTaken:0
                                          })}}/>}
                        <ReadMoreButton
                          text="Não Realizou Atividade/Justificativa"
                          type="button"
                          onClick={()=>{setControlModal({
                            open:true,
                            data:{
                              idUserContentActivity:subscribe.userActivities[0]?.iduser_content_activity,
                              hoursTaken:2,
                              idActivitiesJustifications:null
                            }})}}/>
                      </>:
                      <>
                        <ReadMoreButton
                          text="Realizou Atividade"
                          type="button"
                          onClick={()=>{postActivity({
                                                      idUserSubscription:subscribe.idUserSubscription,
                                                      idContentActivity:activity.idContentActivity,
                                                      hoursTaken:1
                                  })}}/>
                        <ReadMoreButton
                          text="Não Realizou Atividade"
                          type="button"
                          onClick={()=>{postActivity({
                                                      idUserSubscription:subscribe.idUserSubscription,
                                                      idContentActivity:activity.idContentActivity,
                                                      hoursTaken:0
                                  })}}/>
                        <ReadMoreButton
                          text="Não Realizou Atividade/Justificativa"
                          type="button"
                          onClick={()=>{setControlModal({
                            open:true,
                            data:{
                              idUserSubscription:subscribe.idUserSubscription,
                              idContentActivity:activity.idContentActivity,
                              hoursTaken:2
                            }
                          })}}/>
                      </>}
                  </ListItem>
                </ListItem>))}
            </ListContent>
          </>))}
        </>}

        {controlPage.mode === "user" && <>
          {listCompleteSubscribe.map((subscribe)=>(<>
            <ListItem
              border={true}
              roundborder={true} 
              padW={3}
              justify={"flex-start"}
              row={true}
              onClick={()=>{controlPageView===subscribe.idUserSubscription?setControlPageView(""):setControlPageView(subscribe.idUserSubscription)}}
              visible={true}
              pointer={true} 
              selected={controlPageView===subscribe.idUserSubscription}
              key={subscribe.idUserSubscription}>
              {subscribe.name}
              {controlPageView===subscribe.idUserSubscription?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
            </ListItem>
            <ListContent
              visible={controlPageView===subscribe.idUserSubscription}
              key={`content-${subscribe.idContentActivity}`}>
              {subscribe?.listActivities.map((activity)=>(
                <ListItem reduct={5} visible={controlPageView===subscribe.idUserSubscription} row={true} border={true} key={activity.idContentActivity}>
                  <ListItem 
                    padW={0}
                    padV={0}
                    reduct={90}
                    visible={controlPageView===subscribe.idUserSubscription}>
                    
                    {activity.userActivities.length>0 && <IconBox>
                      {activity.userActivities[0].hours_taken===0 && <img src={icons.atividade_falta} alt="Falta"/>}
                      {activity.userActivities[0].hours_taken===1 && <img src={icons.atividade_feito} alt="Presença"/>}
                      {activity.userActivities[0].hours_taken===2 && <><img src={icons.atividade_justificado} alt="Justificado"/></>}
                    </IconBox>}
                  </ListItem>
                  <ListItem 
                    padW={1}
                    padV={0}
                    align={"flex-start"}
                    reduct={35}
                    visible={controlPageView===subscribe.idUserSubscription}>
                      {activity.title} -
                      {format(new Date(parseInt(activity.activityBegin)), "dd/MM/yyyy", {locale: ptBR,})} - 
                      {format(new Date(parseInt(activity.activityEnd)), "dd/MM/yyyy", {locale: ptBR,})}
                      <br/>
                      {activity?.userActivities[0]?.hours_taken===2 && <>Justificativa: {getDescriptionJustify(activity?.userActivities[0]?.idactivities_justifications)} </>}
                  </ListItem>
                  <ListItem 
                    reduct={79}
                    visible={controlPageView===subscribe.idUserSubscription}>
                      {activity.userActivities.length>0?
                      <>
                        {activity.userActivities[0].hours_taken!==1 && <ReadMoreButton
                          text="Realizou Atividade"
                          type="button"
                          onClick={()=>{putActivity({
                                                    idUserContentActivity:activity.userActivities[0]?.iduser_content_activity,
                                                    hoursTaken:1,
                                                    idActivitiesJustifications:null,
                                        })}}/>}
                        {activity.userActivities[0].hours_taken!==0 && <ReadMoreButton
                          text="Não Realizou Atividade"
                          type="button"
                          onClick={()=>{putActivity({
                                                      idUserContentActivity:activity.userActivities[0]?.iduser_content_activity,
                                                      hoursTaken:0,
                                                      idActivitiesJustifications:null,
                                          })}}/>}
                        <ReadMoreButton
                          text="Não Realizou Atividade/Justificativa"
                          type="button"
                          onClick={()=>{setControlModal({
                            open:true,
                            data:{
                              idUserContentActivity:activity.userActivities[0]?.iduser_content_activity,
                              hoursTaken:2,
                              idActivitiesJustifications:null
                            }})}}/>
                      </>:
                      <>
                        <ReadMoreButton
                          text="Realizou Atividade"
                          type="button"
                          onClick={()=>{postActivity({
                                                      idUserSubscription:subscribe.idUserSubscription,
                                                      idContentActivity:activity.idContentActivity,
                                                      hoursTaken:1
                                  })}}/>
                        <ReadMoreButton
                          text="Não Realizou Atividade"
                          type="button"
                          onClick={()=>{postActivity({
                                                      idUserSubscription:subscribe.idUserSubscription,
                                                      idContentActivity:activity.idContentActivity,
                                                      hoursTaken:0
                                  })}}/>
                        <ReadMoreButton
                          text="Não Realizou Atividade/Justificativa"
                          type="button"
                          onClick={()=>{setControlModal({
                            open:true,
                            data:{
                              idUserSubscription:subscribe.idUserSubscription,
                              idContentActivity:activity.idContentActivity,
                              hoursTaken:2
                            }
                          })}}/>
                      </>}
                  </ListItem>
                </ListItem>))}
            </ListContent>
          </>))}
        </>}

        <Modal open={controlModal.open} buttonClose={false}>
          <JustifyList>
            {listJustify.map((justify)=>(
              <ListItem 
                key={justify.idactivities_justifications}
                pointer={true}
                roundborder={true} 
                visible={true}
                className={justify.label}
                onClick={()=>{
                  controlModal.data.idUserContentActivity?
                    putActivity({...controlModal.data,idActivitiesJustifications:justify.idactivities_justifications}):
                    postActivity({...controlModal.data,idActivitiesJustifications:justify.idactivities_justifications})}}>
                {`${justify.label} - ${justify.description}`}
              </ListItem>
            ))}
          </JustifyList>
          <ReadMoreButton
            text="Cancelar"
            type="button"
            onClick={()=>{setControlModal({open:false,data:{}});}}
            disabled={false}
          />
        </Modal>

        <Modal open={modalError.open} buttonClose={false}>
          <div>{modalError.message}</div>
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{setModalError({open:false,message:""});}}
            disabled={false}
          />
        </Modal>

      </ContentBox>

      <Footer />
    </Container>
  );
};

export default CoursesRegisterActivities;
