import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import {
  Contact,
  Container,
  ContentText,
  Images,
  LinkDoc,
  Map,
  Text,
  Title,
  Video,
  ViewImages,
} from "./styles";
import Banner from "../../components/UI/Banner";
import images from "../../constants/images";
import videoMuve from "../../assets/videos/video-muve.mp4";
import depoimentoMuve from "../../assets/videos/depoimentoMuve.mp4";
import muve1 from "../../assets/images/muve1.jpeg";
import muve2 from "../../assets/images/muve2.jpeg";
import leiMuve from "../../assets/docs/lei.pdf";
import regimento from "../../assets/docs/regimento.pdf";
import comissao from "../../assets/docs/decretoCom.pdf";

// const openPadlet = () => {
//   window.open("https://padlet.com/rodrigobrunaikovics/4u3818xq729g7xkh");
// };

const Muve = () => {
  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconMuve}>MUVE</Banner>
      <ContentBox>
        <ContentText>
          <Title>QUEM SOMOS? </Title>
            <Text>  
              O Museu de Vivências Educacionais, criado a partir da LEI No 7.799, de 09 de junho de 2022, vinculado e subordinado administrativamente
              à Secretaria Municipal de Educação, fica localizado no Casarão da Rua Coronel Souza Franco, 917.
            </Text>
            <Text>
              É um centro de Educação Patrimonial dotado de recursos tecnológicos destinados à exposição de conteúdos organizados em salas temáticas, com o
              objetivo de propagar de forma interativa, a história do Município de Mogi das Cruzes.
            </Text>
            <Text>
              O público alvo do MUVE são os estudantes das Redes Municipal, Estadual e Particular de Ensino, bem como os educadores e demais envolvidos no
              processo de ensino e aprendizagem.
            </Text>
            <ViewImages>
              <Images src={muve1} />
              <Images src={muve2} />
            </ViewImages>
            <Text>
              As visitas são agendadas e monitoradas e buscam incentivar a curiosidade, a
              criatividade, o repertório cultural, o pensamento crítico e muito mais.
              A visitação para o público em geral é permitida mediante agendamento,
              atendendo as especificidades do espaço.
            </Text>
            {/* <Title>O que está acontecendo? &nbsp;&nbsp;
              <LinkDoc onClick={openPadlet}>Clique para saber mais</LinkDoc>
            </Title> */}
            <Title>Lei de criação do MUVE: &nbsp;&nbsp;
              <LinkDoc href={leiMuve} target="_blank">Clique para saber mais</LinkDoc>
            </Title>
            <Title>Regimento interno: &nbsp;&nbsp;
              <LinkDoc href={regimento} target="_blank">Clique para saber mais</LinkDoc>
            </Title>
            <Title>Comissão Gestora: &nbsp;&nbsp;
              <LinkDoc href={comissao} target="_blank">Clique para saber mais</LinkDoc>
            </Title>
            <br />
            <Title>ORIENTAÇÕES PARA VISITAÇÃO</Title>
            <Video controls>
              <source src={videoMuve} type="video/mp4" />
            </Video>
            <br />
            <ViewImages>
              <Video controls>
                <source src={depoimentoMuve} type="video/mp4" />
              </Video>
            </ViewImages>

          </ContentText>

        <Map
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.2958542158535!2d-46.190955984494636!3d-23.52185916602098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cdd9807f927551%3A0xd01418daf718fa01!2sMuseu%20de%20Viv%C3%AAncias%20Educacionais%20(MUVE)!5e0!3m2!1spt-BR!2sbr!4v1672763517130!5m2!1spt-BR!2sbr"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />

        <Contact>R. Cel. Souza Franco, 917 - Centro</Contact>
        <Contact>CEP: 08710-025, Mogi das Cruzes - SP.</Contact>
        <Contact>Segunda a sexta-feira, das 8 às 17 horas.</Contact>
        <Contact>(11) 4798-7429</Contact>
        <Contact>muve@sme-mogidascruzes.sp.gov.br</Contact>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Muve;
