import React from "react";

import { useTheme } from "../../hooks/useTheme";

import { Container } from "./styles";

const Theme = ({ children }) => {
  const { themeFile } = useTheme();

  // useEffect(() => {
  //   console.log("theme", themeFile);
  // });

  return <Container themeSchema={themeFile}>{children}</Container>;
};

export default Theme;
