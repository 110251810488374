import React from "react";
import { useTheme } from "../../../hooks/useTheme";
import { Container, PmmcLogo } from "./styles";
import { images } from "../../../constants";

const Footer = () => {
  const { themeFile } = useTheme();
  return (
    <Container>
      {/* <PmmcLogo
        src={
          themeFile.COLORS.highContrast
            ? images.logoPMMCsvgTransparent
            : images.logoPMMCsvgWhite
        }
        alt="Mogi das Cruzes - SP"
      /> */}
      <PmmcLogo
        src={
          themeFile.COLORS.highContrast
            ? images.logoPMMCBrasaosvgBlack
            : images.logoPMMCBrasaosvgWhite
        }
        alt="Mogi das Cruzes - SP"
      />
    </Container>
  );
};

export default Footer;
