import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import * as Yup from "yup";
import ReactCrop from "react-image-crop";
import { useTheme } from "../../../hooks/useTheme";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import getCroppedImg from "../../../utils/getCroppedImg";
import "react-image-crop/dist/ReactCrop.css";
import {
  Container,
  Form,
  Item,
  Greetings,
  Initial,
  CoverUrlBox,
  CoverUrlCropped,
  ModalCoverUrl,
  ModalCoverUrlContent,
  ChangeAvatarBox,
  ChangeAvatarLabel,
  ItemName,
  ItemDados,
  Note,
  DivPend,
  Img,
  DivTitle,
  TitlePend,
  Link,
  ItemDadosRow,
} from "./styles";
import Error from "../../../components/UI/Error";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
// import Cargo from "../../../components/UI/Cargo";
import CargoV2 from "../../../components/UI/CargoV2.0";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";
import Modal from "../../../components/UI/Modal";

import { images } from "../../../constants";

const openForm = () => {
  window.open("https://docs.google.com/forms/d/e/1FAIpQLSdf0X5a782hxjk7SucKnuOIY-zJ2Uxtb44xGUi9c_B5I7whDg/viewform");
};

const Profile = () => {
  const { themeFile } = useTheme();
  const { user, updateUser, switchProfile } = useAuth();
  const [showModalCoverUrl, setShowModalCoverUrl] = useState(false);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 1 / 1,
  });

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  //state result para uso de imagem jpg
  const [result, setResult] = useState(() => {
    return user.profiles[0]?.avatarURL;
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      avatar: "",
      cpf: "",
      classes:{
          rgf1:[],
          rgf2:[]
        },
      phone_number1: "",
      phone_number2: "",
      rgf1:"",
      rgf2:"",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Digite um nome."),
    }),

    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      editName({
        data: {
          name: formik.values.name,
        },
      });
    },
  });

  const handleAttach = (file) => {
    console.log("entrou no handleFile");
    const type = file.target.files[0].type.split("/");
    if (type[0] === "image") {
      setFile(URL.createObjectURL(file.target.files[0]));
      setShowModalCoverUrl(true);
    }
    file.target.value = null;
  };

  const handleCrop = () => {
    if (crop.height !== 0) {
      setResult(getCroppedImg(crop, image));
    } else {
      const full = {
        ...crop,
        width: image.width,
        height: image.height,
      };
      setResult(getCroppedImg(full, image));
    }
    setUploaded(true);
  };

  const handleUpdateCover = () => {
    setShowModalCoverUrl(false);
    // formik.setFieldValue("avatar", result);
    handleUpdateAvatar();
  };

  // console.log(formik.errors);
  // console.log(formik.isValid);

  const [editName, editNameInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/profile",
    method: "patch",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("editou", response);
        // console.log(user);
        const newUser = {
          ...user,
          name: formik.values.name,
        };
        // console.log("edited", newUser);
        // setVisibilities(response.data);
        updateUser(newUser);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", user.name);
    formik.setFieldValue("email", user.dados.email);
    formik.setFieldValue("cpf", user.dados.cpf);
    formik.setFieldValue("classes", user.dados.classes);
    formik.setFieldValue("phone_number1", user.dados.phone_number1);
    formik.setFieldValue("phone_number2", user.dados.phone_number2);
    formik.setFieldValue("rgf1", user.dados.rgf1);
    formik.setFieldValue("rgf2", user.dados.rgf2);

    console.log(user.dados.classes.rgf1);

    let temp = [{dado:"1",numero:1},{dado:"2",numero:2},{dado:"3",numero:3},{dado:"4",numero:4}]

    let temp2 = [temp[2],...temp.filter((teste)=>{return teste!==temp[2]})];
    console.log("teste",temp2)

    // eslint-disable-next-line
  }, []);

  const [updateAvatar, updateAvatarInfo] = useApi({
    debounceDelay: 0,
    url: "avatar/v1",
    method: "patch",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        let profiles = user.profiles;
        profiles[0].avatarURL = response.data.avatar_url;
        const newUser = {
          ...user,
          profiles,
        };
        updateUser(newUser);
        setUploaded(false);
      }
    },
  });

  const handleUpdateAvatar = () => {
    let cover = {
      mime: "",
      encode: "",
      data: "",
    };

    // console.log("INDO PRA API", result);

    cover = {
      mime: result ? result.split(";")[0].split(":")[1] : "",
      encode: result ? result.split(";")[1].split(",")[0] : "",
      data: result ? result.split(",")[1] : "",
    };

    const objDB = {
      cover,
      filename: "",
    };

    updateAvatar({
      data: objDB,
    });
  };

  const checkBase64 = (image) => {
    if (!image) return false;
    const check = image.split(";");
    if (check.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  const handleTime = () => {
    const now = new Date().getHours();

    if (now > 5 && now < 12) {
      return <Greetings>Bom dia, {formik.values.name.split(" ")[0]}</Greetings>;
    }
    if (now > 12 && now < 18) {
      return (
        <Greetings>Boa tarde, {formik.values.name.split(" ")[0]}</Greetings>
      );
    }
    if (now >= 18 && now <= 5) {
      return (
        <Greetings>Boa noite, {formik.values.name.split(" ")[0]}</Greetings>
      );
    }
  };

  return (
    <Container>
      {(updateAvatarInfo.loading || editNameInfo.loading) && (
        <FullScreenLoading />
      )}
      {(updateAvatarInfo.status || editNameInfo.status) && (
        <Error errorCode={401} />
      )}
      <CoverUrlBox>
        {showModalCoverUrl && (
          <ModalCoverUrl>
            <CancelButton
              text="X"
              onClick={() => setShowModalCoverUrl(false)}
            />
            <ModalCoverUrlContent>
              <ReactCrop
                src={file}
                circularCrop={true}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
              />
              <ConfirmButton text="Cortar" type="button" onClick={handleCrop} />
              <CoverUrlCropped
                src={
                  checkBase64(result)
                    ? result
                    : process.env.REACT_APP_CDN_URL + "avatar/" + result
                }
                alt="Nova Capa"
              />
              <ConfirmButton
                text="Salvar"
                type="button"
                disabled={!uploaded}
                onClick={handleUpdateCover}
              />
            </ModalCoverUrlContent>
          </ModalCoverUrl>
        )}
      </CoverUrlBox>
      <Form onSubmit={formik.handleSubmit}>
        <Item>{handleTime()}</Item>
        <Item>
          <Initial>
            Para acessar o conteúdo, clique no menu lateral e escolha a opção
            desejada.
          </Initial>
        </Item>
        <Item>
          <ChangeAvatarBox>
            {result ? (
              <img
                src={
                  !checkBase64(result)
                    ? process.env.REACT_APP_CDN_URL + "avatar/" + result
                    : result
                }
                alt="Avatar"
              />
            ) : (
              <FaRegUserCircle size="100%" color={themeFile.COLORS.primary} />
            )}
            <ChangeAvatarLabel htmlFor="avatarFile">
              Alterar foto
            </ChangeAvatarLabel>
            <input
              type="file"
              id="avatarFile"
              accept="image/*"
              onChange={(file) => handleAttach(file)}
            />
          </ChangeAvatarBox>
        </Item>        
        <ItemName>
            {formik.values.name}
        </ItemName>
        <ItemDadosRow>
            Perfil de acesso: {user.profiles[0].name} {user.profiles.length>1 && <ReadMoreButton
          text="Trocar perfil"
          type="button"
          onClick={()=>{setModal(!modal)}}
        />}
        </ItemDadosRow>
          {/* <InputText
            placeholder="Nome completo"
            columns={90}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name && formik.errors.name
            }
          /> */}

        {/* {formik.values.cargos?.map((cargo,index)=>(
          <Cargo 
              key={index}
              numero={index+1}
              rgf={cargo.rgf}
              cargo={cargo.cargo}
              escola={cargo.escola}
              htpf={cargo.htpf}
            />
        ))} */}

        {
          formik.values.classes.rgf1.filter((classe)=>{return classe?.idUserHasProfile===user.profiles[0]?.idUserHasProfile}).length>0 && <CargoV2 classes={formik.values.classes.rgf1.filter((classe)=>{return classe?.idUserHasProfile===user.profiles[0]?.idUserHasProfile})} />
        }

        {
          formik.values.classes.rgf2.filter((classe)=>{return classe?.idUserHasProfile===user.profiles[0]?.idUserHasProfile}).length>0 && <CargoV2 classes={formik.values.classes.rgf2.filter((classe)=>{return classe?.idUserHasProfile===user.profiles[0]?.idUserHasProfile})} />
        }

        { formik.values.classes.rgf1.filter((classe)=>{return classe?.idUserHasProfile===user.profiles[0]?.idUserHasProfile}).length===0 && <ItemDados>{formik.values.rgf2?<><p>RGF1: {formik.values.rgf1}</p><p>RGF2: {formik.values.rgf2}</p></>:<p>RGF: {formik.values.rgf1}</p>}</ItemDados>}
        
        <ItemDados>
          <span>E-mail: {formik.values.email}</span>
        </ItemDados>

        <ItemDados>
          CPF: {`${formik.values.cpf.slice(0, formik.values.cpf.length-8)}.${formik.values.cpf.slice(formik.values.cpf.length-8, formik.values.cpf.length-5)}.${formik.values.cpf.slice(formik.values.cpf.length-5, formik.values.cpf.length-2)}-${formik.values.cpf.slice(formik.values.cpf.length-2, formik.values.cpf.length)}`}
        </ItemDados>

        <Note>* Qualquer divergência nos seus dados, por favor enviar um e-mail para o canalpedagogico@se-pmmc.com.br. Para servidores da educação municipal de Mogi das cruzes,
          por favor, envie um e-mail também para: rh@se-pmmc.com.br para que seus dados
          sejam corrigidos junto ao SGE.
        </Note>

        {(user.profiles[0].idProfile === 'b56d58fe-2377-4428-854d-5f20670fb166' ||
        user.profiles[0].idProfile === '672c9f68-bcb7-4657-98c6-beb828c0729a' ||
        user.profiles[0].idProfile === 'c20e5631-fe23-4c64-b01c-34463a81c981' ||
        user.profiles[0].idProfile === '8bcc6f27-d0eb-413b-8e11-c34abb07689c' ) && 
          (
            <DivPend>
              <DivTitle>
                <Img src={images.note}></Img>
                <TitlePend>Formulário a preencher:</TitlePend>
               </DivTitle>

               <ItemDados>
               Mapeamento de imigrantes e refugiados - Este formulário destina-se a coletar dados sobre crianças matriculadas na Rede Municipal de Mogi das Cruzes, seja em escolas municipais
                ou em unidades subvencionadas, que sejam elas próprias, ou suas famílias, oriundas de  outros países (aqui referidos como IMIGRANTES)
                ou de outros países passando por situação de violação generalizada de direitos humanos (aqui referidos como REFUGIADOS). 
                <Link onClick={openForm}>Clique aqui para preencher</Link>
              </ItemDados>
            </DivPend>
          )}

        {/* <ItemDados>Editor:<br/>{user.profiles[0].editor.map((item)=>(<p>{item.category}</p>))}</ItemDados> */}

        {/* <Controls>
          <CancelButton text="Cancelar" />
          <ConfirmButton
            text="Salvar"
            type="submit"
            disabled={!formik.isValid}
          />
        </Controls> */}
      </Form>
      <Modal open={modal} buttonClose={false}>
          <div>Trocar Perfil</div>
          {user.profiles.map((item)=>(<ReadMoreButton
            key={item.idProfile}
            text={item.name}
            type="button"
            onClick={()=>{switchProfile(item.name,navigate);setModal(!modal);}}
          />))}
        </Modal>
    </Container>
  );
};

export default Profile;
