import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  color: var(--primary);
  background-color: var(--secondary);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -2.5rem;
  position: relative;
  width: 100%;
`;

export const IconBox = styled.div`
  margin-left: 1rem;
  padding-top: 0.5rem;
  img{
    width: 10rem;
  }
`;

export const ListContent = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction: ${(props) => props.row ? css`row` : css`column`};
  align-items:center;
  justify-content:flex-start;
  flex-wrap: wrap;
  width: ${(props) => css`${100-props.reduct}%`};;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
  gap: 2rem 4rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {}
  @media (max-width: 992px) {}
  @media (max-width: 1200px) {}
`;

export const ListFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items:flex-end;
  justify-content:space-around;
  flex-wrap: no-wrap;
  width: 70vw;
  margin: 0 0 1rem 0;
  padding: 0 0 2rem 0;
  border-color: var(--primary);
  border-width: 3px;
  border-style: none none solid none;
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const TextFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  span.tags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    border-radius: 1rem;
    background-color:var(--gray);
    margin: 0 0 1rem 2rem;
    padding: 0.5rem;
  }
  span.button {
    margin-left: 2rem;
  }
`;

export const ListMode = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:flex-end;
  flex-wrap: no-wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  div {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    cursor: pointer;
  }
`;

export const AdvancedOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content:center;
  width: 55vw;
  margin: 0;
  padding: 2rem;
`;

export const ItemOptions = styled.div`
  margin: 1rem 0;
`;


export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction: ${(props) => props.direction==="grade" ? css`column` : css`row`};
  align-items:center;
  justify-content:${(props) => props.info ? css`space-between` : css`center`};
  flex-wrap:nowrap;
  padding: 1rem;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  cursor: ${(props) => props.pointer ? css`pointer` : css`auto`};
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`3rem` : css`0rem`};
  background-color:var(--softGray);
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: 768px) {}
  @media (max-width: 992px) {}
  @media (max-width: 1200px) {}
`;

export const Info = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  padding: ${(props) => props.mode==="grade" ? css`1rem` : css`1rem`};
  width: 80%;
  margin: 0;
  border: 0;
  background-color:var(--softGray);
  color:var(--primary);
  p {
    display: flex;
    width: 100%;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;
    justify-content:flex-start;
  }
`;

export const Editar = styled.a`
  min-width: 2rem;
  margin-left: 10px;
`;

export const Button = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-end;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0;
  padding: 1rem;
  border: 0;
  background-color:var(--softGray);
  color:var(--primary);
  @media (max-width: 600px) {
    align-items:center;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-around;
  flex-wrap:nowrap;
  width: 100%;
  margin: 1rem 0rem;
`;

export const SelectAbility = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  width: 100%;
  div {
    flex-direction:row;
    border: 1px solid var(--primary);
    width: 100%;
    flex-wrap: wrap;
    border: calc(var(--size) * 0.1) solid var(--primary);
    background-color: var(--secondary);
    border-radius: 1rem;
    padding: 0.6rem 1rem;
    color: var(--primary);
    cursor: context-menu;
  }
  span {
    width: 100%;
    font-size: calc(var(--size) * 1.6);
    font-family: var(--fontFamily);
    font-weight: var(--boldFont);
    color: var(--gray);
    margin: 0;
    padding: 0.3rem;
  }
`;

export const Ability = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  width: 80%;
  height: 80vh;
  div.ability {
    display: flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:flex-start;
    border: calc(var(--size) * 0.1) solid var(--primary);
    border-radius: 1rem;
    padding: 0.6rem 1rem;
    width: 100%;
    padding: 1rem;
    margin: 1rem 5rem 0rem 5rem;
    @media (max-width: 600px) {
      flex-direction:column;
    }
  }
  div.text {
    width: 76%;
  }
  div.button {
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-end;
    width: 24%;
    height: 100%;
    @media (max-width: 600px) {
      width: 100%;
      justify-content:center;
    }
  }
  div.center {
    width: 100%;
    border: 0;
    justify-content:center;
  }
`;

export const ImageBlock = styled.div`
  width: 16vw;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Image = styled.div`
  width: 16vw;
  height: 25vh;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  img{
    max-height: 100%;
    max-width: 100%
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  flex-direction:row;
  flex-wrap:wrap;
  align-items: center;
  justify-content: ${(props) => props.mode==="grade" ? css`center` : css`flex-start`};
  font: var(--title);
`;

export const Ad = styled.div`
  display: block;
  position: relative;
  width: 100%;
  span {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    img {
      max-width: 50px;    
    }
  }
`;

export const FilterBox = styled.div`
  position: fixed;
  top:35vh;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: var(--secondary);
  background-color: var(--purple);
  border-radius: 0 1.5rem 1.5rem 0;
  transition: left 0.3s ease-in-out;
  @media (min-width: 0px) {
    ${(props) =>
      props.isOpen
        ? css`
            left: 30.5rem;
          `
        : css`
            left: 0rem;
          `}
  }

  @media (max-width: 450px) {
    gap: 1rem;
  }
`;

export const FilterList = styled.div`
  position: fixed;
  top:35vh;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  color: var(--secondary);
  background-color: var(--purple);
  border-radius: 0 0 1.5rem 0;
  transition: left 0.3s ease-in-out;
  width: 30.5rem;
  max-height: 60vh;
  z-index: 0;
  gap: 2rem;
  padding: 2rem;
  overflow-y: auto;

  .check {
    color: var(--secondary);
  }

  @media (min-width: 0px) {
    ${(props) =>
      props.isOpen
        ? css`
            left: 0;
          `
        : css`
            left: -30.5rem;
          `}
  }

  @media (max-width: 450px) {
    gap: 1rem;
  }
`;

export const LoadingMore = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  span{
    width:3rem;
  }
`;