import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  button{
    margin: 1rem;
  }
`;

export const Title = styled.h1``;

export const Description = styled.div``;

export const Teacher = styled.div``;

export const Meetings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    margin: 1rem 0;
  }
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin: 1rem 0;
  }
`;

export const Date = styled.div``;

export const Place = styled.div``;

export const VisibilitiesContainer = styled.div``;

export const Table = styled.table`
  width: 100%;
  margin: 2rem 0rem;
  border-collapse: collapse;
  tr{
    border: 1px solid black;
  }
  th{
    text-align: center;
    border: 1px solid black;
  }
  td{
    padding: 0rem 0.75rem;
    text-align: center;
    border: 1px solid black;
  }
  .cpfClass {
    min-width: 14rem;
  }
  .classeClass{
    min-width: 14rem;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button{
      width: 10rem;
    }
  }
`;

export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction: ${(props) => props.row ? css`row` : css`column`};
  align-items:${(props) => props.align ? css`${props.align}` : css`center`};
  justify-content:${(props) => props.info ? css`space-between` : css`center`};
  ${(props) => props.justify && css`justify-content:${props.justify};`}
  flex-wrap:wrap;
  width: ${(props) => (props.divide ? css`${props.divide>2?98/3:98/props.divide}%` : props.reduct ? (css`${100-props.reduct}%`): css`100%`) };
  ${(props) => props.height && css`min-height: ${props.height}rem;`}
  margin: ${(props) => props.marTop ? css`${props.marTop}rem`: css`1rem`} 0 0 0;
  padding: ${(props) => props.padV ? css`${props.padV}rem`: css`1rem`} ${(props) => props.padW ? css`${props.padW}rem`:css`0rem`};
  cursor: ${(props) => props.pointer ? css`pointer` : css`auto`};
  ${(props) => props.border && css`border: 1px solid var(--primary);`}
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
  button {
    margin: 1rem 0;
  }
`;

export const IconBox = styled.div`
  margin: 0rem 1rem;
  padding-top: 1rem;
  img{
    width: ${(props) => props.width ? css`${props.width}rem` : css`7rem`};
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  margin: 0rem 1rem;
  padding-top: 1rem;
  img{
    width: ${(props) => props.width ? css`${props.width}rem` : css`4rem`};
  }
`;

export const Justify = styled.div`
  text-align:center;
`;