import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { isWithinInterval, startOfYear, addMonths, endOfYear, subMonths, getUnixTime} from "date-fns";

import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";
// import * as XLSX from "xlsx";
// import * as ExcelJS from "exceljs";
// import { saveAs } from 'file-saver';

import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
// import ReadMoreButton from "../../components/UI/ReadMoreButton";

import {
  Container,
  // ListContent,
  ListItem,
} from "./styles";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
import { images } from "../../constants";

import useApi from "../../hooks/useApi";
// import { useAuth } from "../../hooks/useAuth";


const CourseReport = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [progressionList, setProgressionList] = useState([]);
  const [update, setUpdate] = useState(-1);
  const [controlTimer, setControlTimer] = useState();
  const [controlPage, setControlPage] = useState({ciclo:"",escola:""});
  const [firstCheck, setFirstCheck] = useState(true);

  const [getApplication, getApplicationInfo] = useApi({
    url: "eaprendi/applications",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("Applications",response.data);
        
        if(response.data?.dados?.length>0){
          let tempList = []
          // console.log("Teste",response.data.dados[0].aplicacoes);
          response.data.dados[0].aplicacoes?.forEach((applic) => {
            let index = tempList.findIndex((app)=>{
              return app.ciclo===applic.ciclo && app.componenteCurricular===applic.componenteCurricular;
            })
            if(index>=0){
              if(tempList[index].id<applic.id){
                tempList[index]=applic;
              }
            }
            else{
              tempList.push(applic);
            }
          });
          console.log("Ultimas aplicações",tempList);
          setApplicationList(tempList);
        }

        // setCoursesList(response.data);
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  // eslint-disable-next-line
  const [getProgressApplication, getProgressApplicationInfo] = useApi({
    url: "eaprendi/applications/progress",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("ApplicationsProgress",response.data);
        let totalDeAlunos=0;
        let finalizados=0;
        console.log(`Reconta total de Alunos (${totalDeAlunos}) e finalizados (${finalizados})`);
        response.data?.dados[0]?.progresso.forEach((escola)=>{
          totalDeAlunos+=escola.totalDeAlunos;
          finalizados+=escola.finalizados;
        });
        console.log(`Reconta total de Alunos (${totalDeAlunos}) e finalizados (${finalizados})`);
        if(update<0){
          setProgressionList([{...applicationList[0],progress:response.data?.dados[0]?.progresso,totalDeAlunos,finalizados}]);
          setUpdate((1<applicationList.length)?1:0);
        }
        else{
          let tempList=[];
          let tempUpdate = true;
          progressionList.forEach((progress)=>{
            if(progress.id===applicationList[update].id){
              tempList.push({...progress,progress:response.data?.dados[0]?.progresso,totalDeAlunos,finalizados});
              tempUpdate = false;
            }
            else{
              tempList.push(progress);
            }
          })
          if(tempUpdate){
            tempList.push({...applicationList[update],progress:response.data?.dados[0]?.progresso,totalDeAlunos,finalizados});
          }
          setProgressionList(tempList);
          console.log(`Application Progress (${update}) - ${applicationList[update].ciclo} - ${applicationList[update].componenteCurricular} - ${finalizados}/${totalDeAlunos}`,tempList);
          if(applicationList.length>1){
            setUpdate((update+1<applicationList.length)?update+1:0);
          }
          else{
            clearTimeout(controlTimer);
            let timer = setTimeout(()=>{
              getProgressApplication({
                params:{
                  idAplicacao:applicationList[update].id,
                }
              });
            },5000)
            setControlTimer(timer);
          }
        }
        // setProgressionList(progressionList);
  
        // setCoursesList(response.data);
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  useEffect(() => {
    if(isWithinInterval(new Date(),{
      start: startOfYear(new Date()),
      end: addMonths(startOfYear(new Date()), 6),
    })){
      let dtInicio = getUnixTime(startOfYear(new Date()));
      // let dtFim = 1710298799;
      let dtFim = getUnixTime(addMonths(startOfYear(new Date()), 6));
      getApplication({
        params:{
          dtInicio,
          dtFim
        }
      })
    }
    else{
      let dtInicio = getUnixTime(subMonths(endOfYear(new Date()),6));
      let dtFim = getUnixTime(endOfYear(new Date()));
      getApplication({
        params:{
          dtInicio,
          dtFim
        }
      })
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(applicationList.length>0){
      // console.log(`first get - ${applicationList[0].id}`)
      getProgressApplication({
        params:{
          idAplicacao:applicationList[0].id,
        }
      });
    }
    //eslint-disable-next-line
  }, [applicationList]);

  useEffect(() => {
    if(firstCheck && applicationList.length>0){
      let index = applicationList.findIndex((app)=>{
        return app?.skip!==true;
      })
      if(index>=0){
        if(applicationList[update]?.skip && progressionList.length>0){
          setUpdate((update+1<applicationList.length)?update+1:0);
        }
        else{
          if(update>=0){
            getProgressApplication({
              params:{
                idAplicacao:applicationList[update].id,
              }
            });
          }
        }
      }
      else{
        console.log("Não existe aplicações validas corrente")
      }
      if(update+1>=applicationList.length){
        setFirstCheck(false);
      }
    }
    else{
      let index = applicationList.findIndex((app)=>{
        return app?.skip!==true;
      })
      if(index>=0){
        if(applicationList[update]?.skip && progressionList.length>0){
          setUpdate((update+1<applicationList.length)?update+1:0);
        }
        else{
          if(update>=0){
            clearTimeout(controlTimer);
            let timer = setTimeout(()=>{
              getProgressApplication({
                params:{
                  idAplicacao:applicationList[update].id,
                }
              });
            },5000)
            setControlTimer(timer);
          }
        }
      }
      else{
        console.log("Não existe aplicações validas corrente")
      }
    }
    
    //eslint-disable-next-line
  }, [update]);

  useEffect(()=>{
    return () => {clearTimeout(controlTimer);};
  });

  return (
    <Container>
      {(getApplicationInfo.loading) && <FullScreenLoading />}
      
      <Accessibility />
      <Menu />
      <Banner icon={images.iconAvaliacao}>Acompanhamento das Avaliações do E-Aprendi</Banner>
      <ContentBox>
        {progressionList.length === 0?<>
        Não há avaliações ativas para acompanhar neste semestre.
        </>:<>
        {progressionList.sort((I1,I2)=>{return I1.ciclo<I2.ciclo?-1:I1.ciclo>I2.ciclo?1:0}).map((ciclo)=>(<>
          <ListItem 
            onClick={()=>{setControlPage({ciclo:ciclo.id===controlPage.ciclo?"":ciclo.id,escola:""})}}
            reduct={0}
            justify={"space-around"}
            border={true}
            pointer={true}
            roundborder={ciclo.id===controlPage.ciclo}
            selected={ciclo.id===controlPage.ciclo}
            porcento={ciclo.finalizados/ciclo.totalDeAlunos*100}
            visible={true}>
              <div>{ciclo.ciclo} - {ciclo.componenteCurricular}</div>
              <div className="row">
                <div>Total de Alunos:{ciclo.totalDeAlunos}</div>
                <div>Finalizados:{ciclo.finalizados} ({Math.round((ciclo.finalizados/ciclo.totalDeAlunos)*10000)/100}%)</div>
              </div>
          </ListItem>
          {ciclo.progress.sort((I1,I2)=>{return I1.nome<I2.nome?-1:I1.nome>I2.nome?1:0}).map((escola)=>(<>
            <ListItem 
              onClick={()=>{setControlPage({ciclo:ciclo.id,escola:escola.nome===controlPage.escola?"":escola.nome})}}
              reduct={5}
              justify={"space-around"}
              border={true}
              pointer={true}
              roundborder={escola.nome===controlPage.escola}
              selected={escola.nome===controlPage.escola}
              porcento={escola.finalizados/escola.totalDeAlunos*100}
              visible={ciclo.id===controlPage.ciclo}>
                <div>{escola.nome}</div>
                <div className="row">
                  <div>Total de Alunos:{escola.totalDeAlunos}</div>
                  <div>Finalizados:{escola.finalizados} ({Math.round((escola.finalizados/escola.totalDeAlunos)*10000)/100}%)</div>
                </div>
            </ListItem>
            {escola.turmas.sort((I1,I2)=>{return I1.turma<I2.turma?-1:I1.turma>I2.turma?1:0}).map((turma)=>(<>
              <ListItem 
                reduct={10}
                justify={"space-around"}
                border={true}
                selected={false}
                pointer={false}
                porcento={turma.finalizados/turma.totalDeAlunos*100}
                visible={ciclo.id===controlPage.ciclo && escola.nome===controlPage.escola}>
                  <div>{turma.turma}</div>
                  <div className="row">
                    <div>Total de Alunos:{turma.totalDeAlunos}</div>
                    <div>Finalizados:{turma.finalizados} ({Math.round((turma.finalizados/turma.totalDeAlunos)*10000)/100}%)</div>
                  </div>
              </ListItem>
            </>))}
          </>))}
        </>))}
        </>}
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CourseReport;