// EXTERNAL REACT LIBRARIES IMPORTS
import React, { useState, useEffect } from "react";
// import { MdFactCheck } from "react-icons/md";
// import { format } from "date-fns";
// import ptBR from "date-fns/esm/locale/pt-BR/";
import { Link, useNavigate } from "react-router-dom";

//HOOKS
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

// UI
import Accessibility from "../../components/UI/Accessibility";
import Menu from "../../components/UI/Menu";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
// import InputSearch from "../../components/UI/Inputs/InputSearch";
import NewsItemCourse from "../../components/UI/NewsItemCourse";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";
import Visibility from "../../components/UI/Visibility";
import Modal from "../../components/UI/Modal"
import ReadMoreButton from "../../components/UI/ReadMoreButton"
// import LoadingItem from "../../components/UI/LoadingItem";
// import cursosProfs from "../../assets/docs/orientacoesprofs.pdf";
// import cursosProfsIngr from "../../assets/docs/orientacoesprofsingr.pdf";
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";

import { format } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/";

//STYLES
import {
  Container,
  // Search,
  Message,
  // LinkDoc,
  PageMode,
  PageModeItem,
  ListContent,
  ListItem,
  Certificate,
  // Loading,
  // LoadingItemContainer,
} from "./styles";
// import SearchMessage from "../../components/UI/SearchMessage";
import ellipsis from "../../utils/ellipsis";
// import LoadingItem from "../../components/UI/LoadingItem";

const Courses = ({action}) => {
  //STATES
  // const [value, setValue] = useState("1");
  const [coursesList, setCoursesList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  // const [inputSearch, setInputSearch] = useState("");
  // const [isEditor, setIsEditor] = useState(false);
  const [pageModeControl, setPageModeControl] = useState(action==='subscription'?1:0);

  const [ModalOpenAlertUnsubscribe, setModalOpenAlertUnsubscribe] = useState(false);
  const [unsubscriptionStatusMessage, setModalOpenUnsubscriptionStatusMessage] = useState("");

  const handleModalAlertUnsubscribe = () => {
    setModalOpenAlertUnsubscribe((oldModalOpenAlertUnsubscribe) => !oldModalOpenAlertUnsubscribe);
  };

  const handleAlertUnsubscribeOk = () => {
    window.location.reload();
  };

  //CONSTANTES
  const { user } = useAuth();
  const navigate = useNavigate();

  // FUNÇÕES
  const [unsubscribe, UnsubscribeInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/subscription",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        setModalOpenUnsubscriptionStatusMessage("Inscrição cancelada com sucesso!");
        handleModalAlertUnsubscribe();
        // console.log("deletou", response);
      }
    },
  });

  const [getCourses, getCoursesInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/byTerm/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        setCoursesList(response.data);
      }
    },
  });

  const [getCertificate, getCertificateInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/certificate/byUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        setCertificateList(response.data);
      }
    },
    onError: (error) =>{
      console.log(error);
    }
  });

  const getEditorId = (type) => {
    let teste = user?.profiles[0]?.editor?.find(
      (item) =>
        item.category === "Formação Elementar" ||
        item.category === "Formação Complementar"
    );
    // setIsEditor(teste?.id ? true : false);
    return teste?.id ? teste.id : false;
  };

  const controlList = (typeChange=0, segmentChange=0)=>{
    let list = [];
    coursesList.forEach((type)=>{
      if(segmentChange!=0){
        if(type.courseType===typeChange){
          type.segments.forEach((segment)=>{
            if(segment.segment===segmentChange){
              if(segment.open){
                segment.open=false;
              }
              else{
                segment.open=true;
              }
            }
          })
        }
      }
      else{
        if(type.courseType===typeChange){
          if(type.open){
            type.open=false;
          }
          else{
            type.open=true;
          }
        }
      }
      list.push(type);
    })
    setCoursesList(list);
  };

  const editorId = getEditorId();

  const handleUnsubscribe = (id) => {
    unsubscribe({
      data: {
        id
      },
    });
  };

  const loadCourses = ()=>{
    if(pageModeControl===1 && user){
      getCourses({params:{onlySubscriptions:1}});
    }
    if(pageModeControl===0){
      getCourses();
    }
    if(user){
      getCertificate({params:{idUser:user.dados.idUser}})
    }
  }

  useEffect(() => {
    console.log("teste");
    loadCourses();
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageModeControl(action==='subscription'?1:action==='certificate'?2:0);
    // eslint-disable-next-line
  }, [action]);

  useEffect(() => {
    loadCourses();
    // eslint-disable-next-line
  }, [pageModeControl]);

  return (
    <Container>
      {(getCoursesInfo.loading || 
        UnsubscribeInfo.loading) && (
        <FullScreenLoading />
      )}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconFormacoes}
        url="/formacoes/add"
        editorId={editorId}
      >
        FORMAÇÕES
      </Banner>
      {/* <Search>
        <InputSearch
          placeholder="Digite o tema da formação"
          value={inputSearch}
        />
      </Search> */}
      <ContentBox>
        {/* <LinkDoc href={cursosProfs} target="_blank">* Orientações 2023 para professores de Infantil e Fundamental I (Clique aqui)</LinkDoc>
        <LinkDoc href={cursosProfsIngr} target="_blank">* Orientações 2023 para professores INGRESSANTES de Infantil e Fundamental I (Clique aqui)</LinkDoc> */}
        {user && <PageMode>
          <PageModeItem key={0} onClick={()=>{navigate(`/formacoes`)}} selected={pageModeControl===0}>Cursos Abertos</PageModeItem>
          <PageModeItem key={1} onClick={()=>{navigate(`/formacoes/subscription`)}} selected={pageModeControl===1}>Meus Cursos</PageModeItem> 
          {certificateList.filter((certificate)=>{return !certificate.canceled;}).length>0 && <PageModeItem key={2} onClick={()=>{navigate(`/formacoes/certificate`)}} selected={pageModeControl===2}>Meus Certificados</PageModeItem>}
        </PageMode>}
        {/* Pagina dos cursos gerais */}
        { ((pageModeControl===0)||(pageModeControl===1)) &&
          coursesList.map((type)=>(type.segments.length>0 &&
            <ListContent key={type.courseType} visible={true}>
              <ListItem key={type.courseType} onClick={()=>{controlList(type.courseType)}} visible={true} reduct={0} roundborder={true} selected={type.open}>{type.courseTypeName} {type.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>
              {type.segments.map((segment)=>(
                <ListContent visible={type.open} key={segment.segment}>
                  {pageModeControl===0 && type.courseTypeName!=="Formação Complementar" && <ListItem key={segment.segment} onClick={()=>{controlList(type.courseType,segment.segment)}} visible={type.open} reduct={2} roundborder={true} selected={segment.open}>{segment.segmentName===null?"OUTROS":segment.segmentName} {segment.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>}
                  {segment.courses.map((course)=>(
                    <ListItem 
                      key={course.id} 
                      visible={(type.courseTypeName==="Formação Complementar"||pageModeControl===1)?type.open:segment.open?true:false} 
                      reduct={type.courseTypeName==="Formação Complementar"?2:pageModeControl===0?4:2} 
                      roundborder={false}>
                      <NewsItemCourse
                        id={course.id}
                        title={
                          <Link to={`/formacoes/${course.id}`}>
                            {course.title}
                          </Link>
                        }
                        titleText={course.title}
                        text={
                          <>
                            <span>{ellipsis(course.description, 180)}</span>
                            {course.hasSubscription && <span>
                                Periodo de inscrição: 
                                {format(parseInt(course.subscriptionBegin), " dd/MM/yyyy ", {locale: ptBR,})}
                                -
                                {format(parseInt(course.subscriptionEnd), " dd/MM/yyyy", {locale: ptBR,})}
                            </span>}
                            {course.hasSubscription && <span>Vagas: {`${(course.subscriptionsLimit-course.totalSubscriptions)<0?0:course.subscriptionsLimit-course.totalSubscriptions}/${course.subscriptionsLimit} atualizado em: ${course.dateSearch}`}</span>}
                            {editorId && course.visibilities?.length>0 && <span>
                              <Visibility visibility={course.visibilities} />
                            </span>}
                          </>
                        }
                        // date={course.meetings[0]?.eventDate}
                        // begin={course.meetings[0]?.eventBegin}
                        // end={course.meetings[0]?.eventEnd}
                        mainCategory="Formações"
                        // local={course.meetings[0]?.place}
                        editorId={editorId}
                        url={`/formacoes/editar/${course.id}`}
                        subscriptionByChannel={course.hasSubscription}
                        subscriptionEnabled = {course.subscriptionEnabled}
                        idUserSubscription = {course.idUserSubscription}
                        subscriptionStatusMessage = {course.subscriptionStatusMessage}
                        subscriptionBegin = {course.subscriptionBegin}
                        subscriptionEnd = {course.subscriptionEnd}
                        subscriptionCancel = {handleUnsubscribe}
                      />
                    </ListItem>
                  ))}
                </ListContent>))}
            </ListContent>))
        }

        { pageModeControl===2 && <ListContent visible={pageModeControl===2}>
          {certificateList.filter((certificate)=>{return !certificate.canceled;}).length>0?
            certificateList.filter((certificate)=>{return !certificate.canceled;}).map((certificate)=>
            <Certificate
              key={certificate.idCertificate}
              roundborder={false}
              reduct={0}
              visible={pageModeControl===2}
            >
              {certificate.courseTitle} - {format(new Date(certificate.createdAt), " dd/MM/yyyy", {locale: ptBR,})}
              <ReadMoreButton onClick={()=>{navigate(`/coursecertificate/${certificate.idCertificate}`);}} text="Visualizar Certificado" />
            </Certificate>)
          :"Não há certificados a serem exibidos"}
        </ListContent>}

        {
          coursesList.length<1 && <ListContent visible={true}>Não há cursos a serem exibidos.</ListContent>
        }
        <Modal  open={ModalOpenAlertUnsubscribe} close={handleModalAlertUnsubscribe} buttonClose = {false} width={50} height={15}>
          <Message>
            <div>{unsubscriptionStatusMessage}</div>
            <ReadMoreButton onClick={handleAlertUnsubscribeOk} text="Ok" />
          </Message>
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Courses;
