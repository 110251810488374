import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Menu from "../../components/UI/Menu";
import { Container } from "./styles";
import Footer from "../../components/UI/Footer";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";
import Profile from "../Forms/ProfileForm2";

// const notificationsView = [
//   {
//     message: "Você se inscreveu na formação 'Educação Infantil', no CEMFORPE.",
//     created_at: "2022-04-01",
//     read: false,
//     link: "",
//     idnotification: "123ab",
//     senderAvatar: "",
//   },
//   {
//     message: "Você se inscreveu na formação 'Educação Infantil', no CEMFORPE.",
//     created_at: "2022-04-01",
//     read: true,
//     link: "",
//     idnotification: "123bb",
//     senderAvatar: "",
//   },
// ];

const MySpace = () => {
  // let notRead = 2;
  // const formatTime = (time) => {
  //   const now = new Date();
  //   const difference = differenceInSeconds(now, parseISO(time));
  //   // const difference = 2000000;
  //   let unity;
  //   let distance;

  //   if (difference < 60) {
  //     unity = difference > 1 ? "segundos" : "segundo";
  //     if (difference === 0) {
  //       return `Enviada agora.`;
  //     } else {
  //       return `Enviada há ${difference} ${unity}.`;
  //     }
  //   } else if (difference < 3600) {
  //     distance = parseInt(difference / 60);
  //     unity = distance > 1 ? "minutos" : "minuto";
  //     return `Enviada há ${distance} ${unity}.`;
  //   } else if (difference < 86400) {
  //     distance = parseInt(difference / 3600);
  //     unity = distance > 1 ? "horas" : "hora";
  //     return `Enviada há ${distance} ${unity}.`;
  //   } else {
  //     distance = parseInt(difference / 86400);
  //     unity = distance > 1 ? "dias" : "dia";
  //     return `Enviada há ${distance} ${unity}.`;
  //   }
  // };

  // const loadMore = (InviewObj, entry) => {
  //   if (InviewObj) {
  //     loadMessages({
  //       params: {
  //         initial: notifications.length + 1,
  //         limit: 10,
  //       },
  //     });
  //   }
  // };

  // const handleNotify = (notificationUrl, idNotification) => {
  //   Antes de navegar, fazer uma chamada para a API marcando como lida
  //   markAsRead({
  //     data: {
  //       idNotification,
  //     },
  //     notificationUrl,
  //   });

  //   Setar no notificationsView e no Notifications como lida
  //   console.log(idNotification);
  //   let newNotifications = notificationsView.map((item) => {
  //     if (item.idnotification === idNotification) {
  //       const itemModified = {
  //         ...item,
  //         read: true,
  //       };
  //       return itemModified;
  //     } else {
  //       return item;
  //     }
  //   });
  //   setNotificationsView(newNotifications);

  //   let decrement = false;

  //   newNotifications = notifications.map((item) => {
  //     if (item.idnotification === idNotification) {
  //       if (!item.read) {
  //         decrement = true;
  //       }
  //       const itemModified = {
  //         ...item,
  //         read: true,
  //       };
  //       return itemModified;
  //     } else {
  //       return item;
  //     }
  //   });
  //   setNotifications(newNotifications);

  //   decrement && setNotRead(notRead - 1);
  // };

  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconPerfil}>MEU ESPAÇO</Banner>
      <ContentBox>
        <Profile />
        {/* <h2>Aqui posteriormente virão as notificações.</h2> */}
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default MySpace;
