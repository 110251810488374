import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 0.4rem;
  cursor: pointer;
  user-select: none;
  font: var(--body);
  color: var(--primary);

  .checkmark {
    height: calc(var(--size) * 2);
    width: calc(var(--size) * 2);
    background-color: var(--secondary);
    border: calc(var(--size) * 0.2) solid var(--purple);
    border-radius: 50%;
    transition: all 0.2s ease;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    &:after {
      content: "";
      display: none;
      width: calc(var(--size) * 0.5);
      height: calc(var(--size) * 0.5);
      border: calc(var(--size) * 0.1) solid var(--purple);
      background-color: var(--purple);
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &:hover {
    .checkmark {
      background-color: var(--gray);
      border: calc(var(--size) * 0.2) solid var(--secondary);
    }
  }

  > input {
    display: none;
  }

  input:checked ~ .checkmark {
    border: calc(var(--size) * 0.2) solid var(--purple);
    background-color: var(--secondary);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
`;
