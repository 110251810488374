import React from "react";
import {
  Container,
  Title,
  Mark,
  TitleText,
  Editar,
  Info,
  MeetingsList,
  Formador,
  Contato,
  Link,
  SubTitle,
  Text,
  MoreInfo,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../../constants";
import { format, parseISO, isSameDay, getHours, getMinutes } from "date-fns";
import ReadMoreButton from "../../../../components/UI/ReadMoreButton";
import ellipsis from "../../../../utils/ellipsis";

const Meet = ({
  atividade = {},
  selectedDay = new Date(),
  isEditor = false,
  category = "",
}) => {

  const navigate = useNavigate();

  const handleView = (id,category) => {
    navigate(`/${category}/${id}`);
  };

  return (
    <Container
      type={
        atividade.mainCategory === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"
          ? "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"
          : atividade.category
      }
    >
      <Title>
        <Mark />
        <TitleText>
          <Link href={`/${category}/${atividade?.id}`}>{atividade?.title}</Link>
        </TitleText>
        {isEditor && (
          <Editar href={`/${category}/editar/${atividade?.id}`}>
            <img src={icons.editBlack} alt="" />
          </Editar>
        )}
      </Title>

      {atividade.eventDate !== null && (
        <Info>
          <SubTitle>Horário:</SubTitle>
          {getMinutes(new Date(atividade?.begin)) ===
            getMinutes(new Date(atividade?.end)) &&
          getHours(new Date(atividade?.begin)) ===
            getHours(new Date(atividade?.end))
            ? format(new Date(atividade?.begin), "HH:mm")
            : `${format(new Date(atividade.begin), "HH:mm")} - ${format(
                new Date(atividade.end),
                "HH:mm"
              )}`}
        </Info>
      )}

      {atividade.meetings.length > 0 && (
        <Info>
          <SubTitle>Horário:</SubTitle>
          <MeetingsList>
            {atividade.meetings.map(
              (meet, index) =>
                isSameDay(new Date(selectedDay), parseISO(meet.eventdate)) && (
                  <Text key={index}>
                    {`${format(new Date(meet.event_begin), "HH:mm")} - ${format(
                      new Date(meet.event_end),
                      "HH:mm"
                    )}`}
                  </Text>
                )
            )}
          </MeetingsList>
        </Info>
      )}

      {atividade.description && <Info>{ellipsis(atividade.description, 125)}</Info>}

      {atividade.mainCategory === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3" && atividade.begin && <Info>
        <SubTitle>
          Período da aplicação:{" "}
        </SubTitle>
        <Text>
          {format(
            new Date(parseInt(atividade.begin)),
            "dd/MM/yyyy"
          )}
        </Text>
        <Text>
          {
            !isSameDay(
              new Date(parseInt(atividade.begin)),
              new Date(parseInt(atividade.end))
            ) &&<><Text> - </Text><Text>{format(
              new Date(parseInt(atividade.end)),
              "dd/MM/yyyy"
            )}</Text></>
          }
        </Text>
      </Info>}

      {atividade.teacher && (
        <Formador>
          <span>Formador:</span> {atividade.teacher}
        </Formador>
      )}

      {atividade.teacher_contact && (
        <Contato>
          <span>Contato:</span> {atividade.teacher_contact}
        </Contato>
      )}

      {atividade.team && (
        <Info>
          <span>Equipe envolvida:</span> {atividade.team}
        </Info>
      )}

      <MoreInfo><ReadMoreButton onClick={()=>{handleView(atividade?.id,category)}} text="Saiba mais" /></MoreInfo>
    </Container>
  );
};

export default Meet;
