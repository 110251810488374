import styled from "styled-components";

export const Container = styled.footer`
  height: 10rem;
  width: 100%;
  background-color: var(--purple);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PmmcLogo = styled.img`
  width: 28rem;
  max-width: 80%;
`;
