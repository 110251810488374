import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { BsTrash } from "react-icons/bs";
import { useFormik } from "formik";
import { MdClose } from "react-icons/md";
import * as Yup from "yup";
import { useTheme } from "../../../hooks/useTheme";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import { v4 as uuidv4 } from "uuid";
import filesize from "filesize";
import {
  getHours,
  getMinutes,
} from "date-fns";

import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import {
  Container,
  Title,
  Form,
  Item,
  ItemFloor,
  Place,
  Controls,
  MeetContainer,
  MeetTitle,
  AddEvent,
  DeleteEvent,
  DocumentContainer,
  DocumentUrl,
  DocumentFile,
  DocumentName,
  DocumentSize,
  DocumentDeleteButton,
  Separator,
  Grade,
  ListButton,
  InputSegment,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputCheck from "../../../components/UI/Inputs/InputCheck";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import InputRadio from "../../../components/UI/Inputs/InputRadio";
import InputFile from "../../../components/UI/Inputs/InputFile";
import InputDate from "../../../components/UI/Inputs/InputDate";
import InputTime from "../../../components/UI/Inputs/InputTime";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import InputSelect from "../../../components/UI/Inputs/InputSelect";
import Modal from "../../../components/UI/Modal";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";

const CourseForm = ({ action }) => {
  const { themeFile } = useTheme();
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);
  const [segmento, setSegmento] = useState([]);
  const [visibilities, setVisibilities] = useState([]);
  const [subscriptionRules, setSubscriptionRules] = useState([]);
  const [schoolGradeList, setSchoolGradeList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const { id } = useParams();

  const [queueList, setQueueList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSegmentOpen, setModalSegmentOpen] = useState(false);

  const [modalSegmentMessage, setModalSegmentMessage] = useState({open:false,message:"",load:false});

  const [finaliza, setFinaliza] = useState(false);

  const [createSegment, setCreateSegment] = useState("");

  const navigate = useNavigate();

  const checkHorario = (begin,end) => {
    console.log("checkHorario",`${getHours(new Date(begin))}:${getMinutes(new Date(begin))}<${getHours(new Date(end))}:${getMinutes(new Date(end))}`);
    if(getHours(new Date(begin))<getHours(new Date(end))){
      console.log("Hora true");
      return true;
    }
    else{
      if(getHours(new Date(begin))===getHours(new Date(end))){
        if(getMinutes(new Date(begin))<getMinutes(new Date(end))){
          console.log("Minuto true");
          return true;
        } 
      }
      console.log("horario errado");
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      courseType: "",
      courseWorkload:0,
      segment:"",
      title: "",
      formType: "public",
      description: "",
      teacher: "",
      teacherContact: "",
      documents: [],
      deletedIds: [],
      visibilities: [],
      iduserTutor:"",
      meetings: [
        {
          id: "",
          eventDate: "",
          eventBegin: "",
          eventEnd: "",
          place: "",
        },
      ],
      hasSubscription: false,
      subscriptionRules:[],
      subscriptionsLimit: 1,
      subscriptionBegin: "",
      subscriptionEnd: "",
      hasNumberOfClass:false,
      initialClassNumber:1,
      finalClassNumber:1,      
    },
    validationSchema: Yup.object({
      meetings: Yup.array().of(
        Yup.object().shape({
          place: Yup.string().required("Digite o local."),
          eventDate: Yup.number().required("Selecione a data de início."),
          eventBegin: Yup.number().test(
            "test-eventbegin",
            "Horario inicial deve ser menor que o horário final",
            function checkEnd(eventBegin) {
              const { eventEnd } = this.parent;
              if (eventEnd === "" || eventBegin === ""){
                return true;
              }
              return checkHorario(checkInt(eventBegin),checkInt(eventEnd));
            }).required("Selecione o horário de início."),
          eventEnd: Yup.number().test(
            "test-eventend",
            "Horario inicial deve ser menor que o horário final",
            function checkEnd(eventEnd) {
              const { eventBegin } = this.parent;
              if (eventEnd === "" || eventBegin === ""){
                return true;
              }
              return checkHorario(checkInt(eventBegin),checkInt(eventEnd));
            }).required("Selecione o horário de término."),
        })
      ),
      formType: Yup.string().required(),
      courseType: Yup.string().required(),
      title: Yup.string().required("Digite o título."),
      description: Yup.string().required("Digite a descrição."),
      teacher: Yup.string().required("Digite o(s) nome(s) do(s) formador(es)."),
      teacherContact: Yup.string().required(
        "Digite um contato para a formação."
      ),
      visibilities: Yup.array().when("formType", {
        is: "private",
        then: Yup.array().min(1, "Selecione 1"),
      }),
      hasSubscription: Yup.bool(),
      subscriptionBegin: Yup.number().test(
          "test-begin",
          "Data inicial deve ser menor que a data final",
          function checkEnd(subscriptionBegin) {
            const { subscriptionEnd } = this.parent;
            if (!(subscriptionEnd && subscriptionBegin)){
              return true;
            }
            if ((subscriptionBegin <= subscriptionEnd)) {
              return true;
            } else {
              return false;
            }
          }
      ).required("Selecione a data de início das inscrições."),
      subscriptionEnd: Yup.number().test(
        "test-End",
        "Data Final deve ser maior que a data inicial",
        function checkBegin(subscriptionEnd) {
          const { subscriptionBegin } = this.parent;
          if (!(subscriptionEnd && subscriptionBegin)){
            return true;
          }
          if ((subscriptionBegin <= subscriptionEnd)) {
            return true;
          } else {
            return false;
          }
        }
    ).required("Selecione a data final das inscrições."),
      subscriptionsLimit: Yup.number().when("hasSubscription", {
        is: true,
        then: Yup.number().min(1, "Defina o limite de incrições para pelo menos 1").required("Digite o numero de inscritos para a formação"),
      }),
      subscriptionRules: Yup.array(),
      hasNumberOfClass: Yup.boolean(),
      initialClassNumber: Yup.number().test("","O numero da turma inicial tem que ser menor ou igual do que da turma final",
                      (initialClassNumber, other)=>{
                        const {hasNumberOfClass, finalClassNumber} = other.parent;
                        if(hasNumberOfClass){
                          if(initialClassNumber>finalClassNumber){
                            return false;
                          }
                        }
                        return true;
                      }),
      finalClassNumber: Yup.number().test("","O numero da turma final tem que ser maior ou igual do que da turma inicial",
                      (finalClassNumber, other)=>{
                        const {hasNumberOfClass, initialClassNumber} = other.parent;
                        if(hasNumberOfClass){
                          if(initialClassNumber>finalClassNumber){
                            return false;
                          }
                        }
                        return true;
                      }),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log("TOSAVE", values);
      let createQueueList = [];
      let form;
      if(values.hasNumberOfClass){
        for(let i=values.initialClassNumber; i<=values.finalClassNumber; i++){
          let queue;
          queue = createForm(values,i);
          // console.log(queue);
          createQueueList.push({queue,turma:i,status:"aguardando"});
        }
      }
      else{
        form = createForm(values);
      }

      // createQueueList?.map((formVerify)=>{
      //   console.log(formVerify);
      //   for (var pair of formVerify.queue.entries()) {
      //     if(pair[0]==="meetings"){
      //       console.log(pair[0]+ ', ' + pair[1]); 
      //     }
      //   }
      // })
      
      if (action === "add") {
        if(values.hasNumberOfClass){
          setQueueList(createQueueList);
          setFinaliza(false);
          setModalOpen(true);
        }
        else{
          saveItem({
            data:form,
          });
        }
        
      } else {
        editItem({
          data: form,
        });
      }
    },
  });

  const createForm = (values,turma=null)=>{
    // console.log("Criando Form: values:",values," turma - ", turma);
    let form = new FormData();
    form.append("courseType", values.courseType);
    form.append("id", values.id);
    form.append("title", `${values.title} ${turma?`(Turma ${turma})`:''}`);
    form.append("formType", values.formType);
    form.append("description", values.description);
    form.append("teacher", values.teacher);
    form.append("teacherContact", values.teacherContact);
    form.append("courseWorkload", values.courseWorkload);
    values.documents.map((item) => {
      return form.append("file", item.file);
    });
    let meetings = [];
    values.meetings.forEach((meeting) =>{
      meetings.push({...meeting,id: uuidv4()});
    });
    form.append("meetings", JSON.stringify(meetings));
    form.append("visibilities", JSON.stringify(values.visibilities));
    form.append("deletedIds", JSON.stringify(values.deletedIds));

    form.append("hasSubscription", values.hasSubscription);

    let subscriptionRules = [];
    values.subscriptionRules.forEach((rule)=>{
      subscriptionRules.push(JSON.parse(rule));
    });

    form.append("subscriptionRules", JSON.stringify(subscriptionRules));

    form.append("subscriptionsLimit", values.subscriptionsLimit);
    form.append("subscriptionBegin", values.subscriptionBegin);
    form.append("subscriptionEnd", values.subscriptionEnd);
    form.append("segment", values.segment);
    form.append("iduserTutor", values.iduserTutor);

    return form;
  };

  // console.log(formik.errors);
  // if (typeof formik.errors.meetings !== "undefined") {
  //   console.log(formik.errors.meetings);
  //   console.log(formik.errors.meetings[0]);
  // }
  // console.log(formik.isValid);

  const handleFileType = (fileType) => {
    switch (fileType[0]) {
      case "image":
        return {
          type: "image",
          extension: fileType[1],
        };
      case "application":
        if (fileType[1] === "pdf") {
          return {
            type: "pdf",
            extension: fileType[1],
          };
        }
        break;
      case "video":
        return {
          type: "video",
          extension: fileType[1],
        };

      default:
        break;
    }
  };

  const handleAttachFiles = (event) => {
    const file = event.target.files[0];

    const exists = formik.values.documents.filter(
      (item) => item.originalName === file.name
    );

    if (exists.length > 0) return false;

    const fileType = handleFileType(file.type.split("/"));
    event.target.value = null;

    const newName = `material_${uuidv4()}.${fileType.extension}`;

    const newFile = {
      id: uuidv4(),
      originalName: file.name,
      newName,
      file,
      sizeInBytes: file.size,
      formatSize: filesize(file.size),
      fileType,
    };

    let newArray = [...formik.values.documents];
    newArray.push(newFile);
    formik.setFieldValue("documents", newArray);
  };

  const handleAddMeet = () => {
    const newMeet = {
      id: uuidv4(),
      eventDate: "",
      eventBegin: "",
      eventEnd: "",
      place: "",
    };
    let newArray = [...formik.values.meetings];
    newArray.push(newMeet);
    formik.setFieldValue("meetings", newArray);
  };

  const handleDeleteMeet = (meetId) => {
    let newArray = [
      ...formik.values.meetings.filter((item) => item.id !== meetId),
    ];
    formik.setFieldValue("meetings", newArray);
  };

  const handleDeleteDocument = (docId) => {
    let newArray = [
      ...formik.values.documents.filter((item) => item.id !== docId),
    ];
    formik.setFieldValue("documents", newArray);

    if (action === "edit") {
      const deletedIds = [...formik.values.deletedIds, docId];
      formik.setFieldValue("deletedIds", deletedIds);
    }
  };

  const [loadVisibilities, loadVisibilitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/visibility",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("visibilities", response);
        setVisibilities(response.data);
      }
    },
  });

  const [loadCategories, loadCategoriesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/category",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log(response);
        setCategories(response.data);
      }
    },
  });

  const [loadSegmento, loadSegmentoInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/category",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("segmento", response);
        setSegmento(response.data);
        if(response.data.length===0){
          formik.setFieldValue("segment", "");
        }
      }
    },
  });

  const [postSegmento, postSegmentoInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/category",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("response");
        setModalSegmentOpen(false);
        setModalSegmentMessage({open:true,message:"Segmento criado com sucesso!",load:true});        
      }
    },
    onError: (error)=>{ 
      console.log(error);
      setModalSegmentMessage({open:true,message:"Erro ao criar Segmento",load:false});        
    },
  });

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    baseURL: process.env.REACT_APP_CDN_API,
    multi: true,
    url: "canalPedagogico/course",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response);
        navigate("/formacoes");
      }
    },
    onError: (error)=>{
      console.log("error",error);
    },
  });

  const [saveItemList, saveItemListInfo] = useApi({
    debounceDelay: 0,
    baseURL: process.env.REACT_APP_CDN_API,
    multi: true,
    url: "canalPedagogico/course",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        let tempList = [];
        queueList.forEach((queue)=>{
          if(queue.status==="iniciado"){
            tempList.push({...queue,status:"pronto"});
          }
          else{
            tempList.push(queue);
          }
        });
        setQueueList(tempList);
      }
      else{
        
      }
    },
    onError: (error)=>{
      let tempList = [];
      queueList.forEach((queue)=>{
        if(queue.status==="iniciado"){
          tempList.push({...queue,status:"Erro"});
        }
        else{
          tempList.push(queue);
        }
      });
      setQueueList(tempList);
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    baseURL: process.env.REACT_APP_CDN_API,
    multi: true,
    url: "canalPedagogico/course",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("editou", response);
        navigate("/formacoes");
      }
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("load", response);
        if (response.data.length > 0) {
          // new visibilities
          let vis = [];
          if (response.data[0].visibilities.length > 0) {
            response.data[0].visibilities.forEach((oldVis) => {
              vis.push(oldVis.idvisibility);
            });
          }
          const oldMeetings = response.data[0].meetings;
          let newMeetings = [];
          if (oldMeetings.length > 0) {
            oldMeetings.map((meet) => {
              let newMeet;
              if (action === "edit"){
                newMeet = {
                  ...meet,
                  eventBegin: parseInt(meet.eventBegin),
                  eventEnd: parseInt(meet.eventEnd),
                  eventDate: parseInt(meet.eventDate),
                };
              }
              if (action === "add"){
                newMeet = {
                  ...meet,
                  id:uuidv4(),
                  eventBegin: parseInt(meet.eventBegin),
                  eventEnd: parseInt(meet.eventEnd),
                  eventDate: parseInt(meet.eventDate),
                };
              }
              return newMeetings.push(newMeet);
            });
          } else {
            newMeetings = [...oldMeetings];
          }

          let rules = [];
          if(response.data[0].subscriptionRules.length>0){
            response.data[0].subscriptionRules.forEach((rule) => {
              rules.push(JSON.stringify({
                // idContent:rule.idContent,
                // idContentSubscriptionRule:rule.idContentSubscriptionRule,
                idSubscriptionRule:rule.idSubscriptionRule,
                value:rule.value,
              }));
            });
          }

          // let subscriptionRulesEdit = []
          // subscriptionRules.forEach((rule)=>{
          //   let index = response.data[0].subscriptionRules.findIndex((id)=>{return id.idSubscriptionRule===rule.idsubscription_rules});
          //   if(index>=0){
          //     subscriptionRulesEdit.push({
          //       ...rule,
          //       idContent:response.data[0].subscriptionRules[index].idContent,
          //       idContentSubscriptionRule:response.data[0].subscriptionRules[index].idContentSubscriptionRule,
          //     })
          //   }
          //   else{
          //     subscriptionRulesEdit.push(rule);
          //   }
          // });
          // console.log("subscriptionRules",subscriptionRules);
          // console.log("subscriptionRulesEdit",subscriptionRulesEdit);
          // setSubscriptionRules(subscriptionRulesEdit);

          // let schoolGradeListEdit = []
          // schoolGradeList.forEach((rule)=>{
          //   let index = response.data[0].subscriptionRules.findIndex((id)=>{return id.value===rule.id});
          //   if(index>=0){
          //     schoolGradeListEdit.push({
          //       ...rule,
          //       idContent:response.data[0].subscriptionRules[index].idContent,
          //       idContentSubscriptionRule:response.data[0].subscriptionRules[index].idContentSubscriptionRule,
          //     })
          //   }
          //   else{
          //     schoolGradeListEdit.push(rule);
          //   }
          // });
          // console.log("schoolGradeList",schoolGradeList);
          // console.log("schoolGradeListEdit",schoolGradeListEdit);
          // setSchoolGradeList(schoolGradeListEdit);

          const initial = {
            ...response.data[0],
            meetings: newMeetings,
            deletedIds: [],
            visibilities: vis,
            subscriptionRules: rules,
            subscriptionRulesData: response.data[0].subscriptionRules,
          };
          formik.setValues(initial);
        } else {
          navigate("/formacoes/add");
        }
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/formacoes");
        // console.log("deletou", response);
      }
    },
  });

  const [loadSubscriptionRules, loadSubscriptionRulesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/subscriptionRules",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("SubscriptionRules", response);

        setSubscriptionRules(response.data);
      }
    },
  });

  const [loadSchoolGradeList, loadSchoolGradeListInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/schoolGradeList",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("SchoolGradeList", response);
        setSchoolGradeList(response.data);
      }
    },
  });

  const [loadTutorList, loadTutorListInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/profile/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("TutorList", response);
        setTutorList(response.data);
      }
    },
  });

  const handleDelete = (itemId) => {
    const confirm = window.confirm("Deseja apagar esse item?");

    if (confirm) {
      deleteItem({
        data: {
          id: itemId,
        },
      });
    }
  };

  const checkInt = (value) => {
    if (value !== "" && !isNaN(value)) {
      return parseInt(value);
    }
    return value;
  };

  useEffect(() => {
    async function load() {
      await loadCategories({
        params: {
          id: "0e3b686a-9b75-4de0-a2b7-28ec578e8119",
          idUserHasProfile: user.profiles[0].idUserHasProfile,
        },
      });
      await loadVisibilities();
      await loadSubscriptionRules();
      await loadSchoolGradeList({
        params:{
          showedInSubscription:true
        }
      });
      await loadTutorList({params:{idProfile:"b56d58fe-2377-4428-854d-5f20670fb166"}});
      if (action === "edit") {
        // console.log("id", id);
        await loadItem({
          params: {
            id,
          },
        });
      } else {
        if(id){
          // console.log("id", id);
          await loadItem({
            params: {
              id,
            },
          });
        }
        else{
          formik.setFieldValue("meetings[0].id", uuidv4());
        }
      }
    }
    load();
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(formik.getFieldProps("courseType").value){
      loadSegmento({
        params: {
          id: formik.getFieldProps("courseType").value,
        }
      })
    }
    // eslint-disable-next-line
  },[formik.getFieldProps("courseType").value]);

  useEffect(()=>{
    // console.log("segmento selecionado", formik.getFieldProps("segment").value);
    // eslint-disable-next-line
  },[formik.getFieldProps("segment").value]);

  useEffect(()=>{
    // console.log("Meetings:",formik.getFieldProps("meetings").value)
    // eslint-disable-next-line
  },[formik.getFieldProps("meetings").value]);

  useEffect(()=>{
    // console.log("queueList",queueList);
    let pending = queueList.filter((queue)=>{return queue.status === "aguardando" })
    let ready = queueList.filter((queue)=>{return queue.status === "iniciado" })
    if(ready.length>0){
      setTimeout(()=>{
        saveItemList({
          data:ready[0].queue,
        });
      },2000);
    }
    else{
      if(pending.length>0){
        let tempList = [];
        let firstReady=true;
        queueList.forEach((queue)=>{
          if(queue.status==="aguardando" && firstReady){
            tempList.push({...queue,status:"iniciado"})
            firstReady=false;
          }
          else{
            tempList.push(queue)
          }
        });
        setQueueList(tempList);
      }
      else{
        setFinaliza(true);
      }
    }
    // eslint-disable-next-line
  },[queueList]);

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading ||
        loadVisibilitiesInfo.loading ||
        loadSubscriptionRulesInfo.loading ||
        loadSchoolGradeListInfo.loading ||
        postSegmentoInfo.loading ||
        loadCategoriesInfo.loading ||
        loadSegmentoInfo.loading ||
        saveItemListInfo.loading ||
        loadTutorListInfo.loading) && <FullScreenLoading />}
      {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ||
        saveItemInfo.status ||
        loadVisibilitiesInfo.status ||
        loadSubscriptionRulesInfo.status ||
        loadSchoolGradeListInfo.status ||
        loadCategoriesInfo.status) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>Formações</Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}
          <Item>
            {categories.length > 0 &&
              categories.map((cat) => (
                <InputRadio
                  key={cat.id}
                  id={cat.id}
                  name="courseType"
                  text={cat.category}
                  onChange={formik.getFieldProps("courseType").onChange}
                  value={cat.id}
                  checked={formik.getFieldProps("courseType").value === cat.id}
                />
              ))}
          </Item>
          
          
          {segmento.length>0 && <ItemFloor>
            <InputSelect 
              onChange={(e)=>{
                formik.setFieldValue("segment", e.target.value);
              }}
              value={formik.values.segment}
              placeholder="Segmento da formação"
            >
              <option value="" defaultValue>Formação sem segmento</option>
              {segmento.map((seg) => (
                    <option key={seg.id} value={seg.id}>
                      {seg.category}
                    </option>
                  ))}
            </InputSelect>
            <ReadMoreButton
              text="Criar Segmento"
              type="button"
              onClick={()=>{setModalSegmentOpen(true);}}
            />
          </ItemFloor>}

          <Item>
            <InputText
              placeholder="Título da Formação"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Descrição da Formação"
              columns={90}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description &&
                formik.errors.description &&
                formik.errors.description
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Formador(es)"
              columns={90}
              name="teacher"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.teacher}
              error={
                formik.touched.teacher &&
                formik.errors.teacher &&
                formik.errors.teacher
              }
            />
          </Item>
          <Item>
            <InputText
              placeholder="Contato do Formador"
              columns={90}
              name="teacherContact"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.teacherContact}
              error={
                formik.touched.teacherContact &&
                formik.errors.teacherContact &&
                formik.errors.teacherContact
              }
            />
          </Item>
          <Item>
            <Place>Encontros</Place>
            {formik.values.meetings &&
              formik.values.meetings.map((meet, index) => (
                <MeetContainer key={meet.id}>
                  <MeetTitle>{index + 1}º encontro</MeetTitle>
                  <InputDate
                    label="Datas"
                    name={`meetings[${index}].eventDate`}
                    value={checkInt(meet.eventDate)}
                    setField={formik.setFieldValue}
                    // onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    // isArray="meetings"
                    error={
                      typeof formik.errors.meetings !== "undefined" &&
                      typeof formik.errors.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings !== "undefined" &&
                      typeof formik.touched.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings[index].eventDate !==
                        "undefined" &&
                      formik.errors.meetings[index].eventDate
                    }
                  />
                  <InputTime
                    label="Previsão de início"
                    name={`meetings[${index}].eventBegin`}
                    value={checkInt(meet.eventBegin)}
                    setField={formik.setFieldValue}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      typeof formik.errors.meetings !== "undefined" &&
                      typeof formik.errors.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings !== "undefined" &&
                      typeof formik.touched.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings[index].eventBegin !==
                        "undefined" &&
                      formik.errors.meetings[index].eventBegin
                    }
                  />
                  <InputTime
                    label="Previsão de término"
                    name={`meetings[${index}].eventEnd`}
                    value={checkInt(meet.eventEnd)}
                    setField={formik.setFieldValue}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      typeof formik.errors.meetings !== "undefined" &&
                      typeof formik.errors.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings !== "undefined" &&
                      typeof formik.touched.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings[index].eventEnd !==
                        "undefined" &&
                      formik.errors.meetings[index].eventEnd
                    }
                  />
                  <Separator />
                  <InputText
                    placeholder="Local ou link para encontro virtual"
                    columns={80}
                    name={`meetings[${index}].place`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={meet.place}
                    error={
                      typeof formik.errors.meetings !== "undefined" &&
                      typeof formik.errors.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings !== "undefined" &&
                      typeof formik.touched.meetings[index] !== "undefined" &&
                      typeof formik.touched.meetings[index].place !==
                        "undefined" &&
                      formik.errors.meetings[index].place
                    }
                  />
                  <DeleteEvent
                    type="button"
                    onClick={() => handleDeleteMeet(meet.id)}
                  >
                    <MdClose size={22} color={themeFile.COLORS.hardRed} />
                  </DeleteEvent>
                </MeetContainer>
              ))}
            <AddEvent type="button" onClick={handleAddMeet}>
              + Adicionar encontro
            </AddEvent>
          </Item>

          <Item>
            <Place>Documentos</Place>
            <InputFile
              accept="application/pdf"
              id="inputDocument"
              text="Anexar Documento"
              onChange={handleAttachFiles}
            />
            {formik.values.documents.length > 0 && (
              <DocumentContainer>
                {formik.values.documents.map((doc) => (
                  <DocumentFile key={doc.id}>
                    {doc.url ? (
                      <DocumentUrl
                        href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/course/${doc.url}`}
                        target="_blank"
                      >
                        {doc.originalName}
                      </DocumentUrl>
                    ) : (
                      <DocumentName>{doc.originalName}</DocumentName>
                    )}

                    <DocumentSize>{doc.formatSize}</DocumentSize>
                    <DocumentDeleteButton
                      onClick={() => handleDeleteDocument(doc.id)}
                    >
                      <BsTrash
                        size={themeFile.SIZES.base * 22}
                        color={themeFile.COLORS.hardRed}
                      />
                    </DocumentDeleteButton>
                  </DocumentFile>
                ))}
              </DocumentContainer>
            )}
          </Item>
          <Item>
            <Place>Visibilidade</Place>
            <InputRadio
              id="public"
              name="formType"
              text="Público"
              onChange={formik.getFieldProps("formType").onChange}
              value="public"
              checked={formik.getFieldProps("formType").value === "public"}
            />
            <InputRadio
              id="private"
              name="formType"
              text="Restrito"
              onChange={formik.getFieldProps("formType").onChange}
              value="private"
              checked={formik.getFieldProps("formType").value === "private"}
            />
          </Item>
          {formik.values.formType === "private" && (
            <Item>
              {visibilities.length > 0 &&
                visibilities.map((vis) => (
                  <InputCheck
                    id={vis.id}
                    key={vis.id}
                    value={vis.id}
                    name="visibilities"
                    text={vis.description}
                    onChange={formik.handleChange}
                    checked={formik.values.visibilities.includes(vis.id)}
                  />
                ))}
            </Item>
          )}

          <Item>
            <InputDate
              label="Inicio das Inscrições"
              name={`subscriptionBegin`}
              value={checkInt(formik.values.subscriptionBegin)}
              setField={formik.setFieldValue}
              // onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              // isArray="meetings"
              error={
                formik.errors.subscriptionBegin
              }
            />
            <InputDate
              label="Fim das Inscrições"
              name={`subscriptionEnd`}
              value={checkInt(formik.values.subscriptionEnd)}
              setField={formik.setFieldValue}
              // onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              // isArray="meetings"
              error={
                formik.errors.subscriptionEnd
              }
            />
          </Item>

          <Item>
            <InputText
              placeholder="Carga Horária"
              columns={15}
              name="courseWorkload"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.courseWorkload}
              error={
                formik.touched.courseWorkload &&
                formik.errors.courseWorkload &&
                formik.errors.courseWorkload
              }
            />
          </Item>
          
          <Item>

            <InputCheck
              name="hasSubscription"
              text={'Incrição realizada pelo canal pedagógico'}
              onChange={formik.handleChange}
              checked={formik.values.hasSubscription}
            />

            {formik.values.hasSubscription && 
              <Item>
                <Item>
                <InputText
                    placeholder="Limite de inscritos"
                    columns={15}
                    name="subscriptionsLimit"
                    type="number"
                    min="1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subscriptionsLimit}
                    error={
                      formik.touched.subscriptionsLimit &&
                      formik.errors.subscriptionsLimit &&
                      formik.errors.subscriptionsLimit
                    }
                  />
                </Item>
                <Item>
                  <Item>
                    Regras de Inscrição:
                  </Item>
                  <Item>
                    {subscriptionRules.map((rules)=>{
                      if(rules.type==='boolean'){
                        return (<InputCheck 
                                    id={rules.idsubscription_rules}
                                    key={rules.idsubscription_rules}
                                    value={JSON.stringify({
                                              // idContent:rules.idContent?rules.idContent:"",
                                              // idContentSubscriptionRule:rules.idContentSubscriptionRule?rules.idContentSubscriptionRule:"",
                                              idSubscriptionRule:rules.idsubscription_rules,
                                              value:'1'
                                          })}
                                    name="subscriptionRules"
                                    text={rules.title}
                                    onChange={formik.handleChange}
                                    checked={formik.values.subscriptionRules.includes(
                                                    JSON.stringify({
                                                                    // idContent:rules.idContent?rules.idContent:"",
                                                                    // idContentSubscriptionRule:rules.idContentSubscriptionRule?rules.idContentSubscriptionRule:"",
                                                                    idSubscriptionRule:rules.idsubscription_rules,
                                                                    value:'1'
                                                                  }))}
                                  />);
                      }
                      if(rules.idsubscription_rules==="1b96897c-df19-4354-84ef-f6176d2ad7c2"){
                        return (<Grade key={rules.idsubscription_rules}><p>{rules.title}:</p>
                                    {schoolGradeList.map((grade)=>(
                                      <InputCheck 
                                              id={grade.id}
                                              key={grade.id}
                                              value={JSON.stringify({
                                                        // idContent:grade.idContent?grade.idContent:"",
                                                        // idContentSubscriptionRule:grade.idContentSubscriptionRule?grade.idContentSubscriptionRule:"",
                                                        idSubscriptionRule:"1b96897c-df19-4354-84ef-f6176d2ad7c2",
                                                        value:grade.id
                                                    })}
                                              name="subscriptionRules"
                                              text={grade.title}
                                              onChange={formik.handleChange}
                                              checked={formik.values.subscriptionRules.includes(
                                                              JSON.stringify({
                                                                              // idContent:grade.idContent?grade.idContent:"",
                                                                              // idContentSubscriptionRule:grade.idContentSubscriptionRule?grade.idContentSubscriptionRule:"",
                                                                              idSubscriptionRule:"1b96897c-df19-4354-84ef-f6176d2ad7c2",
                                                                              value:grade.id
                                                                            }))}
                                        />))}
                                  </Grade>)
                      }
                      return (<></>);
                    })}
                  </Item>
                </Item>
              </Item>
            }
          </Item>

          <Item>
            {tutorList.length>0 && 
              <InputSelect 
                onChange={(e)=>{
                  // console.log("iduserTutor",e.target.value);
                  formik.setFieldValue("iduserTutor", e.target.value);
                }}
                value={formik.values.iduserTutor}
                placeholder="Tutor da formação"
                columns={60}
              >
                <option value={""} key={""} defaultValue>Formação sem tutor</option>
                {tutorList.map((tutor) => (
                      <option key={tutor.iduser} value={tutor.idUserHasProfile==="42644632-7ed6-4867-882a-23afd9dcced2"?"fdfda755-d78f-46ae-99f2-78b12db08f77":tutor.idUserHasProfile}>
                        {tutor.name}
                      </option>
                ))}
            </InputSelect>}
          </Item>

          {action === "add" && <>
            <Item>
              <InputCheck
                name="hasNumberOfClass"
                text={'Criar Multiplas Turmas'}
                onChange={formik.handleChange}
                checked={formik.values.hasNumberOfClass}
              />
            </Item>
            {formik.values.hasNumberOfClass && <Item>
              <InputText
                      placeholder="Numero da turma inicial"
                      columns={30}
                      name="initialClassNumber"
                      type="number"
                      min="1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.initialClassNumber}
                      error={
                        formik.touched.initialClassNumber &&
                        formik.errors.initialClassNumber &&
                        formik.errors.initialClassNumber
                      }
                    />
              <InputText
                      placeholder="Numero da turma Final"
                      columns={30}
                      name="finalClassNumber"
                      type="number"
                      min="1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.finalClassNumber}
                      error={
                        formik.touched.finalClassNumber &&
                        formik.errors.finalClassNumber &&
                        formik.errors.finalClassNumber
                      }
                    />
            </Item>}
          </>}
          
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
        <Modal open={modalOpen} buttonClose={false}>
          {queueList.map((queue)=>(<div key={queue.turma}>{formik.values.title} (turma {queue.turma}) - Status: {queue.status}</div>))}
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{navigate("/formacoes");}}
            disabled={!finaliza}
          />
        </Modal>
        <Modal open={modalSegmentOpen} buttonClose={false}>
          <div>Criar Novo Segmento</div>
          <InputSegment>
            <InputText
              placeholder="Segmento"
              columns={80}
              onChange={(value)=>{setCreateSegment(value.target.value);}}
              value={createSegment}
            />
          </InputSegment>
          <ListButton>
            <ReadMoreButton
              text="Criar Segmento"
              type="button"
              onClick={()=>{postSegmento({data:{name:createSegment,idCategoryFather:"2457a23b-101f-485a-a4a1-c7fa7ab7926b"}});}}
            />
            <ReadMoreButton
              text="Cancelar"
              type="button"
              onClick={()=>{setModalSegmentOpen(false)}}
              disabled={!finaliza}
            />
          </ListButton>
        </Modal>
        <Modal open={modalSegmentMessage.open} buttonClose={false}>
          {modalSegmentMessage.message}
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{
              if(modalSegmentMessage.load){
                loadSegmento({
                  params: {
                    id: formik.getFieldProps("courseType").value,
                  }
                });
              }
              setModalSegmentMessage({open:false,message:"",load:false});
            }}
            disabled={!finaliza}
          />
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CourseForm;
