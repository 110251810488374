import React, { useState, memo, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
// import { io } from "socket.io-client";
// import { InView } from "react-intersection-observer";
import { MdMenu, MdClose } from "react-icons/md";
// import { differenceInSeconds, parseISO } from "date-fns";
import { useNavigate, Link } from "react-router-dom";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import { Player } from "@lottiefiles/react-lottie-player";
import { useTheme } from "../../../hooks/useTheme";
// import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import { useNotify } from "../../../hooks/useNotify";
import {
  icons,
  // images
} from "../../../constants";
import Accessibility from "../Accessibility";
import FullScreenLoading from "../FullScreenLoading";
import {
  Container,
  BurgerMenu,
  List,
  ListScroll,
  Item,
  Icon,
  Name,
  // Logo,
  // LogoButton,
  Notifications,
  // Empty,
  // ResponsiveNotification,
  // NotificationsContainer,
  // NotificationsList,
  // NotificationsContent,
  // NotificationsButtons,
  // NotificationChange,
  // NotificationItem,
  // NotificationAvatar,
  // NotificationInfo,
  // NotificationText,
  // NotificationTime,
  // Profile,
  // AvatarName,
  // AvatarImage,
  // LogoButtonResponsive,
  ContainerItem,
  CloseContainer,
  // HideItem,
  CenterIcon,
  Version,
  SubItem,
  SubItem2
} from "./styles";

// import BetaTag from "../BetaTag";

const Menu = () => {
  const navigate = useNavigate();
  const { themeFile } = useTheme();
  const {
    user,
    // socket,
    // sessionID,
    // setSessionID,
    // setSocket,
    // token,
    logout,
    sessionLogoutInfo,
    accessRight,
  } = useAuth();
  const {
    // notifications,
    // notificationsView,
    notRead,
    // setNotifications,
    // setNotificationsView,
    // setNotRead,
  } = useNotify();
  const [menuOpen, setMenuOpen] = useState(true);
  const [subItemProject, setSubItemProject] = useState(false);
  const [subItemLivros, setSubItemLivros] = useState(false);
  const [subItemPnld, setSubItemPnld] = useState(false);
  const [subItemEstado, setSubItemEstado] = useState(false);
  const [subItemAvaliacoes, setSubItemAvaliacoes] = useState(false);
  const [subItemFormacoes, setSubItemFormacoes] = useState(false);
  // const [showNotificationBox, setShowNotificationBox] = useState(false);
  // const [activeAll, setActiveAll] = useState(true);
  // const [activeNotRead, setActiveNotRead] = useState(false);
  // const [loadMoreText, setLoadMoreText] = useState("Carregando mais...");

  const handleNavigate = (url) => {
    navigate(url);
  };

  // const handleShowNotificationPage = () => {
  //   navigate("/notificacoes");
  // };

  useEffect(() => {
    // console.log("userMenu", user);
  }, [user]);

  // const [markAsRead, markAsReadInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "notification/v1",
  //   method: "put",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log(response);
  //       // Navegar
  //       navigate("/" + response.config.notificationUrl);
  //     }
  //   },
  // });

  // const [loadMessages, loadMessagesInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "notification/v1",
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log(response);
  //       setNotRead(response.data.notRead);
  //       if (response.data.messages.length === 0) {
  //         setLoadMoreText("Sem mais mensagens.");
  //       }
  //       const oldMessages = notifications;
  //       const newMessages = oldMessages.concat(response.data.messages);
  //       console.log(newMessages);
  //       setNotifications(newMessages);
  //       setNotificationsView(newMessages);
  //     }
  //   },
  // });

  // const handleNotify = (notificationUrl, idNotification) => {
  //   // Antes de navegar, fazer uma chamada para a API marcando como lida
  //   markAsRead({
  //     data: {
  //       idNotification,
  //     },
  //     notificationUrl,
  //   });

  //   // Setar no notificationsView e no Notifications como lida
  //   console.log(idNotification);
  //   let newNotifications = notificationsView.map((item) => {
  //     if (item.idnotification === idNotification) {
  //       const itemModified = {
  //         ...item,
  //         read: true,
  //       };
  //       return itemModified;
  //     } else {
  //       return item;
  //     }
  //   });
  //   setNotificationsView(newNotifications);

  //   let decrement = false;

  //   newNotifications = notifications.map((item) => {
  //     if (item.idnotification === idNotification) {
  //       if (!item.read) {
  //         decrement = true;
  //       }
  //       const itemModified = {
  //         ...item,
  //         read: true,
  //       };
  //       return itemModified;
  //     } else {
  //       return item;
  //     }
  //   });
  //   setNotifications(newNotifications);

  //   decrement && setNotRead(notRead - 1);
  // };

  const handleMenuOpen = () => {
    // console.log(menuOpen);
    setMenuOpen((oldMenu) => !oldMenu);
  };

  const handleSubItemProject = () => {
    setSubItemProject((subItemProject) => !subItemProject);
  };

  const handleSubItemLivros = () => {
    setSubItemLivros((subItemLivros) => !subItemLivros);
  };

  const handleSubItemPnld = () => {
    setSubItemPnld((subItemPnld) => !subItemPnld);
  };

  const handleSubItemEstado = () => {
    setSubItemEstado((subItemEstado) => !subItemEstado);
  };

  const handleSubItemAvaliacoes = () => {
    setSubItemAvaliacoes((subItemAvaliacoes) => !subItemAvaliacoes);
  };

  const handleSubItemFormacoes = () => {
    setSubItemFormacoes((subItemFormacoes) => !subItemFormacoes);
  };

  // const handleShowNotificationBox = () => {
  //   // setShowNotificationBox((oldShowNotificationBox) => !oldShowNotificationBox);
  // };

  const handleLogout = () => {
    // Talvez verificar aqui se quer fazer logout mesmo?
    logout({
      navigate,
    });
  };

  // const getAvatar = () => {
  //   // console.log(user.profiles);
  //   const avatar = user.profiles.filter((item) => {
  //     return item.enabled === true;
  //   });
  //   return process.env.REACT_APP_CDN_URL + "avatar/" + avatar[0]?.avatarURL;
  // };

  // const handleShowAllNotifications = () => {
  //   setActiveAll(true);
  //   setActiveNotRead(false);
  //   setNotificationsView(notifications);
  // };

  // const handleShowNotReadNotifications = () => {
  //   setActiveAll(false);
  //   setActiveNotRead(true);
  //   const notReadTemp = notifications.filter((item) => !item.read);
  //   setNotificationsView(notReadTemp);
  // };

  // const formatTime = (time) => {
  //   const now = new Date();
  //   const difference = differenceInSeconds(now, parseISO(time));
  //   // const difference = 2000000;
  //   let unity;
  //   let distance;

  //   if (difference < 60) {
  //     unity = difference > 1 ? "segundos" : "segundo";
  //     if (difference === 0) {
  //       return `Enviada agora.`;
  //     } else {
  //       return `Enviada há ${difference} ${unity}.`;
  //     }
  //   } else if (difference < 3600) {
  //     distance = parseInt(difference / 60);
  //     unity = distance > 1 ? "minutos" : "minuto";
  //     return `Enviada há ${distance} ${unity}.`;
  //   } else if (difference < 86400) {
  //     distance = parseInt(difference / 3600);
  //     unity = distance > 1 ? "horas" : "hora";
  //     return `Enviada há ${distance} ${unity}.`;
  //   } else {
  //     distance = parseInt(difference / 86400);
  //     unity = distance > 1 ? "dias" : "dia";
  //     return `Enviada há ${distance} ${unity}.`;
  //   }
  // };

  // const loadMore = (InviewObj, entry) => {
  //   if (InviewObj) {
  //     loadMessages({
  //       params: {
  //         initial: notifications.length + 1,
  //         limit: 10,
  //       },
  //     });
  //   }
  // };

  // useEffect(() => {
  //   socket?.emit("message", {
  //     data: {
  //       message: "4%",
  //       name: "Ferrugem",
  //     },
  //     status: undefined,
  //     errorMessage: undefined,
  //   });
  //   socket?.on("session", ({ sessionID }) => {
  //     console.log(sessionID);
  //     localStorage.setItem(
  //       `[@${process.env.REACT_APP_NAME}SessionID]`,
  //       sessionID
  //     );
  //     setSessionID(sessionID);
  //   });
  //   socket?.on("welcome", (result) => {
  //     console.log("welcome", result);
  //     setNotRead(parseInt(result.notRead));
  //     setNotifications(result.messages);
  //     setNotificationsView(result.messages);
  //   });
  //   socket?.on("newMessage", (result) => {
  //     console.log("newMESSAGE", result);
  //     setNotRead(parseInt(result.notRead));
  //     setNotifications(result.messages);
  //     setNotificationsView(result.messages);
  //   });
  // }, [socket]);

  // useEffect(() => {
  //   if (!socket) {
  //     console.log("conectou");
  //     const socketConn = io(process.env.REACT_APP_SOCKET_URL, {
  //       auth: { token, sessionID },
  //     });
  //     setSocket(socketConn);
  //   }
  // }, []);

  const isEditor = (id) => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === id
    )
    if( editor > -1 ) {
      return true;
    } else {
      return false;
    }};

  return (
    <>
      {/* <BetaTag /> */}
      {sessionLogoutInfo.loading && <FullScreenLoading />}
      <Container highContrast={themeFile.COLORS.highContrast}>
        <BurgerMenu onClick={() => handleMenuOpen()} isOpen={menuOpen}>
          {notRead > 0 && (
            <Notifications isOpen={!menuOpen}>{notRead}</Notifications>
          )}
          {notRead > 0 && notRead > 99 && (
            <Notifications isOpen={!menuOpen}>+99</Notifications>
          )}
          {/* {menuOpen && (
            <>
              <MdMenu size={38} color={themeFile.COLORS.purple} />
              <span>Menu</span>
            </>
          )} */}
          <>
            <MdMenu size={38} color={themeFile.COLORS.purple} />
            <span>Menu</span>
          </>
        </BurgerMenu>
        <List isOpen={menuOpen}>
          <CloseContainer onClick={() => handleMenuOpen()}>
            <MdClose size={42} color={themeFile.COLORS.secondary} />
          </CloseContainer>
          
          <ListScroll isOpen={menuOpen}>
            {user === null ? (
              <Item onClick={() => handleNavigate("/login")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                      ? icons.login_black
                      : icons.login
                  }
                  alt="Login"
                />
                <Name>Login</Name>
              </Item>
            ) : (
              <Item onClick={() => handleNavigate("/meuespaco")}>
                {!user.profiles[0].avatarURL ? (
                  <Icon
                    src={
                      themeFile.COLORS.highContrast
                        ? icons.login_black
                        : icons.login
                    }
                    alt="Login"
                  />
                ) : (
                  <Icon
                    rounded
                    src={
                      process.env.REACT_APP_CDN_URL +
                      "avatar/" +
                      user.profiles[0].avatarURL
                    }
                    alt="Login"
                  />
                )}

                <Name>Meu espaço</Name>
              </Item>
            )}

            <Item onClick={() => handleNavigate("/sobre")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.conheca_black
                    : icons.conheca
                }
                alt="Sobre o Departamento"
              />
              <Name>Conheça</Name>
            </Item>
            <Item onClick={() => handleNavigate("/documentos")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.documentos_black
                    : icons.documentos
                }
                alt="Documentos"
              />
              <Name>Documentos</Name>
            </Item>
            <Item onClick={() => handleSubItemProject()}>
              <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.iconProject_black
                    : icons.iconProject_white
                  }
                  alt="Projetos"
                />
                <Name>Projetos</Name>  
            </Item>
            {subItemProject && 
              <>
                {/* <SubItem onClick={() => handleNavigate("/projetomusica")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.musica
                    : icons.musica
                  }
                  alt="Projetos"
                />
                  <Name>Música</Name>
                </SubItem> */}
                <SubItem onClick={() => handleNavigate("/cidadeleitora")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.cidadeleitora_black
                    : icons.cidadeleitora
                  }
                  alt="Projetos"
                />
                  <Name>Mogi Cidade Leitora</Name>
                </SubItem>
                {/* <SubItem onClick={() => handleNavigate("/gtseguranca")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.seguranca
                    : icons.seguranca
                  }
                  alt="Projetos"
                />
                  <Name>GT Segurança</Name>
                </SubItem> */}
              </>
            }
            <Item onClick={() => handleNavigate("/agenda")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.agenda_black
                    : icons.agenda
                }
                alt="Agenda"
              />
              <Name>Agenda</Name>
            </Item>
            {accessRight("books",null).SP_erro && <Item onClick={() => handleSubItemLivros() }>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.book_black
                    : icons.book
                }
                alt="Livros"
              />
              <Name>Livros</Name>
            </Item>}
            {subItemLivros &&
                <>
                  <SubItem onClick={() => handleSubItemPnld()}>
                    <Icon
                      src={
                        themeFile.COLORS.highContrast
                        ? icons.pnld_black
                        : icons.pnld
                      }
                      alt="PNLD"
                    />
                      <Name>PNLD</Name>
                    </SubItem>
                      {subItemPnld && subItemLivros &&
                        <>
                          <SubItem2 onClick={() => handleNavigate("/book/346b308f-e29b-4a9b-96eb-68da8b79f915")}>
                          <Icon
                            src={
                              themeFile.COLORS.highContrast
                              ? icons.pen_request_black
                              : icons.pen_request
                            }
                            alt="Requisições"
                          />
                            <Name>Requisições</Name>
                          </SubItem2>
                          <SubItem2 onClick={() => handleNavigate("/bookregister/346b308f-e29b-4a9b-96eb-68da8b79f915")}>
                          <Icon
                            src={
                              themeFile.COLORS.highContrast
                              ? icons.livro_cadastro_black
                              : icons.livro_cadastro
                            }
                            alt="Cadastro de livros"
                          />
                            <Name>Cadastro de livros</Name>
                          </SubItem2>
                        </>
                      }
                <SubItem onClick={() => handleSubItemEstado()}>
                    <Icon
                      src={
                        themeFile.COLORS.highContrast
                        ? icons.livros_estado_black
                        : icons.livros_estado
                      }
                      alt="Livros Estado"
                    />
                      <Name>Livros do Estado</Name>
                    </SubItem>
                      {subItemEstado && subItemLivros &&                                    
                        <>
                          <SubItem2 onClick={() => handleNavigate("/book/b4b08ede-6069-4ee7-aec4-a8ed20c0c593")}>
                          <Icon
                            src={
                              themeFile.COLORS.highContrast
                              ? icons.pen_request_black
                              : icons.pen_request   
                            }
                            alt="Requisições"
                          />
                            <Name>Requisições</Name>
                          </SubItem2>
                          <SubItem2 onClick={() => handleNavigate("/bookregister/b4b08ede-6069-4ee7-aec4-a8ed20c0c593")}>
                          <Icon
                            src={
                              themeFile.COLORS.highContrast
                              ? icons.livro_cadastro_black
                              : icons.livro_cadastro
                            }
                            alt="Cadastro de livros"
                          />
                            <Name>Cadastro de livros</Name>
                          </SubItem2>
                        </>
                      }
              </>}

            <Item onClick={() => {(
              user?.profiles[0].idProfile === "b56d58fe-2377-4428-854d-5f20670fb166" || 
              user?.profiles[0].idProfile === "8a833773-7e1d-47d8-9995-e98b3d6fe04a")?
              handleSubItemFormacoes():
              handleNavigate("/formacoes")}}>
              <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.formacoes_black
                    : icons.formacoes
                  }
                  alt="Formações"
                />
                <Name>Formações</Name>  
            </Item>
            {subItemFormacoes && 
              <>
                <SubItem onClick={() => handleNavigate("/formacoes")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.formacoes_black
                    : icons.formacoes
                  }
                  alt="Formações"
                />
                  <Name>Formações</Name>
                </SubItem>
                <SubItem onClick={() => handleNavigate("/formacoes/manager")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.formacao_gestao_black
                    : icons.formacao_gestao
                  }
                  alt="Gestão de Formações"
                />
                  <Name>Gestão de Formações</Name>
                </SubItem>
              </>
            }
            
            <Item onClick={() => {(
              user?.profiles[0].idProfile === "b56d58fe-2377-4428-854d-5f20670fb166" || 
              user?.profiles[0].idProfile === "09476796-c9f9-4adf-831f-56cff40ce3fc" ||
              user?.profiles[0].idProfile === "8a833773-7e1d-47d8-9995-e98b3d6fe04a")?
              handleSubItemAvaliacoes():
              handleNavigate("/avaliacoes")}}>
              <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.indicadores_black
                    : icons.indicadores
                  }
                  alt="Avaliações"
                />
                <Name>Avaliações</Name>  
            </Item>
            {subItemAvaliacoes && 
              <>
                <SubItem onClick={() => handleNavigate("/avaliacoes")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.indicadores_black
                    : icons.indicadores
                  }
                  alt="Avaliações"
                />
                  <Name>Avaliações</Name>
                </SubItem>
                <SubItem onClick={() => handleNavigate("/testmonitor")}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                    ? icons.acompanhamento_eaprendi_black
                    : icons.acompanhamento_eaprendi
                  }
                  alt="Acompanhamento E-Aprendi"
                />
                  <Name>Acompanhamento<br/>E-Aprendi</Name>
                </SubItem>
              </>
            }
            
            <Item onClick={() => handleNavigate("/biblioteca")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.biblioteca_black
                    : icons.biblioteca
                }
                alt="Biblioteca"
              />
              <Name>Biblioteca</Name>
            </Item>
            <Item onClick={() => handleNavigate("/videoteca")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.videoteca_black
                    : icons.videoteca
                }
                alt="Videoteca"
              />
              <Name>Videoteca</Name>
            </Item>
            <Item onClick={() => handleNavigate("/plataformas")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.plataforma_black
                    : icons.plataforma
                }
                alt="Plataformas"
              />
              <Name>Plataformas</Name>
            </Item>
            {<Item onClick={() => handleNavigate("/reds")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.reds_black
                    : icons.reds
                }
                alt="Recurso Educacional Digital"
              />
              <Name>Recurso Educacional Digital</Name>
            </Item>}
            <Item onClick={() => handleNavigate("/muve")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.museum_black
                    : icons.museum
                }
                alt="Muve"
              />
              <Name>Muve</Name>
            </Item>
            <Item onClick={() => handleNavigate("/school")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast
                    ? icons.school_black
                    : icons.school_white
                }
                alt="Escola Ambiental"
              />
              <Name>Escola Ambiental</Name>
            </Item>
            <Item onClick={() => handleNavigate("/ajuda")}>
              <Icon
                src={
                  themeFile.COLORS.highContrast 
                  ? icons.help_black
                  : icons.help
                }
                alt="Ajuda"
              />
              <Name>Ajuda</Name>
            </Item>
            {user !== null && (
              <Item onClick={handleLogout}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast
                      ? icons.logout_black
                      : icons.logout
                  }
                  alt="Sair"
                />
                <Name>Sair</Name>
              </Item>
            )}

            {/* <Item className="notifyResp">
              <NotificationsContainer onClick={handleShowNotificationPage}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast ? icons.bellBlack : icons.bell
                  }
                  alt="Notificações"
                />
                {notRead > 0 && <Notifications>{notRead}</Notifications>}
                {notRead > 0 && notRead > 99 && (
                  <Notifications>+99</Notifications>
                )}
              </NotificationsContainer>
              <Name onClick={handleShowNotificationPage}>Notificações</Name>
            </Item> */}
            {/* <Item className="notify">
              <NotificationsContainer onClick={handleShowNotificationBox}>
                <Icon
                  src={
                    themeFile.COLORS.highContrast ? icons.bellBlack : icons.bell
                  }
                  alt="Notificações"
                /> */}
            {/* {notRead > 0 && <Notifications>{notRead}</Notifications>}
                {notRead > 0 && notRead > 99 && (
                  <Notifications>+99</Notifications>
                )} */}
            {/* </NotificationsContainer>
              <Name onClick={handleShowNotificationBox}>Notificações</Name> */}
            {/* {showNotificationBox && (
                <ClickAwayListener
                  onClickAway={() => setShowNotificationBox(false)}
                >
                  <NotificationsList number={notifications.length}>
                    {notifications.length === 0 ? (
                      <Empty>Nenhuma notificação encontrada.</Empty>
                    ) : (
                      <NotificationsContent>
                        <NotificationsButtons>
                          <NotificationChange
                            type="button"
                            onClick={handleShowAllNotifications}
                            active={activeAll}
                          >
                            Todas
                          </NotificationChange>
                          <NotificationChange
                            type="button"
                            onClick={handleShowNotReadNotifications}
                            active={activeNotRead}
                          >
                            Não lidas
                          </NotificationChange>
                        </NotificationsButtons>
                        {notificationsView.map((item) => (
                          <NotificationItem
                            onClick={() =>
                              handleNotify(item.link, item.idnotification)
                            }
                            key={item.idnotification}
                            read={item.read}
                          >
                            {!checkJSON(item.senderAvatar) ? (
                              <NotificationAvatar
                                src={
                                  process.env.REACT_APP_CDN_URL +
                                  "avatar/" +
                                  item.senderAvatar
                                }
                                alt={item.idnotification}
                              />
                            ) : (
                              <Player
                                autoplay={true}
                                loop={true}
                                controls={false}
                                src={
                                  process.env.REACT_APP_CDN_URL +
                                  "avatar/" +
                                  item.senderAvatar
                                }
                                style={{
                                  height: "56px",
                                  width: "56px",
                                  display: "block",
                                }}
                              />
                            )}

                            <NotificationInfo>
                              <NotificationText>{item.message}</NotificationText>
                              <NotificationTime>
                                {formatTime(item.created_at)}
                              </NotificationTime>
                            </NotificationInfo>
                          </NotificationItem>
                        ))}
                        {notificationsView.length > 0 && (
                          <InView
                            as="div"
                            onChange={(inView, entry) => loadMore(inView, entry)}
                          >
                            {loadMoreText}
                          </InView>
                        )}
                      </NotificationsContent>
                    )}
                  </NotificationsList>
                </ClickAwayListener>
              )} */}
            {/* </Item> */}
            {/* <HideItem>
              <Accessibility />
            </HideItem> */}
          </ListScroll>
          <Version>
            <p>Depto Pedagógico</p>
            <p>2024 | v1.0</p>
          </Version>
        </List>
        <CenterIcon>
          <Link to={user === null?"/":"/home2"}>
            <Player
              autoplay={true}
              loop={true}
              controls={false}
              src={
                themeFile.COLORS.highContrast
                  ? icons.logoCanalJSONHighContrast
                  : icons.logoCanalJSON
              }
              style={{ height: "100%" }}
            />
          </Link>
        </CenterIcon>
        <ContainerItem>
          <Accessibility />
        </ContainerItem>
      </Container>
    </>
  );
};

export default memo(Menu);
