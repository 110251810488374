import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import {
  BoxNames,
  Contact,
  Container,
  ContentNames,
  ContentText,
  ImgCemforpe,
  // Link,
  Map,
  Text,
  TextItem,
  Title,
} from "./styles";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";

const AboutUs = () => {
  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconConheca}>CONHEÇA</Banner>
      <ContentBox>
        <ImgCemforpe src={images.cemforpe} />
        <ContentText>

          <Text>
            O Departamento Pedagógico, vinculado à Secretaria Municipal de Educação, chancela suas pautas de trabalho por meio de políticas públicas focadas 
            em atender às perspectivas de desenvolvimento integral dos alunos da rede. Para tanto, orienta-se pela relação colaborativa entre a construção de 
            materiais didáticos, recursos tecnológicos, formação continuada e avaliação.
          </Text>
        
          <Text>
            Para o alcance de suas metas, organiza-se em diversas equipes, que trabalham de forma integrada e em sintonia com o que preconizam os documentos 
            norteadores, tendo em vista sempre o atendimento aos Direitos dos estudantes em sua integralidade.
          </Text>
        
          <Text>
            Nossas equipes:
          </Text>
        </ContentText>
        <ContentNames>

          <BoxNames>
          <Text>1 - Direção</Text>
            <TextItem><li>Tatiane Pereira Costa Siqueira</li></TextItem>

            <Text>1 - Educação Infantil</Text>
            <TextItem><li>Ana Clara C. de Moraes e Faria</li></TextItem>
            <TextItem><li>Andréa Cristina Mendes G. Garcia</li></TextItem>
            <TextItem><li>Aniele Armelin Barbosa</li></TextItem>
            <TextItem><li>Lilian Gadini</li></TextItem>
            <TextItem><li>Patrícia de Cássia Barba Macedo</li></TextItem>
  
            <Text>2 - Ensino Fundamental</Text>
            <TextItem><li>Adriana Aparecida da Cunha</li></TextItem>
            <TextItem><li>Alessandra Aparecida Machado</li></TextItem>
            <TextItem><li>Ana Carolina Faber Boog</li></TextItem>
            <TextItem><li>Carolina Domingues Nepumuceno</li></TextItem>
            <TextItem><li>Cíntia Maria Ambrosio de Oliveira</li></TextItem>
            <TextItem><li>Gabriela Lima Cerqueira</li></TextItem>
            <TextItem><li>Geraldo Monteiro Neto</li></TextItem>
            <TextItem><li>Joelma da Silveira</li></TextItem>
            <TextItem><li>Luciana Campos Barbosa Sant'anna</li></TextItem>
            <TextItem><li>Luiz Alberto de Souza</li></TextItem>
            <TextItem><li>Luiz Flávio Silva</li></TextItem>
            <TextItem><li>Mônica Raquel Matheus Felismino</li></TextItem>
            <TextItem><li>Mônica Senziali Santos de Oliveira</li></TextItem>
            <TextItem><li>Patrícia Francisca da Silva Araújo</li></TextItem>
            <TextItem><li>Sue Ellen Souza de Oliveira Camargos</li></TextItem>

            <Text>3 - Equipe de Avaliação</Text>
            <TextItem><li>Maria Cristina Marcatti</li></TextItem>
            <TextItem><li>Nayara Xavier de Souza</li></TextItem>

            <Text>4 - Equipe Auditório</Text>
            <TextItem><li>Alexandre José</li></TextItem>
            <TextItem><li>Carlos Antônio Filho</li></TextItem>
            <TextItem><li>Rafael Neves Bonilha</li></TextItem>
            <TextItem><li>Soraya Dilger Fernandes</li></TextItem>

            <Text>5 - Comunidades de Aprendizagem</Text>
            <TextItem><li>Ana Júlia Bernardo Silva</li></TextItem>
            <TextItem><li>Wander Lopes Primo</li></TextItem>

            <Text>6 - Equipe Audiovisual</Text>
            <TextItem><li>Fabrício Machado da Rocha</li></TextItem>
            <TextItem><li>Jefferson Petreski</li></TextItem>
            <TextItem><li>Luiz Henrique da mota Santos</li></TextItem>

            <Text>7 - Equipe Administrativa</Text>
            <TextItem><li>Gabriella Regina Sala Martinelli</li></TextItem>
            <TextItem><li>Gisele Aparecida Ribeiro de Faria</li></TextItem>
            <TextItem><li>José Vitor Mesquita</li></TextItem>
            <TextItem><li>Rieko Kasuya</li></TextItem>

            <Text>8 - Biblioteca</Text>
            <TextItem><li>Osmar Alves da Silva</li></TextItem>

            <Text>9 - Equipe MUVE</Text>
            <TextItem><li>Elaine Jussara Tolosa</li></TextItem>
            <TextItem><li>Elisabete da Silva Jacques Urizzi Garcia</li></TextItem>
            <TextItem><li>Rodrigo A. Brunaikovics da Silva</li></TextItem>

            <Text>10 - Equipe Escola Ambiental</Text>
            <TextItem><li>Angélica Lucas Bezerra</li></TextItem>
            <TextItem><li>Valter Roberto Machado Giner</li></TextItem>
 
            <Text>11 - Programas Musicais</Text>
            <TextItem><li>Allan Caetano de Paula</li></TextItem>
            <TextItem><li>Eny Massae Sato</li></TextItem>
            <TextItem><li>Henrique Siqueira Ussier</li></TextItem>

            <Text>12 - Equipe de Tecnologia</Text>
            <TextItem><li>Alexandre Roberto Rodrigues</li></TextItem>
            <TextItem><li>André Kimwa Chan</li></TextItem>
            <TextItem><li>Angelo Nunes Camargo</li></TextItem>
            <TextItem><li>Carlos Yukio Komoto</li></TextItem>
            <TextItem><li>Edgar Yoshida Salomão</li></TextItem>
            <TextItem><li>Luciana Paula Leite de Oliveira</li></TextItem>
            <TextItem><li>Luiz Gustavo Firveda de Almeida</li></TextItem>
            <TextItem><li>Márcia Kabakura</li></TextItem>

            <Text>13 - Univesp</Text>
            <TextItem><li>Gilberto Henrique da Silva Junior</li></TextItem>
            <TextItem><li>Paulo Roberto de Oliveira</li></TextItem>
            <TextItem><li>Roberto Scarcelli P. T. Costa</li></TextItem>

            <Text>14 - Cultura Maker</Text>
            <TextItem><li>Jefferson Adriane de Paula</li></TextItem>
            <TextItem><li>Taciano Segóvia Machado</li></TextItem>
            
          </BoxNames>
        </ContentNames>
        <ContentText>
          <Text>
            O Departamento Pedagógico tem sua sede no CEMFORPE (Centro Municipal de Formação Pedagógica), localizado no bairro Nova Mogilar em Mogi das Cruzes.
            Há outros dois espaços que também fazem parte do departamento, que é o MUVE e a Escola Ambiental.
          </Text>
          <Title>MUVE</Title>
          <Text>  
            O Museu de Vivências Educacionais, criado a partir da LEI No 7.799, de 09 de junho de 2022, vinculado e subordinado administrativamente
            à Secretaria Municipal de Educação, fica localizado no Casarão da Rua Coronel Souza Franco, 917.
          </Text>
          <Text>
            É um centro de Educação Patrimonial dotado de recursos tecnológicos destinados à exposição de conteúdos organizados em salas temáticas, com o
            objetivo de propagar de forma interativa, a história do Município de Mogi das Cruzes.
          </Text>
          <Title>ESCOLA AMBIENTAL: </Title>
            <Text>A Escola Ambiental de Mogi das Cruzes da Secretaria Municipal de Educação, instituída pela Lei Municipal 5889/2006 e inaugurada em 5 de junho do mesmo ano,
            é um Centro de Educação Ambiental que contribui para despertar, em cada participante, a vocação para a conservação
            e  preservação da natureza e a promoção do desenvolvimento sustentável. Acreditando sempre, que é a partir da integração do homem com a natureza e do 
            entendimento de sua relação com o todo, que a intenção se transforma em ação e em atitude, para a melhoria
            da qualidade de vida e do equilíbrio ambiental. Fica localizada no bairro das Varinhas em Mogi das Cruzes.
          </Text>
        </ContentText>
        <Map
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.6427879309726!2d-46.18705018449484!3d-23.509372565560813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cdd826b0f6a54d%3A0x193a3e29dd57e3f8!2sCEMFORPE!5e0!3m2!1spt-BR!2sbr!4v1653050822515!5m2!1spt-BR!2sbr"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
        <Contact>Rua Antenor Leite da Cunha, 55 - Nova Mogilar</Contact>
        <Contact>CEP: 08773-395, Mogi das Cruzes - SP.</Contact>
        <Contact>Segunda a sexta-feira, das 8 às 17 horas.</Contact>
        <Contact>(11) 4791-3520</Contact>
        <Contact>deped@sme-mogidascruzes.sp.gov.br</Contact>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default AboutUs;