import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import generatePDF, { Resolution } from "react-to-pdf";
import QRCode from "react-qr-code";
import {format, isSameDay} from "date-fns";
import locale from "date-fns/locale/pt-BR";

import {
  Container,
  CertificadoValida,
  CertificadoInfo,
  CertificadoCancelado,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
import InputSearch from "../../components/UI/Inputs/InputSearch";
import InputText from "../../components/UI/Inputs/InputText";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
import Modal from "../../components/UI/Modal"

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

import { icons, images } from "../../constants";
import formatCPF from "../../utils/formatCpf";

const CoursesCertificateValidation = () => {
  const [code,setCode] = useState("");
  const [flag,setFlag] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [certificate, setCertificate]= useState([]);

  const handleInputChange = (text)=>{
    setCode(text.target.value);
  }

  const [getCertificate, certificateInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/certificate",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        setCertificate(response.data);
      }
    },
  });

  useEffect(() => {
    if ( id ){
      setFlag(false);
      getCertificate({params:{id}});
      //consulta o id
    }
    else{
      setFlag(true);
    }
    //eslint-disable-next-line
  }, [id]);

  return (
    <Container>
      <Menu />
      <Banner>
        Validação de Certificado
      </Banner>

      <ContentBox>
        {(flag||certificate.length===0)? <>
          <InputText placeholder="Insira o código do certificado"
                columns={90}
                onChange={handleInputChange}
                value={code}
                />
          <ReadMoreButton text={"Validar Certificado"} onClick={()=>{navigate(`/valida/${code}`)}} />
          {certificate.length===0 && id && <>Certificado não Localizado</>}
        </>:<>
          {certificate.map((info)=>(
            <CertificadoValida key={info.idCertificate} canceled={info.canceled}>
              <img src={info.canceled?images.certificado_cancelado_logo:images.certificado_valido_logo}/>
              <CertificadoInfo>
                <div><span>Codigo:</span> {info.idCertificate}</div>
                <div><span>Emitido em:</span> {format(new Date(info.createdAt), "dd/MM/yyyy", {locale})}</div>
                {/* <div><span>Situação:</span> {info.canceled?<span className="situacao">Cancelado</span>:"Válido"}</div> */}
                
                <div><span>Nome do cursista:</span> {info.name}</div>
                <div><span>CPF:</span> {formatCPF(info.cpf)}</div>
                
                <div><span>Curso:</span> {info?.courseTitle}</div>
                <div><span>Periodo:</span> {
                  isSameDay(new Date(info.courseBegin),new Date(info.courseEnd))?
                    format(new Date(info.courseBegin), "dd/MM/yyyy", {locale}):
                    `${format(new Date(info.courseBegin), "dd/MM/yyyy", {locale})} - ${format(new Date(info.courseEnd), "dd/MM/yyyy", {locale})}`}
                </div>
                <div><span>Carga Horária:</span> {info.totalWorkload} horas</div>
                <div><span>Carga Horária realizada:</span> {info.hoursTaken} horas</div>
                <div><span>Carga Horária justificada(htpf):</span> {info.hoursJustified} horas</div>
                {info.htpf && <div><span>Formação elementar (lançado pelo DEPED)</span></div>}
                {info.canceled && <div>
                  Certificado Cancelado em <span>{format(new Date(info.updatedAt), "dd/MM/yyyy", {locale})}</span> <br/>
                  pelo motivo: <span>{info.reasonForCancellation}</span>
                </div>}
                <CertificadoCancelado canceled={info.canceled}>{info.canceled?"CERTIFICADO CANCELADO":"CERTIFICADO VALIDADO"}</CertificadoCancelado>
              </CertificadoInfo>
            </CertificadoValida>))}
          <ReadMoreButton
            text="Voltar para validação de certificado"
            type="button"
            onClick={()=>{navigate(`/valida`)}}
            disabled={false}
          />
        </>}
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default CoursesCertificateValidation;
