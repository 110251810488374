import React, { useLayoutEffect } from "react";
import { Routes, Route, BrowserRouter, useLocation} from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import PrivateRouteSchedule from "./utils/PrivateRouteSchedule";
import PrivateRouteLibrary from "./utils/PrivateRouteLibrary";
import PrivateRouteVideo from "./utils/PrivateRouteVideo";
import PrivateRouteCourse from "./utils/PrivateRouteCourse";
import PrivateRouteTest from "./utils/PrivateRouteTest";
import PrivateRouteDocuments from "./utils/PrivateRouteDocuments";
import ProtectedRoute from "./utils/ProtectedRoute";

import AuthRoute from "./utils/AuthRoute";

import Landing from "./pages/Landing";
import Agenda from "./pages/Agenda";
import Avaliacoes from "./pages/Avaliacoes";
import AvaliacoesGraficos from "./pages/AvaliacoesGraficos";
import Documentos from "./pages/Documentos";
import AbouUs from "./pages/AboutUs";
import BookRoom from "./pages/BookRoom";
import Platform from "./pages/Platform";
import VideoLibrary from "./pages/VideoLibrary";
import Courses from "./pages/Courses";
import MySpace from "./pages/MySpace";
import Help from "./pages/Help";
import Login from "./pages/Login";
import Policy from "./pages/Policy";
import UserManual from "./pages/UserManual";
import ManagerManual from "./pages/ManagerManual";
import Muve from "./pages/Muve";
import School from "./pages/School";
import ProjectMusic from "./pages/ProjectMusic";
import ProjectReaderCity from "./pages/ProjectReaderCity";
import ProjectGtSecurity from "./pages/ProjectGtSecurity";
// import PNLD from "./pages/Pnld";
// import ListaPNLD from "./pages/ListaPnld";
import Home from "./pages/Home";
import Home2 from "./pages/Home(2.0)";
import Construction from "./pages/Construction";
import TradeBookControl from "./pages/TradeBookControl";
import TradeBookList from "./pages/TradeBookList";
import CoursesManagerMainPage from "./pages/CoursesManagerMainPage";
import TestMonitor from "./pages/TestMonitor";
import CoursesManager from "./pages/CoursesManager";
import CoursesRegisterActivities from "./pages/CoursesRegisterActivities";
import CoursesManagerList from "./pages/CoursesManagerList";
import CoursesReport from "./pages/CoursesReport";
import CoursesCertificatePrint from "./pages/CoursesCertificatePrint";
import CoursesCertificateValidation from "./pages/CoursesCertificateValidation";
import CoursesSubscriptionManager from "./pages/CoursesSubscriptionManager";
import CoursesSubscriptionManagerList from "./pages/CoursesSubscriptionManagerList";
import CoursesCertificateManager from "./pages/CoursesCertificateManager";
import CoursesHelpUser from "./pages/CoursesHelpUser";
import CoursesHtpf from "./pages/CoursesHtpf";
import CoursesHtpfDetail from "./pages/CoursesHtpfDetail";
import REDs from "./pages/REDs";


//VIEWS
import DocumentosView from "./pages/Views/DocumentosView";
import LibraryView from "./pages/Views/LibraryView";
import CoursesView from "./pages/Views/CoursesView";
import VideotecaView from "./pages/Views/VideotecaView";
import AvaliacoesView from "./pages/Views/AvaliacoesView";
import ScheduleView from "./pages/Views/ScheduleView";
import REDsView from "./pages/Views/REDsView";

//FORMS
import ScheduleForm from "./pages/Forms/ScheduleForm";
import VideoForm from "./pages/Forms/VideoForm";
import DocumentsForm from "./pages/Forms/DocumentsForm";
import CourseForm from "./pages/Forms/CourseForm";
import LibraryInfoForm from "./pages/Forms/LibraryInfoForm";
import LibraryNewsForm from "./pages/Forms/LibraryNewsForm";
import LibraryAdminForm from "./pages/Forms/LibraryAdminForm";
import TestsForm from "./pages/Forms/TestsForm";
import CoursesSubscriptionForm from "./pages/Forms/CoursesSubscriptionForm";
// import BookPNLDForm from "./pages/Forms/BookPNLDForm";
// import PnldForm from "./pages/Forms/PnldForm";
import TradeBookFormRegister from "./pages/Forms/TradeBookFormRegister";
import TradeBookFormRequest from "./pages/Forms/TradeBookFormRequest";
import CoursesActivitiesForm from "./pages/Forms/CourseActivitiesForm";
import REDsForm from "./pages/Forms/REDsForm";

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Wrapper>
      <Routes>
        {/* SIMPLE ROUTES */}
        {/* <Route path="/versaoteste" index element={<ProtectedPage />} /> */}
        <Route path="/" element={<AuthRoute />}>
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="" index element={<Landing />} />
          </Route>
          <Route path="/ajuda" element={<ProtectedRoute/>}>
            <Route path="" element={<Help />} />
          </Route>
          <Route path="/ajuda" element={<ProtectedRoute/>}>
            <Route path="" element={<Help />} />
          </Route>
          <Route path="/ajudaformacao" element={<ProtectedRoute/>}>
            <Route path="" element={<CoursesHelpUser />} />
          </Route>
          <Route path="/videoteca" element={<ProtectedRoute/>}>
            <Route path="" element={<VideoLibrary />} />
          </Route>
          <Route path="/videoteca/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<VideotecaView />} />
          </Route>
          <Route path="/formacoes" element={<ProtectedRoute/>}>
            <Route path="" element={<Courses />} />
          </Route>
          <Route path="/formacoes/subscription" element={<ProtectedRoute/>}>
            <Route path="" element={<Courses action="subscription"/>} />
          </Route>
          <Route path="/formacoes/certificate" element={<ProtectedRoute/>}>
            <Route path="" element={<Courses action="certificate"/>} />
          </Route>
          <Route path="/formacoes/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<CoursesView />} />
          </Route>
          <Route path="/login" element={<ProtectedRoute/>}>
            <Route path="" element={<Login />} />
          </Route>
          <Route path="/login/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<Login />} />
          </Route>
          <Route path="/login/redirect/:local" element={<ProtectedRoute/>}>
            <Route path="" element={<Login />} />
          </Route>
          <Route path="/agenda" element={<ProtectedRoute/>}>
            <Route path="" element={<Agenda />} />
          </Route>
          <Route path="/agenda/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<ScheduleView />} />
          </Route>
          <Route path="/documentos" element={<ProtectedRoute/>}>
            <Route path="" element={<Documentos />} />
          </Route>
          <Route path="/documentos/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<DocumentosView />} />
          </Route>
          <Route path="/avaliacoes" element={<ProtectedRoute/>}>
            <Route path="" element={<Avaliacoes />} />
          </Route>
          <Route path="/avaliacoes/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<AvaliacoesView />} />
          </Route>
          <Route path="/avaliacoes/graficos" element={<ProtectedRoute/>}>
            <Route path="" element={<AvaliacoesGraficos />} />
          </Route>
          <Route path="/sobre" element={<ProtectedRoute/>}>
            <Route path="" element={<AbouUs />} />
          </Route>
          <Route path="/projetomusica" element={<ProtectedRoute/>}>
            <Route path="" element={<ProjectMusic />} />
          </Route>
          <Route path="/cidadeleitora" element={<ProtectedRoute/>}>
            <Route path="" element={<ProjectReaderCity />} />
          </Route>
          <Route path="/gtseguranca" element={<ProtectedRoute/>}>
            <Route path="" element={<ProjectGtSecurity />} />
          </Route>
          <Route path="/biblioteca" element={<ProtectedRoute/>}>
            <Route path="" element={<BookRoom />} />
          </Route>
          <Route path="/biblioteca/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<LibraryView />} />
          </Route>
          <Route path="/plataformas" element={<ProtectedRoute/>}>
            <Route path="" element={<Platform />} />
          </Route>
          <Route path="/muve" element={<ProtectedRoute/>}>
            <Route path="" element={<Muve />} />
          </Route>
          <Route path="/school" element={<ProtectedRoute/>}>
            <Route path="" element={<School />} />
          </Route>
          <Route path="/politicaetermos" element={<ProtectedRoute/>}>
            <Route path="" element={<Policy />} />
          </Route>
          <Route path="/manualusuario" element={<ProtectedRoute/>}>
            <Route path="" element={<UserManual />} />
          </Route>
          <Route path="/manualgestor" element={<ProtectedRoute/>}>
            <Route path="" element={<ManagerManual />} />
          </Route>
          <Route path="/construcao" element={<ProtectedRoute/>}>
            <Route path="" element={<Construction />} />
          </Route>
          <Route path="/valida" element={<ProtectedRoute/>}>
            <Route path="" element={<CoursesCertificateValidation />} />
          </Route>
          <Route path="/valida/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<CoursesCertificateValidation />} />
          </Route>
          
          <Route path="/reds" element={<ProtectedRoute/>}>
            <Route path="" element={<REDs/>} />
          </Route>

          <Route path="/reds/:id" element={<ProtectedRoute/>}>
            <Route path="" element={<REDsView/>} />
          </Route>

          {/* AUTHENTICATED ROUTES */}

          <Route path="/home" element={<PrivateRoute />}>
            <Route path="" element={<Home action="" />} />
          </Route>
          <Route path="/home2" element={<PrivateRoute />}>
            <Route path="" element={<Home2 action="" />} />
          </Route>
          
          <Route path="/subscriptionmanager" element={<PrivateRoute />}>
            <Route path="" element={<CoursesSubscriptionManager action="" />} />
          </Route>
          <Route path="/subscriptionmanagerlist" element={<PrivateRoute />}>
            <Route path="" element={<CoursesSubscriptionManagerList action="" />} />
          </Route>
          
          {/* <Route path="/pnld" element={<PrivateRoute />}>
            <Route path="" element={<PNLD />} />
          </Route>
          <Route path="/pnld/myunity/:id" element={<PrivateRoute />}>
            <Route path="" element={<PNLD action="myunity"/>} />
          </Route>
          
          <Route path="/pnld/dispor/add" element={<PrivateRoute />}>
            <Route path="" element={<PnldForm action="disporAdd"/>} />
          </Route>
          <Route path="/pnld/dispor/edit/:id" element={<PrivateRoute />}>
            <Route path="" element={<PnldForm action="disporEdit"/>} />
          </Route>

          <Route path="/pnld/solicitar/add/:idBook/:idUnity" element={<PrivateRoute />}>
            <Route path="" element={<PnldForm action="solicitarAdd"/>} />
          </Route>
          <Route path="/pnld/solicitar/edit/:id" element={<PrivateRoute />}>
            <Route path="" element={<PnldForm action="solicitarEdit"/>} />
          </Route>

          <Route path="/pnld/aprovar/edit/:id" element={<PrivateRoute />}>
            <Route path="" element={<PnldForm action="aprovarEdit"/>} />
          </Route>

          <Route path="/listapnld" element={<PrivateRoute />}>
            <Route path="" element={<ListaPNLD />} />
          </Route>
          <Route path="/listapnld/add" element={<PrivateRoute />}>
            <Route path="" element={<BookPNLDForm action="add"/>} />
          </Route>
          <Route path="/listapnld/edit/:id" element={<PrivateRoute />}>
            <Route path="" element={<BookPNLDForm action="edit"/>} />
          </Route> */}

          {/* Controle de Estoque e controle de movimentação */}

            {/* Controle de Estoque e controle de movimentação - Geral e  da Unidade */}

              <Route path="/book/:category" element={<PrivateRoute />}>
                <Route path="" element={<TradeBookControl />} />
              </Route>
              <Route path="/book/:category/myunity/:id" element={<PrivateRoute />}>
                <Route path="" element={<TradeBookControl action="myunity"/>} />
              </Route>

            {/* Controle de Estoque e controle de movimentação - Geral e  da Unidade Fim */}
            
            {/* Controle de Estoque e controle de movimentação - Formularios de Requisições */}

              <Route path="/bookpost/:category/add" element={<PrivateRoute />}>
                <Route path="" element={<TradeBookFormRequest action="postAdd"/>} />
              </Route>
              {/* <Route path="/bookpost/:category/edit/:id" element={<PrivateRoute />}>
                <Route path="" element={<PnldForm action="disporEdit"/>} />
              </Route> */}

              <Route path="/bookrequest/:category/add/:idBook/:idUnity" element={<PrivateRoute />}>
                <Route path="" element={<TradeBookFormRequest action="requestAdd"/>} />
              </Route>
              <Route path="/bookrequest/:category/edit/:id" element={<PrivateRoute />}>
                <Route path="" element={<TradeBookFormRequest action="requestEdit"/>} />
              </Route>

              <Route path="/bookapprove/:category/edit/:id" element={<PrivateRoute />}>
                <Route path="" element={<TradeBookFormRequest action="approveEdit"/>} />
              </Route>

            {/* Controle de Estoque e controle de movimentação - Formularios de Requisições Fim*/}

          {/* Controle de Estoque e controle de movimentação Fim */}

          {/* Controle de Cadastro de Livros */}

            <Route path="/bookregister/:category" element={<PrivateRoute />}>
              <Route path="" element={<TradeBookList />} />
            </Route>

            <Route path="/bookregister/:category/add" element={<PrivateRoute />}>
              <Route path="" element={<TradeBookFormRegister action="add"/>} />
            </Route>

            <Route path="/bookregister/:category/add/:isbnRegister" element={<PrivateRoute />}>
              <Route path="" element={<TradeBookFormRegister action="add"/>} />
            </Route>

            <Route path="/bookregister/:category/edit/:id" element={<PrivateRoute />}>
              <Route path="" element={<TradeBookFormRegister action="edit"/>} />
            </Route>

          {/* Controle de Cadastro de Livros */}

          <Route path="/meuespaco" element={<PrivateRoute />}>
            <Route path="" element={<MySpace action="add" />} />
          </Route>
          <Route path="/inscricaoformacoes/:id" element={<PrivateRoute />}>
            <Route path="" element={<CoursesSubscriptionForm />} />
          </Route>
          <Route path="/agenda/add" element={<PrivateRouteSchedule />}>
            <Route path="" element={<ScheduleForm action="add" />} />
          </Route>
          <Route path="/agenda/editar/:id" element={<PrivateRouteSchedule />}>
            <Route path="" element={<ScheduleForm action="edit" />} />
          </Route>
          <Route path="/biblioteca/admin" element={<PrivateRouteLibrary />}>
            <Route path="" element={<LibraryAdminForm />} />
          </Route>
          <Route path="/biblioteca/info/add" element={<PrivateRouteLibrary />}>
            <Route path="" element={<LibraryInfoForm action="add" />} />
          </Route>
          <Route
            path="/biblioteca/info/editar/:id"
            element={<PrivateRouteLibrary />}
          >
            <Route path="" element={<LibraryInfoForm action="edit" />} />
          </Route>
          <Route path="/biblioteca/news/add" element={<PrivateRouteLibrary />}>
            <Route path="" element={<LibraryNewsForm action="add" />} />
          </Route>
          <Route
            path="/biblioteca/news/editar/:id"
            element={<PrivateRouteLibrary />}
          >
            <Route path="" element={<LibraryNewsForm action="edit" />} />
          </Route>
          <Route path="/videoteca/add" element={<PrivateRouteVideo />}>
            <Route path="" element={<VideoForm action="add" />} />
          </Route>
          <Route path="/videoteca/editar/:id" element={<PrivateRouteVideo />}>
            <Route path="" element={<VideoForm action="edit" />} />
          </Route>
          <Route path="/formacoes/add" element={<PrivateRouteCourse />}>
            <Route path="" element={<CourseForm action="add" />} />
          </Route>
          <Route path="/formacoes/add/:id" element={<PrivateRouteCourse />}>
            <Route path="" element={<CourseForm action="add" />} />
          </Route>
          <Route path="/formacoes/editar/:id" element={<PrivateRouteCourse />}>
            <Route path="" element={<CourseForm action="edit" />} />
          </Route>
          <Route path="/avaliacoes/add" element={<PrivateRouteTest />}>
            <Route path="" element={<TestsForm action="add" />} />
          </Route>
          <Route path="/avaliacoes/editar/:id" element={<PrivateRouteTest />}>
            <Route path="" element={<TestsForm action="edit" />} />
          </Route>
          <Route path="/documentos/add" element={<PrivateRouteDocuments />}>
            <Route path="" element={<DocumentsForm action="add" />} />
          </Route>
          <Route
            path="/documentos/editar/:id"
            element={<PrivateRouteDocuments />}
          >
            <Route path="" element={<DocumentsForm action="edit" />} />
          </Route>

          <Route path="/formacoes/manager" element={<PrivateRoute />}>
            <Route path="" element={<CoursesManagerMainPage />} />
          </Route>

          <Route path="/coursemanager/list" element={<PrivateRoute />}>
              <Route path="" element={<CoursesManagerList />} />
          </Route>

          <Route path="/coursemanager/:id" element={<PrivateRoute />}>
              <Route path="" element={<CoursesManager />} />
          </Route>

          <Route path="/coursemanager/:idcourse/activities/add/:typeregister" element={<PrivateRoute />}>
              <Route path="" element={<CoursesActivitiesForm action="add"/>} />
          </Route>
          <Route path="/coursemanager/:idcourse/activities/edit/:id" element={<PrivateRoute />}>
              <Route path="" element={<CoursesActivitiesForm action="edit"/>} />
          </Route>

          <Route path="/courseregistermanager/:id" element={<PrivateRoute />}>
              <Route path="" element={<CoursesRegisterActivities />} />
          </Route>

          <Route path="/coursereport" element={<PrivateRoute />}>
              <Route path="" element={<CoursesReport />} />
          </Route>

          <Route path="/testmonitor" element={<PrivateRoute />}>
              <Route path="" element={<TestMonitor />} />
          </Route>

          <Route path="/coursecertificate/:id" element={<PrivateRoute />}>
              <Route path="" element={<CoursesCertificatePrint />} />
          </Route>

          <Route path="/coursecertificatemanager" element={<PrivateRoute />}>
              <Route path="" element={<CoursesCertificateManager />} />
          </Route>

          <Route path="/htpf" element={<PrivateRoute />}>
              <Route path="" element={<CoursesHtpf />} />
          </Route>

          <Route path="/htpfUpdate" element={<PrivateRoute />}>
              <Route path="" element={<CoursesHtpf />} />
          </Route>

          <Route path="/htpfDetail/:id" element={<PrivateRoute />}>
              <Route path="" element={<CoursesHtpfDetail />} />
          </Route>

          <Route path="/htpfPreview" element={<PrivateRoute />}>
              <Route path="" element={<CoursesHtpfDetail />} />
          </Route>

          <Route path="/reds/add" element={<PrivateRoute />}>
              <Route path="" element={<REDsForm action="add"/>} />
          </Route>

          <Route path="/reds/edit/:id" element={<PrivateRoute />}>
              <Route path="" element={<REDsForm action="edit"/>} />
          </Route>

        </Route>
      </Routes>
      </Wrapper>
    </BrowserRouter>
  );
};

export default AppRoutes;
