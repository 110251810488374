import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import generatePDF, { Resolution } from "react-to-pdf";
import { PDFViewer, PDFDownloadLink, Document, Page, View, Text, Link, StyleSheet, Svg, Rect, Path, G, Font, Image } from '@react-pdf/renderer';
import QRCode from "react-qr-code";
import useQRCodeGenerator from 'react-hook-qrcode-svg'
import { getDay, isSameMonth, isSameDay, getMonth, getYear,format } from "date-fns";
import locale from "date-fns/locale/pt-BR";

import {
  Container,
  ContainerQrCode,
  ContainerCertificate,
  // Page,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
import InputSearch from "../../components/UI/Inputs/InputSearch";
// import CardItem from "../../components/UI/CardItem";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

import { icons, altFonts, images } from "../../constants";

import formatCPF from "../../utils/formatCpf";

const CoursesCertificatePrint = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const QRCODE_SIZE = 80
  const QRCODE_LEVEL = 'H'
  const QRCODE_BORDER = 2

  const [userSubscription,setUserSubscription]= useState("");
  
  const [certificate, setCertificate]= useState({
    idCertificate: "",
		idUserCertificate: "",
		courseTitle: "",
		name: "",
		cpf: "",
		totalWorkload: 0,
		hoursTaken: 0,
		hoursJustified: 0,
		courseBegin: new Date(),
		courseEnd: new Date(),
		createdAt: new Date(),
		updatedAt: "",
		canceled: null,
		reasonForCancellation: null,
		createdBy: ""
  });

  // const { path, viewBox } = useQRCodeGenerator(`${window.location.origin}/certificatevalidation/123456`, QRCODE_LEVEL, QRCODE_BORDER)

  const { path, viewBox } = useQRCodeGenerator(
    `${window.location.origin}/valida/${certificate.idCertificate}`,
    QRCODE_LEVEL,
    QRCODE_BORDER
  )

  // const targetRef = useRef();

  // const options = {
  //     // default is `save`
  //     // method: 'open',
  //     // default is Resolution.MEDIUM = 3, which should be enough, higher values
  //     // increases the image quality but also the size of the PDF, so be careful
  //     // using values higher than 10 when having multiple pages generated, it
  //     // might cause the page to crash or hang.
  //     resolution: Resolution.HIGH,
  //     page: {
  //       // margin is in MM, default is Margin.NONE = 0
  //       // margin: Margin.SMALL,
  //       // default is 'A4'
  //       // format: 'letter',
  //       // default is 'portrait'
  //       orientation: 'landscape',
  //     },
  //     canvas: {
  //       // default is 'image/jpeg' for better size performance
  //       mimeType: 'image/png',
  //       qualityRatio: 1
  //     },
  //     // Customize any value passed to the jsPDF instance and html2canvas
  //     // function. You probably will not need this and things can break, 
  //     // so use with caution.
  //     overrides: {
  //       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
  //       pdf: {
  //           compress: true
  //       },
  //       // see https://html2canvas.hertzen.com/configuration for more options
  //       canvas: {
  //           useCORS: true
  //       }
  //     },
  // };
  
  // // you can use a function to return the target element besides using React refs
  // const getTargetElement = () => document.getElementById('content-id');

  // Font.register({
  //   family: 'Montserrat',
  //   fonts:[
  //     {src:altFonts.OpenSans, fontWeight:300},
  //     {src:altFonts.OpenSans, fontWeight:500},
  //     {src:altFonts.OpenSans, fontWeight:600},
  //   ]
  // })

  Font.register({
    family: 'ShareTechMono',
    src: altFonts.ShareTechMonoRegular,
    fontWeight: 400,
    fontStyle: "normal"
  })

  // Font.register({
  //   family: 'Montserrat',
  //   src: altFonts.OpenSans,
  //   fontStyle: "normal"
  // })

  Font.register({
    family: 'MontserratLight',
    src: altFonts.MontserratLight,
    fontWeight: 300,
    fontStyle: "normal"
  })

  Font.register({
    family: 'MontserratMedium',
    src: altFonts.MontserratMedium,
    fontWeight: 400,
    fontStyle: "normal"
  })

  Font.register({
    family: 'MontserratSemiBold',
    src: altFonts.MontserratSemiBold,
    fontWeight: 600,
    fontStyle: "normal"
  })

  // Font.register({
  //   family: 'Montserrat',
  //   src: altFonts.MontserratRegular,
  //   fontWeight: 400,
  //   fontStyle: "normal"
  // })

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
    },
    // sectionQr: {
      
    // },
    // sectionQrInfo: {
    //   marginTop: 15,
    //   marginLeft: 20,
    //   fontSize: 12.7,
    // },
    content: {
      margin: "1cm",
      flexDirection: 'column',
    },
    // Column: {
    //   margin: 20,
    //   padding: 20,
    //   flexDirection: 'column',
    //   fontSize: "20pt",
    // },
    // textMontLight20: {
    //   fontFamily: "MontserratLight",
    //   fontSize: "20pt",
    //   fontWeight: 300,
    //   fontStyle: 'normal'
    // },
    // textMontLight9: {
    //   fontFamily: "MontserratLight",
    //   fontSize: "9pt",
    //   fontWeight: 300,
    // },
    // textMontLight8: {
    //   fontFamily: "MontserratLight",
    //   fontSize: "8pt",
    //   fontWeight: 300,
    // },
    // textMontMedium24:{
    //   fontFamily: "MontserratMedium",
    //   fontSize: "24pt",
    //   fontWeight: 400,
    // },
    // textMontMedium18:{
    //   fontFamily: "MontserratMedium",
    //   fontSize: "18pt",
    //   fontWeight: 400,
    // },
    // textMontSemi18:{
    //   fontFamily: "MontserratSemiBold",
    //   fontSize: "18pt",
    //   fontWeight: 600,
    // },
    // textMontSemi10:{
    //   fontFamily: "MontserratSemiBold",
    //   fontSize: "10pt",
    //   fontWeight: 600,
    // },
    // textMontSemi9:{
    //   fontFamily: "MontserratSemiBold",
    //   fontSize: "9pt",
    //   fontWeight: 600,
    // },
    // textMontSemi8:{
    //   fontFamily: "MontserratSemiBold",
    //   fontSize: "8pt",
    //   fontWeight: 600,
    // },
    // textShareTech10:{
    //   fontFamily: "ShareTechMono",
    //   fontSize: "10pt",
    //   fontWeight: 400,
    // },
    ViewDados1:{
      // border: "1px solid black",
      width: "440pt",
      height: "305pt",
      position: "absolute",
      top: "-2pt",
      left: "230pt"
    },
    ViewInfo:{
      width: "440pt",
      marginTop: "12pt",
      flexDirection: 'column',
    },
    ViewInfoRow:{
      width: "440pt",
      marginTop: "15pt",
      flexDirection: 'row',
    },
    TextDados:{
      fontFamily: "MontserratLight",
      fontSize: "20pt",
      fontWeight: 300,
    },
    TextNome:{
      // border: "1px solid black",
      fontFamily: "MontserratMedium",
      fontSize: "24pt",
      fontWeight: 500,
      width: "440pt",
      maxHeight: "61pt",
    },
    TextCPF:{
      fontFamily: "MontserratMedium",
      fontSize: "18pt",
      fontWeight: 500,
      width: "440pt",
    },
    TextCurso:{
      fontFamily: "MontserratLight",
      fontSize: "20pt",
      fontWeight: 300,
    },
    TextTitleCurso:{
      // border: "1px solid black",
      fontFamily: "MontserratSemiBold",
      fontSize: "18pt",
      fontWeight: 600,
      maxHeight: "46pt",
    },
    TextPeriodo:{
      fontFamily: "MontserratLight",
      fontSize: "20pt",
      fontWeight: 300,
    },
    TextDataPeriodo:{
      fontFamily: "MontserratSemiBold",
      fontSize: "18pt",
      fontWeight: 600,
    },
    TextCarga:{
      fontFamily: "MontserratLight",
      fontSize: "20pt",
      fontWeight: 300,
    },
    TextCargaBold:{
      fontFamily: "MontserratSemiBold",
      fontSize: "18pt",
      fontWeight: 600,
    },
    ViewLocalData:{
      fontFamily: "MontserratMedium",
      fontSize: "18pt",
      fontWeight: 500,
      width: "440pt",
      position: "absolute",
      top: "310pt",
      left: "230pt"
    },
    ViewInformacao:{
      position: "absolute",
      left: "230pt",
      top: "445pt",
      fontFamily: "MontserratSemiBold",
      fontSize: "10pt",
      fontWeight: 600,
    },
    ViewInformacaoArea:{
      position: "absolute",
      flexDirection: 'row',
      left: "230pt",
      top: "470pt",
      maxWidth: "455pt",
      // border: "1px solid black",
    },
    ViewInformacaoIncial:{
      marginRight:"5pt",
    },
    ViewInformacaoNext:{
      marginRight:"5pt",
      paddingLeft:"10pt",
      borderLeft: "2pt solid Black",
    },
    ViewInfoComplementar:{
      flexDirection: 'row',
    },
    TextInfo:{
      fontFamily: "MontserratLight",
      fontSize: "9pt",
      fontWeight: 300,
    },
    TextInfoBold:{
      fontFamily: "MontserratSemiBold",
      fontSize: "9pt",
      fontWeight: 600,
    },
    Viewvalida:{
      position: "absolute",
      left: "230pt",
      top: "516pt",
    },
    ViewvalidaCodigo:{
      position: "absolute",
      right: "9pt",
      top: "526pt",
    },
    TextValida:{
      fontFamily: "MontserratLight",
      fontSize: "8pt",
      fontWeight: 300,
    },
    TextValidaLink:{
      fontFamily: "MontserratMedium",
      fontSize: "8pt",
      fontWeight: 500,
      color: "black",
      textDecoration: "none",
    },
    TextValidaCodigo:{
      fontFamily: "ShareTechMono",
      fontSize: "10pt",
      fontWeight: 400,
    },
    ViewQrCodigo:{
      position: "absolute",
      top: "444pt",
      left: "695pt"
    },
  });

  const MyDoc = () => (
    <Document author="Canal pedagógico" creator="Canal pedagógico" producer="Canal pedagógico" title={`${certificate.name.trim()} - ${certificate.courseTitle.trim()}`}>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.content}>
          <Image src={images.certificadoPng_Rgb_logo}/>
          <View style={styles.ViewDados1}>
            <View style={styles.ViewInfo}>
              <Text style={styles.TextDados}>Certificamos que</Text>
              <Text style={styles.TextNome}>{certificate.name.trim()}</Text>
              <Text style={styles.TextCPF}>{formatCPF(certificate.cpf)}</Text>
            </View>
            <View style={styles.ViewInfo}>
              <Text style={styles.TextCurso}>participou do curso</Text>
              <Text style={styles.TextTitleCurso}>{certificate.courseTitle.trim()}</Text>
            </View>
            <View style={styles.ViewInfo}>
              <Text style={styles.TextPeriodo}>realizado no período de</Text>
              <Text style={styles.TextDataPeriodo}>{isSameMonth(new Date(certificate.courseBegin),new Date(certificate.courseEnd))?(isSameDay(new Date(certificate.courseBegin),new Date(certificate.courseEnd))?
                format(new Date(certificate.courseBegin), "dd 'de' MMMM 'de' yyyy", {locale}):
                `${format(new Date(certificate.courseBegin), "dd", {locale})} a ${format(new Date(certificate.courseEnd), "dd 'de' MMMM 'de' yyyy", {locale})}`):
                `${format(new Date(certificate.courseBegin), "dd 'de' MMMM", {locale})} a ${format(new Date(certificate.courseEnd), "dd 'de' MMMM 'de' yyyy", {locale})}`}</Text>
            </View>
            <View style={styles.ViewInfoRow}>
              <Text style={styles.TextCarga}>com carga horária de </Text>
              <Text style={styles.TextCargaBold}>{certificate.totalWorkload}</Text>
              <Text style={styles.TextCarga}> horas</Text>
            </View>
          </View>
          <View style={styles.ViewLocalData}>
            <Text>Mogi das Cruzes, {format(new Date(certificate.createdAt), "dd 'de' MMMM 'de' yyyy", {locale})}</Text>
          </View>

          <View style={styles.ViewInformacao}>
            <Text>Informações Complementares</Text>
          </View>

          <View style={styles.ViewInformacaoArea}>
            <View style={styles.ViewInformacaoIncial}>
              <View style={styles.ViewInfoComplementar}>
                <Text style={styles.TextInfo}>Carga Horária Total: </Text>  
                <Text style={styles.TextInfoBold}>{certificate.totalWorkload}</Text>  
                <Text style={styles.TextInfo}> h</Text>  
              </View>
              <View style={styles.ViewInfoComplementar}>
                <Text style={styles.TextInfo}>Carga Horária Realizada: </Text>  
                <Text style={styles.TextInfoBold}>{certificate.hoursTaken}</Text>  
                <Text style={styles.TextInfo}> h</Text>  
              </View>
              <View style={styles.ViewInfoComplementar}>
                <Text style={styles.TextInfo}>Abonadas (HTPF): </Text>  
                <Text style={styles.TextInfoBold}>{certificate.hoursJustified}</Text>  
                <Text style={styles.TextInfo}> h</Text>  
              </View>
            </View>
            {certificate.htpf && <View style={styles.ViewInformacaoNext}>
              <View style={styles.ViewInfoComplementar}>
                <Text style={styles.TextInfo}>Formação elementar (lançado pelo DEPED)</Text>  
              </View>
            </View>}
          </View>

          <View style={styles.Viewvalida}>
            <Text style={styles.TextValida}>Para validar esse certificado, escaneie o QRCode ou entre em:</Text>
            <Link src={`${window.location.origin}/valida/${certificate.idCertificate}`} style={styles.TextValidaLink}>{window.location.origin}/valida</Link>
          </View>
          <View style={styles.ViewvalidaCodigo}>
            <Text style={styles.TextValidaCodigo}>{certificate.idCertificate}</Text>
          </View>

          <View style={styles.ViewQrCodigo}>
            <Svg
              width={80}
              height={80}
              viewBox={viewBox}
              stroke='none'
            >
              <Rect width='100%' height='100%' fill='#ffffff' />
              <Path d={path} fill='#000000' strokeWidth={0.1}/>
            </Svg>
          </View>
        </View>
      </Page>
    </Document>
  );

  const [getCertificate, certificateInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/certificate",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        let certificate = response.data.find((value)=>{return value.canceled===false});
        if(certificate){
          console.log("certificado", certificate);
          setCertificate(certificate);
        }
      }
    },
  });

  useEffect(() => {
    getCertificate({params:{id}});
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Menu />
      {(certificateInfo.loading) && <FullScreenLoading />}
      <Banner>
        Certificado
      </Banner>
      <ContentBox>
        {certificate.idCertificate!==""?
          <ContainerCertificate>
            <PDFViewer width="100%" height="100%">
              <MyDoc />
            </PDFViewer>
          </ContainerCertificate>
        :
        <>Certificado não localizado<br/></>
        }
        <ReadMoreButton text={"voltar"} onClick={()=>{navigate(-1);}}/>
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default CoursesCertificatePrint;
