import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  flex-wrap:nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const AvaliacaoTitle = styled.div`
  display: flex;
  flex-direction: row;
  font: var(--title);
`;

export const AplicationTime = styled.div`
  display: flex;
  flex-direction: row;
  font: var(--body);
`;

export const Orientation = styled.div`
  display: flex;
  flex-direction: row;
  font: var(--body);
`;

export const Material = styled.div`
  display: flex;
  flex-direction: row;
  font: var(--body);
`;

export const Plataforma = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  font: var(--body);
`;

export const Result = styled.div`
  display: flex;
  flex-direction: row;
  font: var(--body);
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  font: var(--body);
  margin-right: 1rem;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  display: flex;
  flex-direction: row;
  align-items:center;  
`;
