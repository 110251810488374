import styled from "styled-components";

export const Container = styled.div`
  /* width: 100%;
  height: 100%; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: var(--primary);
  opacity: 0.9;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 1rem;

  > span {
    font: var(--body);
    color: var(--secondary);
    word-wrap: wrap;
  }
`;
