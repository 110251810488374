import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 118rem;
  min-height: 50vh;
  margin: 2rem auto 2rem auto;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: var(--secondary);
  /* border: 1px solid red; */

  @media (max-width: 1180px) {
    padding: 0 1.6rem;
  }

  /* @media (max-width: 600px) {
    padding: 0;
  } */
`;
