import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth } from "../../hooks/useAuth";

const PrivateRouteDocuments = () => {
  const { user } = useAuth();
  const editor = user?.profiles[0]?.editor?.findIndex(
    (edit) => edit.id === "46150c88-042a-4220-b81e-bb6c399fa275"
  );

  return editor > -1 ? <Outlet /> : <Navigate to="/login/redirect/*documentos" />;
};

export default PrivateRouteDocuments;
