import React, { useEffect } from "react";
import {
  Container,
  Title,
  Item,
  ItemClasse
} from "./styles";

const Cargo = ({
  classes=[]
}) => {
  useEffect(() => {
    // console.log("classes",classes);
  }, [classes]);

  return (
    <Container>
        <Title>RGF: {classes[0]?.rgf}</Title>
        <Item>HTPF: {classes[0]?.htpf?"Sim":"Não"}</Item>
        <Item>Classes:</Item>
        {classes.map(classe => {
          // console.log("classe",classe);
          return (<ItemClasse key={classe.idClass}>{classe.unityName} - {classe.schoolStage} - {classe.schoolGrade} {classe.className}</ItemClasse>);
        })}
    </Container>
  );
};

export default Cargo;
