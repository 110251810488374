import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  margin: 4rem;
  /* border: 1px solid black; */
`;

export const Form = styled.form`
  width: 100%;
  max-width: 90rem;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 4rem;
  gap: 0.5rem;

  @media (max-width: 700px) {
    padding: 0 1.6rem;
  }
`;

export const Greetings = styled.div`
  font: var(--title);
  color: var(--primary);
`;

export const Initial = styled.div`
  margin-bottom: 2rem;
  font: var(--body);
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
`;

export const Place = styled.span`
  color: var(--primary);
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-end;
  gap: 2rem;
`;

export const CoverUrlBox = styled.div`
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  margin-top: 3rem;

  > img {
    margin-left: 1.6rem;
  }
`;

export const ModalCoverUrl = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 1rem;
  top: 0;
  left: 0;
`;

export const ModalCoverUrlContent = styled.div`
  width: 100%;
  background: var(--secondary);
  position: relative;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  > button {
    :first-child {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      background: unset;
      margin: 0;
      z-index: 999;
    }
  }
`;

export const CoverUrlCropped = styled.img`
  width: 16rem;
  height: 16rem;
  margin: 3rem 0;
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.5);
`;

export const ChangeAvatarBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 16rem;
  height: 16rem;
  gap: 1rem;

  img {
    max-width: 100%;
    border-radius: 50%;
  }

  input {
    display: none;
  }
`;

export const ChangeAvatarLabel = styled.label`
  cursor: pointer;
`;

export const ItemName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 3rem;

  font: var(--title);
  color: var(--primary);
`;

export const DivPend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 3rem;
  border: 0.1rem solid;
  border-radius: 2rem;
  color: var(--primary);
  padding: 0 0 2rem 0;
  `;

export const ItemDados = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 95%;
  margin-top: 0rem;
  padding-left: 2rem;

  font: var(--body);
  color: var(--primary);
`;

export const ItemDadosRow = styled.div`
  display: flex;
  flex-direction: row;
  button {
    margin-left: 1rem;
  }

  font: var(--body);
  color: var(--primary);
`;

export const Note = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 95%;
  margin-top: 0rem;
  padding-left: 2rem;
  font: var(--body);
  color: var(--primary);
  font-size: calc(var(--size) * 1.2);
`;

export const Img = styled.img`
  width: 5rem;
`

export const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 0 1rem 4rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
`;

export const TitlePend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-align: justify;
  font: var(--title);
  padding-left: 1rem; 
`

export const Link = styled.div`
  transition: transform 0.3s ease;
  cursor: pointer;

  :hover {
    transform: scale(0.9);
  }
`


