import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  margin-top: 10rem;
  background-color: var(--secondary);
`;

export const Title = styled.h1`
  font: var(--title);
  margin: 4rem 0;
  color: var(--primary);
`;

export const Form = styled.form`
  width: 100%;
  max-width: 90rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 4rem;
  gap: 2rem;

  @media (max-width: 700px) {
    padding: 0 1.6rem;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-end;
  gap: 2rem;
`;

export const SummaryBox = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 0.5rem;
  background-color: var(--softPurple);
  border-radius: 1rem;
`;

export const SummaryTitle = styled.h2`
  font-family: var(--fontFamily);
  font-size: calc(var(--size) * 1.8);
  font-weight: var(--mediumFont);
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 1.8rem;
  width: 100%;
  color: var(--primary);
  font: var(--body);

  > span {
    max-width: 90%;
  }

  > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    background: var(--secondary);
    border: 0.1rem solid var(--hardRed);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: var(--hardRed);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1) rotate(180deg);
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 3rem;
  align-self: center;
`;

export const SearchBox = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 0.5rem;
  border-radius: 1rem;
  border: 0.1rem solid var(--primary);
  max-height: 40rem;
  overflow-y: scroll;
`;

export const SearchItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 1.8rem;
  width: 100%;
  color: var(--primary);
  font: var(--body);

  > span {
    max-width: 90%;
  }

  > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    background: var(--secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    color: var(--purple);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const DeleteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const DeleteButton = styled.button`
  background: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--hardRed);
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 500px) {
    position: initial;
    align-self: center;
  }

  &:hover {
    transform: scale(1.1) rotate(20deg);
  }
`;
