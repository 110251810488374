import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: absolute;
  right: 2rem;
  top: 3rem;
  /* z-index: -3; */
  transition: all 0.2s ease;
  /* flex-direction: column; */
  /* position: absolute; */
  /* right: 0.6rem; */
  /* top: ${(props) => props.marginTop}rem; */
  /* z-index: 1; */

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Icon = styled.button`
  cursor: pointer;
  background: unset;
  transition: all 0.2s ease;
  width: 3.6rem;

  > img {
    max-width: 100%;
  }

  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(90deg);
    `}

  &:hover {
    transform: scale(1.1);
  }
`;
