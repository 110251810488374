import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { useNavigate, useParams } from "react-router-dom";

import {
  Container,
  Description,
  ListContent,
} from "./styles";
import Accessibility from "../../components/UI/Accessibility";
import Menu from "../../components/UI/Menu";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import { images } from "../../constants";
import Banner from "../../components/UI/Banner";


import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import Book from "./Components/Book";

const TradeBookList = ({ action }) => {
  const { user, accessRight } = useAuth();
  const [books, setBooks] = useState([]);
  const { category } = useParams();

  const [loadBooks, loadBooksInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/books/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        console.log("teste",loadBooksInfo);
        setBooks(response.data);
        // console.log("loadDocumentos", response);
      }
      console.log(response.data)
    },
  });

  useEffect(() => {
    console.log("user",user)
    loadBooks({
      params:{
        idCategory:category
      }
    });
    console.log("profiles[0]", user.profiles[0]);
    console.log("bookInventoryRights", accessRight("books",category).SP_rights);
    //eslint-disable-next-line
  }, [category]);

  return (
    <Container>
      {loadBooksInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={
          (category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && images.icon_pnld) ||
          (category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && images.livrosEstado)
        }
        url={`/bookregister/${category}/add`}
        canEdit={accessRight("books",category).SP_erro?false:accessRight("books",category).SP_rights.can_register_books}
      >
        {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>Cadastro de livros PNLD</>}
        {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>Cadastro de livros Livros do Estado</>}
      </Banner>
      <ContentBox>
        <Description>
          {/* Descrição Livros PNLD */}
          {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>
            Este espaço é destinado ao cadastro e consulta de livros disponíveis. O Programa Nacional do Livro e do Material Didático (PNLD) é destinado a avaliar e a disponibilizar obras didáticas, 
            pedagógicas e literárias, entre outros materiais de apoio à prática educativa, de forma sistemática, regular e gratuita, 
            às escolas públicas de educação básica das redes federal, estaduais, municipais e distrital e também às instituições 
            de educação infantil comunitárias, confessionais ou filantrópicas sem fins lucrativos e conveniadas com o Poder Público. 
          </>}

          {/* Descrição Livros do Estado */}
          {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>
            Este espaço é destinado ao cadastro e consulta de livros disponíveis dos livros do Estado
          </>}

        </Description>
        
        <ListContent>

      {accessRight("books",category).SP_erro?false:accessRight("books",category).SP_rights.can_register_books ?
          books.map((item)=>(
            <Book book={item} key={`${item.idBook}`} category={category}/>
          ))
       :
       <Description>* Não há mais conteúdos para o seu usuário</Description> 
        }

        </ListContent>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default TradeBookList;
