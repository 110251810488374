import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import useApi from "../../../hooks/useApi";
import axios from "axios";
import {
  Container,
  Title,
  Form,
  Item,
  Controls,
  Description,
  Icon,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputSelect from "../../../components/UI/Inputs/InputSelect";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";

// import AttachPlaceholder from "../../../components/UI/AttachPlaceholder";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
// import DeleteButton from "../../../components/UI/DeleteButton";
import InputCheck from "../../../components/UI/Inputs/InputCheck";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import Modal from "../../../components/UI/Modal";

import {icons} from "../../../constants";

const TradeBookFormRegister = ({ action }) => {
  // const [result, setResult] = useState("");
  const { category, id, isbnRegister } = useParams();

  // const [data, setData] = useState('not found');

  const [ISBNValido, setISBNValido] = useState(false);
  // const [ISBNCheckSumValido, setISBNCheckSumValido] = useState(false);
  const [ISBNMessage, setISBNMessage] = useState("");

  const [ciclo, setCiclo] = useState([]);
  const [disciplina, setDisciplina] = useState([]);

  const [modalError, setModalError] = useState(false);
  const [modalErrorMenssage, setModalErrorMenssage] = useState("");

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      isbn:"",
      title: "",
      idSubject: "",
      publisherName: "", 
      idSchoolGradeList: "",
      destinedTo: "",
      active:true,
      idCategory: "",
    },
    validationSchema: Yup.object({
      isbn: Yup.string(),
      title: Yup.string().required("Digite um título."),
      idSubject: Yup.string().required("Selecione uma disciplina."),
      publisherName: Yup.string().required("Digite uma editora."),
      idSchoolGradeList: Yup.string().required("Selecione o ano correspondente ao Livro."),
      destinedTo: Yup.string().required("Selecione o tipo do exemplar do Livro."),
      active: Yup.bool(),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values);
      if (action === "add") {
        saveItem({
          data: values,
        });
      } else {
        editItem({
          data: values,
        });
      }
    },
  });

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("salvou", response);
        // setVisibilities(response.data);
        navigate(`/bookregister/${category}`);
      }
    },
    onError: (error)=>{
      console.log("error",error.response);
      if(error.response.status === 400){
        setModalErrorMenssage(error.response.data.message);
        setModalError(true);
      }
    }
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("editou", response);
        // setVisibilities(response.data);
        navigate(`/bookregister/${category}`);
      }
    },
  });

  // const [deleteItem, deleteItemInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "",
  //   method: "delete",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       navigate("/listapnld");
  //       // console.log("deletou", response);
  //       // setVisibilities(response.data);
  //     }
  //   },
  // });

  // const handleDelete = (itemId) => {
  //   const confirm = window.confirm("Deseja apagar esse item?");

  //   if (confirm) {
  //     deleteItem({
  //       data: {
  //         id: itemId,
  //       },
  //     });
  //   }
  // };

  

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/books",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data?.length>0){
          const newBook = {
            ...response.data[0]
          };
          console.log("newBook", newBook);
          formik.setValues(newBook);
          setISBNValido(true);
        }
       console.log("item",response);
      }
    },
  });

  const [subjects, loadSubjectsInfo] = useApi({
    debounceDelay: 0,
    url: "subjects",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data?.length>0){
          setDisciplina(response.data);
        }
        console.log("subjects",response);
      }
    },
  });

  const [schoolGradeList, loadSchoolGradeListInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/schoolGradeList",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data?.length>0){
          setCiclo(response.data);
        }
        console.log("schoolGradeList",response);
      }
    },
  });

  const consultISBN = () => {
    axios.get(`https://brasilapi.com.br/api/isbn/v1/${formik.values.isbn}`).then(function (response) {
      if(response.status===200){
        formik.setFieldValue("title", response.data.title);
        formik.setFieldValue("publisherName", response.data.publisher);
        console.log("teve retorno");
        setISBNValido(true);
      }
      setISBNMessage("")
      console.log(response.data);
      console.log(response.status);
      console.log(response.statusText);
      console.log(response.headers);
      console.log(response.config);
  }).catch(function (error) {
    if (error.response) {
      //Retorno do Servidor com erro 400
      if(error.response.status === 400) {
        formik.setFieldValue("title", "");
        formik.setFieldValue("editora", "");
        setISBNValido(false);
        setISBNMessage("Erro ao localizar o ISBN tente novamente")
        console.log("teste");
      }
    } 
    else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
  });;
  };

  // console.log(formik.errors);
  // console.log(formik.isValid);

  useEffect(() => {
    if (action === "edit") {
      console.log("book id", id);
      loadItem({
        params: {
          idBook:id,
        },
      });
    }
    subjects({
      params: {
      },
    });
    schoolGradeList({
      params: {
        showedInBooksRegister:true
      },
    });

    console.log("agora aqui");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    formik.setFieldValue("idCategory", category);
    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    if(isbnRegister){
      formik.setFieldValue("isbn", isbnRegister);
      validateISBN(isbnRegister);
    }
    // eslint-disable-next-line
  }, [isbnRegister]);

  const validateISBN = (isbn) => {
      isbn = isbn.replace(/[^\d]/g, '');
      // Calculate the checksum for ISBN-10
      if (isbn.length <= 10) {
        let sum = 0;
        for (let i = 0; i < 9; i++) {
          sum += parseInt(isbn[i]) * (10 - i);
        }
                
        if(isNaN(isbn[9])){
          sum += 10 * 1;
        }else{
          sum += parseInt(isbn[9]) * 1;
        }

        if ((sum % 11) === 0) {
          setISBNMessage("");
          consultISBN();
          return true;
        } else {
          setISBNMessage("ISBN inválido para ISBN-10");
          return false;
        }
      }
      // Calculate the checksum for ISBN-13
      if (isbn.length === 13) {
        let sum = 0;
        for (let i = 0; i < 12; i++) {
          sum += parseInt(isbn[i]) * (i % 2 === 0 ? 1 : 3);
        }
        let checkDigit = (10 - (sum % 10)) % 10;
        if (checkDigit === parseInt(isbn[12])) {
          setISBNMessage("");
          consultISBN();
          return true;
        } else {
          setISBNMessage("ISBN inválido para ISBN-13");
          return false;
        }
      }
  };

  useEffect(() => {
    setISBNValido(false);
    if(formik.values.isbn === null){
      formik.setFieldValue("isbn", "");
    }
    if(formik.values.isbn?.length===10||formik.values.isbn?.length===13){
      // Remove any non-digit characters from the ISBN
      // setISBNCheckSumValido(validateISBN(formik.values.isbn));
      validateISBN(formik.values.isbn);
    }
    else{
      // setISBNCheckSumValido(false);
      setISBNMessage("");
    }
    // eslint-disable-next-line
  }, [formik.values.isbn]);

  useEffect(() => {
    console.log("formik values",formik.values);
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    console.log("formik",formik);
    // eslint-disable-next-line
  }, [formik]);

  return (
    <Container>
      {(loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading ||
        loadSubjectsInfo.loading ||
        loadSchoolGradeListInfo.loading) && <FullScreenLoading />}
      {(loadItemInfo.status===401 ||
        editItemInfo.status===401 ||
        saveItemInfo.status===401 ||
        loadSubjectsInfo.status===401 ||
        loadSchoolGradeListInfo.status===401) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <ContentBox>
        <Title>
          <div>
            <Icon src={icons.add_livro} alt="Cadastro de Livros" />
            {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>Cadastro de livros PNLD</>}
            {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>Cadastro de livros do Estado</>}
          </div>
        </Title>
        <Description>
          {!ISBNValido && category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>Indique o número ISBN do livro para iniciar o cadastro.</>}
        </Description>
        <Form onSubmit={formik.handleSubmit}>
          {/* Formulario cadastro Livro PNLD */}
          {category==="346b308f-e29b-4a9b-96eb-68da8b79f915" && <>
            { !ISBNValido && action === "add" && <Item>
              <InputText
                placeholder="ISBN"
                columns={90}
                name="isbn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.isbn}
                error={
                  formik.touched.isbn &&
                  formik.errors.isbn &&
                  formik.errors.isbn
                }
              /> 
              {ISBNMessage}
            </Item>}
            { ISBNValido && (
              <>
                <Item>
                  ISBN: {formik.values.isbn}
                </Item>
                <Item>
                  Titulo: {formik.values.title}
                </Item>
                <Item>
                  Editora: {formik.values.publisherName}
                </Item>
                <Item>
                  <InputSelect 
                    onChange={(e)=>{
                                  formik.setFieldValue("idSubject", e.target.value);
                              }}
                    value={formik.values.idSubject}
                    placeholder="Disciplina:"
                  >
                    <option value="" defaultValue>Selecione a disciplina deste livro</option>
                      {disciplina.map((disci,index) => (
                                        <option key={disci.idSubject} value={disci.idSubject}>
                                          {disci.subjectName}
                                        </option>
                                      ))
                      }
                  </InputSelect>
                </Item>
                
                <Item>
                  <InputSelect 
                    onChange={(e)=>{
                                  formik.setFieldValue("idSchoolGradeList", e.target.value);
                              }}
                    value={formik.values.idSchoolGradeList}
                    placeholder="Ano:"
                  >
                    <option value="" defaultValue>Selecione o ano para este livro</option>
                      {ciclo.map((cicl) => (
                                        <option key={cicl.id} value={cicl.id}>
                                          {cicl.title}
                                        </option>
                                      ))
                      }
                  </InputSelect>
                </Item>

                <Item>
                  <InputSelect 
                    onChange={(e)=>{
                                  formik.setFieldValue("destinedTo", e.target.value);
                              }}
                    value={formik.values.destinedTo}
                    placeholder="Exemplar:"
                  >
                    <option value="" defaultValue>Selecione qual o exemplar do livro</option>
                    <option value="Aluno">Aluno</option>
                    <option value="Manual do Professor">Manual do Professor</option>
                  </InputSelect>
                </Item>

                <Item>
                  {/* <InputSelect 
                    onChange={(e)=>{
                                  formik.setFieldValue("ativo", e.target.value);
                              }}
                    value={formik.values.ativo}
                    placeholder="Situação:"
                  >
                    <option value={null} defaultValue>Selecione a situação do livro</option>
                    <option value={true}>Ativo</option>
                    <option value={false}>Inativo</option>
                  </InputSelect> */}
                  <InputCheck
                      name="active"
                      text={"Ativo"}
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                    />
                </Item>
              </>
            )}
          </>}

          {/* Formulario cadastro Livro PNLD Fim */}

          {/* Formulario cadastro Livro do estado */}
          
          {category==="b4b08ede-6069-4ee7-aec4-a8ed20c0c593" && <>
            <Item>
              <InputText
                placeholder="Titulo"
                columns={90}
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                error={
                  formik.touched.title &&
                  formik.errors.title &&
                  formik.errors.title
                }
              />
            </Item>
            <Item>
              <InputText
                placeholder="Editora"
                columns={90}
                name="publisherName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.publisherName}
                error={
                  formik.touched.publisherName &&
                  formik.errors.publisherName &&
                  formik.errors.publisherName
                }
              />
            </Item>
            <Item>
              <InputSelect 
                onChange={(e)=>{
                  formik.setFieldValue("idSubject", e.target.value);
                }}
                  value={formik.values.idSubject}
                  placeholder="Disciplina:"
                >
                <option value="" defaultValue>Selecione a disciplina deste livro</option>
                {disciplina.map((disci,index) => (
                    <option key={disci.idSubject} value={disci.idSubject}>
                      {disci.subjectName}
                    </option>
                ))}
              </InputSelect>
            </Item>
                
            <Item>
              <InputSelect 
                onChange={(e)=>{
                  formik.setFieldValue("idSchoolGradeList", e.target.value);
                }}
                value={formik.values.idSchoolGradeList}
                placeholder="Ano:"
              >
                <option value="" defaultValue>Selecione o ano para este livro</option>
                {ciclo.map((cicl) => (
                    <option key={cicl.id} value={cicl.id}>
                      {cicl.title}
                    </option>
                ))}
              </InputSelect>
            </Item>

            <Item>
              <InputSelect 
                onChange={(e)=>{
                  formik.setFieldValue("destinedTo", e.target.value);
                }}
                value={formik.values.destinedTo}
                placeholder="Exemplar:"
              >
                <option value="" defaultValue>Selecione qual o exemplar do livro</option>
                <option value="Aluno">Aluno</option>
                <option value="Manual do Professor">Manual do Professor</option>
              </InputSelect>
            </Item>

            <Item>
              <InputCheck
                name="active"
                text={"Ativo"}
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
            </Item>
          </>}

          {/* Formulario cadastro Livro do estado Fim */}

          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
        <Modal open={modalError} buttonClose={false}>
          {modalErrorMenssage}
          <ReadMoreButton
            text="Ok"
            type="button"
            onClick={()=>{setModalError(false);}}
          />
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default TradeBookFormRegister;
