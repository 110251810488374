import React from "react";
import { Container, Title, Tags, Tag, Icon } from "./styles";

const VideoItem = ({
  url,
  title,
  tags = ["OD1", "OD2", "OD3", "LP"],
  icon,
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <Container>
      <iframe
        width="100%"
        height="100%"
        src={url?.replace("watch?v=", "embed/")}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <Title>{title}</Title>
      <Tags>
        {tags.map((item, index) => (
          <Tag key={index}>{item}</Tag>
        ))}
        <Icon>{icon}</Icon>
      </Tags>
    </Container>
  );
};

export default VideoItem;
