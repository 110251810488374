import React from "react";
import { Link } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import "./style.css";
import { images, icons } from "../../constants";
import Accessibility from "../../components/UI/Accessibility";
import { useTheme } from "../../hooks/useTheme";

const Policy = () => {
  const { themeFile } = useTheme();

  return (
    <div id="all">
      <header id="header">
        <Accessibility />
        <Link to="/">
          <Player
            autoplay={true}
            loop={true}
            controls={false}
            src={
              themeFile.COLORS.highContrast
                ? icons.logoCanalJSONHighContrast
                : icons.logoCanalJSON
            }
            style={{ height: "100%" }}
          />
        </Link>
        <h2 id="h2">TERMO DE USO E POLÍTICA DE PRIVACIDADE</h2>
      </header>

      <main id="main">
        <article>
          <div class="flex-row">
            <img id="img" src={images.file} alt="Termos de Uso" />
            <h2 id="h2">TERMO DE USO</h2>
          </div>
          <section>
            <h3 id="h3">Quais informações estão presentes neste documento?</h3>

            <p id="p">
              Neste Termo de Uso, o usuário do serviço Canal Pedagógico
              encontrará informações sobre: o funcionamento do serviço e as
              regras aplicáveis a ele; o arcabouço legal relacionado à prestação
              do serviço; as responsabilidades do usuário ao utilizar o serviço;
              as responsabilidades da administração pública ao prover o serviço;
              informações para contato, caso exista alguma dúvida ou seja
              necessário atualizar informações; e o foro responsável por
              eventuais reclamações caso questões deste Termo de Uso tenham sido
              violadas.
            </p>
            <p id="p">
              Além disso, na Política de Privacidade, o usuário do serviço Canal
              Pedagógico encontrará informações sobre: qual o tratamento dos
              dados pessoais realizados, de forma automatizada ou não, e a sua
              finalidade; os dados pessoais dos usuários necessários para a
              prestação do serviço; a forma como eles são coletados; se há o
              compartilhamento de dados com terceiros; e quais as medidas de
              segurança implementadas para proteger os dados.
            </p>
          </section>

          <section>
            <h3 id="h3">Aceitação do Termo de Uso e Política de Privacidade</h3>

            <p id="p">
              Ao utilizar os serviços, o usuário confirma que leu e compreendeu
              os Termos e Políticas aplicáveis ao serviço Canal Pedagógico e
              concorda em ficar vinculado a eles.
            </p>
          </section>

          <h3 id="h3">Definições</h3>

          <p id="p">
            Para melhor compreensão deste documento, neste Termo de Uso e
            Política de Privacidade, consideram-se:
          </p>

          <p id="p">
            <strong>Dado pessoal</strong>: informação relacionada a pessoa
            natural identificada ou identificável.
          </p>
          <p id="p">
            Titular: pessoa natural a quem se referem os dados pessoais que são
            objeto de tratamento.
          </p>
          <p id="p">
            <strong>Controlador</strong>: pessoa natural ou jurídica, de direito
            público ou privado, a quem competem as decisões referentes ao
            tratamento de dados pessoais.
          </p>
          <p id="p">
            <strong>Operador</strong>: pessoa natural ou jurídica, de direito
            público ou privado, que realiza o tratamento de dados pessoais em
            nome do controlador.
          </p>
          <p id="p">
            <strong>Encarregado</strong>: pessoa indicada pelo controlador e
            operador para atuar como canal de comunicação entre o controlador,
            os titulares dos dados e a Autoridade Nacional de Proteção de Dados
            (ANPD).
          </p>
          <p id="p">
            <strong>Agentes de tratamento</strong> : o controlador e o operador.
          </p>
          <p id="p">
            <strong>Tratamento</strong>: toda operação realizada com dados
            pessoais, como as que se referem a coleta, produção, recepção,
            classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle da informação, modificação,
            comunicação, transferência, difusão ou extração.
          </p>
          <p id="p">
            <strong>Uso compartilhado de dados</strong>: comunicação, difusão,
            transferência internacional, interconexão de dados pessoais ou
            tratamento compartilhado de bancos de dados pessoais por órgãos e
            entidades públicos no cumprimento de suas competências legais, ou
            entre esses e entes privados, reciprocamente, com autorização
            específica, para uma ou mais modalidades de tratamento permitidas
            por esses entes públicos, ou entres privados.
          </p>
          <p id="p">
            <strong>Autoridade nacional</strong>: órgão da administração pública
            responsável por zelar, implementar e fiscalizar o cumprimento desta
            Lei em todo o território nacional.
          </p>
          <p id="p">
            <strong>Internet</strong>: o sistema constituído do conjunto de
            protocolos lógicos, estruturado em escala mundial para uso público e
            irrestrito, com a finalidade de possibilitar a comunicação de dados
            entre terminais por meio de diferentes redes.
          </p>
          <p id="p">
            <strong>Sítios e aplicativos</strong>: sítios e aplicativos por meio
            dos quais o usuário acessa os serviços e conteúdos disponibilizados.
          </p>
          <p id="p">
            <strong>
              Usuários (ou "Usuário", quando individualmente considerado)
            </strong>
            : todas as pessoas naturais que utilizarem o serviço Canal
            Pedagógico.
          </p>

          <h3 id="h3">Descrição do serviço</h3>
          <p id="p">
            A Secretaria Municipal de Educação, com a finalidade de contribuir
            para a divulgação e organização de informações de caráter pedagógico
            criou o Canal Pedagógico, disponibilizado no link{" "}
            <a
              href="https://canalpedagogico.sme-mogidascruzes.sp.gov.br/"
              target="_blank"
              id="a"
              rel="noreferrer"
            >
              https://canalpedagogico.sme-mogidascruzes.sp.gov.br/
            </a>
            . Por meio do Canal Pedagógico, será possível consultar o conteúdo
            fornecido pelo Departamento Pedagógico, como vídeos, documentos,
            formações para os professores, indicadores educacionais, plataformas
            educacionais feitas pela Secretaria, bem como também a biblioteca e
            a agenda dos eventos educacionais.
          </p>

          <h3 class="destaque center">Agentes de tratamento</h3>

          <h4 id="h4">
            A quem compete as decisões referentes ao tratamento de dados
            Pessoais realizado no serviço Canal Pedagógico (Controlador)?
          </h4>
          <p id="p">
            A Lei Geral de Proteção de Dados define como controlador, em seu
            artigo 5º:
          </p>
          <p id="p">
            Art. 5º, VI – controlador: pessoa natural ou jurídica, de direito
            público ou privado, a quem competem as decisões referentes ao
            tratamento de dados pessoais;
          </p>
          <p id="p">
            Para o serviço Canal Pedagógico, as decisões referentes ao
            tratamento de dados pessoais são de responsabilidade do Secretaria
            Municipal de Educação.
          </p>
          <p id="p">
            Endereço: Av. Vereador Narciso Yague Guimarães, 2 - Centro Cívico,
            Mogi das Cruzes - SP.
          </p>
          <p id="p">E-mail: educacao@sme-mogidascruzes.sp.gov.br</p>
          <p id="p">Telefone: (11) 4798-5085</p>

          <h4 id="h4">Quem realiza o tratamento de dados (Operador)?</h4>
          <p id="p">
            A Lei Geral de Proteção de Dados define como operador, em seu artigo
            5º:
          </p>
          <p id="p">
            Art. 5º, VII - operador: pessoa natural ou jurídica, de direito
            público ou privado, que realiza o tratamento de dados pessoais em
            nome do controlador.
          </p>
          <p id="p">
            Para o serviço Canal Pedagógico, quem realiza o tratamento de dados
            pessoais em nome do controlador é o operador Departamento
            Pedagógico. Endereço: R. Antenor Leite da Cunha, 55 - Nova Mogilar,
            Mogi das Cruzes - SP, 08773-395.
          </p>

          <h4 id="h4">
            Quem é o responsável por atuar como canal de comunicação entre o
            controlador, os titulares dos dados e a Autoridade Nacional de
            Proteção de Dados (Encarregado)?
          </h4>

          <p id="p">
            A Lei Geral de Proteção de Dados define como encarregado, em seu
            artigo 5º:
          </p>
          <p id="p">
            Art. 5º, VIII – pessoa indicada pelo controlador e operador para
            atuar como canal de comunicação entre o controlador, os titulares
            dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
          </p>
          <p id="p">
            Para o serviço Canal Pedagógico, responsável por atuar como canal de
            comunicação entre o controlador, os titulares dos dados e a
            Autoridade Nacional de Proteção de Dados é o encarregado .
          </p>
          <p id="p">E-mail: canalpedagogico@se-pmmc.com.br.</p>

          <h4 id="h4">
            Quais são as leis e normativos aplicáveis a esse serviço?
          </h4>
          <p id="p">
            - Lei nº 12.527, de 18 de novembro de 2011 - Lei de Acesso à
            Informação – Regula o acesso a informações previsto na Constituição
            Federal.
          </p>

          <h3 class="destaque center">
            Quais são os direitos do usuário do serviço?
          </h3>

          <p id="p">
            O usuário do serviço possui os seguintes direitos, conferidos pela
            Lei de Proteção de Dados Pessoais:
          </p>
          <p id="p">
            -{" "}
            <strong>Direito de confirmação e acesso (Art. 18, I e II):</strong>{" "}
            é o direito do usuário de obter do serviço a confirmação de que os
            dados pessoais que lhe digam respeito são ou não objeto de
            tratamento e, se for esse o caso, o direito de acessar os seus dados
            pessoais.
          </p>
          <p id="p">
            - <strong>Direito de retificação (Art. 18, III)</strong>: é o
            direito de solicitar a correção de dados incompletos, inexatos ou
            desatualizados.
          </p>
          <p id="p">
            -{" "}
            <strong>
              Direito à limitação do tratamento dos dados (Art. 18, IV):
            </strong>
            é o direito do usuário de limitar o tratamento de seus dados
            pessoais, podendo exigir a eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade com o disposto na Lei
            Geral de Proteção de Dados.
          </p>
          <p id="p">
            - <strong>Direito de oposição (Art. 18, § 2º):</strong> é o direito
            do usuário de, a qualquer momento, se opor ao tratamento de dados
            por motivos relacionados com a sua situação particular, com
            fundamento em uma das hipóteses de dispensa de consentimento ou em
            caso de descumprimento ao disposto na Lei Geral de Proteção de
            Dados.
          </p>
          <p id="p">
            - <strong>Direito de portabilidade dos dados (Art. 18, V):</strong>{" "}
            é o direito do usuário de realizar a portabilidade dos dados a outro
            fornecedor de serviço ou produto, mediante requisição expressa, de
            acordo com a regulamentação da autoridade nacional, observados os
            segredos comercial e industrial.
          </p>
          <p id="p">
            -{" "}
            <strong>
              Direito de não ser submetido a decisões automatizadas (Art. 20,
              LGPD):
            </strong>{" "}
            o titular dos dados tem direito a solicitar a revisão de decisões
            tomadas unicamente com base em tratamento automatizado de dados
            pessoais que afetem seus interesses, incluídas as decisões
            destinadas a definir o seu perfil pessoal, profissional, de consumo
            e de crédito ou os aspectos de sua personalidade.
          </p>

          <h4 id="h4">
            Quais são as obrigações dos usuários que utilizam o serviço?
          </h4>

          <p id="p">
            O usuário se responsabiliza pela precisão e veracidade dos dados
            informados e reconhece que a inconsistência destes poderá implicar a
            impossibilidade de se utilizar o serviço Canal Pedagógico.
          </p>
          <p id="p">
            Durante a utilização do serviço, a fim de resguardar e de proteger
            os direitos de terceiros, o usuário se compromete a fornecer somente
            seus dados pessoais, e não os de terceiros.
          </p>
          <p id="p">
            O login e senha só poderão ser utilizados pelo usuário cadastrado.
            Ele se compromete em manter o sigilo da senha, que é pessoal e
            intransferível, não sendo possível, em qualquer hipótese, a alegação
            de uso indevido, após o ato de compartilhamento.
          </p>
          <p id="p">
            O usuário do serviço é responsável pela atualização das suas
            informações pessoais e consequências na omissão ou erros nas
            informações pessoais cadastradas.
          </p>
          <p id="p">
            O Usuário é responsável pela reparação de todos e quaisquer danos,
            diretos ou indiretos (inclusive decorrentes de violação de quaisquer
            direitos de outros usuários, de terceiros, inclusive direitos de
            propriedade intelectual, de sigilo e de personalidade), que sejam
            causados à Administração Pública, a qualquer outro Usuário, ou,
            ainda, a qualquer terceiro, inclusive em virtude do descumprimento
            do disposto nestes Termos de Uso e Política de Privacidade ou de
            qualquer ato praticado a partir de seu acesso ao serviço.
          </p>

          <p id="p">
            O Órgão não poderá ser responsabilizado pelos seguintes fatos:
          </p>
          <ol id="list-alpha">
            <li>Equipamento infectado ou invadido por atacantes;</li>
            <li>Equipamento avariado no momento do consumo de serviços;</li>
            <li>Proteção do computador;</li>
            <li>
              Proteção das informações baseadas nos computadores dos usuários;
            </li>
            <li>Abuso de uso dos computadores dos usuários;</li>
            <li>Monitoração clandestina do computador dos usuários;</li>
            <li>
              Vulnerabilidades ou instabilidades existentes nos sistemas dos
              usuários;
            </li>
            <li>Perímetro inseguro;</li>
          </ol>
          <p id="p">
            Em nenhuma hipótese, a Administração Pública Municipal será
            responsável pela instalação no equipamento do Usuário ou de
            terceiros, de códigos maliciosos (vírus, trojans, malware, worm,
            bot, backdoor, spyware, rootkit, ou de quaisquer outros que venham a
            ser criados), em decorrência da navegação na Internet pelo Usuário.
          </p>

          <h3 class="destaque center">
            Quais são as responsabilidades da administração pública com meus
            dados?
          </h3>
          <p id="p">
            A Administração Pública se compromete a cumprir todas as legislações
            inerentes ao uso correto dos dados pessoais do cidadão de forma a
            preservar a privacidade dos dados utilizados no serviço, bem como a
            garantir todos os direitos e garantias legais dos titulares dos
            dados. Ela também se obriga a promover, independentemente de
            requerimentos, a divulgação em local de fácil acesso, no âmbito de
            suas competências, de informações de interesse coletivo ou geral por
            eles produzidas ou custodiadas. É de responsabilidade da
            Administração Pública implementar controles de segurança para
            proteção dos dados pessoais dos titulares.
          </p>
          <p id="p">
            A Administração Pública poderá, quanto às ordens judiciais de pedido
            das informações, compartilhar informações necessárias para
            investigações ou tomar medidas relacionadas a atividades ilegais,
            suspeitas de fraude ou ameaças potenciais contra pessoas, bens ou
            sistemas que sustentam o Serviço ou de outra forma necessária para
            cumprir com nossas obrigações legais. Caso ocorra, a Administração
            Pública notificará os titulares dos dados, salvo quando o processo
            estiver em segredo de justiça.
          </p>

          <h3 id="h3">
            Qual o contato pelo qual o usuário do serviço pode tirar suas
            dúvidas?
          </h3>
          <p id="p">
            Caso o usuário tenha alguma dúvida sobre este Termo de Uso, ele
            poderá entrar em contato pelo e-mail canalpedagogico@se-pmmc.com.br.
          </p>

          <section>
            <div class="flex-row">
              <img
                id="img"
                src={images.shieldLock}
                alt="Política de Privacidade"
              />
              <h2 id="h2">POLÍTICA DE PRIVACIDADE</h2>
            </div>

            <p id="p">
              Esta Política de Privacidade foi elaborada em conformidade com a
              Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da
              Internet) e com a Lei Federal n. 13.709, de 14 de agosto de 2018
              (Lei de Proteção de Dados Pessoais).
            </p>
            <img id="img" class="spy" src={images.spy} alt="Privacidade" />
            <p id="p">
              Esta Política de Privacidade poderá ser atualizada em decorrência
              de eventual atualização normativa, razão pela qual se convida o
              usuário a consultar periodicamente esta seção.
            </p>
            <p id="p">
              O site se compromete a cumprir as normas previstas na Lei Geral de
              Proteção de Dados (LGPD), e respeitar os princípios dispostos no
              Art. 6º:
            </p>
            <ol id="list-number">
              <li>
                <strong>finalidade</strong>: realização do tratamento para
                propósitos legítimos, específicos, explícitos e informados ao
                titular, sem possibilidade de tratamento posterior de forma
                incompatível com essas finalidades;
              </li>
              <li>
                <strong>adequação</strong>: compatibilidade do tratamento com as
                finalidades informadas ao titular, de acordo com o contexto do
                tratamento;
              </li>
              <li>
                <strong>necessidade</strong>: limitação do tratamento ao mínimo
                necessário para a realização de suas finalidades, com
                abrangência dos dados pertinentes, proporcionais e não
                excessivos em relação às finalidades do tratamento de dados;
              </li>
              <li>
                <strong>livre acesso</strong>: garantia, aos titulares, de
                consulta facilitada e gratuita sobre a forma e a duração do
                tratamento, bem como sobre a integralidade de seus dados
                pessoais;
              </li>
              <li>
                <strong>qualidade dos dados</strong>: garantia, aos titulares,
                de exatidão, clareza, relevância e atualização dos dados, de
                acordo com a necessidade e para o cumprimento da finalidade de
                seu tratamento;
              </li>
              <li>
                <strong>transparência</strong>: garantia, aos titulares, de
                informações claras, precisas e facilmente acessíveis sobre a
                realização do tratamento e os respectivos agentes de tratamento,
                observados os segredos comercial e industrial;
              </li>
              <li>
                <strong>segurança</strong>: utilização de medidas técnicas e
                administrativas aptas a proteger os dados pessoais de acessos
                não autorizados e de situações acidentais ou ilícitas de
                destruição, perda, alteração, comunicação ou difusão;
              </li>
              <li>
                <strong>prevenção</strong>: adoção de medidas para prevenir a
                ocorrência de danos em virtude do tratamento de dados pessoais;
              </li>
              <li>
                <strong>não discriminação</strong>: impossibilidade de
                realização do tratamento para fins discriminatórios ilícitos ou
                abusivos;
              </li>
              <li>
                <strong>responsabilização e prestação de contas</strong>:
                demonstração, pelo agente, da adoção de medidas eficazes e
                capazes de comprovar a observância e o cumprimento das normas de
                proteção de dados pessoais e, inclusive, da eficácia dessas
                medidas.
              </li>
            </ol>

            <h3 id="h3">Quais dados pessoais são tratados pelo serviço?</h3>

            <p id="p">
              A utilização, pelo usuário, de determinadas funcionalidades do
              serviço dependerá do tratamento dos seguintes dados pessoais:
            </p>
            <ul id="list-disc">
              <li>Nome completo</li>
              <li>Nome social</li>
              <li>Data de nascimento</li>
              <li>Sexo</li>
              <li>Endereço de e-mail</li>
              <li>
                Dados do dispositivo (modelo de hardware, sistema operacional)
              </li>
              <li>Localização do usuário</li>
              <li>Registro de acesso</li>
              <li>Foto do usuário</li>
            </ul>

            <h3 id="h3">Como os dados são coletados?</h3>

            <p id="p">
              Nome completo: Informado pelo usuário ou obtido de terceiros
              (exemplo: Login Único, Google)
            </p>
            <p id="p">
              Nome social: Informado pelo usuário ou obtido de terceiros
              (exemplo: Login Único, Google)
            </p>
            <p id="p">
              Data de nascimento: Informado pelo usuário ou obtido de terceiros
              (exemplo: Login Único, Google)
            </p>
            <p id="p">
              Sexo: Informado pelo usuário ou obtido de terceiros (exemplo:
              Login Único, Google)
            </p>
            <p id="p">
              Endereço de e-mail: Informado pelo usuário ou obtido de terceiros
              (exemplo: Login Único, Google)
            </p>
            <p id="p">
              Dados do dispositivo (modelo de hardware, sistema operacional):
              Obtido de terceiros (exemplo: Login Único, Google, etc)
            </p>
            <p id="p">Localização do usuário: Localização do dispositivo</p>
            <p id="p">Registro de acesso: Obtido pelo dispositivo</p>
            <p id="p">
              Foto do usuário: Informado pelo usuário ou câmera do dispositivo
            </p>

            <h3 id="h3">Para que fim utilizamos seus dados?</h3>

            <p id="p">
              Nome completo: O dado é necessário para identificação do usuário
              dentro do serviço
            </p>
            <p id="p">
              Nome social: O dado é necessário para identificação do usuário
              dentro do serviço
            </p>
            <p id="p">
              Data de nascimento: O dado é necessário para identificação do
              usuário dentro do serviço
            </p>
            <p id="p">
              Sexo: O dado é necessário para identificação do usuário dentro do
              serviço
            </p>
            <p id="p">
              Endereço de e-mail: O dado é necessário para identificação do
              usuário dentro do serviço e para manter o usuário logado
            </p>
            <p id="p">
              Dados do dispositivo (modelo de hardware, sistema operacional): O
              dado é necessário para melhorar e personalizar a experiência do
              usuário
            </p>
            <p id="p">
              Localização do usuário: O dado é necessário para identificação do
              usuário dentro do serviço
            </p>
            <p id="p">
              Registro de acesso: O dado é necessário para manter o usuário
              logado
            </p>
            <p id="p">
              Foto do usuário: O dado é necessário para identificação do usuário
              dentro do serviço
            </p>

            <h3 id="h3">Qual o tratamento realizado com os dados pessoais?</h3>

            <p id="p">Nome completo: UTILIZAÇÃO</p>
            <p id="p">Nome social: UTILIZAÇÃO</p>
            <p id="p">Data de nascimento: UTILIZAÇÃO</p>
            <p id="p">Sexo: UTILIZAÇÃO</p>
            <p id="p">Endereço de e-mail: UTILIZAÇÃO</p>
            <p id="p">
              Dados do dispositivo (modelo de hardware, sistema operacional):
              UTILIZAÇÃO
            </p>
            <p id="p">Localização do usuário: UTILIZAÇÃO</p>
            <p id="p">Registro de acesso: UTILIZAÇÃO</p>
            <p id="p">Foto do usuário: UTILIZAÇÃO</p>

            <h3 id="h3">
              Os dados pessoais utilizados no serviço são compartilhados?
            </h3>

            <p id="p">
              Os dados pessoais do usuário não são compartilhados com terceiros
              em nenhuma hipótese.
            </p>

            <h3 id="h3">
              Segurança no tratamento dos dados pessoais do usuário
            </h3>

            <p id="p">
              O serviço Canal Pedagógico se compromete a aplicar as medidas
              técnicas e organizativas aptas a proteger os dados pessoais de
              acessos não autorizados e de situações de destruição, perda,
              alteração, comunicação ou difusão de tais dados.
            </p>
            <p id="p">
              Para a garantia da segurança, serão adotadas soluções que levem em
              consideração: as técnicas adequadas; os custos de aplicação; a
              natureza, o âmbito, o contexto e as finalidades do tratamento; e
              os riscos para os direitos e liberdades do usuário.
            </p>
            <p id="p">
              O site utiliza criptografia para que os dados sejam transmitidos
              de forma segura e confidencial, de maneira que a transmissão dos
              dados entre o servidor e o usuário, e em retroalimentação, ocorra
              de maneira totalmente cifrada ou encriptada.
            </p>
            <p id="p">
              No entanto, o site se exime de responsabilidade por culpa
              exclusiva de terceiro, como em caso de ataque de hackers ou
              crackers, ou culpa exclusiva do usuário, como no caso em que ele
              mesmo transfere seus dados a terceiro. O serviço Canal Pedagógico
              se compromete, ainda, a comunicar o usuário em prazo adequado caso
              ocorra algum tipo de violação da segurança de seus dados pessoais
              que possa lhe causar um alto risco para seus direitos e liberdades
              pessoais.
            </p>
            <p id="p">
              A violação de dados pessoais é uma violação de segurança que
              provoque, de modo acidental ou ilícito, a destruição, a perda, a
              alteração, a divulgação ou o acesso não autorizado a dados
              pessoais transmitidos, conservados ou sujeitos a qualquer outro
              tipo de tratamento.
            </p>
            <p id="p">
              Por fim, o site se compromete a tratar os dados pessoais do
              usuário com confidencialidade, dentro dos limites legais.
            </p>

            <h3 id="h3">O serviço Canal Pedagógico utiliza cookies?</h3>

            <p id="p">
              <em>Cookies</em> são pequenos arquivos de texto enviados pelo site
              ao computador do usuário e que nele ficam armazenados, com
              informações relacionadas à navegação do site.
            </p>
            <p id="p">
              Por meio dos <em>cookies</em>, pequenas quantidades de informação
              são armazenadas pelo navegador do usuário para que nosso servidor
              possa lê-las posteriormente. Podem ser armazenados, por exemplo,
              dados sobre o dispositivo utilizado pelo usuário, bem como seu
              local e horário de acesso ao site.
            </p>
            <p id="p">
              É importante ressaltar que nem todo <em>cookie</em> contém dados
              pessoais do usuário, já que determinados tipos de <em>cookies</em>{" "}
              podem ser utilizados somente para que o serviço funcione
              corretamente.
            </p>
            <p id="p">
              As informações eventualmente armazenadas em <em>cookies</em>{" "}
              também são consideradas dados pessoais e todas as regras previstas
              nesta Política de Privacidade também são aplicáveis a eles.
            </p>
            <p id="p">O serviço Canal Pedagógico não utiliza cookies.</p>

            <h3 id="h3">Este Termo de Uso pode ser alterado? </h3>

            <p id="p">
              A presente versão desta Política de Privacidade foi atualizada
              pela última vez em: 02/06/2022
            </p>
            <p id="p">
              O editor se reserva o direito de modificar, a qualquer momento o
              site as presentes normas, especialmente para adaptá-las às
              evoluções do serviço Canal Pedagógico, seja pela disponibilização
              de novas funcionalidades, seja pela supressão ou modificação
              daquelas já existentes.
            </p>
            <p id="p">
              O usuário será explicitamente notificado em caso de alteração
              deste Termo de Uso.
            </p>

            <h3 id="h3">
              Qual o foro aplicável caso o usuário queira realizar alguma
              reclamação?
            </h3>

            <p id="p">
              Este Termo será regido pela legislação brasileira. Qualquer
              reclamação ou controvérsia com base neste Termo será dirimida
              exclusivamente pela comarca Mogi das Cruzes - SP.
            </p>
            <p id="p">
              Sem prejuízo de qualquer outra via de recurso administrativo ou
              judicial, todos os titulares de dados têm direito a apresentar
              reclamação à Autoridade Nacional de Proteção de Dados.
            </p>
          </section>
        </article>
      </main>
    </div>
  );
};

export default Policy;
