import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  margin-top: 10rem;
  background-color: var(--secondary);
`;

export const Title = styled.h1`
  font: var(--title);
  margin: 4rem 0;
  color: var(--primary);
`;

export const Form = styled.form`
  width: 100%;
  max-width: 90rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 4rem;
  gap: 2rem;

  @media (max-width: 700px) {
    padding: 0 1.6rem;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-end;
  gap: 2rem;
`;

export const ListButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-around;
  flex-wrap:nowrap;
  width: 100%;
  margin: 1rem 0 0 0;
  background-color: var(--secondary);
  button {
    margin: 0 1rem;
  }
`;