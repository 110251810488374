import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Cover = styled.img`
  border: unset;
`;
export const Title = styled.h2``;
export const Description = styled.div``;
