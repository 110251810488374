import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Feed,
  Thumb,
  Info,
  InfoHeader,
  InfoTitle,
  InfoText,
  IconEdit,
  BoxButtons,
} from "./styles";
import { icons } from "../../../constants";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import { useTheme } from "../../../hooks/useTheme";

const BookItem = ({
  image,
  id,
  title,
  description,
  url,
  editorId = false,
  mainCategory,
}) => {
  const { user } = useAuth();
  const { themeFile } = useTheme();
  const [isEditor, setIsEditor] = useState(false);
  const navigate = useNavigate();

  const handleIcon = () => {
    return themeFile.COLORS.highContrast
      ? icons.biblioteca
      : icons.biblioteca_purple;
  };

  useEffect(() => {
    if (user) {
      const editor = user?.profiles[0]?.editor?.findIndex(
        (editor) => editor.id === editorId
      );
      setIsEditor(editor > -1 ? true : false);
    }
    //eslint-disable-next-line
  }, [user]);

  return (
    <Feed>
      <Thumb src={image ? image : handleIcon(mainCategory)} alt="Feed" />
      <Info>
        <InfoHeader>
          <InfoTitle onClick={() => navigate(`/biblioteca/${id}`)}>
            {title}
          </InfoTitle>
          <InfoText>{description}</InfoText>
        </InfoHeader>
      </Info>
      <BoxButtons>
        {isEditor && (
          <Link to={url}>
            <IconEdit src={icons.editBlack} />
          </Link>
        )}
      </BoxButtons>
    </Feed>
  );
};

export default BookItem;
