import React from "react";
import { BsTrash } from "react-icons/bs";
import { useTheme } from "../../../hooks/useTheme";
import { Container, Delete } from "./styles";

const DeleteButton = ({
  text,
  disabled = false,
  onClick = () => {},
  ...rest
}) => {
  const { themeFile } = useTheme();
  return (
    <Container>
      <Delete type="button" onClick={onClick}>
        <BsTrash
          size={themeFile.SIZES.base * 22}
          color={themeFile.COLORS.hardRed}
        />
        <span>{text}</span>
      </Delete>
    </Container>
  );
};

export default DeleteButton;
