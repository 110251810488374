import React, { useState } from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import ConfirmButton from "../../components/UI/ConfirmButton";
import Menu from "../../components/UI/Menu";
import Modal from "../../components/UI/Modal";
import {
  BoxLogos,
  ButtonLogo,
  Container,
  LogoPlatform,
  Text,
  TextModal,
} from "./styles";
import Footer from "../../components/UI/Footer";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";

const Platform = () => {
  const [ModalOpenSimulados, setModalOpenSimulados] = useState(false);
  const [ModalOpenEaprendi, setModalOpenEaprendi] = useState(false);
  const [ModalOpenMigo, setModalOpenMigo] = useState(false);
  const [ModalOpenHumanizada, setModalOpenHumanizada] = useState(false);
  const [ModalOpenJornada, setModalOpenJornada] = useState(false);
  const [ModalOpenMatific, setModalOpenMatific] = useState(false);

  const handleModalSimulados = () => {
    setModalOpenSimulados((oldModalOpen) => !oldModalOpen);
  };

  const handleModalEaprendi = () => {
    setModalOpenEaprendi((oldModalOpen) => !oldModalOpen);
  };

  const handleModalMigo = () => {
    setModalOpenMigo((oldModalOpen) => !oldModalOpen);
  };

  const handleModalHumanizada = () => {
    setModalOpenHumanizada((oldModalOpen) => !oldModalOpen);
  };

  const handleModalJornada = () => {
    setModalOpenJornada((oldModalOpen) => !oldModalOpen);
  };

  const handleModalMatific = () => {
    setModalOpenMatific((oldModalOpen) => !oldModalOpen);
  };

  const openSimulados = () => {
    window.open("http://www.simulados.se-pmmc.com.br");
  };

  const openEaprendi = () => {
    window.open("http://www.simulados.se-pmmc.com.br");
  };

  const openMigo = () => {
    window.open("http://www.migo.se-pmmc.com.br");
  };

  const openJornada = () => {
    window.open("https://jornadacaqui.sme-mogidascruzes.sp.gov.br/");
  };

  const openHumanizada = () => {
    window.open("https://educacaohumanizada.sme-mogidascruzes.sp.gov.br/");
  };

  const openMatific = () => {
    window.open("https://www.matific.com/pt/pt/login-page/");
  };

  return (
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.iconPlataformas}>PLATAFORMAS</Banner>
      <ContentBox>
        <Text>
          Uma plataforma educacional digital nada mais é do que uma ferramenta
          que utiliza a tecnologia com o intuito de conectar pessoas e promover
          interações, sendo essencial como apoio ao processo de aprendizagem. As
          plataformas visam otimizar ao máximo o encontro da demanda com a
          oferta. Atualmente contamos com 6 plataformas. Destas, 5 foram
          desenvolvidas exclusivamente pelos profisionais de informática da rede
          municipal, acompanhando assim as necessidades e planejamentos
          pedagógicos da rede. Clique para conhecer cada uma delas.
        </Text>
        <BoxLogos>
          <ButtonLogo
            src={images.simulados}
            onClick={handleModalSimulados}
            size={1}
          />
          <ButtonLogo
            src={images.eaprendi}
            onClick={handleModalEaprendi}
            size={1}
          />
          <ButtonLogo
            src={images.educacaoHumanizada}
            onClick={handleModalHumanizada}
            size={1}
          />
          <ButtonLogo src={images.migo} onClick={handleModalMigo} size={0.8} />
          <ButtonLogo
            src={images.jornada}
            onClick={handleModalJornada}
            size={0.8}
          />
          {/* <ButtonLogo
            src={images.matific}
            onClick={handleModalMatific}
            size={1}
          /> */}
        </BoxLogos>
        <Modal open={ModalOpenSimulados} close={handleModalSimulados} width={100}>
          <LogoPlatform src={images.simulados} />
          <TextModal>
            Recurso online em formato de quiz (perguntas e respostas) com login
            e senha individuais. Utiliza também recursos de gamificação
            (pontuação, ranking, percentual de progresso) e atividades
            colaborativas multiplayer online. A plataforma facilita a
            aprendizagem, proporcionando autonomia ao aluno, respeitando o ritmo
            de aprendizagem, auxiliando a avaliação de desempenho e o
            diagnóstico das dificuldades de cada aluno por meio de relatórios
            personalizados de acompanhamento do professor. O sistema foi
            desenvolvido multiplataforma (Computador, Smartphone, Tablets,
            Chromebooks) compatível com os novos dispositivos adquiridos pela
            SME (Secretaria Municipal de Educação).
          </TextModal>
          <TextModal>
            Início em 2015 (Surgiu a partir da necessidade de atividades que
            pudessem proporcionar o registro do progresso nas aulas de
            informática), testes em 5 escolas-piloto em 2017 e implantação no
            início de 2018. Novos módulos e versão definitiva em agosto/2018.
          </TextModal>
          <ConfirmButton onClick={openSimulados} text="Acessar" />
        </Modal>
        <Modal open={ModalOpenEaprendi} close={handleModalEaprendi}>
          <LogoPlatform src={images.eaprendi} />
          <TextModal>
            Avaliação das aprendizagens feita de maneira totalmente online.
            Criada no final de 2019 e implementado o projeto piloto no início de
            2020.
          </TextModal>
          <TextModal>
            Trata-se de uma avaliação que funciona dentro da plataforma
            Simulados, onde o aluno tem acesso a sua prova e os professores e
            gestores aos resultados de seus alunos. A prova conta também com
            recursos de acessibilidade para alunos com dificuldades. Os recursos
            de áudio e vídeo são muito atrativos para os alunos (Recurso esse
            impossível na prova física).
          </TextModal>
          <TextModal>
            Durante a pandemia foi uma forma de avaliar todos os alunos da rede
            municipal, de maneira rápida e com custo zero. Chegamos a 8000
            alunos acessando simultaneamente as provas em suas casas.
          </TextModal>
          <TextModal>
            Para apresentação dos resultados, existem múltiplos gráficos com o
            comparativo entre regiões, escolas e turmas, se tornando uma
            excelente forma de identificar problemas.
          </TextModal>
          <ConfirmButton text={"Acessar"} onClick={openEaprendi} />
        </Modal>
        <Modal open={ModalOpenMigo} close={handleModalMigo}>
          <LogoPlatform src={images.migo} />
          <TextModal>
            Plataforma on­line, em que por meio da aprendizagem autônoma
            (autonomous learning), as crianças aprendem Inglês de forma
            divertida e contextualizada. Migo é um alienígena que caiu numa
            terra em que as pessoas falam uma língua diferente. Ao longo da
            história, Migo aprenderá o Inglês, assim como quem está jogando. Em
            cada ilha, o aluno passa por lugares (places) e aprende vocabulário
            e gramática relacionados ao tema de cada local. Vencendo desafios,
            os jogadores ganham premiações e desbloqueiam novos episódios. O
            sistema foi desenvolvido multiplataforma (Computador, Smartphone,
            Tablets, Chromebooks).
          </TextModal>
          <TextModal>
            O jogo possui uma área de livre acesso e outra área restrita aos
            alunos da rede municipal do ensino fundamental, o acesso se dá com o
            mesmo login e senha do simulados.
          </TextModal>
          <ConfirmButton text={"Acessar"} onClick={openMigo} />
        </Modal>
        <Modal open={ModalOpenHumanizada} close={handleModalHumanizada}>
          <LogoPlatform src={images.educacaoHumanizada} />
          <TextModal>
            Plataforma de apoio ao programa de Educação Humanizada, Integral em
            Comunidades de Aprendizagem, que propõe uma nova construção social
            de aprendizagem e educação, pautada nos valores coletivos e no
            desenvolvimento do indivíduo.
          </TextModal>
          <TextModal>
            Partindo do pressuposto de que todos são especialmente singulares, a
            Educação Humanizada respeita o ritmo, a fala, a curiosidade, os
            sonhos, os desejos, as habilidades e as diferentes formas de
            aprender, de forma integral e significativa. O processo de
            aprendizagem é social, coletivo, colaborativo, afetivo, autoral,
            personalizado, interno, ativo e acontece na relação do educador com
            o educando, valorizando o desenvolvimento pleno (holístico) do
            indivíduo, contemplando as dimensões formativas do sujeito:
            intelectual, física, emocional, social e cultural.
          </TextModal>
          <ConfirmButton text={"Acessar"} onClick={openHumanizada} />
        </Modal>
        <Modal open={ModalOpenJornada} close={handleModalJornada}>
          <LogoPlatform src={images.jornada} />
          <TextModal>
            A Jornada Caqui trata-se de uma gincana virtual, sustenta-se em duas
            ferramentas pedagógicas: GAMIFICAÇÃO e STORYTELLING.
          </TextModal>
          <TextModal>
            A Gamificação é uma estratégia que confere protagonismo aos
            estudantes no processo de ensino e aprendizagem. Para uma geração
            acostumada com o mundo dos jogos, este é um recurso estratégico
            bastante funcional para garantir maior engajamento nas salas de
            aula.
          </TextModal>
          <TextModal>
            Outro instrumento fundamental da Jornada Caqui é o Storytelling da
            Jornada do Herói. Esta potente estrutura narrativa, decifrada por
            Joseph Campbell, está presente desde os mitos fundantes das mais
            variadas tradições de fé até os roteiros dos filmes de maior sucesso
            da contemporaneidade. O uso destas ferramentas tem o poder de
            transformar o processo de ensino em uma grande aventura, onde o
            herói/estudante pode levar um importante tesouro para casa, o
            aprendizado.
          </TextModal>
          <ConfirmButton text={"Acessar"} onClick={openJornada} />
        </Modal>
        <Modal open={ModalOpenMatific} close={handleModalMatific}>
          <LogoPlatform src={images.matific} />
          <TextModal>
            Plataforma de aprendizagem de matemática criada por especialistas em
            educação. Recurso digital de matemática alinhado com o currículo
            para crianças de 4 a 12 anos.
          </TextModal>
          <ConfirmButton text={"Acessar"} onClick={openMatific} />
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Platform;
