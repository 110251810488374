import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  color: var(--primary);
  background-color: var(--secondary);
  /* border: 1px solid orange; */

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -2.4rem;
  position: relative;
  width: 100%;
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: -2.5rem;
  position: relative;
  width: 100%;
  flex-direction: column;
  margin-bottom: ${(props) => `${props.marginBottom}rem`};
  /* border: 1px solid green; */
`;

export const VideosComplementaresContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  /* background-color: var(--softGray); */
  /* padding: 3rem; */
  border-radius: 2.8rem;
  /* max-width: 1200px; */
  width: 100%;
  /* border: 1px solid black; */

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const TituloVideosComplementares = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 80%;
  gap: 2rem;

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const VideoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* border: 1px solid red; */
  width: 100%;
  max-width: 30rem;
  /* padding: 0 2rem; */

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const VideoInfoBox = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  width: 100%;
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
  text-align: center;
  border-radius: 6px;
  padding: 0;
  margin-left: 0.5rem;
  position: absolute;
  z-index: 1;
  top: -10rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const ModalVideoInfo = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 50;
  /* background-color: rgba(0, 0, 0, 0.9); */
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 3rem;
  top: 0;
  left: 0;
`;

export const ModalVideoInfoContent = styled.div`
  width: 80%;
  background: var(--secondary);
  position: absolute;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button {
    :first-child {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      cursor: pointer;
      background: unset;
      font-size: 2.4rem;
      margin: 0;
    }

    margin-top: 1.6rem;

    :last-child {
      margin-top: 0;
    }
  }
`;

export const SearchResult = styled.div`
  /* font-size: calc(var(--size) * 1.6);
  font-family: var(--fontFamily);
  font-weight: var(--regularFont); */
  font: var(--title);
  color: var(--black);
  width: 100%;
  margin-top: 2rem;
`;

export const SearchResultBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.6rem;
  /* font-size: calc(var(--size) * 1.4);
  font-family: var(--fontFamily);
  font-weight: var(--regularFont); */
  font: var(--body);
  color: var(--black);
`;

export const SliderArrow = styled.div``;

export const ButtonBase = styled.div``;

export const ArrowLeft = styled.div``;

export const ArrowRight = styled.div``;

export const SliderContainer = styled.div`
  width: 100%;
`;

export const ItemResultSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 98rem;
  padding: 0.5rem;
  /* transition: all 0.2s ease;
  &:hover {
    transform: scale(1.05);
    background: var(--softGray);
  } */
  /* border: 1px solid black; */
`;

export const ItemResultSearchVideo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* border: 1px solid black; */
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
  height: 30rem;
`;

export const ItemResultSearchInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin-left: 2rem;
  max-width: 70%;
  text-overflow: ellipsis;

  > p {
    color: var(--primary);
  }

  > h2 {
    text-align: center;
    color: var(--primary);

    @media (max-width: 500px) {
      font-size: 2rem;
    }
  }
`;

export const Tag = styled.span`
  padding: 0.2rem 0.4rem;
  background-color: var(--softPurple);
  border-radius: 0.6rem;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
`;

export const IconEdit = styled.img`
  cursor: pointer;
  width: calc(var(--size) * 3);
  /* margin: 0 0 1rem 0; */

  :hover {
    transition: width 0.2s;
    width: calc(var(--size) * 3.3);
  }
`;
