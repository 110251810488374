import React from "react";
import { Outlet } from "react-router";
// import { Navigate, Outlet } from "react-router";
// import { useAuth } from "../../hooks/useAuth";

const ProtectedRoute = () => {
  // const { protectedToken } = useAuth();

  return <Outlet />;
  // return protectedToken ? <Outlet /> : <Navigate to="/versaoteste" />;
};

export default ProtectedRoute;