import React, { useState, memo } from "react";
import { icons, COLORS, COLORS_HIGH_CONTRAST } from "../../../constants";
import { useTheme } from "../../../hooks/useTheme";
// import useApi from "../../../hooks/useApi";
// import { useAuth } from "../../../hooks/useAuth";
import round from "../../../utils/roundNumbers";
import { Container, Icon, IconContainer } from "./styles";

const Accessibility = ({ marginTop = 9 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { themeFile, setThemeFile } = useTheme();
  // const { token } = useAuth();

  // const [saveSettings, saveSettingsInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "settings/v1",
  //   method: "put",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log(response);
  //       console.log(saveSettingsInfo);
  //     }
  //   },
  // });

  const handleOpen = () => {
    setIsOpen((oldIsOpen) => !oldIsOpen);
  };

  const handleIncreaseFont = () => {
    // console.log(themeFile.SIZES.base);
    if (themeFile.SIZES.base === 1.8) return false;
    const now = round(themeFile.SIZES.base + 0.4, 2);
    // console.log(now);
    const sizes = {
      ...themeFile.SIZES,
      base: now,
    };

    localStorage.setItem(
      `[@${process.env.REACT_APP_NAME}Theme]`,
      JSON.stringify({
        ...themeFile,
        SIZES: sizes,
      })
    );

    const changes = {
      ...themeFile,
      SIZES: sizes,
    };

    setThemeFile(changes);

    // if (token) {
    //   saveSettings({
    //     data: {
    //       settings: JSON.stringify(changes),
    //     },
    //   });
    // }
  };

  const handleDecreaseFont = () => {
    // console.log(themeFile.SIZES.base);
    const now = round(themeFile.SIZES.base - 0.4, 2);
    // console.log(now);
    if (now < 1) return null;

    const sizes = {
      ...themeFile.SIZES,
      base: now,
    };

    localStorage.setItem(
      `[@${process.env.REACT_APP_NAME}Theme]`,
      JSON.stringify({
        ...themeFile,
        SIZES: sizes,
      })
    );

    const changes = {
      ...themeFile,
      SIZES: sizes,
    };

    setThemeFile(changes);

    // if (token) {
    //   saveSettings({
    //     data: {
    //       settings: JSON.stringify(changes),
    //     },
    //   });
    // }
  };

  const handleHighContrast = () => {
    const colors = themeFile.COLORS.highContrast
      ? COLORS
      : COLORS_HIGH_CONTRAST;

    localStorage.setItem(
      `[@${process.env.REACT_APP_NAME}Theme]`,
      JSON.stringify({
        ...themeFile,
        COLORS: colors,
      })
    );

    const changes = {
      ...themeFile,
      COLORS: colors,
    };

    setThemeFile(changes);

    // if (token) {
    //   saveSettings({
    //     data: {
    //       settings: JSON.stringify(changes),
    //     },
    //   });
    // }
  };

  return (
    <Container marginTop={marginTop}>
      <Icon onClick={handleOpen} isOpen={isOpen}>
        <img src={icons.accessHand} alt="Acessibilidade" />
      </Icon>
      {isOpen && (
        <IconContainer isOpen={isOpen}>
          <Icon onClick={handleIncreaseFont}>
            <img src={icons.increaseFont} alt="Aumentar Fonte" />
          </Icon>
          <Icon onClick={handleDecreaseFont}>
            <img src={icons.decreaseFont} alt="Diminuir Fonte" />
          </Icon>
          {themeFile.COLORS.highContrast ? (
            <Icon onClick={handleHighContrast}>
              <img src={icons.highContrast2} alt="Alto Contraste" />
            </Icon>
          ) : (
            <Icon onClick={handleHighContrast}>
              <img src={icons.highContrast} alt="Alto Contraste" />
            </Icon>
          )}
        </IconContainer>
      )}
    </Container>
  );
};

export default memo(Accessibility);
