import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(90, 90, 90);
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 1rem;
      height: 1rem;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(90, 90, 90);
    }

    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  html, body, #root{
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-family: 'Open Sans', sans-serif;
  }

  :root{
    font-size: 62.5%;
  }

`;
