import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactCrop from "react-image-crop";
import getCroppedImg from "../../../utils/getCroppedImg";
import "react-image-crop/dist/ReactCrop.css";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import Error from "../../../components/UI/Error";
import Footer from "../../../components/UI/Footer";
import ContentBox from "../../../components/UI/ContentBox";
import useApi from "../../../hooks/useApi";
import {
  Container,
  Title,
  Form,
  Item,
  Controls,
  CoverUrlBox,
  CoverUrlCropped,
  ModalCoverUrl,
  ModalCoverUrlContent,
} from "./styles";
import InputText from "../../../components/UI/Inputs/InputText";
import InputTextArea from "../../../components/UI/Inputs/InputTextArea";
import AttachPlaceholder from "../../../components/UI/AttachPlaceholder";
import CancelButton from "../../../components/UI/CancelButton";
import ConfirmButton from "../../../components/UI/ConfirmButton";
import DeleteButton from "../../../components/UI/DeleteButton";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";

const LibraryNewsForm = ({ action }) => {
  const [showModalCoverUrl, setShowModalCoverUrl] = useState(false);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 1 / 1,
  });
  //state result para uso de imagem jpg
  const [result, setResult] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      editImage: false,
      image: {},
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Digite um título."),
      description: Yup.string().required("Digite uma descrição."),
    }),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values);
      if (action === "add") {
        saveItem({
          data: values,
        });
      } else {
        editItem({
          data: values,
        });
      }
    },
  });

  const handleAttach = (file) => {
    // console.log("entrou no handleFile");
    const type = file.target.files[0].type.split("/");
    if (type[0] === "image") {
      setFile(URL.createObjectURL(file.target.files[0]));
      setShowModalCoverUrl(true);
    }
    file.target.value = null;
  };

  const handleCrop = () => {
    let preview;
    if (crop.height !== 0) {
      preview = getCroppedImg(crop, image);
      setResult(preview);
    } else {
      const full = {
        ...crop,
        width: image.width,
        height: image.height,
      };
      preview = getCroppedImg(full, image);
      setResult(preview);
    }
    setUploaded(true);
    const cover = {
      mime: preview ? preview.split(";")[0].split(":")[1] : "",
      encode: preview ? preview.split(";")[1].split(",")[0] : "",
      data: preview ? preview.split(",")[1] : "",
    };
    formik.setFieldValue("image", cover);
    formik.setFieldValue("editImage", true);
  };

  const handleUpdateCover = () => {
    setShowModalCoverUrl(false);
  };

  const checkBase64 = (image) => {
    if (!image) return false;
    const check = image.split(";");
    if (check.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  const [saveItem, saveItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/news",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("salvou", response);
        // setVisibilities(response.data);
        navigate("/biblioteca");
      }
    },
  });

  const [editItem, editItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/news",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("editou", response);
        // setVisibilities(response.data);
        navigate("/biblioteca");
      }
    },
  });

  const [deleteItem, deleteItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/news",
    method: "delete",
    onCompleted: (response) => {
      if (!response.error) {
        navigate("/biblioteca");
        // console.log("deletou", response);
        // setVisibilities(response.data);
      }
    },
  });

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/library/news",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        // console.log("item", response);
        // setVisibilities(response.data);
        if (response.data.length > 0) {
          formik.setFieldValue("id", response.data[0].id);
          formik.setFieldValue("title", response.data[0].title);
          formik.setFieldValue("description", response.data[0].description);
          if (response.data[0].image) {
            setResult(
              `${process.env.REACT_APP_CDN_URL}/canalPedagogico/libraryNews/${response.data[0].image}`
            );
          }
        } else {
          navigate("/biblioteca/news/add");
        }
      }
    },
  });

  const handleDelete = (itemId) => {
    const confirm = window.confirm("Deseja apagar esse item?");

    if (confirm) {
      deleteItem({
        data: {
          id: itemId,
        },
      });
    }
  };

  // console.log(formik.errors);
  // console.log(formik.isValid);

  useEffect(() => {
    if (action === "edit") {
      // console.log("id", id);
      loadItem({
        params: {
          id,
        },
      });
    }
    console.log("agora aqui");
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {(deleteItemInfo.loading ||
        loadItemInfo.loading ||
        editItemInfo.loading ||
        saveItemInfo.loading) && <FullScreenLoading />}
      {(deleteItemInfo.status ||
        loadItemInfo.status ||
        editItemInfo.status ||
        saveItemInfo.status) && <Error errorCode={401} />}
      <Accessibility />
      <Menu />
      <CoverUrlBox>
        {showModalCoverUrl && (
          <ModalCoverUrl>
            <CancelButton
              text="X"
              onClick={() => setShowModalCoverUrl(false)}
            />
            <ModalCoverUrlContent>
              <ReactCrop
                src={file}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
              />
              <ConfirmButton text="Cortar" type="button" onClick={handleCrop} />
              <CoverUrlCropped
                src={
                  checkBase64(result)
                    ? result
                    : process.env.REACT_APP_CDN_URL +
                      "/canalPedagogico/libraryNews/" +
                      result
                }
                alt="Nova Capa"
              />
              <ConfirmButton
                text="Salvar"
                type="button"
                disabled={!uploaded}
                onClick={handleUpdateCover}
              />
            </ModalCoverUrlContent>
          </ModalCoverUrl>
        )}
      </CoverUrlBox>
      <ContentBox>
        <Title>Novidades</Title>
        <Form onSubmit={formik.handleSubmit}>
          {action === "edit" && (
            <DeleteButton text="Apagar" onClick={() => handleDelete(id)} />
          )}
          <Item>
            <AttachPlaceholder
              onChange={(file) => handleAttach(file)}
              src={result}
            />
          </Item>
          <Item>
            <InputText
              placeholder="Título"
              columns={90}
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              error={
                formik.touched.title &&
                formik.errors.title &&
                formik.errors.title
              }
            />
          </Item>
          <Item>
            <InputTextArea
              placeholder="Descrição"
              columns={90}
              cols={60}
              rows={7}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description &&
                formik.errors.description &&
                formik.errors.description
              }
            />
          </Item>
          <Controls>
            <CancelButton text="Cancelar" />
            <ConfirmButton
              text="Salvar"
              type="submit"
              disabled={!formik.isValid}
            />
          </Controls>
        </Form>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default LibraryNewsForm;
