import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const ListContent = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
`;

export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  cursor: ${(props) => props.disablePointer ? css`auto` : css`pointer`};
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
  div{
    display: ${(props) => props.visible ? css`flex` : css`none`};
    width: 100%;
    flex-direction: row;
    align-items:center;
    justify-content:space-between;
    padding: 0.8rem;
  }
  .buttonList{
    display: ${(props) => props.visible ? css`flex` : css`none`};
    min-width: 21vw;
    width: 21vw;
    flex-direction: row;
    align-items:center;
    justify-content:space-between;
    padding: 0.8rem;
  }
`;

export const Table = styled.table`
  display: ${(props) => props.visible ? css`block` : css`none`};
  width: 100%;
  margin: 2rem 0rem;
  border-collapse: collapse;
  tr{
    border: 1px solid black;
  }
  th{
    text-align: center;
    border: 1px solid black;
  }
  td{
    padding: 0rem 0.75rem;
    text-align: center;
    border: 1px solid black;
  }
  .cpfClass {
    min-width: 14rem;
  }
  .classeClass{
    min-width: 14rem;
  }
`;