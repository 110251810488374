import React, { useState } from "react";
import ConfirmButton from "../ConfirmButton";
import { Box, TermsContainer, TermsDescription } from "./styles";

const Terms = () => {
  const [showPolicy, setShowPolicy] = useState(() => {
    const storageTerms = sessionStorage.getItem(
      `[@${process.env.REACT_APP_NAME}Terms]`
    );

    return storageTerms ? false : true;
  });
  const handlePolicy = () => {
    //salvar no local ou session storage
    sessionStorage.setItem(`[@${process.env.REACT_APP_NAME}Terms]`, true);
    setShowPolicy(false);
  };

  return (
    showPolicy && (
      <Box>
        <TermsContainer>
          <TermsDescription>
            Nós coletamos informações necessárias e usamos outras tecnologias
            para melhorar sua experiência neste site de acordo com nossa{" "}
            <a href="/politicaetermos">
              Política de Privacidade e Termo de Uso
            </a>
            . Ao utilizar nossos serviços, você concorda com estas condições.
          </TermsDescription>
          <ConfirmButton text="CONCORDO" type="button" onClick={handlePolicy} />
        </TermsContainer>
      </Box>
    )
  );
};

export default Terms;
