import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Container, Title, Description, Teacher, Meetings, BoxButtons, Message, ListItem, Legend, IconBox, Justify, Documents } from "./styles";
import { format, isAfter, isBefore } from "date-fns";
import {
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";
import ptBR from "date-fns/esm/locale/pt-BR/";
import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images } from "../../../constants";
import Banner from "../../../components/UI/Banner";
import Visibility from "../../../components/UI/Visibility";
import ellipsis from "../../../utils/ellipsis";

import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";
import ReadMoreButton from "../../../components/UI/ReadMoreButton";
import Modal from "../../../components/UI/Modal"
// import * as XLSX from "xlsx";
import { icons } from "../../../constants";

const CoursesView = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const navigate = useNavigate();

  const [documento, setDocumento] = useState({});
  // const [listInfoSubscribe, setListInfoSubscribe] = useState([]);
  const [activities, setActivities] = useState([]);
  const [listUserActivities, setListUserActivities] = useState([]);
  const [listJustify, setListJustify] = useState([]);

  const [subscriptionText, setSubscriptionText] = useState("");
  const [workload, setWorkload] = useState(0);
  const [abonadas, setAbonadas] = useState(0);

  const [ModalOpenAlert, setModalOpenAlert] = useState(false);
  const [ModalOpenUnsubscribe, setModalOpenUnsubscribe] = useState(false);

  const [ModalOpenAlertUnsubscribe, setModalOpenAlertUnsubscribe] = useState(false);
  const [unsubscriptionStatusMessage, setModalOpenUnsubscriptionStatusMessage] = useState("");

  const [activitiesRights, setActivitiesRights] = useState({tutor:false,editor:false,inscrito:false,load:false});
  const [activityOpen, setActivityOpen] = useState(false);

  const handleModalAlertUnsubscribe = () => {
    setModalOpenAlertUnsubscribe((oldModalOpenAlertUnsubscribe) => !oldModalOpenAlertUnsubscribe);
  };

  const handleAlertUnsubscribeOk = () => {
    if(unsubscriptionStatusMessage==="Inscrição cancelada com sucesso!"){
      navigate("/formacoes");
    }
    else{
      handleModalAlertUnsubscribe();
    }
  };

  const handleModalAlert = () => {
    setModalOpenAlert((oldModalOpenAlert) => !oldModalOpenAlert);
  };

  const handleModalUnsubscribe = () => {
    // console.log("ModalOpenUnsubscribe",ModalOpenUnsubscribe);
    setModalOpenUnsubscribe((oldModalOpenUnsubscribe) => !oldModalOpenUnsubscribe);
  };

  const isEditor = () => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === "0e3b686a-9b75-4de0-a2b7-28ec578e8119"
    );
    return editor > -1 ? true : false;
  };

  const isDeped = () => {
    return user?.profiles[0]?.idProfile === "b56d58fe-2377-4428-854d-5f20670fb166";
  };

  const [loadDocument, loadDocumentInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if(response.data.length>0){
          console.log("Documento formação", response.data[0]);
          setDocumento(response.data[0]);
        }
        else{
          if(user){
            console.log("não carregou dados da formação");
            navigate("/formacoes");
          }
          else{
            navigate(`/login/redirect/*formacoes*${id}`)
          }
        }
      }
    },
  });

  const [loadRights, loadRightsInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/activities/coursesByUser",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("course by user", response.data[0]);
        if(response.data.length>0){
          setActivitiesRights({tutor:response.data[0].isTutor,editor:response.data[0].isEditor,inscrito:response.data[0].isRegistered,load:true});
        }
      }
    },
    onError:(error)=>{
      console.log("error", error);
    },
  });

  const [unsubscribe, UnsubscribeInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/subscription",
    method: "delete",
    onCompleted: (response) => {
      handleModalUnsubscribe();
      handleModalAlertUnsubscribe();
      if (!response.error) {
        setModalOpenUnsubscriptionStatusMessage("Inscrição cancelada com sucesso! Verifique a confirmação no seu e-mail!")
        // alert("Desinscrito com sucesso");
        // window.location.reload();
        // console.log("deletou", response);
      }
      else{
        setModalOpenUnsubscriptionStatusMessage("Erro ao cancelar a inscrição, tente novamente!")
      }
    },
  });

  // const [listSubscribe, ListSubscribeInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "canalPedagogico/course/subscription",
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log("listSubscribe", response.data);
  //       setListInfoSubscribe(response.data);
  //     }
  //   },
  //   onError:(error)=>{
  //     console.log("error", error);
  //   },
  // });

  const [listActivities, ListActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/activities",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listActivities", response.data);
        setActivities(response.data);
      }
    },
    onError:(error)=>{
      console.log("error", error);
    },
  });

  const [loadListUserActivities, LoadListUserActivitiesInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/course",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listUserActivities", response.data);
        let tempList = [];
        let tempWork = 0;
        let tempAbonadas = 0;
        activities.forEach((activity)=>{
          let userActivity = response.data.find((userAct)=>{
            return userAct.idcontent_activity === activity.idContentActivity;
          });
          if(userActivity?.hours_taken===1){
            tempWork+=activity.activityWorkload;
          }
          if(userActivity?.hours_taken===2){
            tempAbonadas+=activity.activityWorkload;
          }
          tempList.push({
            ...activity,
            idactivities_justifications:userActivity?.idactivities_justifications,
            hours_taken:userActivity?.hours_taken,
          })
        });
        console.log("listUsrActivities",tempList);
        setAbonadas(tempAbonadas);
        setWorkload(tempWork);
        setListUserActivities(tempList);
      }
    },
    onError: (error)=>{ 
      console.log("listUserActivities error", error);
      // document.location.reload(false);
    },
  });

  const [getListJustify, getListJustifyInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/course/userActivities/activitiesJustifications",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("listJustify", response.data);
        setListJustify(response.data);
      }
    },
    onError: (error)=>{ 
      // document.location.reload(false);
    },
  });

  const handleUnsubscribe = () => {
      // console.log("desinscreveu", id);
      if(documento.idUserSubscription){
        unsubscribe({
          data: {
            id:documento.idUserSubscription,
          },
        });
      }
  };

  const handleSubscription = () => {
    if(subscriptionText === "Fazer inscrição"){
      navigate(`/inscricaoformacoes/${id}`);  
    }
    if(subscriptionText === "Inscrição indisponível"){
      handleModalAlert();
    }
    if(subscriptionText === "Excluir inscrição"){
      if(documento.idUserSubscription){
        handleModalUnsubscribe();
      }
    }
  };

  useEffect(() => {
    if(activitiesRights.load){
      if (activitiesRights.inscrito||activitiesRights.tutor||activitiesRights.editor){      
        console.log("carregar atividades");
        listActivities({
          params: {
            idContent:id,
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [activitiesRights]);

  useEffect(() => {
    if(activities.length>0){
      console.log("carregou atividades");
      loadListUserActivities({
        params:{
          idContent:id,
          idUserSubscription:documento.idUserSubscription,
        }
      });
    }
    // eslint-disable-next-line
  }, [activities,documento]);

  useEffect(() => {
    loadDocument({
      params: {
        id,
      },
    });
    if(user){
      getListJustify();
      // if (isEditor()||isDeped()){
      //   listSubscribe({
      //     params: {
      //       id,
      //     },
      //   });
      // }
      loadRights({
        params:{
          id,
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(documento.subscriptionEnabled){
      if(!(isAfter(new Date(), new Date(parseInt(documento.subscriptionEnd)))) && !(isBefore(new Date(), new Date(parseInt(documento.subscriptionBegin))))){
        setSubscriptionText("Fazer inscrição");
      }
      else{
        setSubscriptionText("Inscrição indisponível");  
      }
    }
    else{
      setSubscriptionText("Inscrição indisponível");
      if(documento.idUserSubscription){
        if(isAfter(new Date(), new Date(parseInt(documento.subscriptionEnd)))){
          setSubscriptionText("Inscrito");
        }
        else{
          setSubscriptionText("Excluir inscrição");
        }
      }
    }
  }, [documento]);

  const getDescriptionJustify = (id) => {
    return listJustify.find((justify)=>{return justify.idactivities_justifications===id})?.description;
  };

  // const handleDownload = () => {
  //   let maxWidth = [
  //     5,
  //     10,
  //     15,
  //     10,
  //     10,
  //     10
  //   ]

  //   listInfoSubscribe.map((sub,index)=>{
  //     if(sub.RGF?.length>maxWidth[0])maxWidth[0]=sub.RGF.length;
  //     if(sub.Nome?.length>maxWidth[1])maxWidth[1]=sub.Nome.length;
  //     if(sub.CPF?.length>maxWidth[2])maxWidth[2]=sub.CPF.length;
  //     if(sub.email?.length>maxWidth[3])maxWidth[3]=sub.email.length;
  //     if(sub.Escola?.length>maxWidth[4])maxWidth[4]=sub.Escola.length;
  //     if(sub.Classe?.length>maxWidth[5])maxWidth[5]=sub.Classe.length;
  //   });

  //   // create workbook and worksheet
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.table_to_sheet(document.getElementById("tableCourses"));
  //   maxWidth.forEach((width)=>{worksheet['!cols'].push({ wch: width+2 })});
  //   console.log(worksheet);

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  //   XLSX.writeFile(workbook, `${documento.title.trim()}.xlsx`, { compression: true });
  // };

  return (
    <Container>
      {(loadDocumentInfo.loading || 
       ListActivitiesInfo.loading ||
       UnsubscribeInfo.loading ||
      //  ListSubscribeInfo.loading ||
       LoadListUserActivitiesInfo.loading ||
       getListJustifyInfo.loading ||
       loadRightsInfo.loading) && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconDocumentos}
        url={`/formacoes/editar/${documento?.id}`}
        editorId={"0e3b686a-9b75-4de0-a2b7-28ec578e8119"}
      >
        {documento.title && ellipsis(documento.title, 20)}
      </Banner>
      <ContentBox>
        <Title>{documento.title}</Title>
        <Description>{documento.description}</Description>
        <Teacher>
          <p>
            <b>Formador: </b>
            {documento.teacher}{" "}
            {documento.teacherContact && `(${documento.teacherContact})`}
          </p>
          {(isEditor()||isDeped()) && <p>{documento.tutor && <><b>Tutor da formação:</b> {documento.tutor}</>}</p>}
        </Teacher>
        <Documents>
          {documento.documents?.map((d, n) => (
            <span key={n}>
              <a
                href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/course/${d.url}`}
                target="_blank"
                rel="noreferrer"
              >
                {d.originalName || `Documento ${n + 1}`}
              </a>
            </span>
          ))}
        </Documents>
        <Meetings>
          <b>
            {documento.meetings?.length}
            {documento.meetings?.length > 0 && documento.meetings?.length === 1
              ? " Encontro:"
              : " Encontros:"}
          </b>
          {documento.meetings?.map((e, i) => (
            <div key={i}>
              {'\u25CF'}<span>
                {
                (e.place.indexOf("http") !== -1) ? (<Link to={e.place}> {e.place}</Link>) : ` ${e.place}`} - {" "}
                {format(parseInt(e.eventDate), "dd/MM/yyyy", {
                  locale: ptBR,
                })}
                {" das "}
                {format(parseInt(e.eventBegin), "HH'h'mm", {
                  locale: ptBR,
                })}
                {" às "}
                {format(parseInt(e.eventEnd), "HH'h'mm", {
                  locale: ptBR,
                })}
              </span>
            </div>
          ))}
        </Meetings>
        <div>
          Periodo de Incrição:{" "}
                {documento?.subscriptionBegin && format(new Date(parseInt(documento.subscriptionBegin)), "dd/MM/yyyy", {
                  locale: ptBR,
                })}
                {" - "}
                {documento?.subscriptionEnd && format(new Date(parseInt(documento.subscriptionEnd)), "dd/MM/yyyy", {
                  locale: ptBR,
                })}
        </div>

        <div>
          {documento.hasSubscription && <span>Vagas: {`${(documento.subscriptionsLimit-documento.totalSubscriptions)<0?0:documento.subscriptionsLimit-documento.totalSubscriptions}/${documento.subscriptionsLimit} atualizado em: ${documento.dateSearch}`}</span>}
        </div>

        {/* {(isEditor()||isDeped()) && <Table id="tableCourses">
            <thead>
              <tr><th colSpan="6">{documento.title}</th></tr>
              <tr>
                <th>RGF</th>
                <th>Nome</th>
                <th className='cpfClass'>CPF</th>
                <th className='emailClass'>E-mail</th>
                <th>Escola</th>
                <th className='classeClass'>Turma</th>
              </tr>
            </thead>
            <tbody>
              {listInfoSubscribe.map((sub,index)=>(<tr key={index}>
                <td>{sub.RGF}</td>
                <td>{sub.Nome}</td>
                <td>{sub.CPF}</td>
                <td>{sub.email && sub.email}</td>
                <td>{sub.Escola}</td>
                <td>{sub.Classe}</td>
              </tr>))}
            </tbody>
          </Table>}

          {(isEditor()||isDeped()) && <ReadMoreButton onClick={handleDownload} text="Download xlsx" />} */}

          {/* Area de atividades */}

          {<>
            {activitiesRights.inscrito && activities.length>0 && <>
              <ListItem 
                visible={true} 
                border={true}
                roundborder={true} 
                row={true}
                selected={activityOpen}
                onClick={()=>{setActivityOpen(!activityOpen)}}>
                Atividades
                {activityOpen?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}
              </ListItem>

              <ListItem 
                    marTop={0.01} 
                    row = {true}
                    justify={"space-between"}
                    reduct={5}
                    padW={2}
                    visible={activityOpen}>
                  <ListItem 
                      marTop={0.01} 
                      justify={"flex-start"}
                      align={"flex-start"}
                      reduct={31}
                      visible={activityOpen}>
                    <p>Carga Horária Total: {documento.courseWorkload} Horas</p>
                    <p>Carga Horaria Realizada: {workload} Horas</p>
                    <p>Abonadas (HTPF): {abonadas} Horas</p>
                  </ListItem>
                  <ListItem reduct={70}
                            row={true}
                            marTop={0.01} 
                            justify={"space-between"}
                            visible={true}>
                      <Legend><img src={icons.atividade_feito} alt="Concluído"/>Concluído</Legend>
                      <Legend><img src={icons.atividade_falta} alt="Não concluído"/>Não concluído</Legend>
                      <Legend><img src={icons.atividade_justificado} alt="Justificado"/>Justificado</Legend>
                  </ListItem>
              </ListItem>

              {listUserActivities.map((activity)=>(<>
                {(isAfter(new Date(parseInt(activity.activityBegin)),new Date())===false) && <ListItem visible={activityOpen} reduct={5} row={true} justify={"flex-start"} border={true} >
                  <ListItem visible={activityOpen} align={"flex-start"} padW={2} reduct={21}>
                    {activity.module!==null && <div>{activity.module===0?"Modulo único":`Modulo: ${activity.module}` }<br/></div>}
                    <div>{activity.title}</div>
                    <div>{activity.description}</div>
                    <div>Carga Horária: {activity.activityWorkload} Horas</div>
                    <div>Periodo:
                      {format(new Date(parseInt(activity.activityBegin)), "dd/MM/yyyy", {locale: ptBR,})}
                      -
                      {format(new Date(parseInt(activity.activityEnd)), "dd/MM/yyyy", {locale: ptBR,})}
                    </div>
                  </ListItem>
                  <ListItem visible={activityOpen} reduct={80} padV={0}>
                    {activity.hours_taken===0 && <IconBox><img src={icons.atividade_falta} alt=""/></IconBox>}
                    {activity.hours_taken===1 && <IconBox><img src={icons.atividade_feito} alt=""/></IconBox>}
                    {activity.hours_taken===2 && <IconBox><img src={icons.atividade_justificado} alt=""/></IconBox>}
                    {activity.hours_taken===2 && <Justify>{getDescriptionJustify(activity.idactivities_justifications)}</Justify>}
                  </ListItem>
                </ListItem>}
              </>))}

              {/* {activities.length>0 && <ListItem visible={activityOpen} row={true} justify={"flex-end"}>
                <Legend><img src={icons.atividade_feito}/>Presente</Legend>
                <Legend><img src={icons.atividade_falta}/>Falta</Legend>
                <Legend><img src={icons.atividade_justificado}/>Justificada</Legend>
              </ListItem>} */}
              
            </>}
          </>}

          {/* Area de atividades fim */}
        
        {isEditor() && documento.visibilities?.length>0 && (
          <Visibility visibility={documento.visibilities} align="center" />
        )}
        
        <BoxButtons>
          {documento.hasSubscription && (activitiesRights.tutor || activitiesRights.editor) && <ReadMoreButton onClick={()=>{navigate(`/coursemanager/${id}`)}} text="Gerenciamento do Curso" />}

          <CancelButton
            text="Voltar"
            onClick={() => {
              navigate(-1);
           }}
          />

          {(user && !(isEditor()) && documento.hasSubscription) && <ReadMoreButton onClick={handleSubscription} text={subscriptionText} alert={subscriptionText==="Inscrição indisponível"}/>}
        </BoxButtons>
        <Modal  open={ModalOpenAlert} close={handleModalAlert} buttonClose = {false} width={50} height={15}>
          <Message>
            <div>{documento.subscriptionStatusMessage}</div>
            <ReadMoreButton onClick={handleModalAlert} text="Ok" />
          </Message>
        </Modal>
        <Modal  open={ModalOpenUnsubscribe} close={handleModalUnsubscribe} buttonClose = {false} width={100} height={15}>
          <Message>
            <div>Deseja mesmo cancelar sua inscrição em: {documento.title} ?</div>
            <div>
              <ReadMoreButton text="SIM" onClick={handleUnsubscribe}/>
              <ReadMoreButton text="NÃO" onClick={handleModalUnsubscribe}/>
            </div>
          </Message>
        </Modal>
        <Modal  open={ModalOpenAlertUnsubscribe} close={handleModalAlertUnsubscribe} buttonClose = {false} width={50} height={15}>
          <Message>
            <div>{unsubscriptionStatusMessage}</div>
            <ReadMoreButton onClick={handleAlertUnsubscribeOk} text="Ok" />
          </Message>
        </Modal>
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesView;
