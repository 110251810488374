import React from "react";
import { Container, Text, VisibilityItem } from "./styles";

const Visibility = ({
  visibility=[],
  align="flex-start"
}) => {

  // useEffect(()=>{console.log(visibility)},[visibility]);

  return (
    <Container align={align}>
      <Text>Visibilidade: </Text>
      {( (visibility.length===0) || (visibility[0]?.idvisibility===null) )?
        <VisibilityItem>Pública</VisibilityItem>
       :
        visibility.map((item)=>
          <VisibilityItem key={item.idvisibility}>{item?.descricao}</VisibilityItem>
        )
      }
    </Container>
  );
};

export default Visibility;
