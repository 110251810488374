import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Title,
  Description,
  File,
  Link,
  // Tag,
  // TagsContainer,
  Text,
} from "./styles";

// import { MdInsertDriveFile } from "react-icons/md";

import Accessibility from "../../../components/UI/Accessibility";
import Menu from "../../../components/UI/Menu";
import ContentBox from "../../../components/UI/ContentBox";
import Footer from "../../../components/UI/Footer";
import FullScreenLoading from "../../../components/UI/FullScreenLoading";
import { images, icons } from "../../../constants";
import Banner from "../../../components/UI/Banner";

import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";

import CancelButton from "../../../components/UI/CancelButton";
import Visibility from "../../../components/UI/Visibility";
import ellipsis from "../../../utils/ellipsis";

const DocumentosView = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const navigate = useNavigate();

  const [documento, setDocumento] = useState({
    document: [],
    keywords: [],
    visibilities: [],
    error: true,
  });

  const isEditor = (category) => {
    let editor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === category
    );
    return editor > -1 ? true : false;
  };

  const [loadDocument, loadDocumentInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/documents/",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        if (response.data.length > 0) {
          setDocumento({ ...response.data[0] });
        } else {
          if(user){
            navigate("/documentos")
          }
          else{
            navigate(`/login/redirect/*documentos*${id}`)
          }
        }
        console.log("DOC", response);
      }
    },
  });

  useEffect(() => {
    loadDocument({
      params: {
        idDoc: id,
      },
    });
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loadDocumentInfo.loading && <FullScreenLoading />}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconDocumentos}
        url={`/documentos/editar/${documento?.id}`}
        editorId={documento?.type}
      >
        {documento.title && ellipsis(documento.title, 20)}
      </Banner>
      <ContentBox>
        <Title>{documento?.title}</Title>
        <Description>{documento?.description}</Description>

        {documento?.type === "3c0e3d5b-3456-4e3c-a89f-9cb3cf41cc82" && 
          <>
            <Text>{documento?.anoCalendario} - {documento?.bim === "5" ? "Edição Especial"  : `${documento?.bim} ºBimestre`}</Text>
            <Text>ciclo: {documento?.ano?.description}</Text>
          </>
        }

        {documento?.document.length > 0 && (
          <File>
            <Link
              href={`${process.env.REACT_APP_CDN_URL}canalPedagogico/documents/${documento?.document[0]?.url}`}
              target="_blank"
            >
              {/* <MdInsertDriveFile />
              {documento?.document[0]?.originalName} */}
              <img src={icons.btn_download} alt=""/>
            </Link>
          </File>
        )}
        {/* {documento.keywords.length > 0 && (
          <TagsContainer>
            {documento?.keywords.map((key, index) => (
              <Tag key={index}>{key}</Tag>
            ))}
          </TagsContainer>
        )} */}
        {isEditor(documento?.type) && (
          <Visibility visibility={documento?.visibilities} align={"center"} />
        )}

        <CancelButton
          text="Voltar"
          onClick={() => {
            navigate(-1);
          }}
        />
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default DocumentosView;
