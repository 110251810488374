import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  align-self: center;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  animation: rotate 1s linear infinite;
`;
