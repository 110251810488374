import styled, { css } from "styled-components";

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 0.4rem;
  cursor: pointer;
  user-select: none;
  font: var(--body);
  color: ${(props) => props.alterColor ? css`var(--secondary)` : css`var(--primary)`};

  .checkmark {
    height: calc(var(--size) * 2);
    width: calc(var(--size) * 2);
    background-color: var(--gray);
    border-radius: calc(var(--size) * 0.4);
    transition: all 0.2s ease;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    &:after {
      content: "";
      display: none;
      width: calc(var(--size) * 0.5);
      height: calc(var(--size) * 1);
      border: solid var(--secondary);
      border-width: 0 calc(var(--size) * 0.3) calc(var(--size) * 0.3) 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-bottom: calc(var(--size) * 0.4);
    }
  }

  &:hover {
    .checkmark {
      background-color: var(--purple);
    }
  }

  > input {
    display: none;
  }

  input:checked ~ .checkmark {
    background-color: var(--purple);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
`;
