import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  flex-wrap:nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Search = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  margin-top: -2.5rem;
  position: relative;
  width: 100%;
`;

export const Main = styled.div`
 display: flex;
 flex-direction:column;
 align-items:flex-start;
 justify-content:flex-start;
 flex-wrap:nowrap;
 width: 100%;

 font: var(--body);

 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;

export const Title = styled.div`
 display: flex;
 flex-direction:row;
 align-items:flex-start;
 justify-content:flex-start;
 flex-wrap:wrap;
 margin: 2rem 0rem 1rem 3rem;
 cursor: pointer;

 font: var(--title);
 span{
  width: 3rem;
 }
 
 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;

export const List = styled.div`
 width: 100%;
 display: flex;
 flex-direction:column;
 justify-content:center;
 flex-wrap:nowrap;

 /* background-color: var(--gray); */
 /* background-color: var(--softGray); */

 @media (max-width: 600px) {}
 @media (max-width: 768px) {}
 @media (max-width: 992px) {}
 @media (max-width: 1200px) {}
`;

export const Loading = styled.div`
  width:3rem;
  margin-left: 0.8rem;
`;

export const LoadingMore = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  span{
    width:3rem;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  flex-wrap: nowrap;
  line-height: 3rem;
  margin-top: 2rem;
  font: var(--body);
`;

export const Icon = styled.img`
  display: flex;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 2.3rem;
  margin: 0 1rem 0 2rem;
  cursor: pointer;
`;