import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  flex-wrap:nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  flex-wrap: nowrap;
  line-height: 3rem;
  margin-top: 2rem;
  font: var(--body);
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0 0 0 0;
  border-radius: 1rem;
`;

export const ListItem = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 1rem 0 0 0;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: 10rem;
`;

export const Unidade = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0rem 0rem 0rem;

  font: var(--body);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const UnidadeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0.5rem 1rem;
  padding: 0.3rem;
  width: 22rem;
  text-align: center;
  border: ${(props) =>
    props.selected ? css`none` : css`solid 0.1rem var(--primary)`};
  border-radius: 1.5rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};

  @media (max-width: 412px) {
    width: 90%;
  }

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const ListVazio = styled.div`
  display: ${(props)=>(props.visible?css`flex`:css`none`)};
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  margin: 0.5rem 4rem 2rem 4rem;
  /* background-color: var(--softGray); */
  background-color: var(--secondary);
  border-radius: 1rem;
  padding: 1.2rem 2rem 1.2rem 2rem;
  width: 95%;

  @media (max-width: 600px) {
   margin: 0.5rem 3rem 3rem 3rem;
  }
  @media (max-width: 768px) {}
  @media (max-width: 992px) {}
  @media (max-width: 1300px) {
   width: 88%;
  }
`;

export const TableContent = styled.div`
  display: ${(props)=>(props.visible?css`flex`:css`none`)};
  align-items: center;
  justify-content: center;
  width: 100%;
  table {
    width: 97%;
    border-collapse: collapse;
    tr {
      th {
        text-align: center;
        border:1px solid black;
      }
      td {
        text-align: center;
        border:1px solid black;
        padding: 1rem;
        margin: 0;
        div{
          display: flex;
          flex-direction:column;
          align-items:center;
          justify-content:center;
        }
      }
    }
  }

  font: var(--body);

  @media (max-width: 600px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 1200px) {
  }
`;

export const Separador = styled.div`
  display: ${(props)=>(props.visible?css`flex`:css`none`)};
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex-wrap: wrap;
  margin: 0.5rem 4rem 2rem 4rem;
  border: 0px;
  /* background-color: var(--softGray); */
  background-color: var(--secondary);
  padding: 1.2rem 2rem 1.2rem 2rem;
  width: 100%;

  @media (max-width: 600px) {
   margin: 0.5rem 3rem 3rem 3rem;
  }
  @media (max-width: 768px) {}
  @media (max-width: 992px) {}
  @media (max-width: 1300px) {
   width: 88%;
  }
`;