import React, { useState, useEffect } from "react";
import {
  Container,
  Avaliacao,
  AvaliacaoTitle,
  Plataforma,
  Orientacao,
  AvaliacaoList,
  AvaliacaoItem,
  AvaliacaoLink,
  MaterialList,
  Editar,
  OrientacaoItem,
  Link,
  LoadingMore
} from "./styles";
import Accessibility from "../../components/UI/Accessibility";
import { InView } from "react-intersection-observer";
import Menu from "../../components/UI/Menu";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import { format, isSameDay } from "date-fns";
import LoadingItem from "../../components/UI/LoadingItem";

import { MdInsertDriveFile } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import Banner from "../../components/UI/Banner";
import { images, icons } from "../../constants";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
// import FullScreenLoading from "../../components/UI/FullScreenLoading";

import Visibility from "../../components/UI/Visibility";

const Avaliacoes = () => {
  const { user } = useAuth();

  const [editor, setEditor] = useState(false);

  const [avaliacoes, setavaliacoes] = useState([]);

  useEffect(() => {
    loadItem({
      params: {
        initial: avaliacoes.length,
        limit: 10,
        //term:"",
      },
    });
    // loadCategories({
    //   params: {
    //     id: "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3",
    //   },
    // });

    // setavaliacoes([
    //   {
    //     id: 0,
    //     title:'Sondagem',
    //     descricao:'texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto ',
    //     dataAplicacao:{
    //       start: new Date("05/15/2022"),
    //       end: new Date("05/20/2022")
    //     },
    //     orientacoes:'http://www.google.com.br', //url
    //     material:[
    //       {filename:'google 01',url:'http://www.google.com.br'},
    //       {filename:'google 02',url:'http://www.google.com.br'}
    //     ], //lista de url or null
    //     plataforma: null, //url or null
    //     resultado: null, //url or null
    //   },
    //   {
    //     id: 1,
    //     title:'E-aprendi',
    //     descricao:'texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto ',
    //     dataAplicacao:{
    //       start: new Date("05/15/2022"),
    //       end: new Date("05/20/2022")
    //     },
    //     orientacoes:'http://www.google.com.br', //url
    //     material:[], //lista de url
    //     plataforma: 'https://www.simulados.se-pmmc.com.br/', //url or null
    //     resultado: 'https://www.simulados.se-pmmc.com.br/', //url or null
    //   },
    //   {
    //     id: 2,
    //     title:'Sondagem 02',
    //     descricao:'texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto texto ',
    //     dataAplicacao:{
    //       start: new Date("05/22/2022"),
    //       end: new Date("05/22/2022")
    //     },
    //     orientacoes:'http://www.google.com.br', //url
    //     material:[
    //       {filename:'google 01',url:'http://www.google.com.br'},
    //       {filename:'google 02',url:'http://www.google.com.br'}
    //     ], //lista de url or []
    //     plataforma: null, //url or null
    //     resultado: null, //url or null
    //   }
    // ]);

    const isEditor = user?.profiles[0]?.editor?.findIndex(
      (editor) => editor.id === "0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"
    );
    setEditor(isEditor > -1 ? true : false);

    // console.log(loadItemInfo);
    // console.log(loadCategoriesInfo);
    //eslint-disable-next-line
  }, []);

  const [loadItem, loadItemInfo] = useApi({
    debounceDelay: 0,
    url: "canalPedagogico/evaluation/byTerm",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("load", response);
        setavaliacoes([...avaliacoes,...response.data]);
      }
    },
  });

  // const [loadCategories, loadCategoriesInfo] = useApi({
  //   debounceDelay: 0,
  //   url: "canalPedagogico/category",
  //   method: "get",
  //   onCompleted: (response) => {
  //     if (!response.error) {
  //       console.log("categorias",response);
  //     }
  //   },
  // });

  const loadMore = (InviewObj, entry) => {
    if (InviewObj) {
      // console.log("carregar mais");
      loadItem({
        params: {
          initial: avaliacoes.length,
          limit: 10,
          //term:"",
        },
      });
    }
  };

  return (
    <Container>
      {/* {loadItemInfo.loading && <FullScreenLoading />} */}
      <Accessibility />
      <Menu />
      <Banner
        icon={images.iconAvaliacao}
        url="/avaliacoes/add"
        editorId="0d8bd0d9-47f5-4bf6-9ddd-044861e242a3"
      >
        AVALIAÇÕES
      </Banner>
      <ContentBox>
     
        {avaliacoes.map((avaliacao, index) => (
            <Avaliacao key={index}>
              <AvaliacaoTitle>
                <Link href={`/avaliacoes/${avaliacao.id}`}>
                  {avaliacao.title}
                </Link>
                {editor && (
                  <Editar href={`/avaliacoes/editar/${avaliacao.id}`}>
                    <img src={icons.editBlack} alt="" />
                  </Editar>
                )}
              </AvaliacaoTitle>
              <AvaliacaoList>
                <AvaliacaoItem>
                  <BsDot />
                  Período da aplicação:{" "}
                  {format(
                    new Date(parseInt(avaliacao.begin)),
                    "dd/MM/yyyy"
                  )}{" "}
                  {!isSameDay(
                    new Date(parseInt(avaliacao.begin)),
                    new Date(parseInt(avaliacao.end))
                  ) &&
                    ` - ${format(
                      new Date(parseInt(avaliacao.end)),
                      "dd/MM/yyyy"
                    )}`}
                </AvaliacaoItem>
                {avaliacao.tips.length !== 0 && (
                  <Orientacao>
                    <OrientacaoItem>
                      <BsDot />
                      Orientação:
                    </OrientacaoItem>
                    <MaterialList>
                      {avaliacao.tips.map((tip, index)=>(
                        <AvaliacaoLink
                          href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/evaluation/${tip.url}`}
                          target="_blank"
                          key={index}
                        >
                          <MdInsertDriveFile />
                          <span>{tip.originalName}</span>
                        </AvaliacaoLink>))}
                    </MaterialList>
                    {/* <AvaliacaoLink
                      href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/evaluation/${avaliacao.tips[0].url}`}
                      target="_blank"
                    >
                      <MdInsertDriveFile />
                      <span>{avaliacao.tips[0].originalName}</span>
                    </AvaliacaoLink> */}
                  </Orientacao>
                )}
                {avaliacao.documents.length !== 0 && (
                  <>
                    <AvaliacaoItem>
                      <BsDot />
                      Materiais de Apoio
                    </AvaliacaoItem>
                    <MaterialList>
                      {avaliacao.documents.map((material, index) => (
                        <AvaliacaoLink
                          href={`${process.env.REACT_APP_CDN_URL}/canalPedagogico/evaluation/${material.url}`}
                          target="_blank"
                          key={index}
                        >
                          <MdInsertDriveFile />
                          {material.originalName}
                        </AvaliacaoLink>
                      ))}
                    </MaterialList>
                  </>
                )}
                {avaliacao.platform && (
                  <Plataforma>
                    <BsDot />
                    Plataforma:{" "}
                    {avaliacao.platform.indexOf("http") >= 0 ||
                    avaliacao.platform.indexOf("www") >= 0 ? (
                      <AvaliacaoLink href={avaliacao.platform} target="_blank">
                        {avaliacao.platform}
                      </AvaliacaoLink>
                    ) : (
                      <span>{avaliacao.platform}</span>
                    )}
                  </Plataforma>
                )}
                {avaliacao.resultado && (
                  <AvaliacaoLink href={avaliacao.resultado}>
                    <BsDot />
                    Resultado
                  </AvaliacaoLink>
                )}
              </AvaliacaoList>
              {editor && <Visibility visibility={avaliacao.visibilities} />}
            </Avaliacao>
          ))}
          {loadItemInfo.loading && (
            <LoadingMore>
              <span>
                <LoadingItem />
              </span>
            </LoadingMore>
          )}
          {avaliacoes.length > 0 ? (
              <InView
                as="div"
                onChange={(inView, entry) => loadMore(inView, entry)}
              ></InView>
          ) : (<div>Não há informações a serem exibidas. Faça login para ter acesso aos conteúdos.</div>)}

      </ContentBox>
      <Footer />
    </Container>
  );
};

export default Avaliacoes;
