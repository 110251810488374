import React from "react";
import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import {
  Container,
  // Img,
  LinkDoc,
  Text,
  Video,
} from "./styles";
import Banner from "../../components/UI/Banner";
import { images } from "../../constants";
import cidadeLeitora from "../../assets/docs/cidadeLeitora.pdf";
import instCidadeLeitora from "../../assets/videos/cidadeLeitora.mp4";

const ProjectReaderCity = () => {
  
  const openPadletCid = () => {
    window.open("https://padlet.com/cidadeleitora1/projeto-mogi-cidade-leitora-lhzi9bsbkbrhd2q0");
  };

  return (
  
    <Container>
      <Accessibility />
      <Menu />
      <Banner icon={images.icon_cidadeleitora}>MOGI CIDADE LEITORA</Banner>
      <ContentBox>

        {/* <Img src={images.logoCidadeLeitora}></Img> */}

            <Text>
              Neste projeto, pretende-se criar condições para que o prazer da leitura faça parte da vida de todos,
            não só durante as aulas e nas escolas, mas como um ato constituído por diversas ações, possibilitando
            a vivência de um panorama diversificado da leitura e da literatura em nossa cidade.
            </Text>
            

          <LinkDoc href={cidadeLeitora} target="_blank">* Conheça o projeto (Clique aqui)</LinkDoc>
          <LinkDoc onClick={openPadletCid}>* Fotos do projeto (Clique aqui)</LinkDoc>

          <Video controls>
            <source src={instCidadeLeitora} type="video/mp4" />
          </Video>

      </ContentBox>
      <Footer />
    </Container>
  );
};

export default ProjectReaderCity;