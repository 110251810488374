import React, { useRef } from "react";
import {
  Container,
  LogoSearch,
  Input,
  // Button
} from "./styles";
import { icons } from "../../../../constants";
import LoadingItem from "../../LoadingItem";
import { useTheme } from "../../../../hooks/useTheme";

const InputSearch = ({
  loading,
  placeholder = "O que você procura?",
  disabled = false,
  onClick = () => {},
  onChange = () => {},
  ...rest
}) => {
  const { themeFile } = useTheme();
  const inputRef = useRef(null);

  const handleFocus = () => {
    inputRef.current.focus();
  };

  return (
    <Container onClick={handleFocus}>
      <LogoSearch type="button">
        {loading ? (
          <LoadingItem />
        ) : (
          <img
            src={themeFile.COLORS.highContrast ? icons.lupaBlack : icons.lupa}
            alt="Busca"
          />
        )}
      </LogoSearch>
      <Input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        {...rest}
      />
      {/* <Button type="button" onClick={onClick}>
        Buscar!
      </Button> */}
    </Container>
  );
};

export default InputSearch;
