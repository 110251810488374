import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const Area = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
`;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  div {
    margin-right: 1rem;
  }
`;

export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction: row;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
`;

export const ItemCourse = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  align-items:center;
  justify-content:space-between;
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
  div{
    margin: 1rem;
  }
`;

export const ListInfo = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction: column;
  align-items:flex-start;
  justify-content:center;
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  padding: 1rem;
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
`;

export const ListButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-around;
  flex-wrap:nowrap;
  width: 100%;
  margin: 1rem 0 0 0;
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
`;