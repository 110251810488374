import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const NotifyProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationsView, setNotificationsView] = useState([]);
  const [notRead, setNotRead] = useState(0);

  return (
    <AuthContext.Provider
      value={{
        notifications,
        notificationsView,
        notRead,
        setNotRead,
        setNotifications,
        setNotificationsView,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useNotify = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("Must be used within a provider.");
  }

  return context;
};
